import { Component, Input, OnInit } from '@angular/core';

import { FlowSessionService } from '../../services/flow-session.service';
import { FlowComponent } from '../flow-components.model';

@Component({
  selector: 'flow-receipt-recipient',
  templateUrl: './receipt-recipient.component.html',
  styleUrls: ['./receipt-recipient.component.scss']
})
export class ReceiptRecipientComponent implements OnInit, FlowComponent<void, void> {

  @Input() config: void;

  email = '';

  constructor(private flowSession: FlowSessionService) { }

  ngOnInit() {
    this.grabEmailFromSessionData();
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

  private grabEmailFromSessionData() {
    const sessionData =
      this.flowSession.sessionData as { purchaseWithPayment: { payments: { payerEmail: string }[] }, contactInfo: { email: string } };

    if (sessionData.purchaseWithPayment.payments) {
      this.email = sessionData.purchaseWithPayment.payments[0].payerEmail;
    } else {
      this.email = sessionData.contactInfo.email;
    }
  }
}
