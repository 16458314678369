import { NgModule } from '@angular/core';

import { ParagraphComponent } from './paragraph.component';

export * from './paragraph.component';

@NgModule({
    declarations: [
        ParagraphComponent,
    ],
})
export class ParagraphModule { }
