import { NgModule } from '@angular/core';

import { EventStoryComponent } from './event-story.component';
import { PipesModule } from '@onecause/core';

export * from './event-story.component';

@NgModule({
    imports: [
        PipesModule
    ],
    declarations: [
        EventStoryComponent,
    ],
})
export class EventStoryModule { }
