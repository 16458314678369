import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TimerComponent } from './timer.component';

export * from './timer.component';

@NgModule({
  declarations: [
    TimerComponent
  ],
})
export class TimerModule { }
