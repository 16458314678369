import { NgModule } from '@angular/core';

import { ReceiptRecipientComponent } from './receipt-recipient.component';

export * from './receipt-recipient.component';

@NgModule({
    declarations: [
        ReceiptRecipientComponent,
    ],
})
export class ReceiptRecipientModule { }
