import { AfterViewInit, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren, Directive } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlowComponent, FlowComponentUtils } from '../../flow-components/flow-components.model';
import { ComponentDefinition } from '../../models/component.model';
import { PageExecutionError } from '../../models/flow-execution.model';
import { PageWithComponentList } from '../../models/page.model';
import { ElementService } from '../../services/element.service';
import { PageExecutionService } from '../../services/page-execution.service';
import { PrimaryColorService } from '../../services/primary-color.service';
import { CurrentStateService } from '../../services/current-state.service';

export class PageOutput {
    pageData: any;
}

/**
 * `PageOrch` implements common functionality for pages to load content and handle component data and page submission.
 * Any new page layout should implement this class.
 *
 * `loadPageContent` should set up the page layout and load all of the components into the proper positions.
 * `loadComponent` should be used by `loadPageContent()` to inject a component into the correct container.
 *  All containers into which components can be loaded must have the `#componentContainer` attribute to work with `loadComponent()`.
 */
@Directive()
export abstract class PageOrchDirective implements OnInit, AfterViewInit, OnDestroy {

    currentPage: PageWithComponentList;

    @ViewChildren('componentContainer') componentContainers: QueryList<ElementRef<HTMLDivElement>>;

    theme: string;

    private pageComponents: Map<string, FlowComponent> = new Map();
    private removedPageComponents: string[] = [];
    protected destroyTriggered = new Subject<void>();

    private submitting = false;

    private componentsToInject = new ReplaySubject<{
        component: ComponentDefinition,
        targetContainerClass: string,
        replaceComponent: boolean,
    }>(50);

    constructor(
        private elementService: ElementService,
        private pageExecutionService: PageExecutionService,
        private primaryColorService: PrimaryColorService,
        protected currentState: CurrentStateService,
    ) { }

    ngOnInit() {
        this.currentState.page.subscribe((page) => {
            this.currentPage = page;
            this.init();
            this.initializePage();
        });

        this.currentState.flow.pipe(takeUntil(this.destroyTriggered)).subscribe((flow) => {
            this.theme = flow.theme;
        });
    }

    ngAfterViewInit() {
        // Depending on the layout, the component containers may not be ready until after ngOnInit.
        // Because of this, hold onto the components to be injected and wait until the view is initialized
        // to ensure the component containers are ready.
        this.injectComponents();
    }

    ngOnDestroy() {
        this.destroyTriggered.next();
    }

    // init() can be used to execute code in ngOnInit
    protected abstract init(): void;

    protected abstract loadPageContent(page: PageWithComponentList): void;

    protected loadComponent(component: ComponentDefinition, targetContainerClass: string, replaceComponent?: boolean) {
        this.componentsToInject.next({ component, targetContainerClass, replaceComponent });
    }

    private injectComponents() {
        this.componentsToInject.subscribe((component) => {
            const element = this.elementService.createElementFromComponent(component.component);
            const targetContainer = this.getTargetContainer(component.targetContainerClass);
            if (targetContainer) {
                const childNodes = targetContainer.nativeElement.childNodes;
                if (component.replaceComponent && childNodes.length) {
                    targetContainer.nativeElement.replaceChild(element, childNodes[0]);
                }
                else {
                    targetContainer.nativeElement.appendChild(element);
                }
            } else {
                return;
            }

            if (!FlowComponentUtils.isFlowComponent(element)) {
                return;
            }
            this.pageComponents.set(component.component.componentID, element);
            const submitListener = element.listenForSubmit();
            if (submitListener) {
                submitListener.pipe(takeUntil(this.destroyTriggered)).subscribe(() => {
                    this.submitPage();
                });
            }
        });
    }

    protected unloadComponent(component: ComponentDefinition) {
        if (this.pageComponents.has(component.componentID)) {
            this.pageComponents.delete(component.componentID);
            this.removedPageComponents.push(component.componentID);
        }
    }

    private submitPage() {
        if (!this.isPageValid()) {
            this.touchComponents();
            return;
        }

        if (this.submitting) {
            return;
        }
        this.updateSubmittingStatus(true);

        const pageData = this.getPageData();
        const output = new PageOutput();
        output.pageData = pageData;
        this.pageExecutionService.submitPage(output);
    }

    private isPageValid(): boolean {
        let valid = true;
        this.pageComponents.forEach((component) => {
            if (!component.isValid()) {
                valid = false;
            }
        });
        return valid;
    }

    private getPageData(): any {
        const pageData = {};
        for (const [componentID, component] of this.pageComponents.entries()) {
            pageData[componentID] = component.getValue();
        }
        for (const componentID of this.removedPageComponents) {
            if (!this.pageComponents.has(componentID)) {
                pageData[componentID] = null;
            }
        }
        return pageData;
    }

    private passExecutionErrorToComponent(err: PageExecutionError) {
        this.updateSubmittingStatus(false);
        const component = this.pageComponents.get(err.componentID);
        component.handleExecutionError(err.error);
    }

    private touchComponents() {
        this.pageComponents.forEach((component) => {
            component.markAsTouched();
        });
    }

    private updateSubmittingStatus(submitting: boolean) {
        this.submitting = submitting;
        this.currentState.updateSubmittingPageStatus(submitting);
    }

    private initializePage() {
        this.loadPageContent(this.currentPage);
        this.primaryColorService.loadPrimaryColorStylesheet(this.currentPage.primaryColor);
        this.pageExecutionService.listenForPageExecutionError().pipe(takeUntil(this.destroyTriggered)).subscribe((err) => {
            this.passExecutionErrorToComponent(err);
        });
    }

    private getTargetContainer(targetContainerClass: string): ElementRef<HTMLElement> {
        return this.componentContainers.find((el) => {
            return el.nativeElement.classList.contains(targetContainerClass);
        });
    }
}
