import { Component, Input, OnInit } from '@angular/core';

import { PageLayout } from '../../models/page.model';
import { CurrentStateService } from '../../services/current-state.service';
import { FlowComponent } from '../flow-components.model';

export class ImageConfig {
  imageURL: string;
}

@Component({
  selector: 'flow-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})

export class ImageComponent implements FlowComponent, OnInit {
  @Input() config: ImageConfig;

  pageLayout: PageLayout;

  constructor(
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    this.currentState.page.subscribe((page) => {
      this.pageLayout = page.layout;
    });
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }
}
