import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CurrentStateService } from '../../services/current-state.service';
import { FlowComponent } from '../flow-components.model';


export class NextPageButtonConfig {
  text: string;
  stickyAfterScrolled: boolean;
}

@Component({
  selector: 'flow-next-page-button',
  templateUrl: './next-page-button.component.html',
  styleUrls: ['./next-page-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NextPageButtonComponent implements OnInit, FlowComponent<NextPageButtonConfig, void> {

  @Input() config: NextPageButtonConfig;
  @ViewChild('nextPageButtonContainer', { static: true }) nextPageButtonContainer: ElementRef<HTMLDivElement>;

  submitting: boolean;
  originalOffset: number;

  private submitPage = new Subject<void>();

  constructor(
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    if (this.config.text === '') {
      this.config.text = 'PROCEED';
    }

    this.currentState.isSubmittingPage().subscribe((submitting) => {
      this.submitting = submitting;
    });
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitPage.asObservable();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    // only allow sticky mobile two-column layouts
    if (!this.config.stickyAfterScrolled || !document.getElementsByClassName('page-orch__mobile-component-container').length) {
      return;
    }

    const element = this.nextPageButtonContainer.nativeElement;
    const elementOffset = element.offsetTop;
    const pageScrollPosition = window.pageYOffset;
    const stickyClass = 'next-page-button_sticky';
    const isSticky = element.classList.contains(stickyClass);

    if (pageScrollPosition >= elementOffset && !isSticky) {
      this.originalOffset = elementOffset;
      element.classList.add(stickyClass);
      element.parentElement.parentElement.setAttribute('style', 'height:' + element.offsetHeight + 'px');
    }
    else if (pageScrollPosition < this.originalOffset && isSticky) {
      element.classList.remove(stickyClass);
      element.parentElement.parentElement.setAttribute('style', 'height: auto');
    }
  }

  next() {
    this.submitPage.next();
  }
}
