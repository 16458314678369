import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { CoreCurrencyPipe, PipesModule, CountrySelectModule, StateSelectModule } from '@onecause/core';

import { CarouselComponent } from './carousel/carousel.component';
import { SharedDonationFormComponent } from './donation-form/donation-form.component';


@NgModule({
  declarations: [
    SharedDonationFormComponent,
    CarouselComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatProgressBarModule,
    MatCheckboxModule,
    PipesModule,
    MatSelectModule,
    CountrySelectModule,
    StateSelectModule
  ],
  providers: [
    CoreCurrencyPipe,
  ],
  exports: [
    SharedDonationFormComponent,
    CarouselComponent,
  ]
})
export class ModulesModule { }
