import { NgModule } from '@angular/core';

import { LogoWithTextComponent } from './logo-with-text.component';
import { CommonModule } from '@angular/common';

export * from './logo-with-text.component';

@NgModule({
    imports: [
        CommonModule,
    ],
})
export class LogoWithTextModule { }
