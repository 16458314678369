import { Component } from '@angular/core';

import { CurrentStateService } from '../../../services/current-state.service';
import { FlowComponentService } from '../../../services/flow-component.service';
import { PreviewService } from '../../../services/preview.service';
import { ProgressComponentDirective } from '../progress-component';

export class FundraiserProgressExternalData {
  goalAmount: number;
  totalDonated: number;

  constructor(goalAmount?: number, totalDonated?: number) {
    this.goalAmount = goalAmount;
    this.totalDonated = totalDonated;
  }
}

export class FundraiserProgressConfig {
  fundraiserID: string;
  showProgress: boolean;

  constructor(fundraiserID?: string, showProgress?: boolean) {
    this.fundraiserID = fundraiserID;
    this.showProgress = showProgress;
  }
}

@Component({
  selector: 'flow-fundraiser-progress',
  templateUrl: '../progress-component.html',
  styleUrls: ['../progress-component.scss']
})
export class FundraiserProgressComponent extends ProgressComponentDirective {

  constructor(
    private flowComponentService: FlowComponentService,
    currentState: CurrentStateService,
    private previewMode: PreviewService,
  ) {
    super(currentState, previewMode);
  }

  loadRaisedAmount() {
    if (this.previewMode.isPreviewMode()) {
      this.raisedAmount = 14500;
    } else {
      const orgID = this.identity.organizationID;
      const flowID = this.identity.flowID;
      const pageID = this.identity.pageID;
      const componentID = this.identity.componentID;
      this.flowComponentService.getExternalData<FundraiserProgressExternalData>(orgID, flowID, pageID, componentID)
        .subscribe((output) => {
          this.raisedAmount = output.data.totalDonated;
        });
    }
  }

  loadGoalAmount() {
    if (this.previewMode.isPreviewMode()) {
      this.goalAmount = 25000;
    } else {
      const orgID = this.identity.organizationID;
      const flowID = this.identity.flowID;
      const pageID = this.identity.pageID;
      const componentID = this.identity.componentID;
      this.flowComponentService.getExternalData<FundraiserProgressExternalData>(orgID, flowID, pageID, componentID)
        .subscribe((output) => {
           this.goalAmount = output.data.goalAmount;
        });
    }
  }

  getGoalAmount() {
    if (this.previewMode.isPreviewMode()) {
      return 25000;
    } else {
      return this.goalAmount;
    }
  }
}
