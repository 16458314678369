import { Component, Input, ViewEncapsulation, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentDefinition, ComponentPlacementTwoColumns } from '../../models/component.model';

export class Tab {
  id: string;
  name: string;
}

@Component({
  selector: 'flow-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TabsComponent implements OnInit {

  @Input() components: ComponentDefinition[] = [];
  @Input() tabSectionNumber: number;
  @Input() isMobile: boolean;
  @Output() updateComponent: EventEmitter<{
    tabSectionNumber: number,
    added: ComponentDefinition,
    removed: ComponentDefinition
  }> = new EventEmitter();

  tabs: Tab[] = [];
  activeTab: string;

  ngOnInit() {
    this.init();
  }

  setActiveTab(tab: Tab): void {
    const removedID = this.activeTab;
    this.activeTab = tab.id;
    this.updateComponent.emit({
      tabSectionNumber: this.tabSectionNumber,
      added: this.components.find(c => c.componentID === tab.id),
      removed: this.components.find(c => c.componentID === removedID)
    });
  }

  isTabActive(tab: any): boolean {
    return !!(tab.id === this.activeTab);
  }

  private init() {
    this.tabs = this.getTabs();

    if (this.tabs.length) {
      this.setActiveTab(this.tabs[0]);
    }
  }

  private getTabs(): Tab[] {
    return this.components.map(component => {
      const placement = this.getPlacement(component);
      const tabDisplayName = this.isMobile ? placement.mobile.tabDisplay.title : placement.desktop.tabDisplay.title;
      return { id: component.componentID, name: tabDisplayName };
    });
  }

  private getPlacement(component: ComponentDefinition): ComponentPlacementTwoColumns {
    return JSON.parse(component.position.placement) as ComponentPlacementTwoColumns;
  }
}
