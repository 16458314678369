import {
    PhoneNumberValidator,
    PhoneNumberFormatter,
    ZipCodeValidator,
    EmailValidator,
    SnackBarComponent,
    SnackBarConfig,
    SnackBarIcon,
} from '@onecause/core';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Observable } from 'rxjs';

import { CurrentStateService } from '../../services/current-state.service';
import { FlowComponent } from '../flow-components.model';

export class ContactInfoConfig {
    requireAddress: boolean;
}

export class ContactInfoOutput {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.streetAddress = '';
        this.city = '';
        this.state = '';
        this.zip = '';
    }
}

@Component({
    selector: 'flow-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements FlowComponent<ContactInfoConfig, void>, OnInit, OnDestroy {
    @Input() config: ContactInfoConfig;

    contactInfoForm: FormGroup;

    private destroyTriggered = new Subject<void>();
    private submitPage = new Subject<void>();

    constructor(
        private formBuilder: FormBuilder,
        private currentState: CurrentStateService,
        private snackbar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.contactInfoForm = this.buildForm();
        this.currentState.isSubmittingPage().subscribe((submitting) => {
            this.disableForm(submitting);
        });
    }

    ngOnDestroy() {
        this.destroyTriggered.next();
    }

    submit() {
        this.submitPage.next();
    }

    @Input()
    isValid = (): boolean => {
        return this.contactInfoForm.valid;
    }

    @Input()
    getValue = (): ContactInfoOutput => {
        const output = new ContactInfoOutput();
        for (const key of Object.keys(this.contactInfoForm.controls)) {
            const value = this.contactInfoForm.get(key).value;
            if (value != null) {
                output[key] = value;
            }
        }
        return output;
    }

    setValue(info: ContactInfoOutput): void {
        for (const key of Object.keys(info)) {
            this.contactInfoForm.get(key)?.setValue(info[key]);
        }
    }

    @Input()
    markAsTouched = () => {
        Object.keys(this.contactInfoForm.controls).forEach((key) => {
            this.contactInfoForm.get(key)?.markAsTouched();
        });
    }

    @Input()
    handleExecutionError = (error: any): void => {
        this.snackbar.openFromComponent(SnackBarComponent, new SnackBarConfig(error.message, SnackBarIcon.Error, false));
    }

    @Input()
    listenForSubmit = (): Observable<void> => {
        return this.submitPage.asObservable();
    }

    private buildForm(): FormGroup {
        const phoneNumber = this.formBuilder.control('', PhoneNumberValidator());
        PhoneNumberFormatter.initializePhoneFormatter(phoneNumber);

        const contactInfoForm = this.formBuilder.group({
            firstName: this.formBuilder.control('', Validators.required),
            lastName: this.formBuilder.control('', Validators.required),
            email: this.formBuilder.control('', [Validators.required, EmailValidator()]),
            phoneNumber: phoneNumber,
        });

        if (this.config.requireAddress) {
            contactInfoForm.addControl('streetAddress', this.formBuilder.control('', Validators.required));
            contactInfoForm.addControl('city', this.formBuilder.control('', Validators.required));
            contactInfoForm.addControl('state', this.formBuilder.control('', Validators.required));
            contactInfoForm.addControl('zip', this.formBuilder.control('', [Validators.required, ZipCodeValidator()]));
        }

        return contactInfoForm;
    }

    private disableForm(disable: boolean) {
        if (disable) {
            this.contactInfoForm.disable();
        } else {
            this.contactInfoForm.enable();
        }
    }
}
