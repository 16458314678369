import { Component, Input } from '@angular/core';

import { PageLayout } from '../../models/page.model';
import { FlowComponent } from '../flow-components.model';

export class IframeConfig {
  iframeSrc: string;
}

@Component({
  selector: 'flow-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})

export class IframeComponent implements FlowComponent {
  @Input() config: IframeConfig;

  pageLayout: PageLayout;

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }
}
