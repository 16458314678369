import { PageWithComponentList } from './page.model';

export enum FlowType {
    Slideshow = 'slideshow',
    Supporter = 'supporter'
}

export class Flow {
    flowID: string;
    organizationID: string;
    templateID: string;
    name: string;
    shortURL: string;
    type: FlowType;
    slideTiming: number;
    theme: string;
    definition: Definition;
    created: string;
    updated: string;
}

export class Definition {
    pages: PageWithComponentList[];
}
