import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { DonationAmountComponent } from './donation-amount.component';

export * from './donation-amount.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule
    ],
    declarations: [
        DonationAmountComponent,
    ],
})
export class DonationAmountModule { }
