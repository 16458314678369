import { NgModule } from '@angular/core';

import { HeadlineComponent } from './headline.component';

export * from './headline.component';

@NgModule({
    declarations: [
        HeadlineComponent,
    ],
})
export class HeadlineModule { }
