import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FlowOrchestrationComponent } from './components/flow-orchestration/flow-orchestration.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { FeatureFlagRedirectGuard } from './services/feature-flag-redirect-guard.service';

const routes: Routes = [
  {
    path: 'organizations/:organizationID/flows/:flowID',
    component: FlowOrchestrationComponent,
    children: [
      {
        path: 'page/:pageNumber',
        component: PageLayoutComponent,
      }
    ]
  },
  {
    // so that I can copy the url from the management UI
    path: 'organizations/:organizationID/virtualevents',
    redirectTo: 'organizations/:organizationID/virtual-events'
  },
  {
    path: 'organizations/:organizationID/virtual-events',
    canActivate: [FeatureFlagRedirectGuard],
    loadChildren: () => import('./lazy-modules/virtual-event/virtual-event.module').then(m => m.VirtualEventModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
