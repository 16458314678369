import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExecuteFlowPageInput, ExecuteFlowPageOutput, Flow } from 'lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlowService {

  private flowAPISubdomain = 'env:phaas-flow-api';

  constructor(private http: HttpClient) {
  }

  getFlowDefinition(orgID: string, flowID: string): Observable<Flow> {
    const url = `${this.flowAPISubdomain}/organizations/${orgID}/flows/${flowID}/definition`;
    return this.http.get(url).pipe(map((res: any) => {
      return res.payload.data;
    }));
  }

  executePage(orgID: string, flowID: string, pageID: string, input: ExecuteFlowPageInput): Observable<ExecuteFlowPageOutput> {
    const url = `${this.flowAPISubdomain}/organizations/${orgID}/flows/${flowID}/pages/${pageID}/execute`;
    return this.http.post(url, input).pipe(map((res: any) => {
      return res.payload;
    }));
  }
}
