import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { CurrentStateService } from '../../services/current-state.service';
import { FlowComponent } from '../flow-components.model';

export class DonorInfoConfig {
  requireAddress: boolean;
}

export class DonorInfoOutput {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  anonymous: boolean;
  displayName: string;
}

@Component({
  selector: 'flow-donor-info',
  templateUrl: './donor-info.component.html',
  styleUrls: ['./donor-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DonorInfoComponent implements OnInit, OnDestroy, FlowComponent {

  @Input() config: DonorInfoConfig;

  donorInfoForm: FormGroup;

  private destroyTriggered = new Subject<void>();
  private submitPage = new Subject<void>();

  private anonymousStatus = false;

  constructor(
    private formBuilder: FormBuilder,
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.currentState.isSubmittingPage().subscribe((submitting) => {
      this.disableForm(submitting);
    });
  }

  ngOnDestroy() {
    this.destroyTriggered.next();
  }

  submit() {
    this.submitPage.next();
  }

  @Input()
  isValid = (): boolean => {
    return this.donorInfoForm.valid;
  }

  @Input()
  markAsTouched = () => {
    Object.keys(this.donorInfoForm.controls).forEach((key) => {
      this.donorInfoForm.get(key).markAsTouched();
    });
  }

  @Input()
  getValue = (): DonorInfoOutput => {
    const value = new DonorInfoOutput();
    Object.keys(this.donorInfoForm.controls).forEach((key) => {
      value[key] = this.donorInfoForm.get(key).value;
    });
    return value;
  }

  @Input()
  handleExecutionError = (error: any) => { }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitPage.asObservable();
  }

  private buildForm() {
    this.donorInfoForm = this.formBuilder.group({
      firstName: this.formBuilder.control('', [Validators.required]),
      lastName: this.formBuilder.control('', [Validators.required]),
      email: this.formBuilder.control('', [Validators.required, Validators.email]),
      phone: this.formBuilder.control(''),
      street: this.formBuilder.control('', this.config.requireAddress ? Validators.required : Validators.nullValidator),
      city: this.formBuilder.control('', this.config.requireAddress ? Validators.required : Validators.nullValidator),
      state: this.formBuilder.control('', this.config.requireAddress ? Validators.required : Validators.nullValidator),
      zip: this.formBuilder.control('', this.config.requireAddress ? Validators.required : Validators.nullValidator),
      displayName: this.formBuilder.control('', Validators.required),
      anonymous: this.formBuilder.control(this.anonymousStatus)
    });

    this.donorInfoForm.controls.anonymous.valueChanges.subscribe(() => {
      this.anonymousCheckboxToggle();
    });
  }

  private anonymousCheckboxToggle() {
    if (this.anonymousStatus === this.donorInfoForm.controls.anonymous.value) {
      // didn't really change, don't do anything
      return;
    }
    this.anonymousStatus = this.donorInfoForm.controls.anonymous.value;
    if (this.anonymousStatus) {
      this.donorInfoForm.controls.displayName.disable();
      this.donorInfoForm.controls.displayName.patchValue('Anonymous');
    } else {
      this.donorInfoForm.controls.displayName.enable();
      this.donorInfoForm.controls.displayName.reset();
    }
  }

  private disableForm(disable: boolean) {
    if (disable) {
      this.donorInfoForm.disable();
    } else {
      this.donorInfoForm.enable();
    }
  }
}
