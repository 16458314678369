import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EnvironmentService } from '@onecause/core';

import { PreviewService } from '../../services/preview.service';
import { FlowComponent } from '../flow-components.model';

export class OrgLogoConfig {
  logoURL: string;
}

@Component({
  selector: 'flow-org-logo',
  templateUrl: './org-logo.component.html',
  styleUrls: ['./org-logo.component.scss']
})
export class OrgLogoComponent implements FlowComponent<OrgLogoConfig> {
  @Input() config: OrgLogoConfig;
  logoURL: string;

  constructor(
    private previewService: PreviewService,
    private env: EnvironmentService,
  ) { }

  getOrgLogoURL() {
    if (this.previewService.isPreviewMode() && this.config.logoURL === '') {
      this.env.getURL('assets', 'images/default__image.svg').subscribe((image: string) => {
        this.logoURL = image;
      });
    } else {
      this.logoURL = this.config.logoURL;
    }
    return this.logoURL;
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }
}
