import { Inject, Injectable, Injector, Type } from '@angular/core';
import { createCustomElement, NgElement, NgElementConfig, NgElementConstructor, WithProperties } from '@angular/elements';
import { DocumentInjectionToken } from '@onecause/core';

import { FlowComponentSelectorMap } from '../flow-components/component-list';
import { ComponentIdentity, FlowComponent } from '../flow-components/flow-components.model';
import { ComponentDefinition, ComponentType } from '../models/component.model';
import { Flow } from '../models/flow.model';

export class CustomElementsFunctions {
  static createCustomElement<P>(component: Type<any>, config: NgElementConfig): NgElementConstructor<P> {
    return createCustomElement(component, config);
  }

  // tslint:disable-next-line:ban-types
  static register(name: string, constructor: CustomElementConstructor, options?: ElementDefinitionOptions): void {
    customElements.define(name, constructor, options);
  }
}

// @dynamic - this prevents build errors due to the Document interface being used
@Injectable({
  providedIn: 'root'
})
export class ElementService {

  constructor(
    private injector: Injector,
    @Inject(DocumentInjectionToken) private document: Document,
  ) { }

  registerAllFlowComponentsAsElements() {
    FlowComponentSelectorMap.forEach((component, selector) => {
      if (!customElements.get(selector)) {
        CustomElementsFunctions.register(selector, CustomElementsFunctions.createCustomElement(component, { injector: this.injector }));
      }
    });
  }

  registerComponentsForFlowAsElements(flow: Flow) {
    const componentTypesInFlow: ComponentType[] = [];
    flow.definition.pages.forEach((page) => {
      page.components.forEach((component) => {
        if (!componentTypesInFlow.includes(component.type)) {
          componentTypesInFlow.push(component.type);
        }
      });
    });
    componentTypesInFlow.forEach((componentType) => {
      if (!FlowComponentSelectorMap.has(componentType)) {
        console.error(`No component exists for the type "${componentType}"`);
        return;
      }
      if (!customElements.get(componentType)) {
        const ngComponent = FlowComponentSelectorMap.get(componentType);
        CustomElementsFunctions.register(componentType,
          CustomElementsFunctions.createCustomElement(ngComponent, { injector: this.injector }));
      }
    });
  }

  createElementFromComponent(component: ComponentDefinition): NgElement & WithProperties<FlowComponent> {
    const element = this.document.createElement(component.type) as NgElement & WithProperties<FlowComponent>;
    element.identity = new ComponentIdentity(component.organizationID, component.flowID, component.pageID, component.componentID);
    element.config = component.config.data;

    return element;
  }
}
