import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PaymentButtonComponent } from './payment-button.component';

export * from './payment-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    PaymentButtonComponent,
  ],
})
export class PaymentButtonModule { }
