import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlowSessionService {
  organizationID: string;
  flowID: string;
  currentPageNumber: number;
  sessionData: any;
}
