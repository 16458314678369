import { Component, Input, OnInit } from '@angular/core';

import { EventEventAPIModel } from '../../models/event-api.model';
import { FlowComponentService } from '../../services/flow-component.service';
import { ComponentIdentity, FlowComponent } from '../flow-components.model';

export class EventDetailsExternalData {
  eventDetails: EventEventAPIModel;

  constructor(eventDetails?: EventEventAPIModel) {
    this.eventDetails = eventDetails;
  }
}

export class EventDetailsConfig {
  eventID: string;

  constructor(eventID?: string) {
    this.eventID = eventID;
  }
}

@Component({
  selector: 'flow-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})

export class EventDetailsComponent implements OnInit, FlowComponent<EventDetailsConfig> {

  @Input() config: EventDetailsConfig;
  @Input() identity: ComponentIdentity;

  eventDetails: EventEventAPIModel;
  encodedMapsURL: string;

  constructor(
    private flowComponentService: FlowComponentService,
  ) { }

  ngOnInit() {
    this.getEventDetails();
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

  getEventDetails() {
    const orgID = this.identity.organizationID;
    const flowID = this.identity.flowID;
    const pageID = this.identity.pageID;
    const componentID = this.identity.componentID;
    this.flowComponentService.getExternalData<EventDetailsExternalData>(orgID, flowID, pageID, componentID)
      .subscribe((output) => {
        const eventDetails = output.data.eventDetails;
        this.eventDetails = eventDetails;
        this.encodeMapsURL(eventDetails);
      });
  }

  hasLocationDetails(): boolean {
    const e = this.eventDetails;
    return !!e.locationName || !!e.locationStreet || !!e.locationCity || !!e.locationState;
  }

  hasContactInfo(): boolean {
    const e = this.eventDetails;
    return !!e.contactName || !!e.contactPhone || !!e.contactEmail;
  }

  private encodeMapsURL(event: EventEventAPIModel) {
    const queryParam = encodeURIComponent(
      event.locationStreet + ' ' + event.locationCity + ' ' + event.locationState + ' ' + event.locationZip);
    this.encodedMapsURL = 'https://www.google.com/maps/search/?api=1&query=' + queryParam;
  }
}
