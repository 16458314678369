import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

export class CarouselGroup {
  images: string[];
}

@Component({
  selector: 'flow-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CarouselComponent implements OnInit {
  @Input() images: string[];

  readonly GROUP_LIMIT = 3;
  readonly CAROUSEL_TIME_MS = 5000;

  carouselGroups: CarouselGroup[];
  removedGroupIndex: number;
  activeGroupIndex = 0;
  carouselID: any;

  getClassByIndex(index: number): string {
    if (index === this.activeGroupIndex) {
      return 'carousel__group_slide-in';
    }

    if (index === this.removedGroupIndex) {
      return 'carousel__group_slide-out';
    }
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    if (!this.images) {
      return;
    }

    this.carouselGroups = this.getCarouselGroups();
    if (this.carouselGroups.length > 1) {
      this.carouselID = setInterval(() => {
        this.loopCarousel();
      }, this.CAROUSEL_TIME_MS);
    }
  }

  private getCarouselGroups(): CarouselGroup[] {
    let groups: CarouselGroup[] = [];

    if (this.images.length > this.GROUP_LIMIT) {
      const totalGroupsToCreate = this.images.length / this.GROUP_LIMIT;
      let currentImageIndex = 0;
      // create the array of images for each group
      for (let i = 0; i < totalGroupsToCreate; i++) {
        const lastIndexForGroup = currentImageIndex + this.GROUP_LIMIT;
        groups.push({
          images: this.images.slice(currentImageIndex, lastIndexForGroup)
        });
        currentImageIndex = lastIndexForGroup;
      }
    }
    else if (this.images.length) {
      groups = [{ images: this.images }];
    }

    return groups;
  }

  private loopCarousel(): void {
    // remove the currently active group
    this.removedGroupIndex = this.activeGroupIndex;

    // set the next group as active (OR go back to the first group again)
    const nextIndex = this.activeGroupIndex + 1;
    this.activeGroupIndex = nextIndex < this.carouselGroups.length ? nextIndex : 0;

    /*
    reset the removed group after it finishes sliding out to left
    this puts the group back on the right side so it's ready to slide back in
    */
    setTimeout(() => {
      this.removedGroupIndex = null;
    }, 700);
  }
}
