import { AbstractControl } from '@angular/forms';

export class NumbersOnlyFormatter {
  static initialize(control: AbstractControl) {
    let formattedValue = '';
    control.valueChanges.subscribe((value: string) => {
      formattedValue = this.removeNonNumbers(value);
      control.setValue(formattedValue, { emitEvent: false, emitViewToModelChange: false });
    });
  }

  private static removeNonNumbers(newValue: string) {
    let value = '';

    if (newValue !== null && newValue !== undefined) {
      value = newValue.toString().replace(/[^0-9]/g, '');
    }
    return value;
  }
}
