import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService, PhaasResponse } from '@onecause/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Organization } from '../models/org-api.model';

@Injectable({
  providedIn: 'root'
})
export class OrgService {

  constructor(
    private env: EnvironmentService,
    private httpClient: HttpClient,
  ) { }

  getOrganizationSummary(orgID: string): Observable<Organization> {
    return this.env.getURL('phaas-org-api', `organizations/${orgID}/summary`).pipe(switchMap((url) => {
      return this.httpClient.get<PhaasResponse<Organization>>(url).pipe(map((res) => {
        return res.payload;
      }));
    }));
  }
}
