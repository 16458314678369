import { Component, Input, OnInit } from '@angular/core';
import { NumberUtility } from '@onecause/core';
import { Observable, Subject } from 'rxjs';

import { CurrentStateService } from '../../services/current-state.service';
import { FlowSessionService } from '../../services/flow-session.service';
import { FlowComponent } from '../flow-components.model';
import { ReservedTicket, TicketSelectionOutput } from '../ticket-selection/ticket-selection.module';

export enum PaymentButtonTextOption {
  None = '',
  GiveAmount = 'give-amount',
  PurchaseTickets = 'purchase-tickets'
}

export class PaymentButtonConfig {
  textOption: PaymentButtonTextOption;
}

type Donation = {
  amount: number;
  [key: string]: any;
};

type PaymentButtonInputSessionData = {
  purchase: {
    purchase: {
      id: string,
      total: number,
    },
    donations: Donation[],
  },
  ticketSelection: TicketSelectionOutput,
};

@Component({
  selector: 'flow-payment-button',
  templateUrl: './payment-button.component.html',
  styleUrls: ['./payment-button.component.scss']
})
export class PaymentButtonComponent implements OnInit, FlowComponent<PaymentButtonConfig, void> {

  @Input() config: PaymentButtonConfig;

  buttonText = '';
  submitting: boolean;

  private submitPage = new Subject<void>();

  constructor(
    private flowSession: FlowSessionService,
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    this.initButtonText();
    this.currentState.isSubmittingPage().subscribe((submitting) => {
      this.submitting = submitting;
    });
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitPage.asObservable();
  }

  submit() {
    this.submitPage.next();
  }

  private initButtonText() {
    switch (this.config.textOption) {
      case PaymentButtonTextOption.PurchaseTickets:
        this.setButtonTextToPurchaseTickets();
        break;
      case PaymentButtonTextOption.GiveAmount:
      case PaymentButtonTextOption.None:
      default:
        this.setButtonTextToGiveAmount();
        break;
    }
  }

  private setButtonTextToGiveAmount() {
    const amount = NumberUtility.roundHundredths(this.getPaymentTotal());
    this.buttonText = `GIVE $${amount}!`;
  }

  private setButtonTextToPurchaseTickets() {
    const amount = this.getPaymentTotal();
    if (amount > 0) {
      this.buttonText = 'PURCHASE TICKETS';
    } else {
      this.buttonText = 'RESERVE TICKETS';
    }
  }

  private getPaymentTotal(): number {
    const sessionData = this.flowSession.sessionData as PaymentButtonInputSessionData;
    let paymentTotal = 0;
    if (sessionData.purchase) {
      paymentTotal += sessionData.purchase.purchase.total || 0;
    }

    if (sessionData.ticketSelection?.reservedTickets?.length) {
      sessionData.ticketSelection.reservedTickets.forEach((ticket: ReservedTicket) => {
        paymentTotal += (ticket.ticketType.price * ticket.tickets.length) || 0;
      });
    }
    return NumberUtility.roundHundredths(paymentTotal);
  }
}
