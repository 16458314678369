import { Component, Input, OnInit } from '@angular/core';

import { FlowComponent } from '../flow-components.model';
import { FlowSessionService } from '../../services/flow-session.service';
import { TicketType, TicketTypeGroup } from '../../models/event-api.model';
import { CurrencyUtil } from '../../utils/currency.util';

export class OrderSummaryConfig {
}

export class TicketTypeSummary {
    ticketType: TicketType;
    ticketCount: number;
    totalPrice: number;

    get totalDollars(): number {
        return CurrencyUtil.getDollars(this.totalPrice);
    }
    get totalCents(): number {
        return CurrencyUtil.getCents(this.totalPrice);
    }

    constructor() {
        this.ticketCount = 0;
        this.totalPrice = 0;
    }
}

export class OrderSummary {
    ticketTypes: TicketTypeSummary[];
    orderTotal: number;

    get orderTotalDollars(): number {
        return CurrencyUtil.getDollars(this.orderTotal);
    }
    get orderTotalCents(): number {
        return CurrencyUtil.getCents(this.orderTotal);
    }

    addTicketTypeGroup(ticketTypeGroup: TicketTypeGroup) {
        const ticketTypeSummary = new TicketTypeSummary();

        ticketTypeSummary.ticketType = ticketTypeGroup.ticketType;
        ticketTypeSummary.ticketCount = ticketTypeGroup.tickets.length;
        ticketTypeSummary.totalPrice = ticketTypeGroup.ticketType.price * ticketTypeSummary.ticketCount;

        this.ticketTypes.push(ticketTypeSummary);
        this.orderTotal += ticketTypeSummary.totalPrice;
    }

    constructor() {
        this.ticketTypes = [];
        this.orderTotal = 0;
    }
}

export class TicketSelectionOutput {
    reservedTickets: TicketTypeGroup[];

    constructor(obj?: any) {
        this.reservedTickets = [];
        if (obj == null || !(obj.reservedTickets instanceof Array)) {
            return;
        }

        for (const ticket of obj.reservedTickets) {
            this.reservedTickets.push(new TicketTypeGroup(ticket));
        }
    }
}

class SessionData {
    ticketSelection: TicketSelectionOutput;

    constructor(obj: any) {
        if (obj == null) {
            this.ticketSelection = new TicketSelectionOutput();
            return;
        }

        this.ticketSelection = new TicketSelectionOutput(obj.ticketSelection);
    }
}

@Component({
    selector: 'flow-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements FlowComponent<OrderSummaryConfig, void>, OnInit {
    orderSummary: OrderSummary;

    @Input()
    config: OrderSummaryConfig;

    @Input()
    isValid = (): boolean => {
        return true;
    }

    @Input()
    getValue = (): void => { }

    @Input()
    markAsTouched = (): void => { }

    @Input()
    handleExecutionError = (error: any): void => { }

    @Input()
    listenForSubmit = (): void => { }

    constructor(
        private flowSession: FlowSessionService,
    ) { }

    ngOnInit() {
        this.setOrderSummary(new SessionData(this.flowSession.sessionData));
    }

    private setOrderSummary(sessionData: SessionData) {
        const order: OrderSummary = new OrderSummary();

        for (const ticketTypeGroup of sessionData.ticketSelection.reservedTickets) {
            order.addTicketTypeGroup(ticketTypeGroup);
        }

        this.orderSummary = order;
    }
}
