import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  EnvironmentModule, FacebookButtonModule, GainsightModule, GoogleButtonModule, HttpModule,
  PipesModule, SnackBarModule
} from '@onecause/core';
import { FlowComponentsModule, LayoutComponentsModule } from 'lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlowOrchestrationComponent } from './components/flow-orchestration/flow-orchestration.component';
import { SlideshowOrchComponent } from './components/flow-orchestration/slideshow-orch/slideshow-orch.component';
import { SupporterOrchComponent } from './components/flow-orchestration/supporter-orch/supporter-orch.component';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { GeneratedVirtualEventRestService } from './services/zz-generatedvirtualevent-rest.service';


@NgModule({
  declarations: [
    AppComponent,
    FlowOrchestrationComponent,
    PageLayoutComponent,
    SlideshowOrchComponent,
    SupporterOrchComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlowComponentsModule,
    HttpModule,
    EnvironmentModule,
    SnackBarModule,
    MatGridListModule,
    MatDialogModule,
    LayoutComponentsModule,
    FacebookButtonModule,
    GoogleButtonModule,
    PipesModule,
    GainsightModule,
  ],
  providers: [GeneratedVirtualEventRestService],
  bootstrap: [AppComponent]
})
export class AppModule { }
