import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FundraiserProgressComponent } from './fundraiser-progress/fundraiser-progress.component';
import { TagProgressComponent } from './tag-progress/tag-progress.component';
import { EventProgressComponent } from './event-progress/event-progress.component';

export * from './tag-progress/tag-progress.component';
export * from './fundraiser-progress/fundraiser-progress.component';
export * from './event-progress/event-progress.component';

@NgModule({
    imports: [
        CommonModule,
        MatProgressBarModule
    ],
    declarations: [
        FundraiserProgressComponent,
        TagProgressComponent,
        EventProgressComponent,
    ],
})
export class ProgressModule { }
