import { Component, Input, ViewEncapsulation, OnInit, QueryList, ViewChildren } from '@angular/core';

import { FlowComponentService } from '../../services/flow-component.service';
import { ComponentIdentity, FlowComponent } from '../flow-components.model';
import { Ticket, TicketType, TicketTypeLimit } from '../../models/event-api.model';
import { TicketIncrementorComponent } from './ticket-incrementor/ticket-incrementor.component';

export class TicketSelectionExternalData {
  ticketTypes: TicketType[];
  ticketTypeLimits: TicketTypeLimit[];

  constructor(ticketTypes?: TicketType[], ticketTypeLimits?: TicketTypeLimit[]) {
    this.ticketTypes = ticketTypes;
    this.ticketTypeLimits = ticketTypeLimits;
  }
}

export class TicketSelectionConfig {
  eventID: string;
  constructor(eventID?: string) {
    this.eventID = eventID;
  }
}

export class TicketSelectionOutput {
  reservedTickets: ReservedTicket[];
}

export class ReservedTicket {
  ticketType: TicketType;
  tickets: Ticket[];
}

@Component({
  selector: 'flow-ticket-selection',
  templateUrl: './ticket-selection.component.html',
  styleUrls: ['./ticket-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TicketSelectionComponent implements OnInit, FlowComponent<TicketSelectionConfig, void> {
  @ViewChildren(TicketIncrementorComponent) ticketIncrementors: QueryList<TicketIncrementorComponent>;

  @Input() config: TicketSelectionConfig;
  @Input() identity: ComponentIdentity;

  ticketTypes: TicketType[];
  ticketTypeLimits: TicketTypeLimit[];
  overLimit = false;
  loadingComplete = false;

  constructor(
    private flowComponentService: FlowComponentService,
  ) { }

  ngOnInit() {
    this.getActiveTickets();
  }

  @Input()
  isValid = (): boolean => {
    this.checkIfOverLimit();
    const hasTickets = this.hasSelectedTickets();

    return hasTickets && !this.overLimit;
  }

  @Input()
  getValue = (): TicketSelectionOutput => {
    const ticketSelectionOutput: TicketSelectionOutput = { reservedTickets: [] };
    this.ticketIncrementors.forEach((ticketType) => {
      if (ticketType.reservationCount > 0) {
        const reservedTicket: ReservedTicket = { ticketType: ticketType.ticketType, tickets: [] };
        for (let i = 0; i < ticketType.reservationCount; i++) {
          reservedTicket.tickets.push(new Ticket());
        }

        ticketSelectionOutput.reservedTickets.push(reservedTicket);
      }
    });
    return ticketSelectionOutput;
  }

  @Input()
  markAsTouched = (): void => {
  }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

  getActiveTickets() {
    const orgID = this.identity.organizationID;
    const flowID = this.identity.flowID;
    const pageID = this.identity.pageID;
    const componentID = this.identity.componentID;
    this.flowComponentService.getExternalData<TicketSelectionExternalData>(orgID, flowID, pageID, componentID)
      .subscribe((output) => {
        this.ticketTypes = output.data.ticketTypes;
        this.ticketTypeLimits = output.data.ticketTypeLimits;

        if (this.ticketTypes && this.ticketTypes.length > 0) {
          this.setMaxLeft();
          this.loadingComplete = true;
        }
      });
  }

  setMaxLeft() {
    if (!this.ticketTypeLimits) {
      return;
    }

    for (const ticketType of this.ticketTypes) {
      const ticketTypeLimit = this.ticketTypeLimits.find(l => l.ticketTypeID === ticketType.ticketTypeID);
      ticketType.ticketsLeft = Math.max(0, ticketTypeLimit.maxTickets - ticketTypeLimit.currentTicketCount);
    }
  }

  get errorVisible(): boolean {
    if (!this.ticketIncrementors) {
      return false;
    }

    const dirty = this.ticketIncrementors.find(t => t.numberInput.dirty === true || t.numberInput.touched === true);
    if (!this.isValid() && dirty) {
      return true;
    }
    return false;
  }

  checkIfOverLimit() {
    this.overLimit = false;

    if (!this.ticketIncrementors) {
      return;
    }

    this.ticketIncrementors.forEach((incrementor) => {
      const ticketType = this.ticketTypes.find(t => t.ticketTypeID === incrementor.ticketType.ticketTypeID);
      if (incrementor.reservationCount > ticketType.ticketsLeft) {
        this.overLimit = true;
      }
    });
  }

  hasSelectedTickets() {
    let hasTickets = false;
    this.ticketIncrementors.forEach((ticketType) => {
      if (ticketType.reservationCount > 0) {
        hasTickets = true;
      }
    });
    return hasTickets;
  }
}
