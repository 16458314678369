import { Component, Input, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { FlowComponent } from '../flow-components.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarConfig, WindowInjectionToken } from '@onecause/core';

export class RedirectConfig {
  redirectPromptText: string;
  redirectPromptConfirmButtonText: string;
  redirectURL: string;
  redirectTimerMillis: number;
}

@Component({
  selector: 'flow-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RedirectComponent implements FlowComponent<RedirectConfig, void>, OnInit {

  @Input() config: RedirectConfig;

  constructor(
    private snackbar: MatSnackBar,
    @Inject(WindowInjectionToken) private window: Window,
  ) { }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    if (!this.config.redirectURL) {
      return;
    }

    if (!this.config.redirectTimerMillis) { this.config.redirectTimerMillis = 60000; }
    this.showSnackbar();

    setTimeout(() => {
      this.redirect();
    }, this.config.redirectTimerMillis);
  }

  private showSnackbar(): void {
    const config = new SnackBarConfig(
      this.config.redirectPromptText || 'You will be redirected shortly.',
      null,
      false,
      this.config.redirectPromptConfirmButtonText || 'Go Now',
    );
    config.duration = this.config.redirectTimerMillis;
    this.snackbar.openFromComponent(SnackBarComponent, config).onAction().subscribe(() => {
      this.redirect();
    });
  }

  private redirect(): void {
    this.window.location.assign(this.config.redirectURL);
  }
}
