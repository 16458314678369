import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomHtmlComponent } from '../custom-html/custom-html.component';
import { SanitizeModule } from '@onecause/core';

@NgModule({
  imports: [
    CommonModule,
    SanitizeModule,
  ],
  declarations: [
    CustomHtmlComponent,
  ],
})
export class CustomHtmlModule { }
