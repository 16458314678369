import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CountrySelectModule, StateSelectModule } from '@onecause/core';
import { MatSelectModule } from '@angular/material/select';

import { CreditCardFormComponent } from './credit-card-form.component';
import { CreditCardComponent } from './creditcard/creditcard.component';
import { PaymentApiJsComponent } from './payment-api-js/payment-api-js';

export * from './credit-card-form.component';
export * from './creditcard/creditcard.component';

@NgModule({
    declarations: [
        CreditCardFormComponent,
        CreditCardComponent,
        PaymentApiJsComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        CountrySelectModule,
        StateSelectModule,
        MatSelectModule,
    ],
    exports: [
        CreditCardFormComponent,
        CreditCardComponent,
    ]
})
export class CreditCardFormModule { }
