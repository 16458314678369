import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CurrentStateService,
  ExecuteFlowPageInput,
  Flow,
  FlowSessionService,
  PageExecutionError,
  PageExecutionService,
  PageOutput,
} from 'lib';
import { CurrentPageNumberService } from 'src/app/services/current-page-number.service';
import { FlowService } from 'src/app/services/flow.service';

@Component({
  selector: 'app-supporter-orch',
  templateUrl: './supporter-orch.component.html',
  styleUrls: ['./supporter-orch.component.scss']
})
export class SupporterOrchComponent implements OnInit {

  currentFlow: Flow;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currentPageNumberService: CurrentPageNumberService,
    private pageExecutionService: PageExecutionService,
    private flowService: FlowService,
    private flowSession: FlowSessionService,
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    this.currentState.flow.subscribe((flow) => {
      this.currentFlow = flow;
      this.goToFirstFlowPage();
      this.listenForPageSubmission();
    });
  }

  private listenForPageSubmission() {
    this.pageExecutionService.listenForPageSubmission().subscribe((pageOutput) => {
      this.processPageOutput(pageOutput);
    });
  }

  private processPageOutput(output: PageOutput) {
    this.currentState.updateSubmittingPageStatus(true);
    this.flowService.executePage(this.currentFlow.organizationID,
      this.currentFlow.flowID,
      this.getCurrentPageID(),
      new ExecuteFlowPageInput(output.pageData, this.flowSession.sessionData))
      .subscribe((response) => {
        this.currentState.updateSubmittingPageStatus(false);
        this.flowSession.sessionData = response.sessionData;
        this.nextPage();
      }, (err: HttpErrorResponse) => {
        this.currentState.updateSubmittingPageStatus(false);
        const executionErr = new PageExecutionError(err.error.payload);
        this.pageExecutionService.throwPageExecutionError(executionErr);
      });
  }

  private nextPage() {
    const nextPageNumber = this.currentPageNumberService.getCurrentPageNumber() + 1;
    if (nextPageNumber <= this.currentFlow.definition.pages.length) {
      this.goToPage(nextPageNumber);
    }
  }

  private getCurrentPageID(): string {
    const pageNumber = this.currentPageNumberService.getCurrentPageNumber();
    if (this.currentFlow && this.currentFlow.definition.pages && this.currentFlow.definition.pages.length >= pageNumber) {
      return this.currentFlow.definition.pages[pageNumber - 1].pageID;
    }
    return '';
  }

  private goToFirstFlowPage(): Promise<boolean> {
    if (this.currentPageNumberService.getCurrentPageNumber() !== 1) {
      return this.goToPage(1);
    }
    return Promise.resolve(true);
  }

  private goToPage(pageNumber: number): Promise<boolean> {
    return this.router.navigate([`./page/${pageNumber}`], { relativeTo: this.activatedRoute });
  }
}
