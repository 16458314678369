import { Component, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FlowComponent } from '../flow-components.model';


@Component({
  selector: 'flow-for-testing',
  templateUrl: './for-testing.component.html',
  styleUrls: ['./for-testing.component.scss']
})
export class ForTestingComponent implements FlowComponent {

  @Input() config: any;

  @Input()
  valid = true;

  @Input()
  value: any;

  @Input()
  touched: boolean;

  @Input()
  err: any;

  private submitSubject = new Subject<void>();

  @Input()
  isValid = (): boolean => {
    return this.valid;
  }

  @Input()
  getValue = (): void => {
    return this.value;
  }

  @Input()
  markAsTouched = (): void => {
    this.touched = true;
  }

  @Input()
  handleExecutionError = (error: any): void => {
    this.err = error;
  }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitSubject.asObservable();
  }

  @Input()
  submit = () => {
    this.submitSubject.next();
  }
}
