import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService, ElementService, Flow, FlowSessionService } from 'lib';
import { FlowService } from 'src/app/services/flow.service';

import { PageLayoutComponent } from '../page-layout/page-layout.component';

@Component({
  selector: 'app-flow-orchestration',
  templateUrl: './flow-orchestration.component.html',
  styleUrls: ['./flow-orchestration.component.scss']
})
export class FlowOrchestrationComponent implements OnInit {

  currentFlow: Flow;

  constructor(
    private elementService: ElementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private flowService: FlowService,
    private flowSession: FlowSessionService,
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = (route) => {
      return route.component !== PageLayoutComponent;
    };
    this.loadFlow();
  }

  private loadFlow() {
    this.flowSession.organizationID = this.activatedRoute.snapshot.paramMap.get('organizationID');
    this.flowSession.flowID = this.activatedRoute.snapshot.paramMap.get('flowID');
    this.flowService.getFlowDefinition(this.flowSession.organizationID, this.flowSession.flowID).subscribe(async (flow) => {
      this.currentState.updateFlow(flow);
      this.currentFlow = flow;
      this.elementService.registerComponentsForFlowAsElements(flow);
    });
  }
}
