import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NextPageButtonComponent } from './next-page-button.component';

export * from './next-page-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    NextPageButtonComponent,
  ],
})
export class NextPageButtonModule { }
