import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CurrentStateService, PageWithComponentList, PageLayout } from 'lib';
import { Subject } from 'rxjs';
import { CurrentPageNumberService } from 'src/app/services/current-page-number.service';
import { EnvironmentService } from '@onecause/core';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageLayoutComponent implements OnInit, OnDestroy {

  currentPage: PageWithComponentList;
  logo: string;
  theme: string;

  // Reference to the PageLayout enum
  pageLayout = PageLayout;

  private destroyTriggered = new Subject<void>();

  constructor(
    private currentPageNumberService: CurrentPageNumberService,
    private currentState: CurrentStateService,
    private env: EnvironmentService,
  ) { }

  ngOnInit() {
    this.initPage();
    this.env.getURL('assets', 'onecause/one-cause-no-tagline-grey.svg').subscribe((image: string) => {
      this.logo = image;
    });
    return this.logo;
  }

  ngOnDestroy() {
    this.destroyTriggered.next();
  }

  private initPage() {
    this.currentState.flow.subscribe((flow) => {
      const pageNumber = this.currentPageNumberService.getCurrentPageNumber();
      this.currentPage = flow.definition.pages[pageNumber - 1];
      this.currentState.updatePage(this.currentPage);
      this.theme = flow.theme;
    });
  }
}
