import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { RecentDonorsComponent } from './recent-donors.component';

export * from './recent-donors.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  declarations: [RecentDonorsComponent],
})
export class RecentDonorsModule { }
