import { Component, Input } from '@angular/core';

import { FlowComponent } from '../flow-components.model';

export class HeadlineConfig {
  text: string;
}

@Component({
  selector: 'flow-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent implements FlowComponent<HeadlineConfig, void> {

  @Input() config: HeadlineConfig;

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }
}
