import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhoneNumberModule } from '@onecause/core';
import { MatIconModule } from '@angular/material/icon';

import { EventDetailsComponent } from './event-details.component';

export * from './event-details.component';

@NgModule({
  imports: [
    CommonModule,
    PhoneNumberModule,
    MatIconModule
  ],
  declarations: [
    EventDetailsComponent,
  ],
})
export class EventDetailsModule { }
