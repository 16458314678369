import { Component, Input, ViewEncapsulation } from '@angular/core';

import { FlowComponent } from '../flow-components.model';

export class ParagraphConfig {
  paragraph: string;
}

@Component({
  selector: 'flow-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParagraphComponent implements FlowComponent<ParagraphConfig, void> {

  @Input() config: ParagraphConfig;

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

}
