import { Component, ElementRef, ViewChild } from '@angular/core';

import { ComponentDefinition } from '../../../models/component.model';
import { PageWithComponentList } from '../../../models/page.model';
import { ElementService } from '../../../services/element.service';
import { PageExecutionService } from '../../../services/page-execution.service';
import { PreviewService } from '../../../services/preview.service';
import { PrimaryColorService } from '../../../services/primary-color.service';
import { CurrentStateService } from '../../../services/services-exports';
import { PageOrchDirective } from '../page-orch';

class Tile {
  height: number;
  width: number;
  componentID: string;

  constructor(width: number, height: number, componentID?: string) {
    this.width = width;
    this.height = height;
    this.componentID = componentID || 'empty';
  }
}

class ComponentPlacement {
  component: ComponentDefinition;
  x: number;
  y: number;
  height: number;
  width: number;
  containerID: string;
  placed: boolean;

  constructor(component: ComponentDefinition, placed: boolean) {
    const placement = component.position.placement.split(',');
    const size = component.position.size.split(',');
    this.component = component;
    this.x = parseInt(placement[0] || '0', 10);
    this.y = parseInt(placement[1] || '0', 10);
    this.width = parseInt(size[0] || '0', 10);
    this.height = parseInt(size[1] || '0', 10);
    this.placed = placed;
  }
}

class ComponentPlacementMap {
  [id: string]: ComponentPlacement;
}

// @dynamic - this prevents build error due to using Window interface
@Component({
  selector: 'flow-grid-sixteen-by-nine',
  templateUrl: './grid-sixteen-by-nine.component.html',
  styleUrls: ['./grid-sixteen-by-nine.component.scss']
})
export class GridSixteenByNineComponent extends PageOrchDirective {

  @ViewChild('gridContainer', { static: true }) gridContainer: ElementRef<HTMLDivElement>;
  @ViewChild('grid') grid: ElementRef<HTMLDivElement>;

  rowHeight: number;
  tiles: Tile[];

  margin: string;
  transformScale: string;
  pageSelected: boolean;
  selectedComponent: string;

  private readonly GRID_WIDTH = 16;
  private readonly GRID_HEIGHT = 9;
  private readonly BASE_GRID_HEIGHT_PX = 1024;

  constructor(
    elementService: ElementService,
    pageExecutionService: PageExecutionService,
    primaryColorService: PrimaryColorService,
    currentState: CurrentStateService,
    private previewService: PreviewService,
  ) {
    super(elementService, pageExecutionService, primaryColorService, currentState);
  }

  protected init() {
    if (this.previewService.isPreviewMode()) {
      this.previewService.getSelectedPageID().subscribe((pageID) => {
        this.pageSelected = pageID === this.currentPage.pageID;
      });
      this.previewService.getSelectedComponentID().subscribe((componentID) => {
        if (this.pageSelected) {
          this.selectedComponent = componentID;
        }
      });
    }
  }

  setGridStyling() {
    const styles = {};
    styles['grid-container__grid_selected'] = this.pageSelected;
    styles[`grid-container__grid__theme_${this.theme}`] = true;
    return styles;
  }

  protected loadPageContent(page: PageWithComponentList) {
    this.rowHeight = this.BASE_GRID_HEIGHT_PX / 9;
    const gridSize = this.calculateGridSize();
    const scalingFactor = this.calculateScalingFactor(gridSize);
    const padding = this.calculatePadding(gridSize);
    this.margin = `${padding.vertical}px ${padding.horizontal}px`;
    this.transformScale = `scale(${scalingFactor})`;

    const components = this.getComponentPlacementMap(page.components);
    const idList = this.getTileOccupantIDList(components);
    this.tiles = this.buildTileArray(components, idList);
    Object.values(components).forEach((component) => {
      this.loadComponent(component.component, component.containerID);
    });
  }

  private calculateGridSize(): { height: number, width: number } {
    // We want a 16 wide by 9 tall grid with square spaces. Determine the height and
    // width of the grid within the page to enable that.
    const height = this.gridContainer.nativeElement.clientHeight;
    const width = this.gridContainer.nativeElement.clientWidth;
    const ratio = width / height;
    const forcedRatio = this.GRID_WIDTH / this.GRID_HEIGHT;

    let scaledHeight: number;
    let scaledWidth: number;

    if (ratio > forcedRatio) {
      // too wide
      scaledHeight = height;
      scaledWidth = forcedRatio * height;
    } else if (ratio < forcedRatio) {
      // too tall
      scaledWidth = width;
      scaledHeight = (width / forcedRatio);
    } else {
      // just right
      scaledHeight = height;
      scaledWidth = width;
    }
    return {
      height: scaledHeight,
      width: scaledWidth,
    };
  }

  private calculateScalingFactor(size: { height: number, width: number }) {
    return size.height / this.BASE_GRID_HEIGHT_PX;
  }

  private calculatePadding(size: { height: number, width: number }): { vertical: number, horizontal: number } {
    const height = this.gridContainer.nativeElement.clientHeight;
    const width = this.gridContainer.nativeElement.clientWidth;
    return {
      vertical: (height - size.height) / 2,
      horizontal: (width - size.width) / 2,
    };
  }

  private getComponentPlacementMap(components: ComponentDefinition[]): ComponentPlacementMap {
    const output = {};
    components.forEach((component) => {
      const componentPlacement = new ComponentPlacement(component, false);
      if (componentPlacement.height === 0 || componentPlacement.width === 0) {
        componentPlacement.containerID = 'component-container__hidden-components';
      } else {
        componentPlacement.containerID = `component-container__${componentPlacement.component.componentID}`;
      }
      output[component.componentID] = componentPlacement;
    });
    return output;
  }

  private getTileOccupantIDList(components: ComponentPlacementMap): string[][] {
    const grid: string[][] = [];
    for (let y = 0; y < this.GRID_HEIGHT; y++) {
      grid.push([]);
      for (let x = 0; x < this.GRID_WIDTH; x++) {
        grid[y].push('');
      }
    }
    Object.values(components).forEach((component) => {
      for (let y = 0; y < component.height; y++) {
        for (let x = 0; x < component.width; x++) {
          const xPos = component.x + x;
          const yPos = component.y + y;
          if (xPos < this.GRID_WIDTH && yPos < this.GRID_HEIGHT) {
            grid[yPos][xPos] = component.component.componentID;
          } else {
            console.error(`The "${component.component.type}" component was placed outside the grid limits.`, component);
          }
        }
      }
    });

    return grid;
  }

  private buildTileArray(components: ComponentPlacementMap, idList: string[][]): Tile[] {
    const tiles: Tile[] = [];
    for (let y = 0; y < this.GRID_HEIGHT; y++) {
      for (let x = 0; x < this.GRID_WIDTH; x++) {
        if (idList[y][x] !== '') {
          const component = components[idList[y][x]];
          if (!component.placed) {
            tiles.push(new Tile(component.width, component.height, component.component.componentID));
            component.placed = true;
          }
        } else {
          tiles.push(new Tile(1, 1));
        }
      }
    }
    return tiles;
  }
}
