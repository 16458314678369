import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';

import { OnecauseFooterComponent } from './onecause-footer/onecause-footer.component';
import { OrgFooterComponent } from './org-footer/org-footer.component';
import { GridSixteenByNineComponent } from './page-orchs/grid-sixteen-by-nine/grid-sixteen-by-nine.component';
import { SlideOrchComponent } from './page-orchs/slide-orch/slide-orch.component';
import { TabsComponent } from './tabs/tabs.component';
import {
  VerticalPageOrchestrationComponent,
} from './page-orchs/vertical-page-orchestration/vertical-page-orchestration.component';
import { TwoColumnPageOrchestrationComponent } from './page-orchs/two-column-page-orchestration/two-column-page-orchestration.component';

@NgModule({
  declarations: [
    OnecauseFooterComponent,
    OrgFooterComponent,
    GridSixteenByNineComponent,
    VerticalPageOrchestrationComponent,
    SlideOrchComponent,
    TabsComponent,
    TwoColumnPageOrchestrationComponent,
  ],
  imports: [
    CommonModule,
    MatGridListModule
  ],
  exports: [
    OnecauseFooterComponent,
    OrgFooterComponent,
    GridSixteenByNineComponent,
    VerticalPageOrchestrationComponent,
    SlideOrchComponent,
    TabsComponent,
    TwoColumnPageOrchestrationComponent,
  ]

})
export class LayoutComponentsModule { }
