import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CurrentPageNumberService {

  constructor(private activatedRoute: ActivatedRoute) { }

  getCurrentPageNumber(): number {
    for (let routeSegment = this.activatedRoute.root; !!routeSegment; routeSegment = routeSegment.firstChild) {
      if (routeSegment.snapshot.paramMap.has('pageNumber')) {
        const pageNumberParam = routeSegment.snapshot.paramMap.get('pageNumber');
        const pageNumber = parseInt(pageNumberParam, 10);
        if (isNaN(pageNumber)) {
          return NaN;
        }
        return pageNumber;
      }
    }

    return NaN;
  }
}
