import { Component, Input } from '@angular/core';

import { FlowComponent } from '../flow-components.model';

export class LogoWithTextConfig {
  text: string;
  logoURL: string;
}

@Component({
  selector: 'flow-logo-with-text',
  templateUrl: './logo-with-text.component.html',
  styleUrls: ['./logo-with-text.component.scss']
})
export class LogoWithTextComponent implements FlowComponent<LogoWithTextConfig, void> {

  @Input() config: LogoWithTextConfig;

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }
}
