import { UserFriendlyError } from '@onecause/core';

export class ExecuteFlowPageInput {
    pageData: any;
    sessionData: any;

    constructor(pageData: any, sessionData: any) {
        this.pageData = pageData;
        this.sessionData = sessionData;
    }
}

export class ExecuteFlowPageOutput {
    sessionData: any;

    constructor(sessionData?: any) {
        this.sessionData = sessionData;
    }
}

export class PageExecutionError {
    componentID: string;
    error: UserFriendlyError;

    constructor(error: { componentID: string, error: any }) {
        this.componentID = error.componentID || '';
        this.error = new UserFriendlyError(error.error);
    }
}
