import { AfterViewInit, ElementRef, Input, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { AsyncSubject, Subject, timer, zip } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { Flow, FlowType } from '../../models/flow.model';
import { PageLayout, PageWithComponentList } from '../../models/page.model';
import { CurrentStateService } from '../../services/current-state.service';
import { PreviewService } from '../../services/preview.service';

import { ComponentIdentity, FlowComponent } from '../flow-components.model';

@Directive()
export abstract class ProgressComponentDirective implements OnInit, OnDestroy, AfterViewInit, FlowComponent {

    @ViewChild('raisedAmountText') raisedAmountText: ElementRef<HTMLSpanElement>;
    @ViewChild('raisedAmountDollarSign') raisedAmountDollarSign: ElementRef<HTMLSpanElement>;

    @ViewChild('goalAmountText') goalAmountText: ElementRef<HTMLSpanElement>;
    @ViewChild('goalAmountDollarSign') goalAmountDollarSign: ElementRef<HTMLSpanElement>;
    @ViewChild('goalAmountLabel') goalAmountLabel: ElementRef<HTMLSpanElement>;

    @ViewChild('progressBarOuter') progressBarOuter: ElementRef<HTMLSpanElement>;
    @ViewChild('progressBarInner') progressBarInner: ElementRef<HTMLSpanElement>;

    @Input() identity: ComponentIdentity;
    @Input() config: { showProgress: boolean, showDonationCount: boolean } & any;

    currentFlow: Flow;
    currentPage: PageWithComponentList;

    theme: string;

    raisedAmount = 0;
    goalAmount = 0;
    totalDonations = 0;

    private viewInitialized = new AsyncSubject<void>();
    private destroyTriggered = new Subject<void>();

    constructor(
        private currentState: CurrentStateService,
        private previewService: PreviewService,
    ) { }

    ngOnInit() {
        zip(
            this.currentState.flow.pipe(map((flow) => this.currentFlow = flow)),
            this.currentState.page.pipe(map((page) => this.currentPage = page)),
        ).subscribe(() => {
            this.loadGoalAmount();
            this.theme = this.currentFlow.theme;
            if (this.previewService.isPreviewMode()) {
                this.raisedAmount = 14500;
            } else if (!this.previewService.isPreviewMode()) {
                const refreshPeriod = this.currentFlow.type === FlowType.Slideshow ? 3000 : null;
                timer(0, refreshPeriod).pipe(takeUntil(this.destroyTriggered)).subscribe(() => {
                    this.loadRaisedAmount();
                });
            }
        });
    }

    ngOnDestroy() {
        this.destroyTriggered.next();
    }

    abstract loadRaisedAmount(): void;

    abstract loadGoalAmount(): void;

    abstract getGoalAmount(): number;

    ngAfterViewInit() {
        this.viewInitialized.next();
        this.viewInitialized.complete();
    }

    getProgressPercentage(): number {
        if (this.previewService.isPreviewMode()) {
            return 65;
        } else {
            return Math.min(100, Math.floor(100 * this.raisedAmount / this.goalAmount));
        }
    }

    getProgressBarColor(): string {
        if (this.currentPage.primaryColor === '') {
            return '#6A6970';
        } else {
            return this.currentPage.primaryColor;
        }
    }

    shouldShowGoal(): boolean {
        return this.currentPage.layout === PageLayout.Grid16x9 ? this.config.showProgress : true;
    }

    shouldShowComponent(): boolean {
        return this.currentPage.layout === PageLayout.Grid16x9 ? true : this.config.showProgress;
    }

    @Input()
    isValid = (): boolean => {
        return true;
    }

    @Input()
    getValue = (): void => { }

    @Input()
    markAsTouched = (): void => { }

    @Input()
    handleExecutionError = (error: any): void => { }

    @Input()
    listenForSubmit = (): void => { }
}
