import { NgModule } from '@angular/core';

import { RedirectComponent } from './redirect.component';

export * from './redirect.component';

@NgModule({
    declarations: [
        RedirectComponent,
    ],
})
export class RedirectModule { }
