import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    ViewChild,
    ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NumbersOnlyFormatter } from '../../formatters/numbers-only.formatter';
import { NumberUtility } from '@onecause/core';

@Component({
    selector: 'flow-shared-donation-form',
    templateUrl: './donation-form.component.html',
    styleUrls: ['./donation-form.component.scss']
})
export class SharedDonationFormComponent implements OnInit {

    @Input() presetAmounts: number[];
    @Input() recurringPresetAmounts: number[];
    @Input() defaultValue: number;
    @Input() preventDeselect: boolean;
    @Input() showRecurringDonationManagement: boolean;
    @Input() recurringDonationPeriod: string;
    @Output() donationAmountCents: EventEmitter<number> = new EventEmitter();
    @Output() isRecurringDonation: EventEmitter<boolean> = new EventEmitter();
    @Output() isAnonymous: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('customDonationInput') customDonationInput: ElementRef<HTMLInputElement>;

    selectedDonationAmount: number;
    isRecurring = false;
    isCustomActive = false;
    isAnonymousFlag: boolean;
    customDonation: FormControl;

    ngOnInit() {
        if (!this.presetAmounts) { this.presetAmounts = []; }
        if (!this.recurringPresetAmounts) { this.recurringPresetAmounts = []; }
        if (!this.showRecurringDonationManagement) { this.showRecurringDonationManagement = false; }
        if (!this.recurringDonationPeriod) { this.recurringDonationPeriod = ''; }

        this.customDonation = new FormControl('');
        NumbersOnlyFormatter.initialize(this.customDonation);

        this.listenForCustomDonationChanges();

        if (this.defaultValue) {
            this.prepopulateDonation();
        }
        this.isAnonymousFlag = false;
    }

    private listenForCustomDonationChanges(): void {
        this.customDonation.valueChanges.subscribe(amountString => {
            if (amountString === '') {
                this.resetDonationAmount();
            }
            else {
                const amount = parseFloat(amountString);
                if (amount) {
                    this.updateSelectedAmount(amount);
                }
            }
        });
    }

    private prepopulateDonation(): void {
        if (this.presetAmounts.includes(this.defaultValue)) {
            this.selectedDonationAmount = this.defaultValue;
        } else {
            this.isCustomActive = true;
            this.customDonation.setValue(this.defaultValue);
        }
        this.updateSelectedAmount(this.defaultValue);
    }

    onAmountSelected(amount: number): void {
        if (this.selectedDonationAmount === amount && !this.preventDeselect) {
            this.resetDonationAmount();
        }
        else {
            const roundedAmount = NumberUtility.roundHundredths(amount);
            this.isCustomActive = false;
            this.selectedDonationAmount = roundedAmount;
            this.updateSelectedAmount(roundedAmount);
        }
    }

    onCustomAmountSelected(): void {
        if (this.isCustomActive) {
            this.isCustomActive = false;
            this.resetDonationAmount();
        }
        else {
            this.customDonation.reset();
            this.resetDonationAmount();
            this.isCustomActive = true;
            setTimeout(() => {
                if (this.customDonationInput) {
                    this.customDonationInput.nativeElement.focus();
                }
            });
        }
    }

    setRecurring(isRecurring: boolean) {
        this.isRecurring = isRecurring;
        this.isRecurringDonation.next(isRecurring);
    }

    toggleAnonymous() {
        this.isAnonymousFlag = !this.isAnonymousFlag;
        this.isAnonymous.next(this.isAnonymousFlag);
    }

    private resetDonationAmount(): void {
        this.selectedDonationAmount = 0;
        this.updateSelectedAmount(0);
    }

    private updateSelectedAmount(amountDollars: number): void {
        this.donationAmountCents.next(amountDollars * 100);
    }
}
