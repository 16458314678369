import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationUtility, ZipCodeValidator } from '@onecause/core';
import { CardInfo } from './creditcard/creditcard.component';


export class BillingAddress {
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
export class CreditCardFormData {
  billingAddress: BillingAddress;
  cardInfo: CardInfo;
}

@Component({
  selector: 'flow-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CreditCardFormComponent implements OnInit, ControlValueAccessor {
  @Input() donorName = '';
  @Input() organizationID: string;
  @Input() merchantAccountID: string;
  @Input() showBillingAddress = false;
  @Input() requireBillingAddress = false;
  @Input() currencyCode = 'USD';
  @Input() iFrameID;
  @Output() creditCardFormUpdated = new EventEmitter<CreditCardFormData>();

  creditCardForm: FormGroup;
  cardInfo: FormControl;
  cardName: FormControl;

  creditCardFormData: CreditCardFormData;
  billingAddress: BillingAddress;
  street: FormControl;
  city: FormControl;
  stateSelect: FormControl;
  zipCode: FormControl;
  countrySelect: FormControl;
  private countryLookupList: any = LocationUtility.countryListLookup;

  defaultCountryCode = 'USA';

  private onChange: (val: CreditCardFormData) => void = (val: CreditCardFormData) => { };
  private onTouched: () => void = () => { };

  ngOnInit() {
    if (this.currencyCode === 'CAD') {
      this.showBillingAddress = true;
      this.requireBillingAddress = true;
      this.defaultCountryCode = 'CAN';
    }
    this.initForm();
  }

  writeValue(value: CreditCardFormData) {
    this.cardName.setValue(value.cardInfo.name);
    if (this.showBillingAddress) {
      this.street.setValue(value.billingAddress.street);
      this.city.setValue(value.billingAddress.city);
      this.stateSelect.setValue(value.billingAddress.state);
      this.zipCode.setValue(value.billingAddress.zip);
      this.countrySelect.setValue(value.billingAddress.country);
    }
  }

  registerOnChange(fn: (val: CreditCardFormData) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  getZipCodeLabel() {
    return (this.countrySelect?.value === this.countryLookupList.CAN.code) ? 'Postal Code' : 'Zip Code';
  }

  private initForm() {
    this.cardName = new FormControl(this.donorName, Validators.required);
    this.cardInfo = new FormControl(null, [Validators.required]);

    this.creditCardForm = new FormGroup({
      cardName: this.cardName,
      cardInfo: this.cardInfo,
    });

    if (this.showBillingAddress) {
      this.street = new FormControl('');
      this.city = new FormControl('');
      this.stateSelect = new FormControl('');
      this.zipCode = new FormControl('', ZipCodeValidator(this.defaultCountryCode));
      this.countrySelect = new FormControl(this.defaultCountryCode);

      if (this.requireBillingAddress) {
        this.street.setValidators([Validators.required]);
        this.city.setValidators([Validators.required]);
        this.stateSelect.setValidators([Validators.required]);
        this.zipCode.setValidators([Validators.required, ZipCodeValidator(this.defaultCountryCode)]);
        this.countrySelect.setValidators([Validators.required]);
      }

      this.creditCardForm.addControl('street', this.street);
      this.creditCardForm.addControl('city', this.city);
      this.creditCardForm.addControl('countrySelect', this.countrySelect);
      this.creditCardForm.addControl('stateSelect', this.stateSelect);
      this.creditCardForm.addControl('zipCode', this.zipCode);

      this.countrySelect.valueChanges.subscribe(value => {
        this.zipCode.setValidators(ZipCodeValidator(value));
        this.zipCode.updateValueAndValidity();
        if (this.zipCode.invalid) {
          this.zipCode.setValue('');
        }
      });
    }

    this.creditCardForm.valueChanges.subscribe(() => {
      this.onTouched();

      if (!this.creditCardForm.valid) {
        this.outputFormData(null);
        return;
      }

      const cardInfo = this.cardInfo.value as CardInfo;
      cardInfo.name = this.cardName.value;

      let address: BillingAddress = null;
      if (this.showBillingAddress) {
        address = {
          street: this.street.value,
          city: this.city.value,
          state: this.stateSelect.value,
          zip: this.zipCode.value,
          country: this.countrySelect.value,
        };
      }

      const formData = {
        cardInfo: cardInfo,
        billingAddress: address,
      } as CreditCardFormData;

      this.outputFormData(formData);
    });
  }

  private outputFormData(formData: CreditCardFormData) {
    this.onChange(formData);
    this.creditCardFormUpdated.next(formData);
  }
}
