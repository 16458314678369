import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { FlowComponent } from '../flow-components.model';

export class CustomHTMLConfig {
  content: string;
}

@Component({
  selector: 'flow-custom-html',
  templateUrl: './custom-html.component.html',
  styleUrls: ['./custom-html.component.scss']
})
export class CustomHtmlComponent implements FlowComponent<CustomHTMLConfig, void> {

  @Input() config: CustomHTMLConfig;
  @ViewChild('customHTMLContainer', { static: true }) customHTMLContainer: ElementRef<HTMLDivElement>;

  constructor() { }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (): void => { }

  @Input()
  listenForSubmit = (): void => { }
}
