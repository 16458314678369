import { NgModule } from '@angular/core';

import { ForTestingComponent } from './for-testing.component';

export * from './for-testing.component';

@NgModule({
    declarations: [
        ForTestingComponent,
    ],
})
export class ForTestingModule { }
