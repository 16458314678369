/* This file is generated, do not change manually */
/* tslint:disable */
/* eslint-disable */
/* istanbul ignore file */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHttpClient, PagingInputV3, PagingOutputV3, PhaasResponse } from '@onecause/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type TypeWithFieldsOf<T> = Pick<T, {[Key in keyof T]: T[Key] extends Function ? never : Key}[keyof T]>

@Injectable()
export class GeneratedVirtualEventRestService {
  private urlHost = 'env:phaas-virtualevent-api';
  private jwt: string;

  constructor(
    private httpClient: HttpClient,
    private authHttpClient: AuthHttpClient
  ) { }

  withJWT(jwt: string): GeneratedVirtualEventRestService {
    const newService = new GeneratedVirtualEventRestService(this.httpClient, this.authHttpClient);
    newService.jwt = jwt;
    return newService;
  }

  private getAuthHttpClient(): HttpClient {
    if (this.jwt) {
      return this.httpClient;
    } else {
      return this.authHttpClient;
    }
  }

  private getHeaders(): HttpHeaders {
    if (this.jwt) {
      return new HttpHeaders().set('Authorization', 'Bearer ' + this.jwt);
    } else {
      return null;
    }
  }

  addProxyBidder(input: AddProxyBidderInput): Observable<AddProxyBidderOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/proxy-guests/add-proxy-bidder`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<AddProxyBidderOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<AddProxyBidderOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) AddProxyBidderOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<AddProxyBidderOutput>) => {
        return new AddProxyBidderOutput(input).fixClasses();
      }),
    );
  }

  addProxyLink(input: AddProxyLinkInput): Observable<AddProxyLinkOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/proxy-guests/add-proxy-link`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<AddProxyLinkOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<AddProxyLinkOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) AddProxyLinkOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<AddProxyLinkOutput>) => {
        return new AddProxyLinkOutput(input).fixClasses();
      }),
    );
  }

  banFromLivestreamChat(input: BanFromLivestreamChatInput): Observable<BanFromLivestreamChatOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/chat-users/${input.userID}/ban`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<BanFromLivestreamChatOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<BanFromLivestreamChatOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) BanFromLivestreamChatOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<BanFromLivestreamChatOutput>) => {
        return new BanFromLivestreamChatOutput(input).fixClasses();
      }),
    );
  }

  buyItems(input: BuyItemsInput): Observable<BuyItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items`;
    const headers = this.getHeaders();
    return this.httpClient.post<PhaasResponse<TypeWithFieldsOf<BuyItemsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<BuyItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) BuyItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<BuyItemsOutput>) => {
        return new BuyItemsOutput(input).fixClasses();
      }),
    );
  }

  buyNow(input: BuyNowInput): Observable<BuyNowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/buy-now`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<BuyNowOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<BuyNowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) BuyNowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<BuyNowOutput>) => {
        return new BuyNowOutput(input).fixClasses();
      }),
    );
  }

  calculateCoverCost(input: CalculateCoverCostInput): Observable<CalculateCoverCostOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/cover-costs/calculate`;
    const headers = this.getHeaders();
    return this.httpClient.post<PhaasResponse<TypeWithFieldsOf<CalculateCoverCostOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CalculateCoverCostOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CalculateCoverCostOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CalculateCoverCostOutput>) => {
        return new CalculateCoverCostOutput(input).fixClasses();
      }),
    );
  }

  calculatePurchaseTotals(input: CalculatePurchaseTotalsInput): Observable<CalculatePurchaseTotalsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items/totals`;
    const headers = this.getHeaders();
    return this.httpClient.post<PhaasResponse<TypeWithFieldsOf<CalculatePurchaseTotalsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CalculatePurchaseTotalsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CalculatePurchaseTotalsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CalculatePurchaseTotalsOutput>) => {
        return new CalculatePurchaseTotalsOutput(input).fixClasses();
      }),
    );
  }

  calculateTotalAmount(input: CalculateTotalAmountInput): Observable<CalculateTotalAmountOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/calculate-total-amount`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CalculateTotalAmountOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CalculateTotalAmountOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CalculateTotalAmountOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CalculateTotalAmountOutput>) => {
        return new CalculateTotalAmountOutput(input).fixClasses();
      }),
    );
  }

  checkGuestForClaimedTicket(input: CheckGuestForClaimedTicketInput): Observable<CheckGuestForClaimedTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/${input.eventSessionID}/my/check-for-claimed-ticket`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CheckGuestForClaimedTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CheckGuestForClaimedTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CheckGuestForClaimedTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CheckGuestForClaimedTicketOutput>) => {
        return new CheckGuestForClaimedTicketOutput(input).fixClasses();
      }),
    );
  }

  checkInGuestSession(input: CheckInGuestSessionInput): Observable<CheckInGuestSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/${input.guestSessionID}/check-in`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CheckInGuestSessionOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CheckInGuestSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CheckInGuestSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CheckInGuestSessionOutput>) => {
        return new CheckInGuestSessionOutput(input).fixClasses();
      }),
    );
  }

  checkInMyGuestSessions(input: CheckInMyGuestSessionsInput): Observable<CheckInMyGuestSessionsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/my/check-in-my-sessions`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CheckInMyGuestSessionsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CheckInMyGuestSessionsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CheckInMyGuestSessionsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CheckInMyGuestSessionsOutput>) => {
        return new CheckInMyGuestSessionsOutput(input).fixClasses();
      }),
    );
  }

  checkInMyTickets(input: CheckInMyTicketsInput): Observable<CheckInMyTicketsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/check-in-tickets`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<CheckInMyTicketsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CheckInMyTicketsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CheckInMyTicketsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CheckInMyTicketsOutput>) => {
        return new CheckInMyTicketsOutput(input).fixClasses();
      }),
    );
  }

  claimGuestFromToken(input: ClaimGuestFromTokenInput): Observable<ClaimGuestFromTokenOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/${input.eventSessionID}/my/claim-guest-from-token`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<ClaimGuestFromTokenOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ClaimGuestFromTokenOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ClaimGuestFromTokenOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ClaimGuestFromTokenOutput>) => {
        return new ClaimGuestFromTokenOutput(input).fixClasses();
      }),
    );
  }

  claimMyTicket(input: ClaimMyTicketInput): Observable<ClaimMyTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/${input.eventSessionID}/my/claim-my-ticket`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<ClaimMyTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ClaimMyTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ClaimMyTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ClaimMyTicketOutput>) => {
        return new ClaimMyTicketOutput(input).fixClasses();
      }),
    );
  }

  claimTicketAndRegister(input: ClaimTicketAndRegisterInput): Observable<ClaimTicketAndRegisterOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/claim-ticket-and-register`;
    const headers = this.getHeaders();
    return this.httpClient.post<PhaasResponse<TypeWithFieldsOf<ClaimTicketAndRegisterOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ClaimTicketAndRegisterOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ClaimTicketAndRegisterOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ClaimTicketAndRegisterOutput>) => {
        return new ClaimTicketAndRegisterOutput(input).fixClasses();
      }),
    );
  }

  clearAllBids(input: ClearAllBidsInput): Observable<ClearAllBidsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/clear-all-bids`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<ClearAllBidsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ClearAllBidsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ClearAllBidsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ClearAllBidsOutput>) => {
        return new ClearAllBidsOutput(input).fixClasses();
      }),
    );
  }

  closeBidding(input: CloseBiddingInput): Observable<CloseBiddingOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/close-bidding`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<CloseBiddingOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CloseBiddingOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CloseBiddingOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CloseBiddingOutput>) => {
        return new CloseBiddingOutput(input).fixClasses();
      }),
    );
  }

  closeBroadcast(input: CloseBroadcastInput): Observable<CloseBroadcastOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/control/shows/${input.showID}/broadcast-ended`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<CloseBroadcastOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CloseBroadcastOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CloseBroadcastOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CloseBroadcastOutput>) => {
        return new CloseBroadcastOutput(input).fixClasses();
      }),
    );
  }

  completeMyPreEventQuestionnaire(input: CompleteMyPreEventQuestionnaireInput): Observable<CompleteMyPreEventQuestionnaireOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/complete-pre-event-questionnaire`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CompleteMyPreEventQuestionnaireOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CompleteMyPreEventQuestionnaireOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CompleteMyPreEventQuestionnaireOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CompleteMyPreEventQuestionnaireOutput>) => {
        return new CompleteMyPreEventQuestionnaireOutput(input).fixClasses();
      }),
    );
  }

  compTicket(input: CompTicketInput): Observable<CompTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/comp`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CompTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CompTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CompTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CompTicketOutput>) => {
        return new CompTicketOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: CompTicketOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  compTickets(input: CompTicketsInput): Observable<CompTicketsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/comp-tickets`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CompTicketsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CompTicketsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CompTicketsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CompTicketsOutput>) => {
        return new CompTicketsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: CompTicketsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  countDonationMoments(input: CountDonationMomentsInput): Observable<CountDonationMomentsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donationmoments/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountDonationMomentsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountDonationMomentsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountDonationMomentsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountDonationMomentsOutput>) => {
        return new CountDonationMomentsOutput(input).fixClasses();
      }),
    );
  }

  countEvents(input: CountEventsInput): Observable<CountEventsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountEventsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountEventsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountEventsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountEventsOutput>) => {
        return new CountEventsOutput(input).fixClasses();
      }),
    );
  }

  countEventSessions(input: CountEventSessionsInput): Observable<CountEventSessionsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountEventSessionsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountEventSessionsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountEventSessionsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountEventSessionsOutput>) => {
        return new CountEventSessionsOutput(input).fixClasses();
      }),
    );
  }

  countEventSessionTicketConfigs(input: CountEventSessionTicketConfigsInput): Observable<CountEventSessionTicketConfigsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/ticketconfigs/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountEventSessionTicketConfigsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountEventSessionTicketConfigsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountEventSessionTicketConfigsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountEventSessionTicketConfigsOutput>) => {
        return new CountEventSessionTicketConfigsOutput(input).fixClasses();
      }),
    );
  }

  countEventSlideConfigs(input: CountEventSlideConfigsInput): Observable<CountEventSlideConfigsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideconfig/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountEventSlideConfigsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountEventSlideConfigsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountEventSlideConfigsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountEventSlideConfigsOutput>) => {
        return new CountEventSlideConfigsOutput(input).fixClasses();
      }),
    );
  }

  countEventSlideshows(input: CountEventSlideshowsInput): Observable<CountEventSlideshowsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideshow/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountEventSlideshowsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountEventSlideshowsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountEventSlideshowsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountEventSlideshowsOutput>) => {
        return new CountEventSlideshowsOutput(input).fixClasses();
      }),
    );
  }

  countFixedPriceItemPhotos(input: CountFixedPriceItemPhotosInput): Observable<CountFixedPriceItemPhotosOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.fixedPriceItemID}/photos/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountFixedPriceItemPhotosOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountFixedPriceItemPhotosOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountFixedPriceItemPhotosOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountFixedPriceItemPhotosOutput>) => {
        return new CountFixedPriceItemPhotosOutput(input).fixClasses();
      }),
    );
  }

  countFixedPriceItems(input: CountFixedPriceItemsInput): Observable<CountFixedPriceItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountFixedPriceItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountFixedPriceItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountFixedPriceItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountFixedPriceItemsOutput>) => {
        return new CountFixedPriceItemsOutput(input).fixClasses();
      }),
    );
  }

  countGuests(input: CountGuestsInput): Observable<CountGuestsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountGuestsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountGuestsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountGuestsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountGuestsOutput>) => {
        return new CountGuestsOutput(input).fixClasses();
      }),
    );
  }

  countGuestSessions(input: CountGuestSessionsInput): Observable<CountGuestSessionsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountGuestSessionsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountGuestSessionsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountGuestSessionsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountGuestSessionsOutput>) => {
        return new CountGuestSessionsOutput(input).fixClasses();
      }),
    );
  }

  countImpactPhotos(input: CountImpactPhotosInput): Observable<CountImpactPhotosOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}/photos/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountImpactPhotosOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountImpactPhotosOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountImpactPhotosOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountImpactPhotosOutput>) => {
        return new CountImpactPhotosOutput(input).fixClasses();
      }),
    );
  }

  countImpacts(input: CountImpactsInput): Observable<CountImpactsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountImpactsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountImpactsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountImpactsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountImpactsOutput>) => {
        return new CountImpactsOutput(input).fixClasses();
      }),
    );
  }

  countMyTickets(input: CountMyTicketsInput): Observable<CountMyTicketsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountMyTicketsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountMyTicketsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountMyTicketsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountMyTicketsOutput>) => {
        return new CountMyTicketsOutput(input).fixClasses();
      }),
    );
  }

  countOverlays(input: CountOverlaysInput): Observable<CountOverlaysOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}/overlays/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountOverlaysOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountOverlaysOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountOverlaysOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountOverlaysOutput>) => {
        return new CountOverlaysOutput(input).fixClasses();
      }),
    );
  }

  countPickupItems(input: CountPickupItemsInput): Observable<CountPickupItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/pickupitems/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountPickupItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountPickupItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountPickupItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountPickupItemsOutput>) => {
        return new CountPickupItemsOutput(input).fixClasses();
      }),
    );
  }

  countPreEventQuestionConfigs(input: CountPreEventQuestionConfigsInput): Observable<CountPreEventQuestionConfigsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/preeventquestionconfigs/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountPreEventQuestionConfigsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountPreEventQuestionConfigsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountPreEventQuestionConfigsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountPreEventQuestionConfigsOutput>) => {
        return new CountPreEventQuestionConfigsOutput(input).fixClasses();
      }),
    );
  }

  countPurchasedItems(input: CountPurchasedItemsInput): Observable<CountPurchasedItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountPurchasedItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountPurchasedItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountPurchasedItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountPurchasedItemsOutput>) => {
        return new CountPurchasedItemsOutput(input).fixClasses();
      }),
    );
  }

  countPurchasedItemSalesTaxes(input: CountPurchasedItemSalesTaxesInput): Observable<CountPurchasedItemSalesTaxesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchaseditemsalestax/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountPurchasedItemSalesTaxesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountPurchasedItemSalesTaxesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountPurchasedItemSalesTaxesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountPurchasedItemSalesTaxesOutput>) => {
        return new CountPurchasedItemSalesTaxesOutput(input).fixClasses();
      }),
    );
  }

  countRegistrations(input: CountRegistrationsInput): Observable<CountRegistrationsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountRegistrationsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountRegistrationsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountRegistrationsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountRegistrationsOutput>) => {
        return new CountRegistrationsOutput(input).fixClasses();
      }),
    );
  }

  countScenes(input: CountScenesInput): Observable<CountScenesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountScenesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountScenesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountScenesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountScenesOutput>) => {
        return new CountScenesOutput(input).fixClasses();
      }),
    );
  }

  countShows(input: CountShowsInput): Observable<CountShowsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<CountShowsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountShowsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountShowsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountShowsOutput>) => {
        return new CountShowsOutput(input).fixClasses();
      }),
    );
  }

  countVirtualChairs(input: CountVirtualChairsInput): Observable<CountVirtualChairsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountVirtualChairsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountVirtualChairsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountVirtualChairsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountVirtualChairsOutput>) => {
        return new CountVirtualChairsOutput(input).fixClasses();
      }),
    );
  }

  countVirtualTables(input: CountVirtualTablesInput): Observable<CountVirtualTablesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<CountVirtualTablesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CountVirtualTablesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CountVirtualTablesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CountVirtualTablesOutput>) => {
        return new CountVirtualTablesOutput(input).fixClasses();
      }),
    );
  }

  createAuctionAndScene(input: CreateAuctionAndSceneInput): Observable<CreateAuctionAndSceneOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/scenes/auction-scene`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateAuctionAndSceneOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateAuctionAndSceneOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateAuctionAndSceneOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateAuctionAndSceneOutput>) => {
        return new CreateAuctionAndSceneOutput(input).fixClasses();
      }),
    );
  }

  createBidLimit(input: CreateBidLimitInput): Observable<CreateBidLimitOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/create-bid-limit`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateBidLimitOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateBidLimitOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateBidLimitOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateBidLimitOutput>) => {
        return new CreateBidLimitOutput(input).fixClasses();
      }),
    );
  }

  createChannel(input: CreateChannelInput): Observable<CreateChannelOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs/${input.virtualChairID}/channel`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateChannelOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateChannelOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateChannelOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateChannelOutput>) => {
        return new CreateChannelOutput(input).fixClasses();
      }),
    );
  }

  createDonationMoment(input: CreateDonationMomentInput): Observable<CreateDonationMomentOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donationmoments`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateDonationMomentOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateDonationMomentOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateDonationMomentOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateDonationMomentOutput>) => {
        return new CreateDonationMomentOutput(input).fixClasses();
      }),
    );
  }

  createDonationMomentAndSceneOverlay(input: CreateDonationMomentAndSceneOverlayInput): Observable<CreateDonationMomentAndSceneOverlayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donation-moments/scene-overlay`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateDonationMomentAndSceneOverlayOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateDonationMomentAndSceneOverlayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateDonationMomentAndSceneOverlayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateDonationMomentAndSceneOverlayOutput>) => {
        return new CreateDonationMomentAndSceneOverlayOutput(input).fixClasses();
      }),
    );
  }

  createEvent(input: CreateEventInput): Observable<CreateEventOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateEventOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateEventOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateEventOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateEventOutput>) => {
        return new CreateEventOutput(input).fixClasses();
      }),
    );
  }

  createEventSession(input: CreateEventSessionInput): Observable<CreateEventSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateEventSessionOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateEventSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateEventSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateEventSessionOutput>) => {
        return new CreateEventSessionOutput(input).fixClasses();
      }),
    );
  }

  createEventSessionTicketConfig(input: CreateEventSessionTicketConfigInput): Observable<CreateEventSessionTicketConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/ticketconfigs`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateEventSessionTicketConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateEventSessionTicketConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateEventSessionTicketConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateEventSessionTicketConfigOutput>) => {
        return new CreateEventSessionTicketConfigOutput(input).fixClasses();
      }),
    );
  }

  createEventSlideConfig(input: CreateEventSlideConfigInput): Observable<CreateEventSlideConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideconfig`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateEventSlideConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateEventSlideConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateEventSlideConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateEventSlideConfigOutput>) => {
        return new CreateEventSlideConfigOutput(input).fixClasses();
      }),
    );
  }

  createEventSlideshow(input: CreateEventSlideshowInput): Observable<CreateEventSlideshowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideshow`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateEventSlideshowOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateEventSlideshowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateEventSlideshowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateEventSlideshowOutput>) => {
        return new CreateEventSlideshowOutput(input).fixClasses();
      }),
    );
  }

  createFixedPriceItem(input: CreateFixedPriceItemInput): Observable<CreateFixedPriceItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateFixedPriceItemOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateFixedPriceItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateFixedPriceItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateFixedPriceItemOutput>) => {
        return new CreateFixedPriceItemOutput(input).fixClasses();
      }),
    );
  }

  createFixedPriceItemPhoto(input: CreateFixedPriceItemPhotoInput): Observable<CreateFixedPriceItemPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.fixedPriceItemID}/photos`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateFixedPriceItemPhotoOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateFixedPriceItemPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateFixedPriceItemPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateFixedPriceItemPhotoOutput>) => {
        return new CreateFixedPriceItemPhotoOutput(input).fixClasses();
      }),
    );
  }

  createGuest(input: CreateGuestInput): Observable<CreateGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateGuestOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateGuestOutput>) => {
        return new CreateGuestOutput(input).fixClasses();
      }),
    );
  }

  createGuestSession(input: CreateGuestSessionInput): Observable<CreateGuestSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateGuestSessionOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateGuestSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateGuestSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateGuestSessionOutput>) => {
        return new CreateGuestSessionOutput(input).fixClasses();
      }),
    );
  }

  createGuestWithoutHook(input: CreateGuestWithoutHookInput): Observable<CreateGuestWithoutHookOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/create-without-hook`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateGuestWithoutHookOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateGuestWithoutHookOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateGuestWithoutHookOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateGuestWithoutHookOutput>) => {
        return new CreateGuestWithoutHookOutput(input).fixClasses();
      }),
    );
  }

  createImpact(input: CreateImpactInput): Observable<CreateImpactOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateImpactOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateImpactOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateImpactOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateImpactOutput>) => {
        return new CreateImpactOutput(input).fixClasses();
      }),
    );
  }

  createImpactPhoto(input: CreateImpactPhotoInput): Observable<CreateImpactPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}/photos`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateImpactPhotoOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateImpactPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateImpactPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateImpactPhotoOutput>) => {
        return new CreateImpactPhotoOutput(input).fixClasses();
      }),
    );
  }

  createInvitation(input: CreateInvitationInput): Observable<CreateInvitationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateInvitationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateInvitationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateInvitationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateInvitationOutput>) => {
        return new CreateInvitationOutput(input).fixClasses();
      }),
    );
  }

  createOverlay(input: CreateOverlayInput): Observable<CreateOverlayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}/overlays`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateOverlayOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateOverlayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateOverlayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateOverlayOutput>) => {
        return new CreateOverlayOutput(input).fixClasses();
      }),
    );
  }

  createPickupItem(input: CreatePickupItemInput): Observable<CreatePickupItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/pickupitems`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreatePickupItemOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreatePickupItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreatePickupItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreatePickupItemOutput>) => {
        return new CreatePickupItemOutput(input).fixClasses();
      }),
    );
  }

  createPreEventQuestionConfig(input: CreatePreEventQuestionConfigInput): Observable<CreatePreEventQuestionConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/preeventquestionconfigs`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreatePreEventQuestionConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreatePreEventQuestionConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreatePreEventQuestionConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreatePreEventQuestionConfigOutput>) => {
        return new CreatePreEventQuestionConfigOutput(input).fixClasses();
      }),
    );
  }

  createPurchasedItem(input: CreatePurchasedItemInput): Observable<CreatePurchasedItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreatePurchasedItemOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreatePurchasedItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreatePurchasedItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreatePurchasedItemOutput>) => {
        return new CreatePurchasedItemOutput(input).fixClasses();
      }),
    );
  }

  createPurchasedItemSalesTax(input: CreatePurchasedItemSalesTaxInput): Observable<CreatePurchasedItemSalesTaxOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchaseditemsalestax`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreatePurchasedItemSalesTaxOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreatePurchasedItemSalesTaxOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreatePurchasedItemSalesTaxOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreatePurchasedItemSalesTaxOutput>) => {
        return new CreatePurchasedItemSalesTaxOutput(input).fixClasses();
      }),
    );
  }

  createRaffle(input: CreateRaffleInput): Observable<CreateRaffleOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/raffles`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateRaffleOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateRaffleOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateRaffleOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateRaffleOutput>) => {
        return new CreateRaffleOutput(input).fixClasses();
      }),
    );
  }

  createRegistration(input: CreateRegistrationInput): Observable<CreateRegistrationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateRegistrationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateRegistrationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateRegistrationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateRegistrationOutput>) => {
        return new CreateRegistrationOutput(input).fixClasses();
      }),
    );
  }

  createScene(input: CreateSceneInput): Observable<CreateSceneOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateSceneOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateSceneOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateSceneOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateSceneOutput>) => {
        return new CreateSceneOutput(input).fixClasses();
      }),
    );
  }

  createSceneAndOverlay(input: CreateSceneAndOverlayInput): Observable<CreateSceneAndOverlayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/scenes/${input.showID}/scene-overlay`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateSceneAndOverlayOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateSceneAndOverlayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateSceneAndOverlayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateSceneAndOverlayOutput>) => {
        return new CreateSceneAndOverlayOutput(input).fixClasses();
      }),
    );
  }

  createShow(input: CreateShowInput): Observable<CreateShowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateShowOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateShowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateShowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateShowOutput>) => {
        return new CreateShowOutput(input).fixClasses();
      }),
    );
  }

  createTicketTypeAndConfig(input: CreateTicketTypeAndConfigInput): Observable<CreateTicketTypeAndConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/create-ticket-type/${input.eventSessionID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<CreateTicketTypeAndConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateTicketTypeAndConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateTicketTypeAndConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateTicketTypeAndConfigOutput>) => {
        return new CreateTicketTypeAndConfigOutput(input).fixClasses();
      }),
    );
  }

  createVirtualChair(input: CreateVirtualChairInput): Observable<CreateVirtualChairOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateVirtualChairOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateVirtualChairOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateVirtualChairOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateVirtualChairOutput>) => {
        return new CreateVirtualChairOutput(input).fixClasses();
      }),
    );
  }

  createVirtualTable(input: CreateVirtualTableInput): Observable<CreateVirtualTableOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<CreateVirtualTableOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<CreateVirtualTableOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) CreateVirtualTableOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<CreateVirtualTableOutput>) => {
        return new CreateVirtualTableOutput(input).fixClasses();
      }),
    );
  }

  deleteChannel(input: DeleteChannelInput): Observable<DeleteChannelOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs/${input.virtualChairID}/channel`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteChannelOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteChannelOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteChannelOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteChannelOutput>) => {
        return new DeleteChannelOutput(input).fixClasses();
      }),
    );
  }

  deleteDonationMoment(input: DeleteDonationMomentInput): Observable<DeleteDonationMomentOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donationmoments/${input.donationMomentID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteDonationMomentOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteDonationMomentOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteDonationMomentOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteDonationMomentOutput>) => {
        return new DeleteDonationMomentOutput(input).fixClasses();
      }),
    );
  }

  deleteEvent(input: DeleteEventInput): Observable<DeleteEventOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteEventOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteEventOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteEventOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteEventOutput>) => {
        return new DeleteEventOutput(input).fixClasses();
      }),
    );
  }

  deleteEventSession(input: DeleteEventSessionInput): Observable<DeleteEventSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteEventSessionOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteEventSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteEventSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteEventSessionOutput>) => {
        return new DeleteEventSessionOutput(input).fixClasses();
      }),
    );
  }

  deleteEventSessionTicketConfig(input: DeleteEventSessionTicketConfigInput): Observable<DeleteEventSessionTicketConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/ticketconfigs/${input.eventSessionTicketConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteEventSessionTicketConfigOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteEventSessionTicketConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteEventSessionTicketConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteEventSessionTicketConfigOutput>) => {
        return new DeleteEventSessionTicketConfigOutput(input).fixClasses();
      }),
    );
  }

  deleteEventSlideConfig(input: DeleteEventSlideConfigInput): Observable<DeleteEventSlideConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideconfig/${input.eventSlideConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteEventSlideConfigOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteEventSlideConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteEventSlideConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteEventSlideConfigOutput>) => {
        return new DeleteEventSlideConfigOutput(input).fixClasses();
      }),
    );
  }

  deleteEventSlideshow(input: DeleteEventSlideshowInput): Observable<DeleteEventSlideshowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideshow/${input.eventSlideshowID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteEventSlideshowOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteEventSlideshowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteEventSlideshowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteEventSlideshowOutput>) => {
        return new DeleteEventSlideshowOutput(input).fixClasses();
      }),
    );
  }

  deleteFixedPriceItem(input: DeleteFixedPriceItemInput): Observable<DeleteFixedPriceItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.itemID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteFixedPriceItemOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteFixedPriceItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteFixedPriceItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteFixedPriceItemOutput>) => {
        return new DeleteFixedPriceItemOutput(input).fixClasses();
      }),
    );
  }

  deleteFixedPriceItemPhoto(input: DeleteFixedPriceItemPhotoInput): Observable<DeleteFixedPriceItemPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.fixedPriceItemID}/photos/${input.photoID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteFixedPriceItemPhotoOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteFixedPriceItemPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteFixedPriceItemPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteFixedPriceItemPhotoOutput>) => {
        return new DeleteFixedPriceItemPhotoOutput(input).fixClasses();
      }),
    );
  }

  deleteGuest(input: DeleteGuestInput): Observable<DeleteGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/${input.guestID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteGuestOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteGuestOutput>) => {
        return new DeleteGuestOutput(input).fixClasses();
      }),
    );
  }

  deleteGuestSession(input: DeleteGuestSessionInput): Observable<DeleteGuestSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/${input.guestSessionID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteGuestSessionOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteGuestSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteGuestSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteGuestSessionOutput>) => {
        return new DeleteGuestSessionOutput(input).fixClasses();
      }),
    );
  }

  deleteImpact(input: DeleteImpactInput): Observable<DeleteImpactOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteImpactOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteImpactOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteImpactOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteImpactOutput>) => {
        return new DeleteImpactOutput(input).fixClasses();
      }),
    );
  }

  deleteImpactPhoto(input: DeleteImpactPhotoInput): Observable<DeleteImpactPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}/photos/${input.photoID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteImpactPhotoOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteImpactPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteImpactPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteImpactPhotoOutput>) => {
        return new DeleteImpactPhotoOutput(input).fixClasses();
      }),
    );
  }

  deleteInvitation(input: DeleteInvitationInput): Observable<DeleteInvitationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/${input.invitationID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteInvitationOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteInvitationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteInvitationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteInvitationOutput>) => {
        return new DeleteInvitationOutput(input).fixClasses();
      }),
    );
  }

  deleteLivestreamChatMessage(input: DeleteLivestreamChatMessageInput): Observable<DeleteLivestreamChatMessageOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/livestream-chat/channels/${input.livestreamChatChannelID}/messages/${input.messageTimestamp}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteLivestreamChatMessageOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteLivestreamChatMessageOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteLivestreamChatMessageOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteLivestreamChatMessageOutput>) => {
        return new DeleteLivestreamChatMessageOutput(input).fixClasses();
      }),
    );
  }

  deleteOverlay(input: DeleteOverlayInput): Observable<DeleteOverlayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}/overlays/${input.overlayID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteOverlayOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteOverlayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteOverlayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteOverlayOutput>) => {
        return new DeleteOverlayOutput(input).fixClasses();
      }),
    );
  }

  deletePickupItem(input: DeletePickupItemInput): Observable<DeletePickupItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/pickupitems/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeletePickupItemOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeletePickupItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeletePickupItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeletePickupItemOutput>) => {
        return new DeletePickupItemOutput(input).fixClasses();
      }),
    );
  }

  deletePreEventQuestionConfig(input: DeletePreEventQuestionConfigInput): Observable<DeletePreEventQuestionConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/preeventquestionconfigs/${input.preEventQuestionConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeletePreEventQuestionConfigOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeletePreEventQuestionConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeletePreEventQuestionConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeletePreEventQuestionConfigOutput>) => {
        return new DeletePreEventQuestionConfigOutput(input).fixClasses();
      }),
    );
  }

  deletePurchasedItem(input: DeletePurchasedItemInput): Observable<DeletePurchasedItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeletePurchasedItemOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeletePurchasedItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeletePurchasedItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeletePurchasedItemOutput>) => {
        return new DeletePurchasedItemOutput(input).fixClasses();
      }),
    );
  }

  deletePurchasedItemSalesTax(input: DeletePurchasedItemSalesTaxInput): Observable<DeletePurchasedItemSalesTaxOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchaseditemsalestax/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeletePurchasedItemSalesTaxOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeletePurchasedItemSalesTaxOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeletePurchasedItemSalesTaxOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeletePurchasedItemSalesTaxOutput>) => {
        return new DeletePurchasedItemSalesTaxOutput(input).fixClasses();
      }),
    );
  }

  deleteRegistration(input: DeleteRegistrationInput): Observable<DeleteRegistrationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/${input.registrationID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteRegistrationOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteRegistrationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteRegistrationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteRegistrationOutput>) => {
        return new DeleteRegistrationOutput(input).fixClasses();
      }),
    );
  }

  deleteScene(input: DeleteSceneInput): Observable<DeleteSceneOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteSceneOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteSceneOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteSceneOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteSceneOutput>) => {
        return new DeleteSceneOutput(input).fixClasses();
      }),
    );
  }

  deleteShow(input: DeleteShowInput): Observable<DeleteShowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteShowOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteShowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteShowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteShowOutput>) => {
        return new DeleteShowOutput(input).fixClasses();
      }),
    );
  }

  deleteTicket(input: DeleteTicketInput): Observable<DeleteTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/${input.ticketID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteTicketOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteTicketOutput>) => {
        return new DeleteTicketOutput(input).fixClasses();
      }),
    );
  }

  deleteVirtualChair(input: DeleteVirtualChairInput): Observable<DeleteVirtualChairOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteVirtualChairOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteVirtualChairOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteVirtualChairOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteVirtualChairOutput>) => {
        return new DeleteVirtualChairOutput(input).fixClasses();
      }),
    );
  }

  deleteVirtualTable(input: DeleteVirtualTableInput): Observable<DeleteVirtualTableOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DeleteVirtualTableOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DeleteVirtualTableOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DeleteVirtualTableOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DeleteVirtualTableOutput>) => {
        return new DeleteVirtualTableOutput(input).fixClasses();
      }),
    );
  }

  disableLivestreamChat(input: DisableLivestreamChatInput): Observable<DisableLivestreamChatOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/chat-control/livestream/disable`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<DisableLivestreamChatOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DisableLivestreamChatOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DisableLivestreamChatOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DisableLivestreamChatOutput>) => {
        return new DisableLivestreamChatOutput(input).fixClasses();
      }),
    );
  }

  disconnectRaffle(input: DisconnectRaffleInput): Observable<DisconnectRaffleOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/raffles/${input.raffleID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().delete<PhaasResponse<TypeWithFieldsOf<DisconnectRaffleOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DisconnectRaffleOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DisconnectRaffleOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DisconnectRaffleOutput>) => {
        return new DisconnectRaffleOutput(input).fixClasses();
      }),
    );
  }

  donateAndSelfRegister(input: DonateAndSelfRegisterInput): Observable<DonateAndSelfRegisterOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my/donate-and-self-register`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<DonateAndSelfRegisterOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<DonateAndSelfRegisterOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) DonateAndSelfRegisterOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<DonateAndSelfRegisterOutput>) => {
        return new DonateAndSelfRegisterOutput(input).fixClasses();
      }),
    );
  }

  enableLivestreamChat(input: EnableLivestreamChatInput): Observable<EnableLivestreamChatOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/chat-control/livestream/enable`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<EnableLivestreamChatOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<EnableLivestreamChatOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) EnableLivestreamChatOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<EnableLivestreamChatOutput>) => {
        return new EnableLivestreamChatOutput(input).fixClasses();
      }),
    );
  }

  fetchPurchasedItemsForRefundIDs(input: FetchPurchasedItemsForRefundIDsInput): Observable<FetchPurchasedItemsForRefundIDsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/refunds/item-details`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<FetchPurchasedItemsForRefundIDsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<FetchPurchasedItemsForRefundIDsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) FetchPurchasedItemsForRefundIDsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<FetchPurchasedItemsForRefundIDsOutput>) => {
        return new FetchPurchasedItemsForRefundIDsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: FetchPurchasedItemsForRefundIDsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getAppealLevels(input: GetAppealLevelsInput): Observable<GetAppealLevelsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/overlays/appeal-levels`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetAppealLevelsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetAppealLevelsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetAppealLevelsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetAppealLevelsOutput>) => {
        return new GetAppealLevelsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetAppealLevelsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getAttendeeExperience(input: GetAttendeeExperienceInput): Observable<GetAttendeeExperienceOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guestexperience`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetAttendeeExperienceOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetAttendeeExperienceOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetAttendeeExperienceOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetAttendeeExperienceOutput>) => {
        return new GetAttendeeExperienceOutput(input).fixClasses();
      }),
    );
  }

  getChatUser(input: GetChatUserInput): Observable<GetChatUserOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/chat-users/by-user-token`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetChatUserOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetChatUserOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetChatUserOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetChatUserOutput>) => {
        return new GetChatUserOutput(input).fixClasses();
      }),
    );
  }

  getConnectionResourcesForChannel(input: GetConnectionResourcesForChannelInput): Observable<GetConnectionResourcesForChannelOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/connection-resources-for-channel`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<GetConnectionResourcesForChannelOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetConnectionResourcesForChannelOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetConnectionResourcesForChannelOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetConnectionResourcesForChannelOutput>) => {
        return new GetConnectionResourcesForChannelOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetConnectionResourcesForChannelOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getDuringEventAnalytics(input: GetDuringEventAnalyticsInput): Observable<GetDuringEventAnalyticsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tablemanagement/analytics/${input.eventSessionID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetDuringEventAnalyticsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetDuringEventAnalyticsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetDuringEventAnalyticsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetDuringEventAnalyticsOutput>) => {
        return new GetDuringEventAnalyticsOutput(input).fixClasses();
      }),
    );
  }

  getEventNotificationToken(input: GetEventNotificationTokenInput): Observable<GetEventNotificationTokenOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/notifications/token`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<GetEventNotificationTokenOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetEventNotificationTokenOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetEventNotificationTokenOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetEventNotificationTokenOutput>) => {
        return new GetEventNotificationTokenOutput(input).fixClasses();
      }),
    );
  }

  getGuestMessagingRecipients(input: GetGuestMessagingRecipientsInput): Observable<GetGuestMessagingRecipientsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guestmessaging/personalize`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetGuestMessagingRecipientsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetGuestMessagingRecipientsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetGuestMessagingRecipientsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetGuestMessagingRecipientsOutput>) => {
        return new GetGuestMessagingRecipientsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetGuestMessagingRecipientsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getGuestMobilePhoneNumbers(input: GetGuestMobilePhoneNumbersInput): Observable<GetGuestMobilePhoneNumbersOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guestmessaging`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetGuestMobilePhoneNumbersOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetGuestMobilePhoneNumbersOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetGuestMobilePhoneNumbersOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetGuestMobilePhoneNumbersOutput>) => {
        return new GetGuestMobilePhoneNumbersOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetGuestMobilePhoneNumbersOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getGuestMobilePhoneNumbersCount(input: GetGuestMobilePhoneNumbersCountInput): Observable<GetGuestMobilePhoneNumbersCountOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guestmessaging/count`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetGuestMobilePhoneNumbersCountOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetGuestMobilePhoneNumbersCountOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetGuestMobilePhoneNumbersCountOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetGuestMobilePhoneNumbersCountOutput>) => {
        return new GetGuestMobilePhoneNumbersCountOutput(input).fixClasses();
      }),
    );
  }

  getGuestSessionSummary(input: GetGuestSessionSummaryInput): Observable<GetGuestSessionSummaryOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guest-sessions/${input.ticketTicketID}/guest-session-summary`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetGuestSessionSummaryOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetGuestSessionSummaryOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetGuestSessionSummaryOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetGuestSessionSummaryOutput>) => {
        return new GetGuestSessionSummaryOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetGuestSessionSummaryOutput) => {
        return input.convertDates();
      }),
    );
  }

  getGuestSessionSummaryByID(input: GetGuestSessionSummaryByIDInput): Observable<GetGuestSessionSummaryByIDOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guest-sessions/summary`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetGuestSessionSummaryByIDOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetGuestSessionSummaryByIDOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetGuestSessionSummaryByIDOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetGuestSessionSummaryByIDOutput>) => {
        return new GetGuestSessionSummaryByIDOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetGuestSessionSummaryByIDOutput) => {
        return input.convertDates();
      }),
    );
  }

  getGuestSummary(input: GetGuestSummaryInput): Observable<GetGuestSummaryOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/${input.ticketTicketID}/summary`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetGuestSummaryOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetGuestSummaryOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetGuestSummaryOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetGuestSummaryOutput>) => {
        return new GetGuestSummaryOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetGuestSummaryOutput) => {
        return input.convertDates();
      }),
    );
  }

  getListDonationMomentsToCSVURL(input: ListDonationMomentsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donationmoments/csv`;
    return url;
  }

  getListEventSessionsToCSVURL(input: ListEventSessionsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/csv`;
    return url;
  }

  getListEventSessionTicketConfigsToCSVURL(input: ListEventSessionTicketConfigsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/ticketconfigs/csv`;
    return url;
  }

  getListEventSlideConfigsToCSVURL(input: ListEventSlideConfigsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideconfig/csv`;
    return url;
  }

  getListEventSlideshowsToCSVURL(input: ListEventSlideshowsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideshow/csv`;
    return url;
  }

  getListEventsToCSVURL(input: ListEventsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/csv`;
    return url;
  }

  getListFixedPriceItemPhotosToCSVURL(input: ListFixedPriceItemPhotosToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.fixedPriceItemID}/photos/csv`;
    return url;
  }

  getListFixedPriceItemsToCSVURL(input: ListFixedPriceItemsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/csv`;
    return url;
  }

  getListGuestSessionsToCSVURL(input: ListGuestSessionsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/csv`;
    return url;
  }

  getListGuestsToCSVURL(input: ListGuestsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/csv`;
    return url;
  }

  getListImpactPhotosToCSVURL(input: ListImpactPhotosToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}/photos/csv`;
    return url;
  }

  getListImpactsToCSVURL(input: ListImpactsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/csv`;
    return url;
  }

  getListOverlaysToCSVURL(input: ListOverlaysToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}/overlays/csv`;
    return url;
  }

  getListPickupItemsToCSVURL(input: ListPickupItemsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/pickupitems/csv`;
    return url;
  }

  getListPreEventQuestionConfigsToCSVURL(input: ListPreEventQuestionConfigsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/preeventquestionconfigs/csv`;
    return url;
  }

  getListPurchasedItemSalesTaxesToCSVURL(input: ListPurchasedItemSalesTaxesToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchaseditemsalestax/csv`;
    return url;
  }

  getListPurchasedItemsToCSVURL(input: ListPurchasedItemsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/csv`;
    return url;
  }

  getListRegistrationsToCSVURL(input: ListRegistrationsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/csv`;
    return url;
  }

  getListScenesToCSVURL(input: ListScenesToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/csv`;
    return url;
  }

  getListShowsToCSVURL(input: ListShowsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/csv`;
    return url;
  }

  getListVirtualChairsToCSVURL(input: ListVirtualChairsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs/csv`;
    return url;
  }

  getListVirtualTablesToCSVURL(input: ListVirtualTablesToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/csv`;
    return url;
  }

  getMyCheckInStatus(input: GetMyCheckInStatusInput): Observable<GetMyCheckInStatusOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/my/check-in-status`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetMyCheckInStatusOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetMyCheckInStatusOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetMyCheckInStatusOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetMyCheckInStatusOutput>) => {
        return new GetMyCheckInStatusOutput(input).fixClasses();
      }),
    );
  }

  getMyExperienceType(input: GetMyExperienceTypeInput): Observable<GetMyExperienceTypeOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/my/experience-type`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetMyExperienceTypeOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetMyExperienceTypeOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetMyExperienceTypeOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetMyExperienceTypeOutput>) => {
        return new GetMyExperienceTypeOutput(input).fixClasses();
      }),
    );
  }

  getMyGuestDemographics(input: GetMyGuestDemographicsInput): Observable<GetMyGuestDemographicsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/my/guest-demographics`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetMyGuestDemographicsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetMyGuestDemographicsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetMyGuestDemographicsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetMyGuestDemographicsOutput>) => {
        return new GetMyGuestDemographicsOutput(input).fixClasses();
      }),
    );
  }

  getMyGuestDemographicsPublic(input: GetMyGuestDemographicsPublicInput): Observable<GetMyGuestDemographicsPublicOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/my/guest-demographics-public`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<GetMyGuestDemographicsPublicOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetMyGuestDemographicsPublicOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetMyGuestDemographicsPublicOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetMyGuestDemographicsPublicOutput>) => {
        return new GetMyGuestDemographicsPublicOutput(input).fixClasses();
      }),
    );
  }

  getMyGuestSessionSummary(input: GetMyGuestSessionSummaryInput): Observable<GetMyGuestSessionSummaryOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/my/guest-session-summary`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetMyGuestSessionSummaryOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetMyGuestSessionSummaryOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetMyGuestSessionSummaryOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetMyGuestSessionSummaryOutput>) => {
        return new GetMyGuestSessionSummaryOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetMyGuestSessionSummaryOutput) => {
        return input.convertDates();
      }),
    );
  }

  getOrgPickupItemOptions(input: GetOrgPickupItemOptionsInput): Observable<GetOrgPickupItemOptionsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/orgpickupitemoptions`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<GetOrgPickupItemOptionsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetOrgPickupItemOptionsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetOrgPickupItemOptionsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetOrgPickupItemOptionsOutput>) => {
        return new GetOrgPickupItemOptionsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetOrgPickupItemOptionsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getPreEventAnalytics(input: GetPreEventAnalyticsInput): Observable<GetPreEventAnalyticsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tablemanagement/analytics/${input.eventSessionID}/preevent`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetPreEventAnalyticsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetPreEventAnalyticsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetPreEventAnalyticsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetPreEventAnalyticsOutput>) => {
        return new GetPreEventAnalyticsOutput(input).fixClasses();
      }),
    );
  }

  getPrimaryBidder(input: GetPrimaryBidderInput): Observable<GetPrimaryBidderOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/get-primary-bidder/${input.bidderNumber}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetPrimaryBidderOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetPrimaryBidderOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetPrimaryBidderOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetPrimaryBidderOutput>) => {
        return new GetPrimaryBidderOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetPrimaryBidderOutput) => {
        return input.convertDates();
      }),
    );
  }

  getPurchasedItemsUserSummary(input: GetPurchasedItemsUserSummaryInput): Observable<GetPurchasedItemsUserSummaryOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items/purchased-summary`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetPurchasedItemsUserSummaryOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetPurchasedItemsUserSummaryOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetPurchasedItemsUserSummaryOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetPurchasedItemsUserSummaryOutput>) => {
        return new GetPurchasedItemsUserSummaryOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetPurchasedItemsUserSummaryOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetPurchasedItemsUserSummaryOutput) => {
        return input.convertDates();
      }),
    );
  }

  getSelectedPickupTimeSlots(input: GetSelectedPickupTimeSlotsInput): Observable<GetSelectedPickupTimeSlotsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/checkout/selected-pickup-time-slots`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetSelectedPickupTimeSlotsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetSelectedPickupTimeSlotsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetSelectedPickupTimeSlotsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetSelectedPickupTimeSlotsOutput>) => {
        return new GetSelectedPickupTimeSlotsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetSelectedPickupTimeSlotsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetSelectedPickupTimeSlotsOutput) => {
        return input.convertDates();
      }),
    );
  }

  getSendEventAuctionClosedURL(input: SendEventAuctionClosedInput): string {
    const url = `${this.urlHost}/deventer/AuctionClosed`;
    return url;
  }

  getSendEventAuctionUpdatedURL(input: SendEventAuctionUpdatedInput): string {
    const url = `${this.urlHost}/deventer/AuctionUpdated`;
    return url;
  }

  getSendEventDailyURL(input: SendEventDailyInput): string {
    const url = `${this.urlHost}/deventer/Daily`;
    return url;
  }

  getSendEventGuestSessionCreatedURL(input: SendEventGuestSessionCreatedInput): string {
    const url = `${this.urlHost}/deventer/GuestSessionCreated`;
    return url;
  }

  getSendEventHourlyURL(input: SendEventHourlyInput): string {
    const url = `${this.urlHost}/deventer/Hourly`;
    return url;
  }

  getSendEventInventoryItemPhotoUpdatedURL(input: SendEventInventoryItemPhotoUpdatedInput): string {
    const url = `${this.urlHost}/deventer/InventoryItemPhotoUpdated`;
    return url;
  }

  getSendEventLotClosedURL(input: SendEventLotClosedInput): string {
    const url = `${this.urlHost}/deventer/LotClosed`;
    return url;
  }

  getSendEventNightlyURL(input: SendEventNightlyInput): string {
    const url = `${this.urlHost}/deventer/Nightly`;
    return url;
  }

  getSendEventRafflePurchaseLevelCreatedURL(input: SendEventRafflePurchaseLevelCreatedInput): string {
    const url = `${this.urlHost}/deventer/RafflePurchaseLevelCreated`;
    return url;
  }

  getSendEventRafflePurchaseLevelDeletedURL(input: SendEventRafflePurchaseLevelDeletedInput): string {
    const url = `${this.urlHost}/deventer/RafflePurchaseLevelDeleted`;
    return url;
  }

  getSendEventRafflePurchaseLevelUpdatedURL(input: SendEventRafflePurchaseLevelUpdatedInput): string {
    const url = `${this.urlHost}/deventer/RafflePurchaseLevelUpdated`;
    return url;
  }

  getSendEventRaffleRaffleDeletedURL(input: SendEventRaffleRaffleDeletedInput): string {
    const url = `${this.urlHost}/deventer/RaffleRaffleDeleted`;
    return url;
  }

  getSendEventRaffleRaffleUpdatedURL(input: SendEventRaffleRaffleUpdatedInput): string {
    const url = `${this.urlHost}/deventer/RaffleRaffleUpdated`;
    return url;
  }

  getSendEventSendCheckedInNotifcationURL(input: SendEventSendCheckedInNotifcationInput): string {
    const url = `${this.urlHost}/deventer/SendCheckedInNotifcation`;
    return url;
  }

  getSendEventSendCheckInMessageURL(input: SendEventSendCheckInMessageInput): string {
    const url = `${this.urlHost}/deventer/SendCheckInMessage`;
    return url;
  }

  getSendEventSendPreEventQuestionnaireMessageURL(input: SendEventSendPreEventQuestionnaireMessageInput): string {
    const url = `${this.urlHost}/deventer/SendPreEventQuestionnaireMessage`;
    return url;
  }

  getSendEventSendTicketInviteEmailURL(input: SendEventSendTicketInviteEmailInput): string {
    const url = `${this.urlHost}/deventer/SendTicketInviteEmail`;
    return url;
  }

  getSendEventSendTicketManagerEmailURL(input: SendEventSendTicketManagerEmailInput): string {
    const url = `${this.urlHost}/deventer/SendTicketManagerEmail`;
    return url;
  }

  getSendEventSupporterUserUpdatedURL(input: SendEventSupporterUserUpdatedInput): string {
    const url = `${this.urlHost}/deventer/SupporterUserUpdated`;
    return url;
  }

  getSendEventVirtualEventImpactGoalMetURL(input: SendEventVirtualEventImpactGoalMetInput): string {
    const url = `${this.urlHost}/deventer/VirtualEventImpactGoalMet`;
    return url;
  }

  getSendEventVirtualEventProcessPendingPurchasedItemsURL(input: SendEventVirtualEventProcessPendingPurchasedItemsInput): string {
    const url = `${this.urlHost}/deventer/VirtualEventProcessPendingPurchasedItems`;
    return url;
  }

  getSendEventVirtualEventPurchaseSuccessfulURL(input: SendEventVirtualEventPurchaseSuccessfulInput): string {
    const url = `${this.urlHost}/deventer/VirtualEventPurchaseSuccessful`;
    return url;
  }

  getSendEventVirtualEventPurchaseSuccessfulWithoutPaymentURL(input: SendEventVirtualEventPurchaseSuccessfulWithoutPaymentInput): string {
    const url = `${this.urlHost}/deventer/VirtualEventPurchaseSuccessfulWithoutPayment`;
    return url;
  }

  getSendEventVirtualEventRegistrationCreatedURL(input: SendEventVirtualEventRegistrationCreatedInput): string {
    const url = `${this.urlHost}/deventer/VirtualEventRegistrationCreated`;
    return url;
  }

  getSharedCartGuestList(input: GetSharedCartGuestListInput): Observable<GetSharedCartGuestListOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/${input.guestID}/shared-cart-guests`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetSharedCartGuestListOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetSharedCartGuestListOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetSharedCartGuestListOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetSharedCartGuestListOutput>) => {
        return new GetSharedCartGuestListOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetSharedCartGuestListOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: GetSharedCartGuestListOutput) => {
        return input.convertDates();
      }),
    );
  }

  getTaxCentsForGuestPurchasedItems(input: GetTaxCentsForGuestPurchasedItemsInput): Observable<GetTaxCentsForGuestPurchasedItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items/sales-tax-guest`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<GetTaxCentsForGuestPurchasedItemsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetTaxCentsForGuestPurchasedItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetTaxCentsForGuestPurchasedItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetTaxCentsForGuestPurchasedItemsOutput>) => {
        return new GetTaxCentsForGuestPurchasedItemsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetTaxCentsForGuestPurchasedItemsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getTaxCentsForPurchasedItems(input: GetTaxCentsForPurchasedItemsInput): Observable<GetTaxCentsForPurchasedItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items/sales-tax`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<GetTaxCentsForPurchasedItemsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetTaxCentsForPurchasedItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetTaxCentsForPurchasedItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetTaxCentsForPurchasedItemsOutput>) => {
        return new GetTaxCentsForPurchasedItemsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: GetTaxCentsForPurchasedItemsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  getTrackInvitationEmailOpenedURL(input: TrackInvitationEmailOpenedInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/${input.invitationID}/email-opened`;
    return url;
  }

  getUnprocessedItemsData(input: GetUnprocessedItemsDataInput): Observable<GetUnprocessedItemsDataOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items/unprocessed`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<GetUnprocessedItemsDataOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<GetUnprocessedItemsDataOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) GetUnprocessedItemsDataOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<GetUnprocessedItemsDataOutput>) => {
        return new GetUnprocessedItemsDataOutput(input).fixClasses();
      }),
    );
  }

  getViewBannedLivestreamChatUserRegistrationsToCSVURL(input: ViewBannedLivestreamChatUserRegistrationsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/bannedlivestreamchatuserregistrations/csv`;
    return url;
  }

  getViewDonationItemToCSVURL(input: ViewDonationItemToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/donationitem/csv`;
    return url;
  }

  getViewDonationsByDayToCSVURL(input: ViewDonationsByDayToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/donationsbyday/csv`;
    return url;
  }

  getViewDonationSumPerDonorToCSVURL(input: ViewDonationSumPerDonorToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/donationsumperdonor/csv`;
    return url;
  }

  getViewEventRegistrationPurchasesToCSVURL(input: ViewEventRegistrationPurchasesToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/eventregistrationpurchases/csv`;
    return url;
  }

  getViewEventRegistrationsByDayToCSVURL(input: ViewEventRegistrationsByDayToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/eventregistrationsbyday/csv`;
    return url;
  }

  getViewEventRegistrationsToCSVURL(input: ViewEventRegistrationsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/eventregistrations/csv`;
    return url;
  }

  getViewFixedPriceDetailsToCSVURL(input: ViewFixedPriceDetailsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/view/fixedpricedetails/csv`;
    return url;
  }

  getViewGuestsWithPurchasedItemCountsToCSVURL(input: ViewGuestsWithPurchasedItemCountsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/view/guestswithpurchaseditemcounts/csv`;
    return url;
  }

  getViewPurchasedItemAnonymousToCSVURL(input: ViewPurchasedItemAnonymousToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/purchaseditemanonymous/csv`;
    return url;
  }

  getViewPurchasedItemToCSVURL(input: ViewPurchasedItemToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/purchaseditem/csv`;
    return url;
  }

  getViewRecentDonorsToCSVURL(input: ViewRecentDonorsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/recentdonors/csv`;
    return url;
  }

  getViewSearchGuestsToCSVURL(input: ViewSearchGuestsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/view/searchguests/csv`;
    return url;
  }

  getViewStatusSumItemsToCSVURL(input: ViewStatusSumItemsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/statussumitems/csv`;
    return url;
  }

  getViewSupporterPurchasedItemByPickupTimeToCSVURL(input: ViewSupporterPurchasedItemByPickupTimeToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/supporterpurchaseditembypickuptime/csv`;
    return url;
  }

  getViewSupporterPurchasedItemDetailToCSVURL(input: ViewSupporterPurchasedItemDetailToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/supporterpurchaseditemdetail/csv`;
    return url;
  }

  getViewSupporterPurchasedItemToCSVURL(input: ViewSupporterPurchasedItemToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/supporterpurchaseditem/csv`;
    return url;
  }

  getViewVirtualChairsToCSVURL(input: ViewVirtualChairsToCSVInput): string {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/view/virtualchairs/csv`;
    return url;
  }

  hasAssignedTicketForTicketManagerTokenEmail(input: HasAssignedTicketForTicketManagerTokenEmailInput): Observable<HasAssignedTicketForTicketManagerTokenEmailOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/has-assigned-ticket-for-ticket-manager-token-email`;
    const headers = this.getHeaders();
    return this.httpClient.post<PhaasResponse<TypeWithFieldsOf<HasAssignedTicketForTicketManagerTokenEmailOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<HasAssignedTicketForTicketManagerTokenEmailOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) HasAssignedTicketForTicketManagerTokenEmailOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<HasAssignedTicketForTicketManagerTokenEmailOutput>) => {
        return new HasAssignedTicketForTicketManagerTokenEmailOutput(input).fixClasses();
      }),
    );
  }

  initiateRecurringDonation(input: InitiateRecurringDonationInput): Observable<InitiateRecurringDonationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/recurring-donation`;
    const headers = this.getHeaders();
    return this.httpClient.post<PhaasResponse<TypeWithFieldsOf<InitiateRecurringDonationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<InitiateRecurringDonationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) InitiateRecurringDonationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<InitiateRecurringDonationOutput>) => {
        return new InitiateRecurringDonationOutput(input).fixClasses();
      }),
    );
  }

  isRegistrationComped(input: IsRegistrationCompedInput): Observable<IsRegistrationCompedOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my/is-comped`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<IsRegistrationCompedOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<IsRegistrationCompedOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) IsRegistrationCompedOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<IsRegistrationCompedOutput>) => {
        return new IsRegistrationCompedOutput(input).fixClasses();
      }),
    );
  }

  isTicketManagerTokenEmailRegistered(input: IsTicketManagerTokenEmailRegisteredInput): Observable<IsTicketManagerTokenEmailRegisteredOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/is-ticket-manager-token-email-registered`;
    const headers = this.getHeaders();
    return this.httpClient.post<PhaasResponse<TypeWithFieldsOf<IsTicketManagerTokenEmailRegisteredOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<IsTicketManagerTokenEmailRegisteredOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) IsTicketManagerTokenEmailRegisteredOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<IsTicketManagerTokenEmailRegisteredOutput>) => {
        return new IsTicketManagerTokenEmailRegisteredOutput(input).fixClasses();
      }),
    );
  }

  listAttendeesByGroup(input: ListAttendeesByGroupInput): Observable<ListAttendeesByGroupOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tablemanagement/group/${input.groupID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListAttendeesByGroupOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListAttendeesByGroupOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListAttendeesByGroupOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListAttendeesByGroupOutput>) => {
        return new ListAttendeesByGroupOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListAttendeesByGroupOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListAttendeesByGroupOutput) => {
        return input.convertDates();
      }),
    );
  }

  listAvailableCreditCards(input: ListAvailableCreditCardsInput): Observable<ListAvailableCreditCardsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/giving/credit-cards`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<ListAvailableCreditCardsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListAvailableCreditCardsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListAvailableCreditCardsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListAvailableCreditCardsOutput>) => {
        return new ListAvailableCreditCardsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListAvailableCreditCardsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listDonationMoments(input: ListDonationMomentsInput): Observable<ListDonationMomentsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donationmoments`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListDonationMomentsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListDonationMomentsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListDonationMomentsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListDonationMomentsOutput>) => {
        return new ListDonationMomentsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListDonationMomentsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listEvents(input: ListEventsInput): Observable<ListEventsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListEventsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListEventsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListEventsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListEventsOutput>) => {
        return new ListEventsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListEventsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListEventsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listEventSessions(input: ListEventSessionsInput): Observable<ListEventSessionsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListEventSessionsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListEventSessionsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListEventSessionsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListEventSessionsOutput>) => {
        return new ListEventSessionsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListEventSessionsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listEventSessionTicketConfigs(input: ListEventSessionTicketConfigsInput): Observable<ListEventSessionTicketConfigsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/ticketconfigs`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListEventSessionTicketConfigsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListEventSessionTicketConfigsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListEventSessionTicketConfigsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListEventSessionTicketConfigsOutput>) => {
        return new ListEventSessionTicketConfigsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListEventSessionTicketConfigsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listEventSlideConfigs(input: ListEventSlideConfigsInput): Observable<ListEventSlideConfigsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideconfig`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListEventSlideConfigsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListEventSlideConfigsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListEventSlideConfigsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListEventSlideConfigsOutput>) => {
        return new ListEventSlideConfigsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListEventSlideConfigsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listEventSlideshows(input: ListEventSlideshowsInput): Observable<ListEventSlideshowsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideshow`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListEventSlideshowsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListEventSlideshowsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListEventSlideshowsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListEventSlideshowsOutput>) => {
        return new ListEventSlideshowsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListEventSlideshowsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listFixedPriceItemPhotos(input: ListFixedPriceItemPhotosInput): Observable<ListFixedPriceItemPhotosOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.fixedPriceItemID}/photos`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListFixedPriceItemPhotosOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListFixedPriceItemPhotosOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListFixedPriceItemPhotosOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListFixedPriceItemPhotosOutput>) => {
        return new ListFixedPriceItemPhotosOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListFixedPriceItemPhotosOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listFixedPriceItems(input: ListFixedPriceItemsInput): Observable<ListFixedPriceItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListFixedPriceItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListFixedPriceItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListFixedPriceItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListFixedPriceItemsOutput>) => {
        return new ListFixedPriceItemsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListFixedPriceItemsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listGuests(input: ListGuestsInput): Observable<ListGuestsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListGuestsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListGuestsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListGuestsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListGuestsOutput>) => {
        return new ListGuestsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListGuestsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListGuestsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listGuestSessions(input: ListGuestSessionsInput): Observable<ListGuestSessionsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListGuestSessionsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListGuestSessionsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListGuestSessionsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListGuestSessionsOutput>) => {
        return new ListGuestSessionsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListGuestSessionsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListGuestSessionsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listGuestSessionSummaries(input: ListGuestSessionSummariesInput): Observable<ListGuestSessionSummariesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/guest-session-summaries`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListGuestSessionSummariesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListGuestSessionSummariesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListGuestSessionSummariesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListGuestSessionSummariesOutput>) => {
        return new ListGuestSessionSummariesOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListGuestSessionSummariesOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListGuestSessionSummariesOutput) => {
        return input.convertDates();
      }),
    );
  }

  listGuestSessionSummariesForTickets(input: ListGuestSessionSummariesForTicketsInput): Observable<ListGuestSessionSummariesForTicketsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/guest-session-summaries-for-tickets`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListGuestSessionSummariesForTicketsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListGuestSessionSummariesForTicketsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListGuestSessionSummariesForTicketsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListGuestSessionSummariesForTicketsOutput>) => {
        return new ListGuestSessionSummariesForTicketsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListGuestSessionSummariesForTicketsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListGuestSessionSummariesForTicketsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listImpactPhotos(input: ListImpactPhotosInput): Observable<ListImpactPhotosOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}/photos`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListImpactPhotosOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListImpactPhotosOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListImpactPhotosOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListImpactPhotosOutput>) => {
        return new ListImpactPhotosOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListImpactPhotosOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listImpacts(input: ListImpactsInput): Observable<ListImpactsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListImpactsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListImpactsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListImpactsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListImpactsOutput>) => {
        return new ListImpactsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListImpactsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListImpactsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listInvitations(input: ListInvitationsInput): Observable<ListInvitationsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListInvitationsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListInvitationsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListInvitationsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListInvitationsOutput>) => {
        return new ListInvitationsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListInvitationsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListInvitationsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listItemsForGuest(input: ListItemsForGuestInput): Observable<ListItemsForGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/guest-items`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListItemsForGuestOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListItemsForGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListItemsForGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListItemsForGuestOutput>) => {
        return new ListItemsForGuestOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListItemsForGuestOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListItemsForGuestOutput) => {
        return input.convertDates();
      }),
    );
  }

  listItemsForUser(input: ListItemsForUserInput): Observable<ListItemsForUserOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListItemsForUserOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListItemsForUserOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListItemsForUserOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListItemsForUserOutput>) => {
        return new ListItemsForUserOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListItemsForUserOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListItemsForUserOutput) => {
        return input.convertDates();
      }),
    );
  }

  listItemsForUserRefund(input: ListItemsForUserRefundInput): Observable<ListItemsForUserRefundOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items-for-refund`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<ListItemsForUserRefundOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListItemsForUserRefundOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListItemsForUserRefundOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListItemsForUserRefundOutput>) => {
        return new ListItemsForUserRefundOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListItemsForUserRefundOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListItemsForUserRefundOutput) => {
        return input.convertDates();
      }),
    );
  }

  listMyAvailableCreditCards(input: ListMyAvailableCreditCardsInput): Observable<ListMyAvailableCreditCardsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/giving/my/credit-cards`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListMyAvailableCreditCardsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListMyAvailableCreditCardsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListMyAvailableCreditCardsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListMyAvailableCreditCardsOutput>) => {
        return new ListMyAvailableCreditCardsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListMyAvailableCreditCardsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listMyGuestSessionSummaries(input: ListMyGuestSessionSummariesInput): Observable<ListMyGuestSessionSummariesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/my/guest-session-summaries`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListMyGuestSessionSummariesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListMyGuestSessionSummariesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListMyGuestSessionSummariesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListMyGuestSessionSummariesOutput>) => {
        return new ListMyGuestSessionSummariesOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListMyGuestSessionSummariesOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListMyGuestSessionSummariesOutput) => {
        return input.convertDates();
      }),
    );
  }

  listMyIncompleteGuestSessionIDs(input: ListMyIncompleteGuestSessionIDsInput): Observable<ListMyIncompleteGuestSessionIDsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/my/incomplete-guest-session-ids`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListMyIncompleteGuestSessionIDsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListMyIncompleteGuestSessionIDsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListMyIncompleteGuestSessionIDsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListMyIncompleteGuestSessionIDsOutput>) => {
        return new ListMyIncompleteGuestSessionIDsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListMyIncompleteGuestSessionIDsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listMyTickets(input: ListMyTicketsInput): Observable<ListMyTicketsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListMyTicketsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListMyTicketsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListMyTicketsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListMyTicketsOutput>) => {
        return new ListMyTicketsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListMyTicketsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListMyTicketsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listOverlays(input: ListOverlaysInput): Observable<ListOverlaysOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}/overlays`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListOverlaysOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListOverlaysOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListOverlaysOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListOverlaysOutput>) => {
        return new ListOverlaysOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListOverlaysOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listPickupItems(input: ListPickupItemsInput): Observable<ListPickupItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/pickupitems`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListPickupItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListPickupItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListPickupItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListPickupItemsOutput>) => {
        return new ListPickupItemsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListPickupItemsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListPickupItemsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listPreEventQuestionConfigs(input: ListPreEventQuestionConfigsInput): Observable<ListPreEventQuestionConfigsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/preeventquestionconfigs`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListPreEventQuestionConfigsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListPreEventQuestionConfigsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListPreEventQuestionConfigsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListPreEventQuestionConfigsOutput>) => {
        return new ListPreEventQuestionConfigsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListPreEventQuestionConfigsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listPurchasedItems(input: ListPurchasedItemsInput): Observable<ListPurchasedItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListPurchasedItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListPurchasedItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListPurchasedItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListPurchasedItemsOutput>) => {
        return new ListPurchasedItemsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListPurchasedItemsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListPurchasedItemsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listPurchasedItemSalesTaxes(input: ListPurchasedItemSalesTaxesInput): Observable<ListPurchasedItemSalesTaxesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchaseditemsalestax`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListPurchasedItemSalesTaxesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListPurchasedItemSalesTaxesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListPurchasedItemSalesTaxesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListPurchasedItemSalesTaxesOutput>) => {
        return new ListPurchasedItemSalesTaxesOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListPurchasedItemSalesTaxesOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListPurchasedItemSalesTaxesOutput) => {
        return input.convertDates();
      }),
    );
  }

  listRaffles(input: ListRafflesInput): Observable<ListRafflesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/raffles`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListRafflesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListRafflesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListRafflesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListRafflesOutput>) => {
        return new ListRafflesOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListRafflesOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListRafflesOutput) => {
        return input.convertDates();
      }),
    );
  }

  listRegistrations(input: ListRegistrationsInput): Observable<ListRegistrationsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListRegistrationsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListRegistrationsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListRegistrationsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListRegistrationsOutput>) => {
        return new ListRegistrationsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListRegistrationsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listScenes(input: ListScenesInput): Observable<ListScenesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListScenesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListScenesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListScenesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListScenesOutput>) => {
        return new ListScenesOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListScenesOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listShows(input: ListShowsInput): Observable<ListShowsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ListShowsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListShowsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListShowsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListShowsOutput>) => {
        return new ListShowsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListShowsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listSpotterToolDonations(input: ListSpotterToolDonationsInput): Observable<ListSpotterToolDonationsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/spotter-tool-donations`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListSpotterToolDonationsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListSpotterToolDonationsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListSpotterToolDonationsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListSpotterToolDonationsOutput>) => {
        return new ListSpotterToolDonationsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListSpotterToolDonationsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  listVirtualChairs(input: ListVirtualChairsInput): Observable<ListVirtualChairsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListVirtualChairsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListVirtualChairsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListVirtualChairsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListVirtualChairsOutput>) => {
        return new ListVirtualChairsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListVirtualChairsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListVirtualChairsOutput) => {
        return input.convertDates();
      }),
    );
  }

  listVirtualTables(input: ListVirtualTablesInput): Observable<ListVirtualTablesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ListVirtualTablesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ListVirtualTablesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ListVirtualTablesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ListVirtualTablesOutput>) => {
        return new ListVirtualTablesOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ListVirtualTablesOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ListVirtualTablesOutput) => {
        return input.convertDates();
      }),
    );
  }

  lotListWithWinner(input: LotListWithWinnerInput): Observable<LotListWithWinnerOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/lot-winner`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<LotListWithWinnerOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<LotListWithWinnerOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) LotListWithWinnerOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<LotListWithWinnerOutput>) => {
        return new LotListWithWinnerOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: LotListWithWinnerOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: LotListWithWinnerOutput) => {
        return input.convertDates();
      }),
    );
  }

  mySupporterPurchasedItemsDetails(input: MySupporterPurchasedItemsDetailsInput): Observable<MySupporterPurchasedItemsDetailsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/guests/my/item-detail`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<MySupporterPurchasedItemsDetailsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<MySupporterPurchasedItemsDetailsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) MySupporterPurchasedItemsDetailsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<MySupporterPurchasedItemsDetailsOutput>) => {
        return new MySupporterPurchasedItemsDetailsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: MySupporterPurchasedItemsDetailsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: MySupporterPurchasedItemsDetailsOutput) => {
        return input.convertDates();
      }),
    );
  }

  noOrgListEvents(input: NoOrgListEventsInput): Observable<NoOrgListEventsOutput> {
    const url = `${this.urlHost}/events`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<NoOrgListEventsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<NoOrgListEventsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) NoOrgListEventsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<NoOrgListEventsOutput>) => {
        return new NoOrgListEventsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: NoOrgListEventsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: NoOrgListEventsOutput) => {
        return input.convertDates();
      }),
    );
  }

  notifyLotUpdated(input: NotifyLotUpdatedInput): Observable<NotifyLotUpdatedOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/lot-updated`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<NotifyLotUpdatedOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<NotifyLotUpdatedOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) NotifyLotUpdatedOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<NotifyLotUpdatedOutput>) => {
        return new NotifyLotUpdatedOutput(input).fixClasses();
      }),
    );
  }

  openBidding(input: OpenBiddingInput): Observable<OpenBiddingOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/open-bidding`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<OpenBiddingOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<OpenBiddingOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) OpenBiddingOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<OpenBiddingOutput>) => {
        return new OpenBiddingOutput(input).fixClasses();
      }),
    );
  }

  openCheckoutNotification(input: OpenCheckoutNotificationInput): Observable<OpenCheckoutNotificationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/checkout/open`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<OpenCheckoutNotificationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<OpenCheckoutNotificationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) OpenCheckoutNotificationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<OpenCheckoutNotificationOutput>) => {
        return new OpenCheckoutNotificationOutput(input).fixClasses();
      }),
    );
  }

  openLotAndAssignWinner(input: OpenLotAndAssignWinnerInput): Observable<OpenLotAndAssignWinnerOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/open-lot-and-assign-winner`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<OpenLotAndAssignWinnerOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<OpenLotAndAssignWinnerOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) OpenLotAndAssignWinnerOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<OpenLotAndAssignWinnerOutput>) => {
        return new OpenLotAndAssignWinnerOutput(input).fixClasses();
      }),
    );
  }

  pauseBidding(input: PauseBiddingInput): Observable<PauseBiddingOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/pause-bidding`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<PauseBiddingOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<PauseBiddingOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) PauseBiddingOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<PauseBiddingOutput>) => {
        return new PauseBiddingOutput(input).fixClasses();
      }),
    );
  }

  payForAttendeeItems(input: PayForAttendeeItemsInput): Observable<PayForAttendeeItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items/pay-for-attendee`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<PayForAttendeeItemsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<PayForAttendeeItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) PayForAttendeeItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<PayForAttendeeItemsOutput>) => {
        return new PayForAttendeeItemsOutput(input).fixClasses();
      }),
    );
  }

  payForItems(input: PayForItemsInput): Observable<PayForItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/items/pay`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<PayForItemsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<PayForItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) PayForItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<PayForItemsOutput>) => {
        return new PayForItemsOutput(input).fixClasses();
      }),
    );
  }

  placeBid(input: PlaceBidInput): Observable<PlaceBidOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/place-bid`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<PlaceBidOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<PlaceBidOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) PlaceBidOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<PlaceBidOutput>) => {
        return new PlaceBidOutput(input).fixClasses();
      }),
    );
  }

  raiseVirtualEventProcessPendingPurchasedItems(input: RaiseVirtualEventProcessPendingPurchasedItemsInput): Observable<RaiseVirtualEventProcessPendingPurchasedItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/raise-process-pending-items-event`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<RaiseVirtualEventProcessPendingPurchasedItemsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<RaiseVirtualEventProcessPendingPurchasedItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) RaiseVirtualEventProcessPendingPurchasedItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<RaiseVirtualEventProcessPendingPurchasedItemsOutput>) => {
        return new RaiseVirtualEventProcessPendingPurchasedItemsOutput(input).fixClasses();
      }),
    );
  }

  readDefaultInvitationTemplate(input: ReadDefaultInvitationTemplateInput): Observable<ReadDefaultInvitationTemplateOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/invitation-template`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadDefaultInvitationTemplateOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadDefaultInvitationTemplateOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadDefaultInvitationTemplateOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadDefaultInvitationTemplateOutput>) => {
        return new ReadDefaultInvitationTemplateOutput(input).fixClasses();
      }),
    );
  }

  readDonationMoment(input: ReadDonationMomentInput): Observable<ReadDonationMomentOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donationmoments/${input.donationMomentID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadDonationMomentOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadDonationMomentOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadDonationMomentOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadDonationMomentOutput>) => {
        return new ReadDonationMomentOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadDonationMomentOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  readEvent(input: ReadEventInput): Observable<ReadEventOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadEventOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadEventOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadEventOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadEventOutput>) => {
        return new ReadEventOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadEventOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadEventOutput) => {
        return input.convertDates();
      }),
    );
  }

  readEventSession(input: ReadEventSessionInput): Observable<ReadEventSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadEventSessionOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadEventSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadEventSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadEventSessionOutput>) => {
        return new ReadEventSessionOutput(input).fixClasses();
      }),
    );
  }

  readEventSessionTicketConfig(input: ReadEventSessionTicketConfigInput): Observable<ReadEventSessionTicketConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/ticketconfigs/${input.eventSessionTicketConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadEventSessionTicketConfigOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadEventSessionTicketConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadEventSessionTicketConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadEventSessionTicketConfigOutput>) => {
        return new ReadEventSessionTicketConfigOutput(input).fixClasses();
      }),
    );
  }

  readEventShowControl(input: ReadEventShowControlInput): Observable<ReadEventShowControlOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/control`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadEventShowControlOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadEventShowControlOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadEventShowControlOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadEventShowControlOutput>) => {
        return new ReadEventShowControlOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadEventShowControlOutput) => {
        return input.convertDates();
      }),
    );
  }

  readEventSlideConfig(input: ReadEventSlideConfigInput): Observable<ReadEventSlideConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideconfig/${input.eventSlideConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadEventSlideConfigOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadEventSlideConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadEventSlideConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadEventSlideConfigOutput>) => {
        return new ReadEventSlideConfigOutput(input).fixClasses();
      }),
    );
  }

  readEventSlideshow(input: ReadEventSlideshowInput): Observable<ReadEventSlideshowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideshow/${input.eventSlideshowID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadEventSlideshowOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadEventSlideshowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadEventSlideshowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadEventSlideshowOutput>) => {
        return new ReadEventSlideshowOutput(input).fixClasses();
      }),
    );
  }

  readFixedPriceItem(input: ReadFixedPriceItemInput): Observable<ReadFixedPriceItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.itemID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadFixedPriceItemOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadFixedPriceItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadFixedPriceItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadFixedPriceItemOutput>) => {
        return new ReadFixedPriceItemOutput(input).fixClasses();
      }),
    );
  }

  readFixedPriceItemPhoto(input: ReadFixedPriceItemPhotoInput): Observable<ReadFixedPriceItemPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.fixedPriceItemID}/photos/${input.photoID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadFixedPriceItemPhotoOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadFixedPriceItemPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadFixedPriceItemPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadFixedPriceItemPhotoOutput>) => {
        return new ReadFixedPriceItemPhotoOutput(input).fixClasses();
      }),
    );
  }

  readGuest(input: ReadGuestInput): Observable<ReadGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/${input.guestID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadGuestOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadGuestOutput>) => {
        return new ReadGuestOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadGuestOutput) => {
        return input.convertDates();
      }),
    );
  }

  readGuestSession(input: ReadGuestSessionInput): Observable<ReadGuestSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/${input.guestSessionID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadGuestSessionOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadGuestSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadGuestSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadGuestSessionOutput>) => {
        return new ReadGuestSessionOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadGuestSessionOutput) => {
        return input.convertDates();
      }),
    );
  }

  readImpact(input: ReadImpactInput): Observable<ReadImpactOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadImpactOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadImpactOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadImpactOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadImpactOutput>) => {
        return new ReadImpactOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadImpactOutput) => {
        return input.convertDates();
      }),
    );
  }

  readImpactPhoto(input: ReadImpactPhotoInput): Observable<ReadImpactPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}/photos/${input.photoID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadImpactPhotoOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadImpactPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadImpactPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadImpactPhotoOutput>) => {
        return new ReadImpactPhotoOutput(input).fixClasses();
      }),
    );
  }

  readInvitation(input: ReadInvitationInput): Observable<ReadInvitationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/${input.invitationID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadInvitationOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadInvitationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadInvitationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadInvitationOutput>) => {
        return new ReadInvitationOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadInvitationOutput) => {
        return input.convertDates();
      }),
    );
  }

  readMyRegistration(input: ReadMyRegistrationInput): Observable<ReadMyRegistrationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadMyRegistrationOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadMyRegistrationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadMyRegistrationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadMyRegistrationOutput>) => {
        return new ReadMyRegistrationOutput(input).fixClasses();
      }),
    );
  }

  readMyTicket(input: ReadMyTicketInput): Observable<ReadMyTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/${input.ticketID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadMyTicketOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadMyTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadMyTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadMyTicketOutput>) => {
        return new ReadMyTicketOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadMyTicketOutput) => {
        return input.convertDates();
      }),
    );
  }

  readMyTicketOptionAnswers(input: ReadMyTicketOptionAnswersInput): Observable<ReadMyTicketOptionAnswersOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/${input.ticketID}/ticket-option-answers`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadMyTicketOptionAnswersOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadMyTicketOptionAnswersOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadMyTicketOptionAnswersOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadMyTicketOptionAnswersOutput>) => {
        return new ReadMyTicketOptionAnswersOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadMyTicketOptionAnswersOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadMyTicketOptionAnswersOutput) => {
        return input.convertDates();
      }),
    );
  }

  readMyVirtualChair(input: ReadMyVirtualChairInput): Observable<ReadMyVirtualChairOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/my-chair`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadMyVirtualChairOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadMyVirtualChairOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadMyVirtualChairOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadMyVirtualChairOutput>) => {
        return new ReadMyVirtualChairOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadMyVirtualChairOutput) => {
        return input.convertDates();
      }),
    );
  }

  readMyVirtualTable(input: ReadMyVirtualTableInput): Observable<ReadMyVirtualTableOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/my-table`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadMyVirtualTableOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadMyVirtualTableOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadMyVirtualTableOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadMyVirtualTableOutput>) => {
        return new ReadMyVirtualTableOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadMyVirtualTableOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  readOverlay(input: ReadOverlayInput): Observable<ReadOverlayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}/overlays/${input.overlayID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadOverlayOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadOverlayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadOverlayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadOverlayOutput>) => {
        return new ReadOverlayOutput(input).fixClasses();
      }),
    );
  }

  readPeerUserInfo(input: ReadPeerUserInfoInput): Observable<ReadPeerUserInfoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my/peer-user/${input.peerUserID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadPeerUserInfoOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadPeerUserInfoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadPeerUserInfoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadPeerUserInfoOutput>) => {
        return new ReadPeerUserInfoOutput(input).fixClasses();
      }),
    );
  }

  readPickupItem(input: ReadPickupItemInput): Observable<ReadPickupItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/pickupitems/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadPickupItemOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadPickupItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadPickupItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadPickupItemOutput>) => {
        return new ReadPickupItemOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadPickupItemOutput) => {
        return input.convertDates();
      }),
    );
  }

  readPreEventQuestionConfig(input: ReadPreEventQuestionConfigInput): Observable<ReadPreEventQuestionConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/preeventquestionconfigs/${input.preEventQuestionConfigID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadPreEventQuestionConfigOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadPreEventQuestionConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadPreEventQuestionConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadPreEventQuestionConfigOutput>) => {
        return new ReadPreEventQuestionConfigOutput(input).fixClasses();
      }),
    );
  }

  readPurchasedItem(input: ReadPurchasedItemInput): Observable<ReadPurchasedItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadPurchasedItemOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadPurchasedItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadPurchasedItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadPurchasedItemOutput>) => {
        return new ReadPurchasedItemOutput(input).fixClasses();
      }),
    );
  }

  readPurchasedItemSalesTax(input: ReadPurchasedItemSalesTaxInput): Observable<ReadPurchasedItemSalesTaxOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchaseditemsalestax/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadPurchasedItemSalesTaxOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadPurchasedItemSalesTaxOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadPurchasedItemSalesTaxOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadPurchasedItemSalesTaxOutput>) => {
        return new ReadPurchasedItemSalesTaxOutput(input).fixClasses();
      }),
    );
  }

  readRecentAppealItems(input: ReadRecentAppealItemsInput): Observable<ReadRecentAppealItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/appeal-items`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadRecentAppealItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadRecentAppealItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadRecentAppealItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadRecentAppealItemsOutput>) => {
        return new ReadRecentAppealItemsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadRecentAppealItemsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  readRegistration(input: ReadRegistrationInput): Observable<ReadRegistrationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/${input.registrationID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadRegistrationOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadRegistrationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadRegistrationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadRegistrationOutput>) => {
        return new ReadRegistrationOutput(input).fixClasses();
      }),
    );
  }

  readScene(input: ReadSceneInput): Observable<ReadSceneOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadSceneOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadSceneOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadSceneOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadSceneOutput>) => {
        return new ReadSceneOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadSceneOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  readShow(input: ReadShowInput): Observable<ReadShowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}`;
    const headers = this.getHeaders();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ReadShowOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadShowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadShowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadShowOutput>) => {
        return new ReadShowOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadShowOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  readTags(input: ReadTagsInput): Observable<ReadTagsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/tags`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadTagsOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadTagsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadTagsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadTagsOutput>) => {
        return new ReadTagsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ReadTagsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  readVirtualChair(input: ReadVirtualChairInput): Observable<ReadVirtualChairOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadVirtualChairOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadVirtualChairOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadVirtualChairOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadVirtualChairOutput>) => {
        return new ReadVirtualChairOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadVirtualChairOutput) => {
        return input.convertDates();
      }),
    );
  }

  readVirtualTable(input: ReadVirtualTableInput): Observable<ReadVirtualTableOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ReadVirtualTableOutput>>>(url, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ReadVirtualTableOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ReadVirtualTableOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ReadVirtualTableOutput>) => {
        return new ReadVirtualTableOutput(input).fixClasses();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ReadVirtualTableOutput) => {
        return input.convertDates();
      }),
    );
  }

  refundPayment(input: RefundPaymentInput): Observable<RefundPaymentOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/refund-payment`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<RefundPaymentOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<RefundPaymentOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) RefundPaymentOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<RefundPaymentOutput>) => {
        return new RefundPaymentOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: RefundPaymentOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  registrationFreePayForItems(input: RegistrationFreePayForItemsInput): Observable<RegistrationFreePayForItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/guests/${input.guestID}/items/registration-free-pay`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<RegistrationFreePayForItemsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<RegistrationFreePayForItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) RegistrationFreePayForItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<RegistrationFreePayForItemsOutput>) => {
        return new RegistrationFreePayForItemsOutput(input).fixClasses();
      }),
    );
  }

  removeProxyGuest(input: RemoveProxyGuestInput): Observable<RemoveProxyGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/proxy-guests/remove-proxy-guest`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<RemoveProxyGuestOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<RemoveProxyGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) RemoveProxyGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<RemoveProxyGuestOutput>) => {
        return new RemoveProxyGuestOutput(input).fixClasses();
      }),
    );
  }

  removeProxyLink(input: RemoveProxyLinkInput): Observable<RemoveProxyLinkOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/proxy-guests/remove-proxy-link/${input.guestID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<RemoveProxyLinkOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<RemoveProxyLinkOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) RemoveProxyLinkOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<RemoveProxyLinkOutput>) => {
        return new RemoveProxyLinkOutput(input).fixClasses();
      }),
    );
  }

  rescindHighestBid(input: RescindHighestBidInput): Observable<RescindHighestBidOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/rescind-highest-bid`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<RescindHighestBidOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<RescindHighestBidOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) RescindHighestBidOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<RescindHighestBidOutput>) => {
        return new RescindHighestBidOutput(input).fixClasses();
      }),
    );
  }

  rescindPurchasedItem(input: RescindPurchasedItemInput): Observable<RescindPurchasedItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/rescind-purchase-item`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<RescindPurchasedItemOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<RescindPurchasedItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) RescindPurchasedItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<RescindPurchasedItemOutput>) => {
        return new RescindPurchasedItemOutput(input).fixClasses();
      }),
    );
  }

  resetMyTicket(input: ResetMyTicketInput): Observable<ResetMyTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/${input.ticketID}/reset`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<ResetMyTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ResetMyTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ResetMyTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ResetMyTicketOutput>) => {
        return new ResetMyTicketOutput(input).fixClasses();
      }),
    );
  }

  resetTicket(input: ResetTicketInput): Observable<ResetTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/${input.ticketID}/reset`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<ResetTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ResetTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ResetTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ResetTicketOutput>) => {
        return new ResetTicketOutput(input).fixClasses();
      }),
    );
  }

  searchGuests(input: SearchGuestsInput): Observable<SearchGuestsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<SearchGuestsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SearchGuestsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SearchGuestsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SearchGuestsOutput>) => {
        return new SearchGuestsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: SearchGuestsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  selfRegisterForEvent(input: SelfRegisterForEventInput): Observable<SelfRegisterForEventOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SelfRegisterForEventOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SelfRegisterForEventOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SelfRegisterForEventOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SelfRegisterForEventOutput>) => {
        return new SelfRegisterForEventOutput(input).fixClasses();
      }),
    );
  }

  sendEmail(input: SendEmailInput): Observable<SendEmailOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/send-email`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SendEmailOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SendEmailOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SendEmailOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SendEmailOutput>) => {
        return new SendEmailOutput(input).fixClasses();
      }),
    );
  }

  sendMyTicket(input: SendMyTicketInput): Observable<SendMyTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/${input.ticketID}/send`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SendMyTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SendMyTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SendMyTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SendMyTicketOutput>) => {
        return new SendMyTicketOutput(input).fixClasses();
      }),
    );
  }

  sendPickupReadyNotification(input: SendPickupReadyNotificationInput): Observable<SendPickupReadyNotificationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/checkout/readyPickup`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SendPickupReadyNotificationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SendPickupReadyNotificationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SendPickupReadyNotificationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SendPickupReadyNotificationOutput>) => {
        return new SendPickupReadyNotificationOutput(input).fixClasses();
      }),
    );
  }

  sendSilentAuctionReminderAlerts(input: SendSilentAuctionReminderAlertsInput): Observable<SendSilentAuctionReminderAlertsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/silent-auction-reminder`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SendSilentAuctionReminderAlertsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SendSilentAuctionReminderAlertsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SendSilentAuctionReminderAlertsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SendSilentAuctionReminderAlertsOutput>) => {
        return new SendSilentAuctionReminderAlertsOutput(input).fixClasses();
      }),
    );
  }

  sendSingleEmail(input: SendSingleEmailInput): Observable<SendSingleEmailOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/${input.invitationID}/send-email`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SendSingleEmailOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SendSingleEmailOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SendSingleEmailOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SendSingleEmailOutput>) => {
        return new SendSingleEmailOutput(input).fixClasses();
      }),
    );
  }

  sendTicket(input: SendTicketInput): Observable<SendTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/${input.ticketID}/send`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SendTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SendTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SendTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SendTicketOutput>) => {
        return new SendTicketOutput(input).fixClasses();
      }),
    );
  }

  setActiveLot(input: SetActiveLotInput): Observable<SetActiveLotOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/active-lot`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<SetActiveLotOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SetActiveLotOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SetActiveLotOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SetActiveLotOutput>) => {
        return new SetActiveLotOutput(input).fixClasses();
      }),
    );
  }

  setMyPreEventQuestionnaireCompleteTime(input: SetMyPreEventQuestionnaireCompleteTimeInput): Observable<SetMyPreEventQuestionnaireCompleteTimeOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/my/set-pre-event-questionnaire-complete-time`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SetMyPreEventQuestionnaireCompleteTimeOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SetMyPreEventQuestionnaireCompleteTimeOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SetMyPreEventQuestionnaireCompleteTimeOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SetMyPreEventQuestionnaireCompleteTimeOutput>) => {
        return new SetMyPreEventQuestionnaireCompleteTimeOutput(input).fixClasses();
      }),
    );
  }

  signSignalingClientConnection(input: SignSignalingClientConnectionInput): Observable<SignSignalingClientConnectionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/sign-signaling-client-connection`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SignSignalingClientConnectionOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SignSignalingClientConnectionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SignSignalingClientConnectionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SignSignalingClientConnectionOutput>) => {
        return new SignSignalingClientConnectionOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: SignSignalingClientConnectionOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  supporterPurchasedItemsDetails(input: SupporterPurchasedItemsDetailsInput): Observable<SupporterPurchasedItemsDetailsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/guests/${input.guestID}/item-detail`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<SupporterPurchasedItemsDetailsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SupporterPurchasedItemsDetailsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SupporterPurchasedItemsDetailsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SupporterPurchasedItemsDetailsOutput>) => {
        return new SupporterPurchasedItemsDetailsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: SupporterPurchasedItemsDetailsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: SupporterPurchasedItemsDetailsOutput) => {
        return input.convertDates();
      }),
    );
  }

  syncGuestSessionsAndAttendees(input: SyncGuestSessionsAndAttendeesInput): Observable<SyncGuestSessionsAndAttendeesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tablemanagement/guest-attendee-sync`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<SyncGuestSessionsAndAttendeesOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<SyncGuestSessionsAndAttendeesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) SyncGuestSessionsAndAttendeesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<SyncGuestSessionsAndAttendeesOutput>) => {
        return new SyncGuestSessionsAndAttendeesOutput(input).fixClasses();
      }),
    );
  }

  textPaymentLink(input: TextPaymentLinkInput): Observable<TextPaymentLinkOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guestmessaging/${input.guestID}/text-payment`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<TextPaymentLinkOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<TextPaymentLinkOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) TextPaymentLinkOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<TextPaymentLinkOutput>) => {
        return new TextPaymentLinkOutput(input).fixClasses();
      }),
    );
  }

  unbanFromLivestreamChat(input: UnbanFromLivestreamChatInput): Observable<UnbanFromLivestreamChatOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/chat-users/${input.userID}/unban`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UnbanFromLivestreamChatOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UnbanFromLivestreamChatOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UnbanFromLivestreamChatOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UnbanFromLivestreamChatOutput>) => {
        return new UnbanFromLivestreamChatOutput(input).fixClasses();
      }),
    );
  }

  undoCheckInGuestSession(input: UndoCheckInGuestSessionInput): Observable<UndoCheckInGuestSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/${input.guestSessionID}/undo-check-in`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UndoCheckInGuestSessionOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UndoCheckInGuestSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UndoCheckInGuestSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UndoCheckInGuestSessionOutput>) => {
        return new UndoCheckInGuestSessionOutput(input).fixClasses();
      }),
    );
  }

  updateActiveOverlayConfig(input: UpdateActiveOverlayConfigInput): Observable<UpdateActiveOverlayConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/control/shows/${input.showID}/scenes/${input.sceneID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateActiveOverlayConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateActiveOverlayConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateActiveOverlayConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateActiveOverlayConfigOutput>) => {
        return new UpdateActiveOverlayConfigOutput(input).fixClasses();
      }),
    );
  }

  updateAttendeeExperience(input: UpdateAttendeeExperienceInput): Observable<UpdateAttendeeExperienceOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guestexperience`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UpdateAttendeeExperienceOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateAttendeeExperienceOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateAttendeeExperienceOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateAttendeeExperienceOutput>) => {
        return new UpdateAttendeeExperienceOutput(input).fixClasses();
      }),
    );
  }

  updateDonationMoment(input: UpdateDonationMomentInput): Observable<UpdateDonationMomentOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/donationmoments/${input.donationMomentID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateDonationMomentOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateDonationMomentOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateDonationMomentOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateDonationMomentOutput>) => {
        return new UpdateDonationMomentOutput(input).fixClasses();
      }),
    );
  }

  updateEvent(input: UpdateEventInput): Observable<UpdateEventOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateEventOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateEventOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateEventOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateEventOutput>) => {
        return new UpdateEventOutput(input).fixClasses();
      }),
    );
  }

  updateEventSession(input: UpdateEventSessionInput): Observable<UpdateEventSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateEventSessionOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateEventSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateEventSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateEventSessionOutput>) => {
        return new UpdateEventSessionOutput(input).fixClasses();
      }),
    );
  }

  updateEventSessionTicketConfig(input: UpdateEventSessionTicketConfigInput): Observable<UpdateEventSessionTicketConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/ticketconfigs/${input.eventSessionTicketConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateEventSessionTicketConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateEventSessionTicketConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateEventSessionTicketConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateEventSessionTicketConfigOutput>) => {
        return new UpdateEventSessionTicketConfigOutput(input).fixClasses();
      }),
    );
  }

  updateEventShowControl(input: UpdateEventShowControlInput): Observable<UpdateEventShowControlOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/control`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateEventShowControlOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateEventShowControlOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateEventShowControlOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateEventShowControlOutput>) => {
        return new UpdateEventShowControlOutput(input).fixClasses();
      }),
    );
  }

  updateEventSlideConfig(input: UpdateEventSlideConfigInput): Observable<UpdateEventSlideConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideconfig/${input.eventSlideConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateEventSlideConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateEventSlideConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateEventSlideConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateEventSlideConfigOutput>) => {
        return new UpdateEventSlideConfigOutput(input).fixClasses();
      }),
    );
  }

  updateEventSlideshow(input: UpdateEventSlideshowInput): Observable<UpdateEventSlideshowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/eventslideshow/${input.eventSlideshowID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateEventSlideshowOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateEventSlideshowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateEventSlideshowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateEventSlideshowOutput>) => {
        return new UpdateEventSlideshowOutput(input).fixClasses();
      }),
    );
  }

  updateFixedPriceItem(input: UpdateFixedPriceItemInput): Observable<UpdateFixedPriceItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.itemID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateFixedPriceItemOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateFixedPriceItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateFixedPriceItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateFixedPriceItemOutput>) => {
        return new UpdateFixedPriceItemOutput(input).fixClasses();
      }),
    );
  }

  updateFixedPriceItemPhoto(input: UpdateFixedPriceItemPhotoInput): Observable<UpdateFixedPriceItemPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/${input.fixedPriceItemID}/photos/${input.photoID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateFixedPriceItemPhotoOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateFixedPriceItemPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateFixedPriceItemPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateFixedPriceItemPhotoOutput>) => {
        return new UpdateFixedPriceItemPhotoOutput(input).fixClasses();
      }),
    );
  }

  updateFixedPricePickedUp(input: UpdateFixedPricePickedUpInput): Observable<UpdateFixedPricePickedUpOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedprice/update-fixedPrice-pickedUp`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateFixedPricePickedUpOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateFixedPricePickedUpOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateFixedPricePickedUpOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateFixedPricePickedUpOutput>) => {
        return new UpdateFixedPricePickedUpOutput(input).fixClasses();
      }),
    );
  }

  updateFixedPriceSortOrder(input: UpdateFixedPriceSortOrderInput): Observable<UpdateFixedPriceSortOrderOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedprice/update-sort-order`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UpdateFixedPriceSortOrderOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateFixedPriceSortOrderOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateFixedPriceSortOrderOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateFixedPriceSortOrderOutput>) => {
        return new UpdateFixedPriceSortOrderOutput(input).fixClasses();
      }),
    );
  }

  updateGuest(input: UpdateGuestInput): Observable<UpdateGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/${input.guestID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateGuestOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateGuestOutput>) => {
        return new UpdateGuestOutput(input).fixClasses();
      }),
    );
  }

  updateGuestSession(input: UpdateGuestSessionInput): Observable<UpdateGuestSessionOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/${input.guestSessionID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateGuestSessionOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateGuestSessionOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateGuestSessionOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateGuestSessionOutput>) => {
        return new UpdateGuestSessionOutput(input).fixClasses();
      }),
    );
  }

  updateGuestsForTickets(input: UpdateGuestsForTicketsInput): Observable<UpdateGuestsForTicketsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/sessions/${input.eventSessionID}/guests/update-guests-for-tickets`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UpdateGuestsForTicketsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateGuestsForTicketsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateGuestsForTicketsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateGuestsForTicketsOutput>) => {
        return new UpdateGuestsForTicketsOutput(input).fixClasses();
      }),
    );
  }

  updateImpact(input: UpdateImpactInput): Observable<UpdateImpactOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateImpactOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateImpactOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateImpactOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateImpactOutput>) => {
        return new UpdateImpactOutput(input).fixClasses();
      }),
    );
  }

  updateImpactPhoto(input: UpdateImpactPhotoInput): Observable<UpdateImpactPhotoOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/impacts/${input.impactID}/photos/${input.photoID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateImpactPhotoOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateImpactPhotoOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateImpactPhotoOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateImpactPhotoOutput>) => {
        return new UpdateImpactPhotoOutput(input).fixClasses();
      }),
    );
  }

  updateInvitation(input: UpdateInvitationInput): Observable<UpdateInvitationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/${input.invitationID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateInvitationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateInvitationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateInvitationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateInvitationOutput>) => {
        return new UpdateInvitationOutput(input).fixClasses();
      }),
    );
  }

  updateLiveAppealConfig(input: UpdateLiveAppealConfigInput): Observable<UpdateLiveAppealConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/control/shows/${input.showID}/scenes/${input.sceneID}/overlays/${input.overlayID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateLiveAppealConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateLiveAppealConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateLiveAppealConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateLiveAppealConfigOutput>) => {
        return new UpdateLiveAppealConfigOutput(input).fixClasses();
      }),
    );
  }

  updateLotIncrement(input: UpdateLotIncrementInput): Observable<UpdateLotIncrementOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/auctions/${input.auctionID}/lot-increment`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateLotIncrementOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateLotIncrementOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateLotIncrementOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateLotIncrementOutput>) => {
        return new UpdateLotIncrementOutput(input).fixClasses();
      }),
    );
  }

  updateMyExperienceType(input: UpdateMyExperienceTypeInput): Observable<UpdateMyExperienceTypeOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/my/experience-type`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UpdateMyExperienceTypeOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateMyExperienceTypeOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateMyExperienceTypeOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateMyExperienceTypeOutput>) => {
        return new UpdateMyExperienceTypeOutput(input).fixClasses();
      }),
    );
  }

  updateMyGuest(input: UpdateMyGuestInput): Observable<UpdateMyGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/event-guests/my`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateMyGuestOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateMyGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateMyGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateMyGuestOutput>) => {
        return new UpdateMyGuestOutput(input).fixClasses();
      }),
    );
  }

  updateMyProfile(input: UpdateMyProfileInput): Observable<UpdateMyProfileOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my/update-profile`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateMyProfileOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateMyProfileOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateMyProfileOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateMyProfileOutput>) => {
        return new UpdateMyProfileOutput(input).fixClasses();
      }),
    );
  }

  updateMyProfileAndRegistration(input: UpdateMyProfileAndRegistrationInput): Observable<UpdateMyProfileAndRegistrationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my/update-profile-and-registration`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateMyProfileAndRegistrationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateMyProfileAndRegistrationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateMyProfileAndRegistrationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateMyProfileAndRegistrationOutput>) => {
        return new UpdateMyProfileAndRegistrationOutput(input).fixClasses();
      }),
    );
  }

  updateMyRegistration(input: UpdateMyRegistrationInput): Observable<UpdateMyRegistrationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/my`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateMyRegistrationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateMyRegistrationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateMyRegistrationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateMyRegistrationOutput>) => {
        return new UpdateMyRegistrationOutput(input).fixClasses();
      }),
    );
  }

  updateMyTicket(input: UpdateMyTicketInput): Observable<UpdateMyTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/my/${input.ticketID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateMyTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateMyTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateMyTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateMyTicketOutput>) => {
        return new UpdateMyTicketOutput(input).fixClasses();
      }),
    );
  }

  updateOverlay(input: UpdateOverlayInput): Observable<UpdateOverlayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}/overlays/${input.overlayID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateOverlayOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateOverlayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateOverlayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateOverlayOutput>) => {
        return new UpdateOverlayOutput(input).fixClasses();
      }),
    );
  }

  updatePickedUp(input: UpdatePickedUpInput): Observable<UpdatePickedUpOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchases/isPickedUp`;
    const headers = this.getHeaders();
    return this.httpClient.put<PhaasResponse<TypeWithFieldsOf<UpdatePickedUpOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdatePickedUpOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdatePickedUpOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdatePickedUpOutput>) => {
        return new UpdatePickedUpOutput(input).fixClasses();
      }),
    );
  }

  updatePickupItem(input: UpdatePickupItemInput): Observable<UpdatePickupItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/pickupitems/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdatePickupItemOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdatePickupItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdatePickupItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdatePickupItemOutput>) => {
        return new UpdatePickupItemOutput(input).fixClasses();
      }),
    );
  }

  updatePickupPreference(input: UpdatePickupPreferenceInput): Observable<UpdatePickupPreferenceOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/checkout/pickupPreference`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UpdatePickupPreferenceOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdatePickupPreferenceOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdatePickupPreferenceOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdatePickupPreferenceOutput>) => {
        return new UpdatePickupPreferenceOutput(input).fixClasses();
      }),
    );
  }

  updatePreEventQuestionConfig(input: UpdatePreEventQuestionConfigInput): Observable<UpdatePreEventQuestionConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/preeventquestionconfigs/${input.preEventQuestionConfigID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdatePreEventQuestionConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdatePreEventQuestionConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdatePreEventQuestionConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdatePreEventQuestionConfigOutput>) => {
        return new UpdatePreEventQuestionConfigOutput(input).fixClasses();
      }),
    );
  }

  updatePurchasedItem(input: UpdatePurchasedItemInput): Observable<UpdatePurchasedItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdatePurchasedItemOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdatePurchasedItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdatePurchasedItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdatePurchasedItemOutput>) => {
        return new UpdatePurchasedItemOutput(input).fixClasses();
      }),
    );
  }

  updatePurchasedItemSalesTax(input: UpdatePurchasedItemSalesTaxInput): Observable<UpdatePurchasedItemSalesTaxOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchaseditemsalestax/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdatePurchasedItemSalesTaxOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdatePurchasedItemSalesTaxOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdatePurchasedItemSalesTaxOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdatePurchasedItemSalesTaxOutput>) => {
        return new UpdatePurchasedItemSalesTaxOutput(input).fixClasses();
      }),
    );
  }

  updateRegistration(input: UpdateRegistrationInput): Observable<UpdateRegistrationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/${input.registrationID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateRegistrationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateRegistrationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateRegistrationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateRegistrationOutput>) => {
        return new UpdateRegistrationOutput(input).fixClasses();
      }),
    );
  }

  updateScene(input: UpdateSceneInput): Observable<UpdateSceneOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}/scenes/${input.sceneID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateSceneOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateSceneOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateSceneOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateSceneOutput>) => {
        return new UpdateSceneOutput(input).fixClasses();
      }),
    );
  }

  updateShow(input: UpdateShowInput): Observable<UpdateShowOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/shows/${input.showID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateShowOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateShowOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateShowOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateShowOutput>) => {
        return new UpdateShowOutput(input).fixClasses();
      }),
    );
  }

  updateTicket(input: UpdateTicketInput): Observable<UpdateTicketOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/${input.ticketID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateTicketOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateTicketOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateTicketOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateTicketOutput>) => {
        return new UpdateTicketOutput(input).fixClasses();
      }),
    );
  }

  updateTicketAndInviteProxyGuest(input: UpdateTicketAndInviteProxyGuestInput): Observable<UpdateTicketAndInviteProxyGuestOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/${input.ticketID}/invite-proxy-guest`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UpdateTicketAndInviteProxyGuestOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateTicketAndInviteProxyGuestOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateTicketAndInviteProxyGuestOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateTicketAndInviteProxyGuestOutput>) => {
        return new UpdateTicketAndInviteProxyGuestOutput(input).fixClasses();
      }),
    );
  }

  updateTicketTypeAndConfig(input: UpdateTicketTypeAndConfigInput): Observable<UpdateTicketTypeAndConfigOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tickets/update-ticket-type/${input.eventSessionID}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateTicketTypeAndConfigOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateTicketTypeAndConfigOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateTicketTypeAndConfigOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateTicketTypeAndConfigOutput>) => {
        return new UpdateTicketTypeAndConfigOutput(input).fixClasses();
      }),
    );
  }

  updateVirtualChair(input: UpdateVirtualChairInput): Observable<UpdateVirtualChairOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.virtualTableID}/virtual-chairs/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateVirtualChairOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateVirtualChairOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateVirtualChairOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateVirtualChairOutput>) => {
        return new UpdateVirtualChairOutput(input).fixClasses();
      }),
    );
  }

  updateVirtualTable(input: UpdateVirtualTableInput): Observable<UpdateVirtualTableOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/${input.id}`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().put<PhaasResponse<TypeWithFieldsOf<UpdateVirtualTableOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UpdateVirtualTableOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UpdateVirtualTableOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UpdateVirtualTableOutput>) => {
        return new UpdateVirtualTableOutput(input).fixClasses();
      }),
    );
  }

  uploadInvitations(input: UploadInvitationsInput): Observable<UploadInvitationsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/invitations/upload-csv-list`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UploadInvitationsOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UploadInvitationsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UploadInvitationsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UploadInvitationsOutput>) => {
        return new UploadInvitationsOutput(input).fixClasses();
      }),
    );
  }

  uploadTableDataCSV(input: UploadTableDataCSVInput): Observable<UploadTableDataCSVOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/tablemanagement/upload-csv`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<UploadTableDataCSVOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<UploadTableDataCSVOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) UploadTableDataCSVOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<UploadTableDataCSVOutput>) => {
        return new UploadTableDataCSVOutput(input).fixClasses();
      }),
    );
  }

  validateVirtualTableCreation(input: ValidateVirtualTableCreationInput): Observable<ValidateVirtualTableCreationOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/validate-and-create-tables`;
    const headers = this.getHeaders();
    return this.getAuthHttpClient().post<PhaasResponse<TypeWithFieldsOf<ValidateVirtualTableCreationOutput>>>(url, input, { headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ValidateVirtualTableCreationOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ValidateVirtualTableCreationOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ValidateVirtualTableCreationOutput>) => {
        return new ValidateVirtualTableCreationOutput(input).fixClasses();
      }),
    );
  }

  viewBannedLivestreamChatUserRegistrations(input: ViewBannedLivestreamChatUserRegistrationsInput): Observable<ViewBannedLivestreamChatUserRegistrationsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/bannedlivestreamchatuserregistrations`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewBannedLivestreamChatUserRegistrationsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewBannedLivestreamChatUserRegistrationsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewBannedLivestreamChatUserRegistrationsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewBannedLivestreamChatUserRegistrationsOutput>) => {
        return new ViewBannedLivestreamChatUserRegistrationsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewBannedLivestreamChatUserRegistrationsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  viewDonationItem(input: ViewDonationItemInput): Observable<ViewDonationItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/donationitem`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewDonationItemOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewDonationItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewDonationItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewDonationItemOutput>) => {
        return new ViewDonationItemOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewDonationItemOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewDonationItemOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewDonationsByDay(input: ViewDonationsByDayInput): Observable<ViewDonationsByDayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/donationsbyday`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewDonationsByDayOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewDonationsByDayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewDonationsByDayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewDonationsByDayOutput>) => {
        return new ViewDonationsByDayOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewDonationsByDayOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewDonationsByDayOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewDonationSumPerDonor(input: ViewDonationSumPerDonorInput): Observable<ViewDonationSumPerDonorOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/donationsumperdonor`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewDonationSumPerDonorOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewDonationSumPerDonorOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewDonationSumPerDonorOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewDonationSumPerDonorOutput>) => {
        return new ViewDonationSumPerDonorOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewDonationSumPerDonorOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewDonationSumPerDonorOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewEventRegistrationPurchases(input: ViewEventRegistrationPurchasesInput): Observable<ViewEventRegistrationPurchasesOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/eventregistrationpurchases`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewEventRegistrationPurchasesOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewEventRegistrationPurchasesOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewEventRegistrationPurchasesOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewEventRegistrationPurchasesOutput>) => {
        return new ViewEventRegistrationPurchasesOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewEventRegistrationPurchasesOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewEventRegistrationPurchasesOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewEventRegistrations(input: ViewEventRegistrationsInput): Observable<ViewEventRegistrationsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/eventregistrations`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewEventRegistrationsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewEventRegistrationsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewEventRegistrationsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewEventRegistrationsOutput>) => {
        return new ViewEventRegistrationsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewEventRegistrationsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewEventRegistrationsOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewEventRegistrationsByDay(input: ViewEventRegistrationsByDayInput): Observable<ViewEventRegistrationsByDayOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/registrations/view/eventregistrationsbyday`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewEventRegistrationsByDayOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewEventRegistrationsByDayOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewEventRegistrationsByDayOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewEventRegistrationsByDayOutput>) => {
        return new ViewEventRegistrationsByDayOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewEventRegistrationsByDayOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewEventRegistrationsByDayOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewFixedPriceDetails(input: ViewFixedPriceDetailsInput): Observable<ViewFixedPriceDetailsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/fixedpriceitems/view/fixedpricedetails`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ViewFixedPriceDetailsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewFixedPriceDetailsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewFixedPriceDetailsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewFixedPriceDetailsOutput>) => {
        return new ViewFixedPriceDetailsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewFixedPriceDetailsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  viewGuestsWithPurchasedItemCounts(input: ViewGuestsWithPurchasedItemCountsInput): Observable<ViewGuestsWithPurchasedItemCountsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/view/guestswithpurchaseditemcounts`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewGuestsWithPurchasedItemCountsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewGuestsWithPurchasedItemCountsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewGuestsWithPurchasedItemCountsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewGuestsWithPurchasedItemCountsOutput>) => {
        return new ViewGuestsWithPurchasedItemCountsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewGuestsWithPurchasedItemCountsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewGuestsWithPurchasedItemCountsOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewImpactsForOrg(input: ViewImpactsForOrgInput): Observable<ViewImpactsForOrgOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/impacts`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewImpactsForOrgOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewImpactsForOrgOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewImpactsForOrgOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewImpactsForOrgOutput>) => {
        return new ViewImpactsForOrgOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewImpactsForOrgOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewImpactsForOrgOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewPurchasedItem(input: ViewPurchasedItemInput): Observable<ViewPurchasedItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/purchaseditem`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewPurchasedItemOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewPurchasedItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewPurchasedItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewPurchasedItemOutput>) => {
        return new ViewPurchasedItemOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewPurchasedItemOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewPurchasedItemOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewPurchasedItemAnonymous(input: ViewPurchasedItemAnonymousInput): Observable<ViewPurchasedItemAnonymousOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/purchaseditemanonymous`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewPurchasedItemAnonymousOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewPurchasedItemAnonymousOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewPurchasedItemAnonymousOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewPurchasedItemAnonymousOutput>) => {
        return new ViewPurchasedItemAnonymousOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewPurchasedItemAnonymousOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewPurchasedItemAnonymousOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewRecentDonors(input: ViewRecentDonorsInput): Observable<ViewRecentDonorsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/recentdonors`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.httpClient.get<PhaasResponse<TypeWithFieldsOf<ViewRecentDonorsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewRecentDonorsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewRecentDonorsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewRecentDonorsOutput>) => {
        return new ViewRecentDonorsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewRecentDonorsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewRecentDonorsOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewSearchGuests(input: ViewSearchGuestsInput): Observable<ViewSearchGuestsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/guests/view/searchguests`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewSearchGuestsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewSearchGuestsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewSearchGuestsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewSearchGuestsOutput>) => {
        return new ViewSearchGuestsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewSearchGuestsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  viewStatusSumItems(input: ViewStatusSumItemsInput): Observable<ViewStatusSumItemsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/statussumitems`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewStatusSumItemsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewStatusSumItemsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewStatusSumItemsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewStatusSumItemsOutput>) => {
        return new ViewStatusSumItemsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewStatusSumItemsOutput) => {
        return input.convertUnexpectedNulls();
      }),
    );
  }

  viewSupporterPurchasedItem(input: ViewSupporterPurchasedItemInput): Observable<ViewSupporterPurchasedItemOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/supporterpurchaseditem`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewSupporterPurchasedItemOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewSupporterPurchasedItemOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewSupporterPurchasedItemOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewSupporterPurchasedItemOutput>) => {
        return new ViewSupporterPurchasedItemOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewSupporterPurchasedItemOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewSupporterPurchasedItemOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewSupporterPurchasedItemByPickupTime(input: ViewSupporterPurchasedItemByPickupTimeInput): Observable<ViewSupporterPurchasedItemByPickupTimeOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/supporterpurchaseditembypickuptime`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewSupporterPurchasedItemByPickupTimeOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewSupporterPurchasedItemByPickupTimeOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewSupporterPurchasedItemByPickupTimeOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewSupporterPurchasedItemByPickupTimeOutput>) => {
        return new ViewSupporterPurchasedItemByPickupTimeOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewSupporterPurchasedItemByPickupTimeOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewSupporterPurchasedItemByPickupTimeOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewSupporterPurchasedItemDetail(input: ViewSupporterPurchasedItemDetailInput): Observable<ViewSupporterPurchasedItemDetailOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.eventID}/purchased-items/view/supporterpurchaseditemdetail`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewSupporterPurchasedItemDetailOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewSupporterPurchasedItemDetailOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewSupporterPurchasedItemDetailOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewSupporterPurchasedItemDetailOutput>) => {
        return new ViewSupporterPurchasedItemDetailOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewSupporterPurchasedItemDetailOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewSupporterPurchasedItemDetailOutput) => {
        return input.convertDates();
      }),
    );
  }

  viewVirtualChairs(input: ViewVirtualChairsInput): Observable<ViewVirtualChairsOutput> {
    const url = `${this.urlHost}/organizations/${input.organizationID}/events/${input.virtualEventID}/virtual-tables/view/virtualchairs`;
    const headers = this.getHeaders();
    const params = input.getParams();
    return this.getAuthHttpClient().get<PhaasResponse<TypeWithFieldsOf<ViewVirtualChairsOutput>>>(url, { params, headers }).pipe(
      map((response: PhaasResponse<TypeWithFieldsOf<ViewVirtualChairsOutput>>) => {
        return response.payload!;
      }),
      // The HttpClient returns an object, not a(n) ViewVirtualChairsOutput instance, so create a class instance from that object
      map((input: TypeWithFieldsOf<ViewVirtualChairsOutput>) => {
        return new ViewVirtualChairsOutput(input).fixClasses();
      }),
      // Go slices and maps may serialize as null, so if they do, convert them to empty arrays/objects
      map((input: ViewVirtualChairsOutput) => {
        return input.convertUnexpectedNulls();
      }),
      // JSON actually serializes dates as strings, so convert them back to JavaScript Date objects
      map((input: ViewVirtualChairsOutput) => {
        return input.convertDates();
      }),
    );
  }
}

export class AddProxyBidderInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  proxyGuestID: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    proxyGuestID,
  }: Partial<TypeWithFieldsOf<AddProxyBidderInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
  }

  fixClasses(): AddProxyBidderInput {
    return this;
  }
}

export class AddProxyBidderOutput {
  proxyBidderID: string;

  constructor({
    proxyBidderID,
  }: Partial<TypeWithFieldsOf<AddProxyBidderOutput>> = {}) {
    this.proxyBidderID = proxyBidderID ?? "";
  }

  fixClasses(): AddProxyBidderOutput {
    return this;
  }
}

export class AddProxyLinkInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  proxyGuestID: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    proxyGuestID,
  }: Partial<TypeWithFieldsOf<AddProxyLinkInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
  }

  fixClasses(): AddProxyLinkInput {
    return this;
  }
}

export class AddProxyLinkOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<AddProxyLinkOutput>> = {}) {
  }

  fixClasses(): AddProxyLinkOutput {
    return this;
  }
}

export class AppealRecentDonor {
  firstName: string;
  lastName: string;
  emojiSelected: string;

  constructor({
    firstName,
    lastName,
    emojiSelected,
  }: Partial<TypeWithFieldsOf<AppealRecentDonor>> = {}) {
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.emojiSelected = emojiSelected ?? "";
  }

  fixClasses(): AppealRecentDonor {
    return this;
  }
}

export class Attendee {
  tableID: string;
  ticketID: string;
  guestID: string;
  attendeeID: string;
  firstName: string;
  lastName: string;
  ticketTypeID: string;
  ticketTypeName: string;
  ticketStatus: string;
  checkInTime: (Date | null);
  claimedTime: (Date | null);
  tableName: string;
  tableNumber: string;
  groupID: string;

  constructor({
    tableID,
    ticketID,
    guestID,
    attendeeID,
    firstName,
    lastName,
    ticketTypeID,
    ticketTypeName,
    ticketStatus,
    checkInTime,
    claimedTime,
    tableName,
    tableNumber,
    groupID,
  }: Partial<TypeWithFieldsOf<Attendee>> = {}) {
    this.tableID = tableID ?? "";
    this.ticketID = ticketID ?? "";
    this.guestID = guestID ?? "";
    this.attendeeID = attendeeID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.ticketTypeName = ticketTypeName ?? "";
    this.ticketStatus = ticketStatus ?? "";
    this.checkInTime = checkInTime ?? null;
    this.claimedTime = claimedTime ?? null;
    this.tableName = tableName ?? "";
    this.tableNumber = tableNumber ?? "";
    this.groupID = groupID ?? "";
  }

  convertDates(): Attendee {
    if (this.checkInTime !== undefined && this.checkInTime !== null) {
      this.checkInTime = new Date(this.checkInTime!);
    }
    if (this.claimedTime !== undefined && this.claimedTime !== null) {
      this.claimedTime = new Date(this.claimedTime!);
    }
    return this;
  }

  fixClasses(): Attendee {
    return this;
  }
}

export class BanFromLivestreamChatInput {
  organizationID: string;
  eventID: string;
  userID: string;

  constructor({
    organizationID,
    eventID,
    userID,
  }: Partial<TypeWithFieldsOf<BanFromLivestreamChatInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
  }

  fixClasses(): BanFromLivestreamChatInput {
    return this;
  }
}

export class BanFromLivestreamChatOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<BanFromLivestreamChatOutput>> = {}) {
  }

  fixClasses(): BanFromLivestreamChatOutput {
    return this;
  }
}

export class BuyFixedPrices {
  fixedPriceItemID: string;
  name: string;
  quantity: number;
  amountCents: number;

  constructor({
    fixedPriceItemID,
    name,
    quantity,
    amountCents,
  }: Partial<TypeWithFieldsOf<BuyFixedPrices>> = {}) {
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.name = name ?? "";
    this.quantity = quantity ?? 0;
    this.amountCents = amountCents ?? 0;
  }

  fixClasses(): BuyFixedPrices {
    return this;
  }
}

export class BuyItemAppealDonation {
  amountCents: number;
  emojiSelected: string;
  isAnonymous: boolean;
  fromSpotterTool: boolean;
  greatfeatsParticipantID: string;
  greatfeatsParticipantName: string;

  constructor({
    amountCents,
    emojiSelected,
    isAnonymous,
    fromSpotterTool,
    greatfeatsParticipantID,
    greatfeatsParticipantName,
  }: Partial<TypeWithFieldsOf<BuyItemAppealDonation>> = {}) {
    this.amountCents = amountCents ?? 0;
    this.emojiSelected = emojiSelected ?? "";
    this.isAnonymous = isAnonymous ?? false;
    this.fromSpotterTool = fromSpotterTool ?? false;
    this.greatfeatsParticipantID = greatfeatsParticipantID ?? "";
    this.greatfeatsParticipantName = greatfeatsParticipantName ?? "";
  }

  fixClasses(): BuyItemAppealDonation {
    return this;
  }
}

export class BuyItemAuctionLot {
  amountCents: number;
  auctionID: string;
  lotID: string;

  constructor({
    amountCents,
    auctionID,
    lotID,
  }: Partial<TypeWithFieldsOf<BuyItemAuctionLot>> = {}) {
    this.amountCents = amountCents ?? 0;
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
  }

  fixClasses(): BuyItemAuctionLot {
    return this;
  }
}

export class BuyItemDonation {
  amountCents: number;
  isAnonymous: boolean;
  greatfeatsParticipantID: string;
  greatfeatsParticipantName: string;

  constructor({
    amountCents,
    isAnonymous,
    greatfeatsParticipantID,
    greatfeatsParticipantName,
  }: Partial<TypeWithFieldsOf<BuyItemDonation>> = {}) {
    this.amountCents = amountCents ?? 0;
    this.isAnonymous = isAnonymous ?? false;
    this.greatfeatsParticipantID = greatfeatsParticipantID ?? "";
    this.greatfeatsParticipantName = greatfeatsParticipantName ?? "";
  }

  fixClasses(): BuyItemDonation {
    return this;
  }
}

export class BuyItemImpact {
  amountCents: number;
  impactID: string;
  name: string;
  isAnonymous: boolean;

  constructor({
    amountCents,
    impactID,
    name,
    isAnonymous,
  }: Partial<TypeWithFieldsOf<BuyItemImpact>> = {}) {
    this.amountCents = amountCents ?? 0;
    this.impactID = impactID ?? "";
    this.name = name ?? "";
    this.isAnonymous = isAnonymous ?? false;
  }

  fixClasses(): BuyItemImpact {
    return this;
  }
}

export class BuyItemRaffleTickets {
  amountCents: number;
  raffleID: string;
  purchaseLevelID: string;
  quantity: number;

  constructor({
    amountCents,
    raffleID,
    purchaseLevelID,
    quantity,
  }: Partial<TypeWithFieldsOf<BuyItemRaffleTickets>> = {}) {
    this.amountCents = amountCents ?? 0;
    this.raffleID = raffleID ?? "";
    this.purchaseLevelID = purchaseLevelID ?? "";
    this.quantity = quantity ?? 0;
  }

  fixClasses(): BuyItemRaffleTickets {
    return this;
  }
}

export class BuyItemsInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  donations: BuyItemDonation[];
  appealDonations: BuyItemAppealDonation[];
  impacts: BuyItemImpact[];
  raffleTickets: BuyItemRaffleTickets[];
  auctionLots: BuyItemAuctionLot[];
  tickets: BuyItemTickets[];
  fixedPrices: BuyFixedPrices[];
  miscellaneousCharges: BuyMiscellaneousCharges[];
  payAfterEvent: boolean;
  cardID: string;
  cardHolderGuestID: string;
  cardName: string;
  expiration: string;
  cardType: string;
  cardLastFour: string;
  token: string;
  coverFee: boolean;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  payerState: string;
  payerZip: string;
  payerCountry: string;
  fakeTransactionResult: string;
  isExternalPayment: boolean;
  isAnonymous: boolean;
  overrideCCRequirement: boolean;
  overrideUserID: string;
  saveCardForFutureUse: boolean;
  paymentType: string;
  checkNumber: string;
  paymentOtherTypeNotes: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    donations,
    appealDonations,
    impacts,
    raffleTickets,
    auctionLots,
    tickets,
    fixedPrices,
    miscellaneousCharges,
    payAfterEvent,
    cardID,
    cardHolderGuestID,
    cardName,
    expiration,
    cardType,
    cardLastFour,
    token,
    coverFee,
    payerName,
    payerStreet,
    payerCity,
    payerState,
    payerZip,
    payerCountry,
    fakeTransactionResult,
    isExternalPayment,
    isAnonymous,
    overrideCCRequirement,
    overrideUserID,
    saveCardForFutureUse,
    paymentType,
    checkNumber,
    paymentOtherTypeNotes,
  }: Partial<TypeWithFieldsOf<BuyItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.donations = donations ?? [];
    this.appealDonations = appealDonations ?? [];
    this.impacts = impacts ?? [];
    this.raffleTickets = raffleTickets ?? [];
    this.auctionLots = auctionLots ?? [];
    this.tickets = tickets ?? [];
    this.fixedPrices = fixedPrices ?? [];
    this.miscellaneousCharges = miscellaneousCharges ?? [];
    this.payAfterEvent = payAfterEvent ?? false;
    this.cardID = cardID ?? "";
    this.cardHolderGuestID = cardHolderGuestID ?? "";
    this.cardName = cardName ?? "";
    this.expiration = expiration ?? "";
    this.cardType = cardType ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.token = token ?? "";
    this.coverFee = coverFee ?? false;
    this.payerName = payerName ?? "";
    this.payerStreet = payerStreet ?? "";
    this.payerCity = payerCity ?? "";
    this.payerState = payerState ?? "";
    this.payerZip = payerZip ?? "";
    this.payerCountry = payerCountry ?? "";
    this.fakeTransactionResult = fakeTransactionResult ?? "";
    this.isExternalPayment = isExternalPayment ?? false;
    this.isAnonymous = isAnonymous ?? false;
    this.overrideCCRequirement = overrideCCRequirement ?? false;
    this.overrideUserID = overrideUserID ?? "";
    this.saveCardForFutureUse = saveCardForFutureUse ?? false;
    this.paymentType = paymentType ?? "";
    this.checkNumber = checkNumber ?? "";
    this.paymentOtherTypeNotes = paymentOtherTypeNotes ?? "";
  }

  convertUnexpectedNulls(): BuyItemsInput {
    if (!this.donations) {
      this.donations = [];
    }
    if (!this.appealDonations) {
      this.appealDonations = [];
    }
    if (!this.impacts) {
      this.impacts = [];
    }
    if (!this.raffleTickets) {
      this.raffleTickets = [];
    }
    if (!this.auctionLots) {
      this.auctionLots = [];
    }
    if (!this.tickets) {
      this.tickets = [];
    }
    for (let i0 = 0; i0 < this.tickets.length; i0 += 1) {
      this.tickets[i0] = this.tickets[i0]!.convertUnexpectedNulls();
    }
    if (!this.fixedPrices) {
      this.fixedPrices = [];
    }
    if (!this.miscellaneousCharges) {
      this.miscellaneousCharges = [];
    }
    return this;
  }

  fixClasses(): BuyItemsInput {
    for (let i0 = 0; i0 < this.donations.length; i0 += 1) {
      this.donations[i0] = new BuyItemDonation(this.donations[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.appealDonations.length; i0 += 1) {
      this.appealDonations[i0] = new BuyItemAppealDonation(this.appealDonations[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.impacts.length; i0 += 1) {
      this.impacts[i0] = new BuyItemImpact(this.impacts[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.raffleTickets.length; i0 += 1) {
      this.raffleTickets[i0] = new BuyItemRaffleTickets(this.raffleTickets[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.auctionLots.length; i0 += 1) {
      this.auctionLots[i0] = new BuyItemAuctionLot(this.auctionLots[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.tickets.length; i0 += 1) {
      this.tickets[i0] = new BuyItemTickets(this.tickets[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.fixedPrices.length; i0 += 1) {
      this.fixedPrices[i0] = new BuyFixedPrices(this.fixedPrices[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.miscellaneousCharges.length; i0 += 1) {
      this.miscellaneousCharges[i0] = new BuyMiscellaneousCharges(this.miscellaneousCharges[i0]!).fixClasses();
    }
    return this;
  }
}

export class BuyItemsOutput {
  ticketManagementToken: string;

  constructor({
    ticketManagementToken,
  }: Partial<TypeWithFieldsOf<BuyItemsOutput>> = {}) {
    this.ticketManagementToken = ticketManagementToken ?? "";
  }

  fixClasses(): BuyItemsOutput {
    return this;
  }
}

export class BuyItemTickets {
  ticketTypeID: string;
  attendeeFirstName: string;
  attendeeLastName: string;
  attendeeEmail: string;
  ticketOptionAnswers: TicketOptionAnswer[];
  isClaiming: boolean;
  promoCode: TicketPromoCodeSummary;

  constructor({
    ticketTypeID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
    ticketOptionAnswers,
    isClaiming,
    promoCode,
  }: Partial<TypeWithFieldsOf<BuyItemTickets>> = {}) {
    this.ticketTypeID = ticketTypeID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? "";
    this.attendeeLastName = attendeeLastName ?? "";
    this.attendeeEmail = attendeeEmail ?? "";
    this.ticketOptionAnswers = ticketOptionAnswers ?? [];
    this.isClaiming = isClaiming ?? false;
    this.promoCode = promoCode ?? new TicketPromoCodeSummary();
  }

  convertUnexpectedNulls(): BuyItemTickets {
    if (!this.ticketOptionAnswers) {
      this.ticketOptionAnswers = [];
    }
    this.promoCode = this.promoCode!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): BuyItemTickets {
    for (let i0 = 0; i0 < this.ticketOptionAnswers.length; i0 += 1) {
      this.ticketOptionAnswers[i0] = new TicketOptionAnswer(this.ticketOptionAnswers[i0]!).fixClasses();
    }
    this.promoCode = new TicketPromoCodeSummary(this.promoCode!).fixClasses();
    return this;
  }
}

export class BuyMiscellaneousCharges {
  description: string;
  amountCents: number;

  constructor({
    description,
    amountCents,
  }: Partial<TypeWithFieldsOf<BuyMiscellaneousCharges>> = {}) {
    this.description = description ?? "";
    this.amountCents = amountCents ?? 0;
  }

  fixClasses(): BuyMiscellaneousCharges {
    return this;
  }
}

export class BuyNowInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;
  auctionType: string;
  bidderID: string;
  bidTime: Date;
  guestID: string;
  assignWinnerOverride: boolean;
  assignWinnerAmountCents: number;
  overrideUserID: string;
  isUnregisteredGuest: boolean;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
    auctionType,
    bidderID,
    bidTime,
    guestID,
    assignWinnerOverride,
    assignWinnerAmountCents,
    overrideUserID,
    isUnregisteredGuest,
  }: Partial<TypeWithFieldsOf<BuyNowInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.auctionType = auctionType ?? "";
    this.bidderID = bidderID ?? "";
    this.bidTime = bidTime ?? new Date();
    this.guestID = guestID ?? "";
    this.assignWinnerOverride = assignWinnerOverride ?? false;
    this.assignWinnerAmountCents = assignWinnerAmountCents ?? 0;
    this.overrideUserID = overrideUserID ?? "";
    this.isUnregisteredGuest = isUnregisteredGuest ?? false;
  }

  convertDates(): BuyNowInput {
    this.bidTime = new Date(this.bidTime!);
    return this;
  }

  fixClasses(): BuyNowInput {
    return this;
  }
}

export class BuyNowOutput {
  bidID: string;

  constructor({
    bidID,
  }: Partial<TypeWithFieldsOf<BuyNowOutput>> = {}) {
    this.bidID = bidID ?? "";
  }

  fixClasses(): BuyNowOutput {
    return this;
  }
}

export class CalculateCoverCostInput {
  organizationID: string;
  virtualEventID: string;
  cardType: string;
  amountCents: number;

  constructor({
    organizationID,
    virtualEventID,
    cardType,
    amountCents,
  }: Partial<TypeWithFieldsOf<CalculateCoverCostInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.cardType = cardType ?? "";
    this.amountCents = amountCents ?? 0;
  }

  fixClasses(): CalculateCoverCostInput {
    return this;
  }
}

export class CalculateCoverCostOutput {
  coverCostCents: number;
  subTotalCents: number;
  totalCents: number;

  constructor({
    coverCostCents,
    subTotalCents,
    totalCents,
  }: Partial<TypeWithFieldsOf<CalculateCoverCostOutput>> = {}) {
    this.coverCostCents = coverCostCents ?? 0;
    this.subTotalCents = subTotalCents ?? 0;
    this.totalCents = totalCents ?? 0;
  }

  fixClasses(): CalculateCoverCostOutput {
    return this;
  }
}

export class CalculatePurchaseTotalsInput {
  organizationID: string;
  eventID: string;
  tickets: BuyItemTickets[];

  constructor({
    organizationID,
    eventID,
    tickets,
  }: Partial<TypeWithFieldsOf<CalculatePurchaseTotalsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.tickets = tickets ?? [];
  }

  convertUnexpectedNulls(): CalculatePurchaseTotalsInput {
    if (!this.tickets) {
      this.tickets = [];
    }
    for (let i0 = 0; i0 < this.tickets.length; i0 += 1) {
      this.tickets[i0] = this.tickets[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): CalculatePurchaseTotalsInput {
    for (let i0 = 0; i0 < this.tickets.length; i0 += 1) {
      this.tickets[i0] = new BuyItemTickets(this.tickets[i0]!).fixClasses();
    }
    return this;
  }
}

export class CalculatePurchaseTotalsOutput {
  ticketsTotalCents: number;
  ticketFeesTotalCents: number;
  feesTotalCents: number;
  subtotalCents: number;
  totalCents: number;

  constructor({
    ticketsTotalCents,
    ticketFeesTotalCents,
    feesTotalCents,
    subtotalCents,
    totalCents,
  }: Partial<TypeWithFieldsOf<CalculatePurchaseTotalsOutput>> = {}) {
    this.ticketsTotalCents = ticketsTotalCents ?? 0;
    this.ticketFeesTotalCents = ticketFeesTotalCents ?? 0;
    this.feesTotalCents = feesTotalCents ?? 0;
    this.subtotalCents = subtotalCents ?? 0;
    this.totalCents = totalCents ?? 0;
  }

  fixClasses(): CalculatePurchaseTotalsOutput {
    return this;
  }
}

export class CalculateTotalAmountInput {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;
  itemIDs: string[];
  coveredCostSelected: boolean;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
    itemIDs,
    coveredCostSelected,
  }: Partial<TypeWithFieldsOf<CalculateTotalAmountInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.itemIDs = itemIDs ?? [];
    this.coveredCostSelected = coveredCostSelected ?? false;
  }

  convertUnexpectedNulls(): CalculateTotalAmountInput {
    if (!this.itemIDs) {
      this.itemIDs = [];
    }
    return this;
  }

  fixClasses(): CalculateTotalAmountInput {
    return this;
  }
}

export class CalculateTotalAmountOutput {
  totalAmountCents: number;
  coveredCostAmountCents: number;
  salesTaxAmountCents: number;
  subtotalAmountCents: number;

  constructor({
    totalAmountCents,
    coveredCostAmountCents,
    salesTaxAmountCents,
    subtotalAmountCents,
  }: Partial<TypeWithFieldsOf<CalculateTotalAmountOutput>> = {}) {
    this.totalAmountCents = totalAmountCents ?? 0;
    this.coveredCostAmountCents = coveredCostAmountCents ?? 0;
    this.salesTaxAmountCents = salesTaxAmountCents ?? 0;
    this.subtotalAmountCents = subtotalAmountCents ?? 0;
  }

  fixClasses(): CalculateTotalAmountOutput {
    return this;
  }
}

export class Chair {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  channelName: string;
  channelARN: string;

  constructor({
    id,
    email,
    firstName,
    lastName,
    channelName,
    channelARN,
  }: Partial<TypeWithFieldsOf<Chair>> = {}) {
    this.id = id ?? "";
    this.email = email ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.channelName = channelName ?? "";
    this.channelARN = channelARN ?? "";
  }

  fixClasses(): Chair {
    return this;
  }
}

export class CheckGuestForClaimedTicketInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<CheckGuestForClaimedTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): CheckGuestForClaimedTicketInput {
    return this;
  }
}

export class CheckGuestForClaimedTicketOutput {
  action: string;

  constructor({
    action,
  }: Partial<TypeWithFieldsOf<CheckGuestForClaimedTicketOutput>> = {}) {
    this.action = action ?? "";
  }

  fixClasses(): CheckGuestForClaimedTicketOutput {
    return this;
  }
}

export class CheckInGuestSessionInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    guestSessionID,
  }: Partial<TypeWithFieldsOf<CheckInGuestSessionInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestSessionID = guestSessionID ?? "";
  }

  fixClasses(): CheckInGuestSessionInput {
    return this;
  }
}

export class CheckInGuestSessionOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<CheckInGuestSessionOutput>> = {}) {
  }

  fixClasses(): CheckInGuestSessionOutput {
    return this;
  }
}

export class CheckInMyGuestSessionsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    guestSessionID,
  }: Partial<TypeWithFieldsOf<CheckInMyGuestSessionsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestSessionID = guestSessionID ?? "";
  }

  fixClasses(): CheckInMyGuestSessionsInput {
    return this;
  }
}

export class CheckInMyGuestSessionsOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<CheckInMyGuestSessionsOutput>> = {}) {
  }

  fixClasses(): CheckInMyGuestSessionsOutput {
    return this;
  }
}

export class CheckInMyTicketsInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<CheckInMyTicketsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): CheckInMyTicketsInput {
    return this;
  }
}

export class CheckInMyTicketsOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<CheckInMyTicketsOutput>> = {}) {
  }

  fixClasses(): CheckInMyTicketsOutput {
    return this;
  }
}

export class ClaimGuestFromTokenInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestToken: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    guestToken,
  }: Partial<TypeWithFieldsOf<ClaimGuestFromTokenInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestToken = guestToken ?? "";
  }

  fixClasses(): ClaimGuestFromTokenInput {
    return this;
  }
}

export class ClaimGuestFromTokenOutput {
  result: string;

  constructor({
    result,
  }: Partial<TypeWithFieldsOf<ClaimGuestFromTokenOutput>> = {}) {
    this.result = result ?? "";
  }

  fixClasses(): ClaimGuestFromTokenOutput {
    return this;
  }
}

export class ClaimMyTicketInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  ticketAssignmentToken: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    ticketAssignmentToken,
  }: Partial<TypeWithFieldsOf<ClaimMyTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.ticketAssignmentToken = ticketAssignmentToken ?? "";
  }

  fixClasses(): ClaimMyTicketInput {
    return this;
  }
}

export class ClaimMyTicketOutput {
  ticketID: string;

  constructor({
    ticketID,
  }: Partial<TypeWithFieldsOf<ClaimMyTicketOutput>> = {}) {
    this.ticketID = ticketID ?? "";
  }

  fixClasses(): ClaimMyTicketOutput {
    return this;
  }
}

export class ClaimTicketAndRegisterInput {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  ticketID: string;
  ticketOptionAnswers: TicketOptionAnswer[];
  payerName: string;
  payerStreet: string;
  payerCity: string;
  payerState: string;
  payerZip: string;
  payerCountry: string;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    email,
    mobilePhone,
    ticketID,
    ticketOptionAnswers,
    payerName,
    payerStreet,
    payerCity,
    payerState,
    payerZip,
    payerCountry,
  }: Partial<TypeWithFieldsOf<ClaimTicketAndRegisterInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.ticketID = ticketID ?? "";
    this.ticketOptionAnswers = ticketOptionAnswers ?? [];
    this.payerName = payerName ?? "";
    this.payerStreet = payerStreet ?? "";
    this.payerCity = payerCity ?? "";
    this.payerState = payerState ?? "";
    this.payerZip = payerZip ?? "";
    this.payerCountry = payerCountry ?? "";
  }

  convertUnexpectedNulls(): ClaimTicketAndRegisterInput {
    if (!this.ticketOptionAnswers) {
      this.ticketOptionAnswers = [];
    }
    return this;
  }

  fixClasses(): ClaimTicketAndRegisterInput {
    for (let i0 = 0; i0 < this.ticketOptionAnswers.length; i0 += 1) {
      this.ticketOptionAnswers[i0] = new TicketOptionAnswer(this.ticketOptionAnswers[i0]!).fixClasses();
    }
    return this;
  }
}

export class ClaimTicketAndRegisterOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<ClaimTicketAndRegisterOutput>> = {}) {
  }

  fixClasses(): ClaimTicketAndRegisterOutput {
    return this;
  }
}

export class ClearAllBidsInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;
  bidID: string;
  userID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
    bidID,
    userID,
  }: Partial<TypeWithFieldsOf<ClearAllBidsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.bidID = bidID ?? "";
    this.userID = userID ?? "";
  }

  fixClasses(): ClearAllBidsInput {
    return this;
  }
}

export class ClearAllBidsOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<ClearAllBidsOutput>> = {}) {
  }

  fixClasses(): ClearAllBidsOutput {
    return this;
  }
}

export class CloseBiddingInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
  }: Partial<TypeWithFieldsOf<CloseBiddingInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
  }

  fixClasses(): CloseBiddingInput {
    return this;
  }
}

export class CloseBiddingOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<CloseBiddingOutput>> = {}) {
  }

  fixClasses(): CloseBiddingOutput {
    return this;
  }
}

export class CloseBroadcastInput {
  organizationID: string;
  eventID: string;
  showID: string;

  constructor({
    organizationID,
    eventID,
    showID,
  }: Partial<TypeWithFieldsOf<CloseBroadcastInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
  }

  fixClasses(): CloseBroadcastInput {
    return this;
  }
}

export class CloseBroadcastOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<CloseBroadcastOutput>> = {}) {
  }

  fixClasses(): CloseBroadcastOutput {
    return this;
  }
}

export class CompleteMyPreEventQuestionnaireInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<CompleteMyPreEventQuestionnaireInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): CompleteMyPreEventQuestionnaireInput {
    return this;
  }
}

export class CompleteMyPreEventQuestionnaireOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<CompleteMyPreEventQuestionnaireOutput>> = {}) {
  }

  fixClasses(): CompleteMyPreEventQuestionnaireOutput {
    return this;
  }
}

export class CompTicketInput {
  organizationID: string;
  eventID: string;
  ticketTypeID: string;
  attendeeFirstName: string;
  attendeeLastName: string;
  attendeeEmail: string;
  managerFirstName: string;
  managerLastName: string;
  managerEmail: string;
  isManaged: boolean;
  canSkipEmail: boolean;

  constructor({
    organizationID,
    eventID,
    ticketTypeID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
    managerFirstName,
    managerLastName,
    managerEmail,
    isManaged,
    canSkipEmail,
  }: Partial<TypeWithFieldsOf<CompTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? "";
    this.attendeeLastName = attendeeLastName ?? "";
    this.attendeeEmail = attendeeEmail ?? "";
    this.managerFirstName = managerFirstName ?? "";
    this.managerLastName = managerLastName ?? "";
    this.managerEmail = managerEmail ?? "";
    this.isManaged = isManaged ?? false;
    this.canSkipEmail = canSkipEmail ?? false;
  }

  fixClasses(): CompTicketInput {
    return this;
  }
}

export class CompTicketOutput {
  ticketIDs: string[];

  constructor({
    ticketIDs,
  }: Partial<TypeWithFieldsOf<CompTicketOutput>> = {}) {
    this.ticketIDs = ticketIDs ?? [];
  }

  convertUnexpectedNulls(): CompTicketOutput {
    if (!this.ticketIDs) {
      this.ticketIDs = [];
    }
    return this;
  }

  fixClasses(): CompTicketOutput {
    return this;
  }
}

export class CompTicketsInput {
  organizationID: string;
  eventID: string;
  ticketTypeIDs: string[];
  managerFirstName: string;
  managerLastName: string;
  managerEmail: string;
  isManaged: boolean;

  constructor({
    organizationID,
    eventID,
    ticketTypeIDs,
    managerFirstName,
    managerLastName,
    managerEmail,
    isManaged,
  }: Partial<TypeWithFieldsOf<CompTicketsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketTypeIDs = ticketTypeIDs ?? [];
    this.managerFirstName = managerFirstName ?? "";
    this.managerLastName = managerLastName ?? "";
    this.managerEmail = managerEmail ?? "";
    this.isManaged = isManaged ?? false;
  }

  convertUnexpectedNulls(): CompTicketsInput {
    if (!this.ticketTypeIDs) {
      this.ticketTypeIDs = [];
    }
    return this;
  }

  fixClasses(): CompTicketsInput {
    return this;
  }
}

export class CompTicketsOutput {
  ticketIDs: string[];

  constructor({
    ticketIDs,
  }: Partial<TypeWithFieldsOf<CompTicketsOutput>> = {}) {
    this.ticketIDs = ticketIDs ?? [];
  }

  convertUnexpectedNulls(): CompTicketsOutput {
    if (!this.ticketIDs) {
      this.ticketIDs = [];
    }
    return this;
  }

  fixClasses(): CompTicketsOutput {
    return this;
  }
}

export class CountDonationMomentsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountDonationMomentsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountDonationMomentsInput {
    return this;
  }
}

export class CountDonationMomentsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountDonationMomentsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountDonationMomentsOutput {
    return this;
  }
}

export class CountEventSessionsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountEventSessionsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountEventSessionsInput {
    return this;
  }
}

export class CountEventSessionsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountEventSessionsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountEventSessionsOutput {
    return this;
  }
}

export class CountEventSessionTicketConfigsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<CountEventSessionTicketConfigsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountEventSessionTicketConfigsInput {
    return this;
  }
}

export class CountEventSessionTicketConfigsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountEventSessionTicketConfigsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountEventSessionTicketConfigsOutput {
    return this;
  }
}

export class CountEventsInput {
  organizationID: string;
  timezone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    timezone,
    paging,
  }: Partial<TypeWithFieldsOf<CountEventsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.timezone = timezone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountEventsInput {
    return this;
  }
}

export class CountEventSlideConfigsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountEventSlideConfigsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountEventSlideConfigsInput {
    return this;
  }
}

export class CountEventSlideConfigsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountEventSlideConfigsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountEventSlideConfigsOutput {
    return this;
  }
}

export class CountEventSlideshowsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountEventSlideshowsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountEventSlideshowsInput {
    return this;
  }
}

export class CountEventSlideshowsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountEventSlideshowsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountEventSlideshowsOutput {
    return this;
  }
}

export class CountEventsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountEventsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountEventsOutput {
    return this;
  }
}

export class CountFixedPriceItemPhotosInput {
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    fixedPriceItemID,
    paging,
  }: Partial<TypeWithFieldsOf<CountFixedPriceItemPhotosInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountFixedPriceItemPhotosInput {
    return this;
  }
}

export class CountFixedPriceItemPhotosOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountFixedPriceItemPhotosOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountFixedPriceItemPhotosOutput {
    return this;
  }
}

export class CountFixedPriceItemsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountFixedPriceItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountFixedPriceItemsInput {
    return this;
  }
}

export class CountFixedPriceItemsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountFixedPriceItemsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountFixedPriceItemsOutput {
    return this;
  }
}

export class CountGuestSessionsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<CountGuestSessionsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountGuestSessionsInput {
    return this;
  }
}

export class CountGuestSessionsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountGuestSessionsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountGuestSessionsOutput {
    return this;
  }
}

export class CountGuestsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountGuestsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountGuestsInput {
    return this;
  }
}

export class CountGuestsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountGuestsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountGuestsOutput {
    return this;
  }
}

export class CountImpactPhotosInput {
  organizationID: string;
  eventID: string;
  impactID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    impactID,
    paging,
  }: Partial<TypeWithFieldsOf<CountImpactPhotosInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountImpactPhotosInput {
    return this;
  }
}

export class CountImpactPhotosOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountImpactPhotosOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountImpactPhotosOutput {
    return this;
  }
}

export class CountImpactsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountImpactsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountImpactsInput {
    return this;
  }
}

export class CountImpactsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountImpactsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountImpactsOutput {
    return this;
  }
}

export class CountMyTicketsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountMyTicketsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountMyTicketsInput {
    return this;
  }
}

export class CountMyTicketsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountMyTicketsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountMyTicketsOutput {
    return this;
  }
}

export class CountOverlaysInput {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    paging,
  }: Partial<TypeWithFieldsOf<CountOverlaysInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountOverlaysInput {
    return this;
  }
}

export class CountOverlaysOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountOverlaysOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountOverlaysOutput {
    return this;
  }
}

export class CountPickupItemsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountPickupItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountPickupItemsInput {
    return this;
  }
}

export class CountPickupItemsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountPickupItemsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountPickupItemsOutput {
    return this;
  }
}

export class CountPreEventQuestionConfigsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountPreEventQuestionConfigsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountPreEventQuestionConfigsInput {
    return this;
  }
}

export class CountPreEventQuestionConfigsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountPreEventQuestionConfigsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountPreEventQuestionConfigsOutput {
    return this;
  }
}

export class CountPurchasedItemSalesTaxesInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountPurchasedItemSalesTaxesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountPurchasedItemSalesTaxesInput {
    return this;
  }
}

export class CountPurchasedItemSalesTaxesOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountPurchasedItemSalesTaxesOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountPurchasedItemSalesTaxesOutput {
    return this;
  }
}

export class CountPurchasedItemsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountPurchasedItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountPurchasedItemsInput {
    return this;
  }
}

export class CountPurchasedItemsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountPurchasedItemsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountPurchasedItemsOutput {
    return this;
  }
}

export class CountRegistrationsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountRegistrationsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountRegistrationsInput {
    return this;
  }
}

export class CountRegistrationsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountRegistrationsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountRegistrationsOutput {
    return this;
  }
}

export class CountScenesInput {
  organizationID: string;
  eventID: string;
  showID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    showID,
    paging,
  }: Partial<TypeWithFieldsOf<CountScenesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountScenesInput {
    return this;
  }
}

export class CountScenesOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountScenesOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountScenesOutput {
    return this;
  }
}

export class CountShowsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountShowsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountShowsInput {
    return this;
  }
}

export class CountShowsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountShowsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountShowsOutput {
    return this;
  }
}

export class CountVirtualChairsInput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    paging,
  }: Partial<TypeWithFieldsOf<CountVirtualChairsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountVirtualChairsInput {
    return this;
  }
}

export class CountVirtualChairsOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountVirtualChairsOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountVirtualChairsOutput {
    return this;
  }
}

export class CountVirtualTablesInput {
  organizationID: string;
  virtualEventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    paging,
  }: Partial<TypeWithFieldsOf<CountVirtualTablesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): CountVirtualTablesInput {
    return this;
  }
}

export class CountVirtualTablesOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<CountVirtualTablesOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): CountVirtualTablesOutput {
    return this;
  }
}

export class CreateAuctionAndSceneInput {
  organizationID: string;
  eventID: string;
  name: string;
  description: string;
  openTime: (Date | null);
  closeTime: (Date | null);
  type: string;
  activeLotID: string;
  auctionGoalCents: number;
  inPerson: boolean;

  constructor({
    organizationID,
    eventID,
    name,
    description,
    openTime,
    closeTime,
    type,
    activeLotID,
    auctionGoalCents,
    inPerson,
  }: Partial<TypeWithFieldsOf<CreateAuctionAndSceneInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? "";
    this.description = description ?? "";
    this.openTime = openTime ?? null;
    this.closeTime = closeTime ?? null;
    this.type = type ?? "";
    this.activeLotID = activeLotID ?? "";
    this.auctionGoalCents = auctionGoalCents ?? 0;
    this.inPerson = inPerson ?? false;
  }

  convertDates(): CreateAuctionAndSceneInput {
    if (this.openTime !== undefined && this.openTime !== null) {
      this.openTime = new Date(this.openTime!);
    }
    if (this.closeTime !== undefined && this.closeTime !== null) {
      this.closeTime = new Date(this.closeTime!);
    }
    return this;
  }

  fixClasses(): CreateAuctionAndSceneInput {
    return this;
  }
}

export class CreateAuctionAndSceneOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateAuctionAndSceneOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateAuctionAndSceneOutput {
    return this;
  }
}

export class CreateBidLimitInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;
  amountCents: number;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
    amountCents,
  }: Partial<TypeWithFieldsOf<CreateBidLimitInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.amountCents = amountCents ?? 0;
  }

  fixClasses(): CreateBidLimitInput {
    return this;
  }
}

export class CreateBidLimitOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<CreateBidLimitOutput>> = {}) {
  }

  fixClasses(): CreateBidLimitOutput {
    return this;
  }
}

export class CreateChannelInput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  virtualChairID: string;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    virtualChairID,
  }: Partial<TypeWithFieldsOf<CreateChannelInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.virtualChairID = virtualChairID ?? "";
  }

  fixClasses(): CreateChannelInput {
    return this;
  }
}

export class CreateChannelOutput {
  channelARN: string;
  channelName: string;

  constructor({
    channelARN,
    channelName,
  }: Partial<TypeWithFieldsOf<CreateChannelOutput>> = {}) {
    this.channelARN = channelARN ?? "";
    this.channelName = channelName ?? "";
  }

  fixClasses(): CreateChannelOutput {
    return this;
  }
}

export class CreateDonationMomentAndSceneOverlayInput {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  name: string;
  type: string;
  donationLevelsAmountCents: number[];
  disableOnlineDonations: boolean;
  giveAtAnyLevelEnabled: boolean;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    name,
    type,
    donationLevelsAmountCents,
    disableOnlineDonations,
    giveAtAnyLevelEnabled,
  }: Partial<TypeWithFieldsOf<CreateDonationMomentAndSceneOverlayInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.name = name ?? "";
    this.type = type ?? "";
    this.donationLevelsAmountCents = donationLevelsAmountCents ?? [];
    this.disableOnlineDonations = disableOnlineDonations ?? false;
    this.giveAtAnyLevelEnabled = giveAtAnyLevelEnabled ?? false;
  }

  convertUnexpectedNulls(): CreateDonationMomentAndSceneOverlayInput {
    if (!this.donationLevelsAmountCents) {
      this.donationLevelsAmountCents = [];
    }
    return this;
  }

  fixClasses(): CreateDonationMomentAndSceneOverlayInput {
    return this;
  }
}

export class CreateDonationMomentAndSceneOverlayOutput {
  donationMomentID: string;
  sceneID: string;
  overlayID: string;

  constructor({
    donationMomentID,
    sceneID,
    overlayID,
  }: Partial<TypeWithFieldsOf<CreateDonationMomentAndSceneOverlayOutput>> = {}) {
    this.donationMomentID = donationMomentID ?? "";
    this.sceneID = sceneID ?? "";
    this.overlayID = overlayID ?? "";
  }

  fixClasses(): CreateDonationMomentAndSceneOverlayOutput {
    return this;
  }
}

export class CreateDonationMomentInput {
  organizationID: string;
  eventID: string;
  name: string;
  type: string;
  donationLevelsAmountCents: number[];
  disableOnlineDonations: boolean;
  giveAtAnyLevelEnabled: boolean;
  giveAtAnyLevelActive: boolean;

  constructor({
    organizationID,
    eventID,
    name,
    type,
    donationLevelsAmountCents,
    disableOnlineDonations,
    giveAtAnyLevelEnabled,
    giveAtAnyLevelActive,
  }: Partial<TypeWithFieldsOf<CreateDonationMomentInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? "";
    this.type = type ?? "";
    this.donationLevelsAmountCents = donationLevelsAmountCents ?? [];
    this.disableOnlineDonations = disableOnlineDonations ?? false;
    this.giveAtAnyLevelEnabled = giveAtAnyLevelEnabled ?? false;
    this.giveAtAnyLevelActive = giveAtAnyLevelActive ?? false;
  }

  convertUnexpectedNulls(): CreateDonationMomentInput {
    if (!this.donationLevelsAmountCents) {
      this.donationLevelsAmountCents = [];
    }
    return this;
  }

  fixClasses(): CreateDonationMomentInput {
    return this;
  }
}

export class CreateDonationMomentOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateDonationMomentOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateDonationMomentOutput {
    return this;
  }
}

export class CreateEventInput {
  organizationID: string;
  name: string;
  eventType: string;
  primaryDate: (Date | null);
  primaryEndDate: (Date | null);
  timezone: string;
  isCoverCostActive: boolean;
  coverCostPercent: number;
  coverCostCustomMessage: string;
  coverCostDefault: boolean;
  enableTicketing: boolean;
  thermometerConfig: ThermometerSetupConfig;
  testEvent: boolean;
  enableSelfCheckin: boolean;
  salesTaxPercent: number;
  salesTaxAppliesTo: string;
  salesTaxCoverageType: string;
  externalID: string;

  constructor({
    organizationID,
    name,
    eventType,
    primaryDate,
    primaryEndDate,
    timezone,
    isCoverCostActive,
    coverCostPercent,
    coverCostCustomMessage,
    coverCostDefault,
    enableTicketing,
    thermometerConfig,
    testEvent,
    enableSelfCheckin,
    salesTaxPercent,
    salesTaxAppliesTo,
    salesTaxCoverageType,
    externalID,
  }: Partial<TypeWithFieldsOf<CreateEventInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.name = name ?? "";
    this.eventType = eventType ?? "";
    this.primaryDate = primaryDate ?? null;
    this.primaryEndDate = primaryEndDate ?? null;
    this.timezone = timezone ?? "";
    this.isCoverCostActive = isCoverCostActive ?? false;
    this.coverCostPercent = coverCostPercent ?? 0;
    this.coverCostCustomMessage = coverCostCustomMessage ?? "";
    this.coverCostDefault = coverCostDefault ?? false;
    this.enableTicketing = enableTicketing ?? false;
    this.thermometerConfig = thermometerConfig ?? new ThermometerSetupConfig();
    this.testEvent = testEvent ?? false;
    this.enableSelfCheckin = enableSelfCheckin ?? false;
    this.salesTaxPercent = salesTaxPercent ?? 0;
    this.salesTaxAppliesTo = salesTaxAppliesTo ?? "";
    this.salesTaxCoverageType = salesTaxCoverageType ?? "";
    this.externalID = externalID ?? "";
  }

  convertDates(): CreateEventInput {
    if (this.primaryDate !== undefined && this.primaryDate !== null) {
      this.primaryDate = new Date(this.primaryDate!);
    }
    if (this.primaryEndDate !== undefined && this.primaryEndDate !== null) {
      this.primaryEndDate = new Date(this.primaryEndDate!);
    }
    return this;
  }

  fixClasses(): CreateEventInput {
    this.thermometerConfig = new ThermometerSetupConfig(this.thermometerConfig!).fixClasses();
    return this;
  }
}

export class CreateEventOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateEventOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateEventOutput {
    return this;
  }
}

export class CreateEventSessionInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<CreateEventSessionInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): CreateEventSessionInput {
    return this;
  }
}

export class CreateEventSessionOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateEventSessionOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateEventSessionOutput {
    return this;
  }
}

export class CreateEventSessionTicketConfigInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  ticketTypeID: string;
  attendeeExperience: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    ticketTypeID,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<CreateEventSessionTicketConfigInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.attendeeExperience = attendeeExperience ?? "";
  }

  fixClasses(): CreateEventSessionTicketConfigInput {
    return this;
  }
}

export class CreateEventSessionTicketConfigOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateEventSessionTicketConfigOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateEventSessionTicketConfigOutput {
    return this;
  }
}

export class CreateEventSlideConfigInput {
  organizationID: string;
  eventID: string;
  slideType: string;
  detailsJSON: string;

  constructor({
    organizationID,
    eventID,
    slideType,
    detailsJSON,
  }: Partial<TypeWithFieldsOf<CreateEventSlideConfigInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.slideType = slideType ?? "";
    this.detailsJSON = detailsJSON ?? "";
  }

  fixClasses(): CreateEventSlideConfigInput {
    return this;
  }
}

export class CreateEventSlideConfigOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateEventSlideConfigOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateEventSlideConfigOutput {
    return this;
  }
}

export class CreateEventSlideshowInput {
  organizationID: string;
  eventID: string;
  activeSlideURL: string;

  constructor({
    organizationID,
    eventID,
    activeSlideURL,
  }: Partial<TypeWithFieldsOf<CreateEventSlideshowInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.activeSlideURL = activeSlideURL ?? "";
  }

  fixClasses(): CreateEventSlideshowInput {
    return this;
  }
}

export class CreateEventSlideshowOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateEventSlideshowOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateEventSlideshowOutput {
    return this;
  }
}

export class CreateFixedPriceItemInput {
  organizationID: string;
  eventID: string;
  name: string;
  priceCents: number;
  valueCents: number;
  isTaxable: boolean;
  description: string;
  numPurchased: number;
  maxNumAvailable: number;
  numUnlimited: boolean;
  inPersonOnly: boolean;
  pickupLater: boolean;
  pickupTonight: boolean;
  isPickUpEventLevel: boolean;
  sortOrder: number;

  constructor({
    organizationID,
    eventID,
    name,
    priceCents,
    valueCents,
    isTaxable,
    description,
    numPurchased,
    maxNumAvailable,
    numUnlimited,
    inPersonOnly,
    pickupLater,
    pickupTonight,
    isPickUpEventLevel,
    sortOrder,
  }: Partial<TypeWithFieldsOf<CreateFixedPriceItemInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? "";
    this.priceCents = priceCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.isTaxable = isTaxable ?? false;
    this.description = description ?? "";
    this.numPurchased = numPurchased ?? 0;
    this.maxNumAvailable = maxNumAvailable ?? 0;
    this.numUnlimited = numUnlimited ?? false;
    this.inPersonOnly = inPersonOnly ?? false;
    this.pickupLater = pickupLater ?? false;
    this.pickupTonight = pickupTonight ?? false;
    this.isPickUpEventLevel = isPickUpEventLevel ?? false;
    this.sortOrder = sortOrder ?? 0;
  }

  fixClasses(): CreateFixedPriceItemInput {
    return this;
  }
}

export class CreateFixedPriceItemOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateFixedPriceItemOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateFixedPriceItemOutput {
    return this;
  }
}

export class CreateFixedPriceItemPhotoInput {
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  url: string;

  constructor({
    organizationID,
    eventID,
    fixedPriceItemID,
    url,
  }: Partial<TypeWithFieldsOf<CreateFixedPriceItemPhotoInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.url = url ?? "";
  }

  fixClasses(): CreateFixedPriceItemPhotoInput {
    return this;
  }
}

export class CreateFixedPriceItemPhotoOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateFixedPriceItemPhotoOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateFixedPriceItemPhotoOutput {
    return this;
  }
}

export class CreateGuestInput {
  organizationID: string;
  eventID: string;
  userID: string;
  registrationID: string;
  bidderNumber: number;
  attendeeNumber: string;
  managingGuestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  preEventQuestionnaireCompleteTime: (Date | null);
  preEventQuestionOneAnswer: boolean;
  preEventQuestionTwoAnswer: boolean;
  preEventQuestionThreeAnswer: boolean;
  preEventConsentSelfCompleted: boolean;
  purchasedItemPrimaryUserID: string;
  purchasedItemPrimaryGuestID: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  userCardID: string;

  constructor({
    organizationID,
    eventID,
    userID,
    registrationID,
    bidderNumber,
    attendeeNumber,
    managingGuestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    preEventQuestionnaireCompleteTime,
    preEventQuestionOneAnswer,
    preEventQuestionTwoAnswer,
    preEventQuestionThreeAnswer,
    preEventConsentSelfCompleted,
    purchasedItemPrimaryUserID,
    purchasedItemPrimaryGuestID,
    address,
    city,
    state,
    postalCode,
    country,
    userCardID,
  }: Partial<TypeWithFieldsOf<CreateGuestInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.registrationID = registrationID ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.attendeeNumber = attendeeNumber ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.preEventQuestionOneAnswer = preEventQuestionOneAnswer ?? false;
    this.preEventQuestionTwoAnswer = preEventQuestionTwoAnswer ?? false;
    this.preEventQuestionThreeAnswer = preEventQuestionThreeAnswer ?? false;
    this.preEventConsentSelfCompleted = preEventConsentSelfCompleted ?? false;
    this.purchasedItemPrimaryUserID = purchasedItemPrimaryUserID ?? "";
    this.purchasedItemPrimaryGuestID = purchasedItemPrimaryGuestID ?? "";
    this.address = address ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.postalCode = postalCode ?? "";
    this.country = country ?? "";
    this.userCardID = userCardID ?? "";
  }

  convertDates(): CreateGuestInput {
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): CreateGuestInput {
    return this;
  }
}

export class CreateGuestOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateGuestOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateGuestOutput {
    return this;
  }
}

export class CreateGuestSessionInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestID: string;
  isCheckInWithManagingGuest: boolean;
  checkedInTime: (Date | null);
  tableAttendeeID: string;
  ticketID: string;
  initialTableName: string;
  initialTableNumber: string;
  totalProceedsAmountCents: number;
  attendeeExperience: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    guestID,
    isCheckInWithManagingGuest,
    checkedInTime,
    tableAttendeeID,
    ticketID,
    initialTableName,
    initialTableNumber,
    totalProceedsAmountCents,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<CreateGuestSessionInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestID = guestID ?? "";
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? false;
    this.checkedInTime = checkedInTime ?? null;
    this.tableAttendeeID = tableAttendeeID ?? "";
    this.ticketID = ticketID ?? "";
    this.initialTableName = initialTableName ?? "";
    this.initialTableNumber = initialTableNumber ?? "";
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.attendeeExperience = attendeeExperience ?? "";
  }

  convertDates(): CreateGuestSessionInput {
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    return this;
  }

  fixClasses(): CreateGuestSessionInput {
    return this;
  }
}

export class CreateGuestSessionOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateGuestSessionOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateGuestSessionOutput {
    return this;
  }
}

export class CreateGuestWithoutHookInput {
  organizationID: string;
  eventID: string;
  userID: string;
  registrationID: string;
  bidderNumber: number;
  attendeeNumber: string;
  managingGuestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  preEventQuestionnaireCompleteTime: (Date | null);
  preEventQuestionOneAnswer: boolean;
  preEventQuestionTwoAnswer: boolean;
  preEventQuestionThreeAnswer: boolean;
  preEventConsentSelfCompleted: boolean;
  purchasedItemPrimaryUserID: string;
  purchasedItemPrimaryGuestID: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  userCardID: string;

  constructor({
    organizationID,
    eventID,
    userID,
    registrationID,
    bidderNumber,
    attendeeNumber,
    managingGuestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    preEventQuestionnaireCompleteTime,
    preEventQuestionOneAnswer,
    preEventQuestionTwoAnswer,
    preEventQuestionThreeAnswer,
    preEventConsentSelfCompleted,
    purchasedItemPrimaryUserID,
    purchasedItemPrimaryGuestID,
    address,
    city,
    state,
    postalCode,
    country,
    userCardID,
  }: Partial<TypeWithFieldsOf<CreateGuestWithoutHookInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.registrationID = registrationID ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.attendeeNumber = attendeeNumber ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.preEventQuestionOneAnswer = preEventQuestionOneAnswer ?? false;
    this.preEventQuestionTwoAnswer = preEventQuestionTwoAnswer ?? false;
    this.preEventQuestionThreeAnswer = preEventQuestionThreeAnswer ?? false;
    this.preEventConsentSelfCompleted = preEventConsentSelfCompleted ?? false;
    this.purchasedItemPrimaryUserID = purchasedItemPrimaryUserID ?? "";
    this.purchasedItemPrimaryGuestID = purchasedItemPrimaryGuestID ?? "";
    this.address = address ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.postalCode = postalCode ?? "";
    this.country = country ?? "";
    this.userCardID = userCardID ?? "";
  }

  convertDates(): CreateGuestWithoutHookInput {
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): CreateGuestWithoutHookInput {
    return this;
  }
}

export class CreateGuestWithoutHookOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateGuestWithoutHookOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateGuestWithoutHookOutput {
    return this;
  }
}

export class CreateImpactInput {
  organizationID: string;
  eventID: string;
  name: string;
  amountCents: number;
  descriptionMarkdown: string;
  totalPurchasesLimit: number;
  totalPurchasesCountDisplayThreshold: number;
  totalPurchasesAmountCentsDisplayThreshold: number;
  goalType: string;
  goalPurchasesCount: number;
  goalPurchasesCountDisplayThreshold: number;
  goalPurchasesAmountCents: number;
  goalPurchasesAmountCentsDisplayThreshold: number;
  goalMetEmailSubject: string;
  goalMetEmailBodyMarkdown: string;
  goalMetSMSBody: string;
  hideFromFlow: boolean;
  fixedPriceItemID: string;

  constructor({
    organizationID,
    eventID,
    name,
    amountCents,
    descriptionMarkdown,
    totalPurchasesLimit,
    totalPurchasesCountDisplayThreshold,
    totalPurchasesAmountCentsDisplayThreshold,
    goalType,
    goalPurchasesCount,
    goalPurchasesCountDisplayThreshold,
    goalPurchasesAmountCents,
    goalPurchasesAmountCentsDisplayThreshold,
    goalMetEmailSubject,
    goalMetEmailBodyMarkdown,
    goalMetSMSBody,
    hideFromFlow,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<CreateImpactInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? "";
    this.amountCents = amountCents ?? 0;
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.totalPurchasesLimit = totalPurchasesLimit ?? 0;
    this.totalPurchasesCountDisplayThreshold = totalPurchasesCountDisplayThreshold ?? 0;
    this.totalPurchasesAmountCentsDisplayThreshold = totalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.goalType = goalType ?? "";
    this.goalPurchasesCount = goalPurchasesCount ?? 0;
    this.goalPurchasesCountDisplayThreshold = goalPurchasesCountDisplayThreshold ?? 0;
    this.goalPurchasesAmountCents = goalPurchasesAmountCents ?? 0;
    this.goalPurchasesAmountCentsDisplayThreshold = goalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.goalMetEmailSubject = goalMetEmailSubject ?? "";
    this.goalMetEmailBodyMarkdown = goalMetEmailBodyMarkdown ?? "";
    this.goalMetSMSBody = goalMetSMSBody ?? "";
    this.hideFromFlow = hideFromFlow ?? false;
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  fixClasses(): CreateImpactInput {
    return this;
  }
}

export class CreateImpactOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateImpactOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateImpactOutput {
    return this;
  }
}

export class CreateImpactPhotoInput {
  organizationID: string;
  eventID: string;
  impactID: string;
  url: string;

  constructor({
    organizationID,
    eventID,
    impactID,
    url,
  }: Partial<TypeWithFieldsOf<CreateImpactPhotoInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
    this.url = url ?? "";
  }

  fixClasses(): CreateImpactPhotoInput {
    return this;
  }
}

export class CreateImpactPhotoOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateImpactPhotoOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateImpactPhotoOutput {
    return this;
  }
}

export class CreateInvitationInput {
  organizationID: string;
  eventID: string;
  externalID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  messageTemplateMarkdown: string;
  invitationEmailSubject: string;

  constructor({
    organizationID,
    eventID,
    externalID,
    firstName,
    lastName,
    email,
    mobilePhone,
    messageTemplateMarkdown,
    invitationEmailSubject,
  }: Partial<TypeWithFieldsOf<CreateInvitationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.externalID = externalID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.messageTemplateMarkdown = messageTemplateMarkdown ?? "";
    this.invitationEmailSubject = invitationEmailSubject ?? "";
  }

  fixClasses(): CreateInvitationInput {
    return this;
  }
}

export class CreateInvitationOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateInvitationOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateInvitationOutput {
    return this;
  }
}

export class CreateOrUpdateTicketOptionAnswer {
  ticketOptionID: string;
  ticketOptionAnswerID: string;
  ticketOptionPromptChoiceID: string;
  ticketOptionAnswer: string;

  constructor({
    ticketOptionID,
    ticketOptionAnswerID,
    ticketOptionPromptChoiceID,
    ticketOptionAnswer,
  }: Partial<TypeWithFieldsOf<CreateOrUpdateTicketOptionAnswer>> = {}) {
    this.ticketOptionID = ticketOptionID ?? "";
    this.ticketOptionAnswerID = ticketOptionAnswerID ?? "";
    this.ticketOptionPromptChoiceID = ticketOptionPromptChoiceID ?? "";
    this.ticketOptionAnswer = ticketOptionAnswer ?? "";
  }

  fixClasses(): CreateOrUpdateTicketOptionAnswer {
    return this;
  }
}

export class CreateOverlayInput {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  name: string;
  type: string;
  config: string;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    name,
    type,
    config,
  }: Partial<TypeWithFieldsOf<CreateOverlayInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.name = name ?? "";
    this.type = type ?? "";
    this.config = config ?? "";
  }

  fixClasses(): CreateOverlayInput {
    return this;
  }
}

export class CreateOverlayOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateOverlayOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateOverlayOutput {
    return this;
  }
}

export class CreatePickupItemInput {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;
  transactionID: string;
  pickupTimeSlot: (Date | null);
  isPickedUp: boolean;
  isReadyPickUp: boolean;
  pickedUpByGuestID: string;
  pickupPreference: string;
  purchasedItemID: string;
  itemID: string;
  inventoryLotID: string;
  inventoryAllocationID: string;
  fixedPriceItemID: string;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
    transactionID,
    pickupTimeSlot,
    isPickedUp,
    isReadyPickUp,
    pickedUpByGuestID,
    pickupPreference,
    purchasedItemID,
    itemID,
    inventoryLotID,
    inventoryAllocationID,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<CreatePickupItemInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.transactionID = transactionID ?? "";
    this.pickupTimeSlot = pickupTimeSlot ?? null;
    this.isPickedUp = isPickedUp ?? false;
    this.isReadyPickUp = isReadyPickUp ?? false;
    this.pickedUpByGuestID = pickedUpByGuestID ?? "";
    this.pickupPreference = pickupPreference ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
    this.itemID = itemID ?? "";
    this.inventoryLotID = inventoryLotID ?? "";
    this.inventoryAllocationID = inventoryAllocationID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  convertDates(): CreatePickupItemInput {
    if (this.pickupTimeSlot !== undefined && this.pickupTimeSlot !== null) {
      this.pickupTimeSlot = new Date(this.pickupTimeSlot!);
    }
    return this;
  }

  fixClasses(): CreatePickupItemInput {
    return this;
  }
}

export class CreatePickupItemOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreatePickupItemOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreatePickupItemOutput {
    return this;
  }
}

export class CreatePreEventQuestionConfigInput {
  organizationID: string;
  eventID: string;
  questionOnePrompt: string;
  questionOneActive: boolean;
  questionTwoPrompt: string;
  questionTwoActive: boolean;
  questionThreePrompt: string;
  questionThreeActive: boolean;

  constructor({
    organizationID,
    eventID,
    questionOnePrompt,
    questionOneActive,
    questionTwoPrompt,
    questionTwoActive,
    questionThreePrompt,
    questionThreeActive,
  }: Partial<TypeWithFieldsOf<CreatePreEventQuestionConfigInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.questionOnePrompt = questionOnePrompt ?? "";
    this.questionOneActive = questionOneActive ?? false;
    this.questionTwoPrompt = questionTwoPrompt ?? "";
    this.questionTwoActive = questionTwoActive ?? false;
    this.questionThreePrompt = questionThreePrompt ?? "";
    this.questionThreeActive = questionThreeActive ?? false;
  }

  fixClasses(): CreatePreEventQuestionConfigInput {
    return this;
  }
}

export class CreatePreEventQuestionConfigOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreatePreEventQuestionConfigOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreatePreEventQuestionConfigOutput {
    return this;
  }
}

export class CreatePurchasedItemInput {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  userID: string;
  guestID: string;
  email: string;
  mobilePhone: string;
  amountCents: number;
  type: string;
  impactID: string;
  auctionID: string;
  lotID: string;
  ticketID: string;
  fixedPriceItemID: string;
  raffleDetails: RafflePurchaseDetails;
  payAfterEvent: boolean;
  purchaseID: string;
  transactionStatus: string;
  transactionID: string;
  refundID: string;
  itemDetails: ItemPurchaseDetails;
  isExternal: boolean;
  isAnonymous: boolean;
  overriddenByUserID: string;
  proxyUserID: string;
  proxyGuestID: string;
  paidByGuestID: string;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    userID,
    guestID,
    email,
    mobilePhone,
    amountCents,
    type,
    impactID,
    auctionID,
    lotID,
    ticketID,
    fixedPriceItemID,
    raffleDetails,
    payAfterEvent,
    purchaseID,
    transactionStatus,
    transactionID,
    refundID,
    itemDetails,
    isExternal,
    isAnonymous,
    overriddenByUserID,
    proxyUserID,
    proxyGuestID,
    paidByGuestID,
  }: Partial<TypeWithFieldsOf<CreatePurchasedItemInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.amountCents = amountCents ?? 0;
    this.type = type ?? "";
    this.impactID = impactID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.ticketID = ticketID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.raffleDetails = raffleDetails ?? new RafflePurchaseDetails();
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.refundID = refundID ?? "";
    this.itemDetails = itemDetails ?? new ItemPurchaseDetails();
    this.isExternal = isExternal ?? false;
    this.isAnonymous = isAnonymous ?? false;
    this.overriddenByUserID = overriddenByUserID ?? "";
    this.proxyUserID = proxyUserID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
    this.paidByGuestID = paidByGuestID ?? "";
  }

  fixClasses(): CreatePurchasedItemInput {
    this.raffleDetails = new RafflePurchaseDetails(this.raffleDetails!).fixClasses();
    this.itemDetails = new ItemPurchaseDetails(this.itemDetails!).fixClasses();
    return this;
  }
}

export class CreatePurchasedItemOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreatePurchasedItemOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreatePurchasedItemOutput {
    return this;
  }
}

export class CreatePurchasedItemSalesTaxInput {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;
  amountCents: number;
  salesTaxPercent: number;
  payAfterEvent: boolean;
  purchaseID: string;
  purchasedItemID: string;
  transactionStatus: string;
  transactionID: string;
  refundID: string;
  overriddenByUserID: string;
  proxyUserID: string;
  proxyGuestID: string;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
    amountCents,
    salesTaxPercent,
    payAfterEvent,
    purchaseID,
    purchasedItemID,
    transactionStatus,
    transactionID,
    refundID,
    overriddenByUserID,
    proxyUserID,
    proxyGuestID,
  }: Partial<TypeWithFieldsOf<CreatePurchasedItemSalesTaxInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.amountCents = amountCents ?? 0;
    this.salesTaxPercent = salesTaxPercent ?? 0;
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.refundID = refundID ?? "";
    this.overriddenByUserID = overriddenByUserID ?? "";
    this.proxyUserID = proxyUserID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
  }

  fixClasses(): CreatePurchasedItemSalesTaxInput {
    return this;
  }
}

export class CreatePurchasedItemSalesTaxOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreatePurchasedItemSalesTaxOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreatePurchasedItemSalesTaxOutput {
    return this;
  }
}

export class CreateRaffleInput {
  organizationID: string;
  eventID: string;
  name: string;
  description: string;

  constructor({
    organizationID,
    eventID,
    name,
    description,
  }: Partial<TypeWithFieldsOf<CreateRaffleInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? "";
    this.description = description ?? "";
  }

  fixClasses(): CreateRaffleInput {
    return this;
  }
}

export class CreateRaffleOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateRaffleOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateRaffleOutput {
    return this;
  }
}

export class CreateRegistrationInput {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  accessCode: string;
  wantEmailUpdates: boolean;
  wantSMSUpdates: boolean;
  passthroughInvitationID: string;
  greatfeatsParticipantID: string;
  detailsJSON: string;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    email,
    mobilePhone,
    accessCode,
    wantEmailUpdates,
    wantSMSUpdates,
    passthroughInvitationID,
    greatfeatsParticipantID,
    detailsJSON,
  }: Partial<TypeWithFieldsOf<CreateRegistrationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.accessCode = accessCode ?? "";
    this.wantEmailUpdates = wantEmailUpdates ?? false;
    this.wantSMSUpdates = wantSMSUpdates ?? false;
    this.passthroughInvitationID = passthroughInvitationID ?? "";
    this.greatfeatsParticipantID = greatfeatsParticipantID ?? "";
    this.detailsJSON = detailsJSON ?? "";
  }

  fixClasses(): CreateRegistrationInput {
    return this;
  }
}

export class CreateRegistrationOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateRegistrationOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateRegistrationOutput {
    return this;
  }
}

export class CreateSceneAndOverlayInput {
  organizationID: string;
  eventID: string;
  showID: string;
  name: string;
  auctionID: string;
  donationMomentID: string;
  inheritDefaultStream: boolean;
  plannedDurationMinutes: number;
  streamType: string;
  url: string;
  overlayType: string;

  constructor({
    organizationID,
    eventID,
    showID,
    name,
    auctionID,
    donationMomentID,
    inheritDefaultStream,
    plannedDurationMinutes,
    streamType,
    url,
    overlayType,
  }: Partial<TypeWithFieldsOf<CreateSceneAndOverlayInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.name = name ?? "";
    this.auctionID = auctionID ?? "";
    this.donationMomentID = donationMomentID ?? "";
    this.inheritDefaultStream = inheritDefaultStream ?? false;
    this.plannedDurationMinutes = plannedDurationMinutes ?? 0;
    this.streamType = streamType ?? "";
    this.url = url ?? "";
    this.overlayType = overlayType ?? "";
  }

  fixClasses(): CreateSceneAndOverlayInput {
    return this;
  }
}

export class CreateSceneAndOverlayOutput {
  sceneID: string;
  overlayID: string;

  constructor({
    sceneID,
    overlayID,
  }: Partial<TypeWithFieldsOf<CreateSceneAndOverlayOutput>> = {}) {
    this.sceneID = sceneID ?? "";
    this.overlayID = overlayID ?? "";
  }

  fixClasses(): CreateSceneAndOverlayOutput {
    return this;
  }
}

export class CreateSceneInput {
  organizationID: string;
  eventID: string;
  showID: string;
  streamID: string;
  name: string;
  descriptionMarkdown: string;
  plannedDurationMinutes: number;
  type: string;
  defaultOverlayConfig: OverlayConfiguration;
  activeOverlayConfig: OverlayConfiguration;
  isCurtain: boolean;
  inheritDefaultStream: boolean;
  url: string;

  constructor({
    organizationID,
    eventID,
    showID,
    streamID,
    name,
    descriptionMarkdown,
    plannedDurationMinutes,
    type,
    defaultOverlayConfig,
    activeOverlayConfig,
    isCurtain,
    inheritDefaultStream,
    url,
  }: Partial<TypeWithFieldsOf<CreateSceneInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.streamID = streamID ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.plannedDurationMinutes = plannedDurationMinutes ?? 0;
    this.type = type ?? "";
    this.defaultOverlayConfig = defaultOverlayConfig ?? new OverlayConfiguration();
    this.activeOverlayConfig = activeOverlayConfig ?? new OverlayConfiguration();
    this.isCurtain = isCurtain ?? false;
    this.inheritDefaultStream = inheritDefaultStream ?? false;
    this.url = url ?? "";
  }

  convertUnexpectedNulls(): CreateSceneInput {
    this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): CreateSceneInput {
    this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    return this;
  }
}

export class CreateSceneOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateSceneOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateSceneOutput {
    return this;
  }
}

export class CreateShowInput {
  organizationID: string;
  eventID: string;
  name: string;
  descriptionMarkdown: string;
  defaultOverlayConfig: OverlayConfiguration;
  overrideOverlayConfig: OverlayConfiguration;
  activeOverlayConfig: OverlayConfiguration;
  defaultStreamType: string;
  defaultStreamID: string;
  url: string;

  constructor({
    organizationID,
    eventID,
    name,
    descriptionMarkdown,
    defaultOverlayConfig,
    overrideOverlayConfig,
    activeOverlayConfig,
    defaultStreamType,
    defaultStreamID,
    url,
  }: Partial<TypeWithFieldsOf<CreateShowInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.defaultOverlayConfig = defaultOverlayConfig ?? new OverlayConfiguration();
    this.overrideOverlayConfig = overrideOverlayConfig ?? new OverlayConfiguration();
    this.activeOverlayConfig = activeOverlayConfig ?? new OverlayConfiguration();
    this.defaultStreamType = defaultStreamType ?? "";
    this.defaultStreamID = defaultStreamID ?? "";
    this.url = url ?? "";
  }

  convertUnexpectedNulls(): CreateShowInput {
    this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    this.overrideOverlayConfig = this.overrideOverlayConfig!.convertUnexpectedNulls();
    this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): CreateShowInput {
    this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    this.overrideOverlayConfig = new OverlayConfiguration(this.overrideOverlayConfig!).fixClasses();
    this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    return this;
  }
}

export class CreateShowOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateShowOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateShowOutput {
    return this;
  }
}

export class CreateTicketTypeAndConfigInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  name: string;
  description: string;
  maxTickets: number;
  quantityPerGroup: number;
  priceCents: number;
  valueCents: number;
  active: boolean;
  attendanceType: string;
  ticketOptions: string[];

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    name,
    description,
    maxTickets,
    quantityPerGroup,
    priceCents,
    valueCents,
    active,
    attendanceType,
    ticketOptions,
  }: Partial<TypeWithFieldsOf<CreateTicketTypeAndConfigInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.name = name ?? "";
    this.description = description ?? "";
    this.maxTickets = maxTickets ?? 0;
    this.quantityPerGroup = quantityPerGroup ?? 0;
    this.priceCents = priceCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.active = active ?? false;
    this.attendanceType = attendanceType ?? "";
    this.ticketOptions = ticketOptions ?? [];
  }

  convertUnexpectedNulls(): CreateTicketTypeAndConfigInput {
    if (!this.ticketOptions) {
      this.ticketOptions = [];
    }
    return this;
  }

  fixClasses(): CreateTicketTypeAndConfigInput {
    return this;
  }
}

export class CreateTicketTypeAndConfigOutput {
  ticketTypeID: string;
  eventSessionTicketConfigID: string;

  constructor({
    ticketTypeID,
    eventSessionTicketConfigID,
  }: Partial<TypeWithFieldsOf<CreateTicketTypeAndConfigOutput>> = {}) {
    this.ticketTypeID = ticketTypeID ?? "";
    this.eventSessionTicketConfigID = eventSessionTicketConfigID ?? "";
  }

  fixClasses(): CreateTicketTypeAndConfigOutput {
    return this;
  }
}

export class CreateVirtualChairInput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userID: string;
  channelName: string;
  channelARN: string;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    userEmail,
    userFirstName,
    userLastName,
    userID,
    channelName,
    channelARN,
  }: Partial<TypeWithFieldsOf<CreateVirtualChairInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.userEmail = userEmail ?? "";
    this.userFirstName = userFirstName ?? "";
    this.userLastName = userLastName ?? "";
    this.userID = userID ?? "";
    this.channelName = channelName ?? "";
    this.channelARN = channelARN ?? "";
  }

  fixClasses(): CreateVirtualChairInput {
    return this;
  }
}

export class CreateVirtualChairOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateVirtualChairOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateVirtualChairOutput {
    return this;
  }
}

export class CreateVirtualTableInput {
  organizationID: string;
  virtualEventID: string;
  name: string;
  tableNumber: number;

  constructor({
    organizationID,
    virtualEventID,
    name,
    tableNumber,
  }: Partial<TypeWithFieldsOf<CreateVirtualTableInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.name = name ?? "";
    this.tableNumber = tableNumber ?? 0;
  }

  fixClasses(): CreateVirtualTableInput {
    return this;
  }
}

export class CreateVirtualTableOutput {
  id: string;

  constructor({
    id,
  }: Partial<TypeWithFieldsOf<CreateVirtualTableOutput>> = {}) {
    this.id = id ?? "";
  }

  fixClasses(): CreateVirtualTableOutput {
    return this;
  }
}

export class DeleteChannelInput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  virtualChairID: string;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    virtualChairID,
  }: Partial<TypeWithFieldsOf<DeleteChannelInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.virtualChairID = virtualChairID ?? "";
  }

  fixClasses(): DeleteChannelInput {
    return this;
  }
}

export class DeleteChannelOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteChannelOutput>> = {}) {
  }

  fixClasses(): DeleteChannelOutput {
    return this;
  }
}

export class DeleteDonationMomentInput {
  donationMomentID: string;
  organizationID: string;
  eventID: string;

  constructor({
    donationMomentID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteDonationMomentInput>> = {}) {
    this.donationMomentID = donationMomentID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteDonationMomentInput {
    return this;
  }
}

export class DeleteDonationMomentOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteDonationMomentOutput>> = {}) {
  }

  fixClasses(): DeleteDonationMomentOutput {
    return this;
  }
}

export class DeleteEventInput {
  eventID: string;
  organizationID: string;
  timezone: string;

  constructor({
    eventID,
    organizationID,
    timezone,
  }: Partial<TypeWithFieldsOf<DeleteEventInput>> = {}) {
    this.eventID = eventID ?? "";
    this.organizationID = organizationID ?? "";
    this.timezone = timezone ?? "";
  }

  fixClasses(): DeleteEventInput {
    return this;
  }
}

export class DeleteEventOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteEventOutput>> = {}) {
  }

  fixClasses(): DeleteEventOutput {
    return this;
  }
}

export class DeleteEventSessionInput {
  eventSessionID: string;
  organizationID: string;
  eventID: string;

  constructor({
    eventSessionID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteEventSessionInput>> = {}) {
    this.eventSessionID = eventSessionID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteEventSessionInput {
    return this;
  }
}

export class DeleteEventSessionOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteEventSessionOutput>> = {}) {
  }

  fixClasses(): DeleteEventSessionOutput {
    return this;
  }
}

export class DeleteEventSessionTicketConfigInput {
  eventSessionTicketConfigID: string;
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    eventSessionTicketConfigID,
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<DeleteEventSessionTicketConfigInput>> = {}) {
    this.eventSessionTicketConfigID = eventSessionTicketConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): DeleteEventSessionTicketConfigInput {
    return this;
  }
}

export class DeleteEventSessionTicketConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteEventSessionTicketConfigOutput>> = {}) {
  }

  fixClasses(): DeleteEventSessionTicketConfigOutput {
    return this;
  }
}

export class DeleteEventSlideConfigInput {
  eventSlideConfigID: string;
  organizationID: string;
  eventID: string;

  constructor({
    eventSlideConfigID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteEventSlideConfigInput>> = {}) {
    this.eventSlideConfigID = eventSlideConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteEventSlideConfigInput {
    return this;
  }
}

export class DeleteEventSlideConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteEventSlideConfigOutput>> = {}) {
  }

  fixClasses(): DeleteEventSlideConfigOutput {
    return this;
  }
}

export class DeleteEventSlideshowInput {
  eventSlideshowID: string;
  organizationID: string;
  eventID: string;

  constructor({
    eventSlideshowID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteEventSlideshowInput>> = {}) {
    this.eventSlideshowID = eventSlideshowID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteEventSlideshowInput {
    return this;
  }
}

export class DeleteEventSlideshowOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteEventSlideshowOutput>> = {}) {
  }

  fixClasses(): DeleteEventSlideshowOutput {
    return this;
  }
}

export class DeleteFixedPriceItemInput {
  itemID: string;
  organizationID: string;
  eventID: string;

  constructor({
    itemID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteFixedPriceItemInput>> = {}) {
    this.itemID = itemID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteFixedPriceItemInput {
    return this;
  }
}

export class DeleteFixedPriceItemOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteFixedPriceItemOutput>> = {}) {
  }

  fixClasses(): DeleteFixedPriceItemOutput {
    return this;
  }
}

export class DeleteFixedPriceItemPhotoInput {
  photoID: string;
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;

  constructor({
    photoID,
    organizationID,
    eventID,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<DeleteFixedPriceItemPhotoInput>> = {}) {
    this.photoID = photoID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  fixClasses(): DeleteFixedPriceItemPhotoInput {
    return this;
  }
}

export class DeleteFixedPriceItemPhotoOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteFixedPriceItemPhotoOutput>> = {}) {
  }

  fixClasses(): DeleteFixedPriceItemPhotoOutput {
    return this;
  }
}

export class DeleteGuestInput {
  guestID: string;
  organizationID: string;
  eventID: string;

  constructor({
    guestID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteGuestInput>> = {}) {
    this.guestID = guestID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteGuestInput {
    return this;
  }
}

export class DeleteGuestOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteGuestOutput>> = {}) {
  }

  fixClasses(): DeleteGuestOutput {
    return this;
  }
}

export class DeleteGuestSessionInput {
  guestSessionID: string;
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    guestSessionID,
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<DeleteGuestSessionInput>> = {}) {
    this.guestSessionID = guestSessionID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): DeleteGuestSessionInput {
    return this;
  }
}

export class DeleteGuestSessionOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteGuestSessionOutput>> = {}) {
  }

  fixClasses(): DeleteGuestSessionOutput {
    return this;
  }
}

export class DeleteImpactInput {
  impactID: string;
  organizationID: string;
  eventID: string;

  constructor({
    impactID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteImpactInput>> = {}) {
    this.impactID = impactID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteImpactInput {
    return this;
  }
}

export class DeleteImpactOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteImpactOutput>> = {}) {
  }

  fixClasses(): DeleteImpactOutput {
    return this;
  }
}

export class DeleteImpactPhotoInput {
  photoID: string;
  organizationID: string;
  eventID: string;
  impactID: string;

  constructor({
    photoID,
    organizationID,
    eventID,
    impactID,
  }: Partial<TypeWithFieldsOf<DeleteImpactPhotoInput>> = {}) {
    this.photoID = photoID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
  }

  fixClasses(): DeleteImpactPhotoInput {
    return this;
  }
}

export class DeleteImpactPhotoOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteImpactPhotoOutput>> = {}) {
  }

  fixClasses(): DeleteImpactPhotoOutput {
    return this;
  }
}

export class DeleteInvitationInput {
  organizationID: string;
  eventID: string;
  invitationID: string;

  constructor({
    organizationID,
    eventID,
    invitationID,
  }: Partial<TypeWithFieldsOf<DeleteInvitationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.invitationID = invitationID ?? "";
  }

  fixClasses(): DeleteInvitationInput {
    return this;
  }
}

export class DeleteInvitationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteInvitationOutput>> = {}) {
  }

  fixClasses(): DeleteInvitationOutput {
    return this;
  }
}

export class DeleteLivestreamChatMessageInput {
  organizationID: string;
  eventID: string;
  livestreamChatChannelID: string;
  messageTimestamp: string;

  constructor({
    organizationID,
    eventID,
    livestreamChatChannelID,
    messageTimestamp,
  }: Partial<TypeWithFieldsOf<DeleteLivestreamChatMessageInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.livestreamChatChannelID = livestreamChatChannelID ?? "";
    this.messageTimestamp = messageTimestamp ?? "";
  }

  fixClasses(): DeleteLivestreamChatMessageInput {
    return this;
  }
}

export class DeleteLivestreamChatMessageOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteLivestreamChatMessageOutput>> = {}) {
  }

  fixClasses(): DeleteLivestreamChatMessageOutput {
    return this;
  }
}

export class DeleteOverlayInput {
  overlayID: string;
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;

  constructor({
    overlayID,
    organizationID,
    eventID,
    showID,
    sceneID,
  }: Partial<TypeWithFieldsOf<DeleteOverlayInput>> = {}) {
    this.overlayID = overlayID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
  }

  fixClasses(): DeleteOverlayInput {
    return this;
  }
}

export class DeleteOverlayOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteOverlayOutput>> = {}) {
  }

  fixClasses(): DeleteOverlayOutput {
    return this;
  }
}

export class DeletePickupItemInput {
  id: string;
  organizationID: string;
  eventID: string;

  constructor({
    id,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeletePickupItemInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeletePickupItemInput {
    return this;
  }
}

export class DeletePickupItemOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeletePickupItemOutput>> = {}) {
  }

  fixClasses(): DeletePickupItemOutput {
    return this;
  }
}

export class DeletePreEventQuestionConfigInput {
  preEventQuestionConfigID: string;
  organizationID: string;
  eventID: string;

  constructor({
    preEventQuestionConfigID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeletePreEventQuestionConfigInput>> = {}) {
    this.preEventQuestionConfigID = preEventQuestionConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeletePreEventQuestionConfigInput {
    return this;
  }
}

export class DeletePreEventQuestionConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeletePreEventQuestionConfigOutput>> = {}) {
  }

  fixClasses(): DeletePreEventQuestionConfigOutput {
    return this;
  }
}

export class DeletePurchasedItemInput {
  id: string;
  organizationID: string;
  eventID: string;

  constructor({
    id,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeletePurchasedItemInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeletePurchasedItemInput {
    return this;
  }
}

export class DeletePurchasedItemOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeletePurchasedItemOutput>> = {}) {
  }

  fixClasses(): DeletePurchasedItemOutput {
    return this;
  }
}

export class DeletePurchasedItemSalesTaxInput {
  id: string;
  organizationID: string;
  eventID: string;

  constructor({
    id,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeletePurchasedItemSalesTaxInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeletePurchasedItemSalesTaxInput {
    return this;
  }
}

export class DeletePurchasedItemSalesTaxOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeletePurchasedItemSalesTaxOutput>> = {}) {
  }

  fixClasses(): DeletePurchasedItemSalesTaxOutput {
    return this;
  }
}

export class DeleteRegistrationInput {
  registrationID: string;
  organizationID: string;
  eventID: string;

  constructor({
    registrationID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteRegistrationInput>> = {}) {
    this.registrationID = registrationID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteRegistrationInput {
    return this;
  }
}

export class DeleteRegistrationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteRegistrationOutput>> = {}) {
  }

  fixClasses(): DeleteRegistrationOutput {
    return this;
  }
}

export class DeleteSceneInput {
  sceneID: string;
  organizationID: string;
  eventID: string;
  showID: string;

  constructor({
    sceneID,
    organizationID,
    eventID,
    showID,
  }: Partial<TypeWithFieldsOf<DeleteSceneInput>> = {}) {
    this.sceneID = sceneID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
  }

  fixClasses(): DeleteSceneInput {
    return this;
  }
}

export class DeleteSceneOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteSceneOutput>> = {}) {
  }

  fixClasses(): DeleteSceneOutput {
    return this;
  }
}

export class DeleteShowInput {
  showID: string;
  organizationID: string;
  eventID: string;

  constructor({
    showID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DeleteShowInput>> = {}) {
    this.showID = showID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DeleteShowInput {
    return this;
  }
}

export class DeleteShowOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteShowOutput>> = {}) {
  }

  fixClasses(): DeleteShowOutput {
    return this;
  }
}

export class DeleteTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;

  constructor({
    organizationID,
    eventID,
    ticketID,
  }: Partial<TypeWithFieldsOf<DeleteTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
  }

  fixClasses(): DeleteTicketInput {
    return this;
  }
}

export class DeleteTicketOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteTicketOutput>> = {}) {
  }

  fixClasses(): DeleteTicketOutput {
    return this;
  }
}

export class DeleteVirtualChairInput {
  id: string;
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;

  constructor({
    id,
    organizationID,
    virtualEventID,
    virtualTableID,
  }: Partial<TypeWithFieldsOf<DeleteVirtualChairInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
  }

  fixClasses(): DeleteVirtualChairInput {
    return this;
  }
}

export class DeleteVirtualChairOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteVirtualChairOutput>> = {}) {
  }

  fixClasses(): DeleteVirtualChairOutput {
    return this;
  }
}

export class DeleteVirtualTableInput {
  id: string;
  organizationID: string;
  virtualEventID: string;

  constructor({
    id,
    organizationID,
    virtualEventID,
  }: Partial<TypeWithFieldsOf<DeleteVirtualTableInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
  }

  fixClasses(): DeleteVirtualTableInput {
    return this;
  }
}

export class DeleteVirtualTableOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DeleteVirtualTableOutput>> = {}) {
  }

  fixClasses(): DeleteVirtualTableOutput {
    return this;
  }
}

export class DisableLivestreamChatInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<DisableLivestreamChatInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): DisableLivestreamChatInput {
    return this;
  }
}

export class DisableLivestreamChatOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DisableLivestreamChatOutput>> = {}) {
  }

  fixClasses(): DisableLivestreamChatOutput {
    return this;
  }
}

export class DisconnectRaffleInput {
  organizationID: string;
  eventID: string;
  raffleID: string;

  constructor({
    organizationID,
    eventID,
    raffleID,
  }: Partial<TypeWithFieldsOf<DisconnectRaffleInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.raffleID = raffleID ?? "";
  }

  fixClasses(): DisconnectRaffleInput {
    return this;
  }
}

export class DisconnectRaffleOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DisconnectRaffleOutput>> = {}) {
  }

  fixClasses(): DisconnectRaffleOutput {
    return this;
  }
}

export class DonateAndSelfRegisterInput {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  passthroughInvitationID: string;
  amountCents: number;
  cardName: string;
  expiration: string;
  cardType: string;
  cardLastFour: string;
  token: string;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  payerState: string;
  payerZip: string;
  payerCountry: string;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    mobilePhone,
    passthroughInvitationID,
    amountCents,
    cardName,
    expiration,
    cardType,
    cardLastFour,
    token,
    payerName,
    payerStreet,
    payerCity,
    payerState,
    payerZip,
    payerCountry,
  }: Partial<TypeWithFieldsOf<DonateAndSelfRegisterInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.passthroughInvitationID = passthroughInvitationID ?? "";
    this.amountCents = amountCents ?? 0;
    this.cardName = cardName ?? "";
    this.expiration = expiration ?? "";
    this.cardType = cardType ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.token = token ?? "";
    this.payerName = payerName ?? "";
    this.payerStreet = payerStreet ?? "";
    this.payerCity = payerCity ?? "";
    this.payerState = payerState ?? "";
    this.payerZip = payerZip ?? "";
    this.payerCountry = payerCountry ?? "";
  }

  fixClasses(): DonateAndSelfRegisterInput {
    return this;
  }
}

export class DonateAndSelfRegisterOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<DonateAndSelfRegisterOutput>> = {}) {
  }

  fixClasses(): DonateAndSelfRegisterOutput {
    return this;
  }
}

export class DonationMomentSummary {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  type: string;
  donationLevelsAmountCents: number[];
  currentDonationTierAmountCents: number;
  disableOnlineDonations: boolean;
  giveAtAnyLevelEnabled: boolean;
  giveAtAnyLevelActive: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    type,
    donationLevelsAmountCents,
    currentDonationTierAmountCents,
    disableOnlineDonations,
    giveAtAnyLevelEnabled,
    giveAtAnyLevelActive,
  }: Partial<TypeWithFieldsOf<DonationMomentSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.type = type ?? "";
    this.donationLevelsAmountCents = donationLevelsAmountCents ?? [];
    this.currentDonationTierAmountCents = currentDonationTierAmountCents ?? 0;
    this.disableOnlineDonations = disableOnlineDonations ?? false;
    this.giveAtAnyLevelEnabled = giveAtAnyLevelEnabled ?? false;
    this.giveAtAnyLevelActive = giveAtAnyLevelActive ?? false;
  }

  convertUnexpectedNulls(): DonationMomentSummary {
    if (!this.donationLevelsAmountCents) {
      this.donationLevelsAmountCents = [];
    }
    return this;
  }

  fixClasses(): DonationMomentSummary {
    return this;
  }
}

export class EnableLivestreamChatInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<EnableLivestreamChatInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): EnableLivestreamChatInput {
    return this;
  }
}

export class EnableLivestreamChatOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<EnableLivestreamChatOutput>> = {}) {
  }

  fixClasses(): EnableLivestreamChatOutput {
    return this;
  }
}

export class EventSessionSummary {
  organizationID: string;
  eventID: string;
  id: string;
  tableImageURL: string;
  tableImageUploadToken: string;

  constructor({
    organizationID,
    eventID,
    id,
    tableImageURL,
    tableImageUploadToken,
  }: Partial<TypeWithFieldsOf<EventSessionSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.tableImageURL = tableImageURL ?? "";
    this.tableImageUploadToken = tableImageUploadToken ?? "";
  }

  fixClasses(): EventSessionSummary {
    return this;
  }
}

export class EventSessionTicketConfigSummary {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  id: string;
  ticketTypeID: string;
  attendeeExperience: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    id,
    ticketTypeID,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<EventSessionTicketConfigSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.id = id ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.attendeeExperience = attendeeExperience ?? "";
  }

  fixClasses(): EventSessionTicketConfigSummary {
    return this;
  }
}

export class EventSlideConfigSummary {
  organizationID: string;
  eventID: string;
  id: string;
  slideType: string;

  constructor({
    organizationID,
    eventID,
    id,
    slideType,
  }: Partial<TypeWithFieldsOf<EventSlideConfigSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.slideType = slideType ?? "";
  }

  fixClasses(): EventSlideConfigSummary {
    return this;
  }
}

export class EventSlideshowSummary {
  organizationID: string;
  eventID: string;
  id: string;
  activeSlideURL: string;

  constructor({
    organizationID,
    eventID,
    id,
    activeSlideURL,
  }: Partial<TypeWithFieldsOf<EventSlideshowSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.activeSlideURL = activeSlideURL ?? "";
  }

  fixClasses(): EventSlideshowSummary {
    return this;
  }
}

export class EventSummary {
  organizationID: string;
  id: string;
  name: string;
  descriptionMarkdown: string;
  eventType: string;
  primaryDate: (Date | null);
  preEventScheduledNotificationID: string;
  preEventQuestionnaireNotificationID: string;
  primaryEndDate: (Date | null);
  timezone: string;
  shortURL: string;
  totalDonationsCount: number;
  totalDonationsAmountCents: number;
  totalAppealAmountCents: number;
  totalRegistrationsCount: number;
  goalTotalDonationsAmountCents: number;
  goalTotalDonationsAmountCentsMetOn: (Date | null);
  goalTotalDonationsSeedAmountCents: number;
  themePrimaryColor: string;
  themeSecondaryColor: string;
  bannerImageURL: string;
  logoImageURL: string;
  merchantAccountID: string;
  isCoverCostActive: boolean;
  coverCostPercent: number;
  coverCostCustomMessage: string;
  coverCostDefault: boolean;
  enableRaffles: boolean;
  enableFixedPrice: boolean;
  noticeEmailsSent: boolean;
  paymentsProcessed: boolean;
  isVirtualTableActive: boolean;
  isChatOn: boolean;
  livestreamChatChannelCount: number;
  showRecurringDonationManagement: boolean;
  recurringDonationPeriod: string;
  hostURL: string;
  hostShortenedURL: string;
  allowPickupLater: boolean;
  allowPickupTonight: boolean;
  isCheckoutOpen: boolean;
  thermometerConfig: ThermometerSetupConfig;
  totalDonationsProceedsAmountCents: number;
  totalOfflineDonationsAmountCents: number;
  totalLiveAuctionProceedsAmountCents: number;
  totalSilentAuctionProceedsAmountCents: number;
  totalFixedPriceProceedsAmountCents: number;
  totalTicketsProceedsAmountCents: number;
  totalRaffleProceedsAmountCents: number;
  totalSponsorAttachmentEventSalesProceedsAmountCents: number;
  totalMiscProceedsAmountCents: number;
  testEvent: boolean;
  enableSelfCheckin: boolean;
  eventDayToolShortURL: string;
  shareableSlideshowShortURL: string;
  salesTaxPercent: number;
  salesTaxAppliesTo: string;
  salesTaxCoverageType: string;
  customTags: string[];
  externalID: string;
  enableBidderNumbers: boolean;
  enableAutomaticBidderNumbers: boolean;
  startingBidderNumber: (number | null);

  constructor({
    organizationID,
    id,
    name,
    descriptionMarkdown,
    eventType,
    primaryDate,
    preEventScheduledNotificationID,
    preEventQuestionnaireNotificationID,
    primaryEndDate,
    timezone,
    shortURL,
    totalDonationsCount,
    totalDonationsAmountCents,
    totalAppealAmountCents,
    totalRegistrationsCount,
    goalTotalDonationsAmountCents,
    goalTotalDonationsAmountCentsMetOn,
    goalTotalDonationsSeedAmountCents,
    themePrimaryColor,
    themeSecondaryColor,
    bannerImageURL,
    logoImageURL,
    merchantAccountID,
    isCoverCostActive,
    coverCostPercent,
    coverCostCustomMessage,
    coverCostDefault,
    enableRaffles,
    enableFixedPrice,
    noticeEmailsSent,
    paymentsProcessed,
    isVirtualTableActive,
    isChatOn,
    livestreamChatChannelCount,
    showRecurringDonationManagement,
    recurringDonationPeriod,
    hostURL,
    hostShortenedURL,
    allowPickupLater,
    allowPickupTonight,
    isCheckoutOpen,
    thermometerConfig,
    totalDonationsProceedsAmountCents,
    totalOfflineDonationsAmountCents,
    totalLiveAuctionProceedsAmountCents,
    totalSilentAuctionProceedsAmountCents,
    totalFixedPriceProceedsAmountCents,
    totalTicketsProceedsAmountCents,
    totalRaffleProceedsAmountCents,
    totalSponsorAttachmentEventSalesProceedsAmountCents,
    totalMiscProceedsAmountCents,
    testEvent,
    enableSelfCheckin,
    eventDayToolShortURL,
    shareableSlideshowShortURL,
    salesTaxPercent,
    salesTaxAppliesTo,
    salesTaxCoverageType,
    customTags,
    externalID,
    enableBidderNumbers,
    enableAutomaticBidderNumbers,
    startingBidderNumber,
  }: Partial<TypeWithFieldsOf<EventSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.eventType = eventType ?? "";
    this.primaryDate = primaryDate ?? null;
    this.preEventScheduledNotificationID = preEventScheduledNotificationID ?? "";
    this.preEventQuestionnaireNotificationID = preEventQuestionnaireNotificationID ?? "";
    this.primaryEndDate = primaryEndDate ?? null;
    this.timezone = timezone ?? "";
    this.shortURL = shortURL ?? "";
    this.totalDonationsCount = totalDonationsCount ?? 0;
    this.totalDonationsAmountCents = totalDonationsAmountCents ?? 0;
    this.totalAppealAmountCents = totalAppealAmountCents ?? 0;
    this.totalRegistrationsCount = totalRegistrationsCount ?? 0;
    this.goalTotalDonationsAmountCents = goalTotalDonationsAmountCents ?? 0;
    this.goalTotalDonationsAmountCentsMetOn = goalTotalDonationsAmountCentsMetOn ?? null;
    this.goalTotalDonationsSeedAmountCents = goalTotalDonationsSeedAmountCents ?? 0;
    this.themePrimaryColor = themePrimaryColor ?? "";
    this.themeSecondaryColor = themeSecondaryColor ?? "";
    this.bannerImageURL = bannerImageURL ?? "";
    this.logoImageURL = logoImageURL ?? "";
    this.merchantAccountID = merchantAccountID ?? "";
    this.isCoverCostActive = isCoverCostActive ?? false;
    this.coverCostPercent = coverCostPercent ?? 0;
    this.coverCostCustomMessage = coverCostCustomMessage ?? "";
    this.coverCostDefault = coverCostDefault ?? false;
    this.enableRaffles = enableRaffles ?? false;
    this.enableFixedPrice = enableFixedPrice ?? false;
    this.noticeEmailsSent = noticeEmailsSent ?? false;
    this.paymentsProcessed = paymentsProcessed ?? false;
    this.isVirtualTableActive = isVirtualTableActive ?? false;
    this.isChatOn = isChatOn ?? false;
    this.livestreamChatChannelCount = livestreamChatChannelCount ?? 0;
    this.showRecurringDonationManagement = showRecurringDonationManagement ?? false;
    this.recurringDonationPeriod = recurringDonationPeriod ?? "";
    this.hostURL = hostURL ?? "";
    this.hostShortenedURL = hostShortenedURL ?? "";
    this.allowPickupLater = allowPickupLater ?? false;
    this.allowPickupTonight = allowPickupTonight ?? false;
    this.isCheckoutOpen = isCheckoutOpen ?? false;
    this.thermometerConfig = thermometerConfig ?? new ThermometerSetupConfig();
    this.totalDonationsProceedsAmountCents = totalDonationsProceedsAmountCents ?? 0;
    this.totalOfflineDonationsAmountCents = totalOfflineDonationsAmountCents ?? 0;
    this.totalLiveAuctionProceedsAmountCents = totalLiveAuctionProceedsAmountCents ?? 0;
    this.totalSilentAuctionProceedsAmountCents = totalSilentAuctionProceedsAmountCents ?? 0;
    this.totalFixedPriceProceedsAmountCents = totalFixedPriceProceedsAmountCents ?? 0;
    this.totalTicketsProceedsAmountCents = totalTicketsProceedsAmountCents ?? 0;
    this.totalRaffleProceedsAmountCents = totalRaffleProceedsAmountCents ?? 0;
    this.totalSponsorAttachmentEventSalesProceedsAmountCents = totalSponsorAttachmentEventSalesProceedsAmountCents ?? 0;
    this.totalMiscProceedsAmountCents = totalMiscProceedsAmountCents ?? 0;
    this.testEvent = testEvent ?? false;
    this.enableSelfCheckin = enableSelfCheckin ?? false;
    this.eventDayToolShortURL = eventDayToolShortURL ?? "";
    this.shareableSlideshowShortURL = shareableSlideshowShortURL ?? "";
    this.salesTaxPercent = salesTaxPercent ?? 0;
    this.salesTaxAppliesTo = salesTaxAppliesTo ?? "";
    this.salesTaxCoverageType = salesTaxCoverageType ?? "";
    this.customTags = customTags ?? [];
    this.externalID = externalID ?? "";
    this.enableBidderNumbers = enableBidderNumbers ?? false;
    this.enableAutomaticBidderNumbers = enableAutomaticBidderNumbers ?? false;
    this.startingBidderNumber = startingBidderNumber ?? null;
  }

  convertDates(): EventSummary {
    if (this.primaryDate !== undefined && this.primaryDate !== null) {
      this.primaryDate = new Date(this.primaryDate!);
    }
    if (this.primaryEndDate !== undefined && this.primaryEndDate !== null) {
      this.primaryEndDate = new Date(this.primaryEndDate!);
    }
    if (this.goalTotalDonationsAmountCentsMetOn !== undefined && this.goalTotalDonationsAmountCentsMetOn !== null) {
      this.goalTotalDonationsAmountCentsMetOn = new Date(this.goalTotalDonationsAmountCentsMetOn!);
    }
    return this;
  }

  convertUnexpectedNulls(): EventSummary {
    if (!this.customTags) {
      this.customTags = [];
    }
    return this;
  }

  fixClasses(): EventSummary {
    this.thermometerConfig = new ThermometerSetupConfig(this.thermometerConfig!).fixClasses();
    return this;
  }
}

export class FetchPurchasedItemsForRefundIDsInput {
  organizationID: string;
  refundIDs: string[];

  constructor({
    organizationID,
    refundIDs,
  }: Partial<TypeWithFieldsOf<FetchPurchasedItemsForRefundIDsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.refundIDs = refundIDs ?? [];
  }

  convertUnexpectedNulls(): FetchPurchasedItemsForRefundIDsInput {
    if (!this.refundIDs) {
      this.refundIDs = [];
    }
    return this;
  }

  fixClasses(): FetchPurchasedItemsForRefundIDsInput {
    return this;
  }
}

export class FetchPurchasedItemsForRefundIDsOutput {
  organizationID: string;
  items: RefundItemDetail[];

  constructor({
    organizationID,
    items,
  }: Partial<TypeWithFieldsOf<FetchPurchasedItemsForRefundIDsOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.items = items ?? [];
  }

  convertUnexpectedNulls(): FetchPurchasedItemsForRefundIDsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): FetchPurchasedItemsForRefundIDsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new RefundItemDetail(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class FixedPriceItemPhotoSummary {
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  id: string;
  url: string;
  uploadToken: string;

  constructor({
    organizationID,
    eventID,
    fixedPriceItemID,
    id,
    url,
    uploadToken,
  }: Partial<TypeWithFieldsOf<FixedPriceItemPhotoSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.id = id ?? "";
    this.url = url ?? "";
    this.uploadToken = uploadToken ?? "";
  }

  fixClasses(): FixedPriceItemPhotoSummary {
    return this;
  }
}

export class FixedPriceItemSummary {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  priceCents: number;
  valueCents: number;
  isTaxable: boolean;
  description: string;
  numPurchased: number;
  maxNumAvailable: number;
  numUnlimited: boolean;
  inPersonOnly: boolean;
  pickupLater: boolean;
  pickupTonight: boolean;
  isPickUpEventLevel: boolean;
  sortOrder: number;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    priceCents,
    valueCents,
    isTaxable,
    description,
    numPurchased,
    maxNumAvailable,
    numUnlimited,
    inPersonOnly,
    pickupLater,
    pickupTonight,
    isPickUpEventLevel,
    sortOrder,
  }: Partial<TypeWithFieldsOf<FixedPriceItemSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.priceCents = priceCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.isTaxable = isTaxable ?? false;
    this.description = description ?? "";
    this.numPurchased = numPurchased ?? 0;
    this.maxNumAvailable = maxNumAvailable ?? 0;
    this.numUnlimited = numUnlimited ?? false;
    this.inPersonOnly = inPersonOnly ?? false;
    this.pickupLater = pickupLater ?? false;
    this.pickupTonight = pickupTonight ?? false;
    this.isPickUpEventLevel = isPickUpEventLevel ?? false;
    this.sortOrder = sortOrder ?? 0;
  }

  fixClasses(): FixedPriceItemSummary {
    return this;
  }
}

export class GetAppealLevelsInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetAppealLevelsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetAppealLevelsInput {
    return this;
  }
}

export class GetAppealLevelsOutput {
  appealLevelsCents: number[];

  constructor({
    appealLevelsCents,
  }: Partial<TypeWithFieldsOf<GetAppealLevelsOutput>> = {}) {
    this.appealLevelsCents = appealLevelsCents ?? [];
  }

  convertUnexpectedNulls(): GetAppealLevelsOutput {
    if (!this.appealLevelsCents) {
      this.appealLevelsCents = [];
    }
    return this;
  }

  fixClasses(): GetAppealLevelsOutput {
    return this;
  }
}

export class GetAttendeeExperienceInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  guestSessionID: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    guestSessionID,
  }: Partial<TypeWithFieldsOf<GetAttendeeExperienceInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.guestSessionID = guestSessionID ?? "";
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.guestID ? params.set('guestID', this.guestID.toString()) : params;
    params = this.guestSessionID ? params.set('guestSessionID', this.guestSessionID.toString()) : params;
    return params;
  }

  fixClasses(): GetAttendeeExperienceInput {
    return this;
  }
}

export class GetAttendeeExperienceOutput {
  experience: string;

  constructor({
    experience,
  }: Partial<TypeWithFieldsOf<GetAttendeeExperienceOutput>> = {}) {
    this.experience = experience ?? "";
  }

  fixClasses(): GetAttendeeExperienceOutput {
    return this;
  }
}

export class GetChatUserInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetChatUserInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetChatUserInput {
    return this;
  }
}

export class GetChatUserOutput {
  organizationID: string;
  eventID: string;
  userID: string;
  pubnubAuthKey: string;
  livestreamChatChannelID: string;

  constructor({
    organizationID,
    eventID,
    userID,
    pubnubAuthKey,
    livestreamChatChannelID,
  }: Partial<TypeWithFieldsOf<GetChatUserOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.pubnubAuthKey = pubnubAuthKey ?? "";
    this.livestreamChatChannelID = livestreamChatChannelID ?? "";
  }

  fixClasses(): GetChatUserOutput {
    return this;
  }
}

export class GetConnectionResourcesForChannelInput {
  organizationID: string;
  virtualEventID: string;
  channelARN: string;
  channelRole: string;

  constructor({
    organizationID,
    virtualEventID,
    channelARN,
    channelRole,
  }: Partial<TypeWithFieldsOf<GetConnectionResourcesForChannelInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.channelARN = channelARN ?? "";
    this.channelRole = channelRole ?? "";
  }

  fixClasses(): GetConnectionResourcesForChannelInput {
    return this;
  }
}

export class GetConnectionResourcesForChannelOutput {
  awsRegion: string;
  wssEndpointURL: string;
  httpsEndpointURL: string;
  iceServerList: IceServer[];

  constructor({
    awsRegion,
    wssEndpointURL,
    httpsEndpointURL,
    iceServerList,
  }: Partial<TypeWithFieldsOf<GetConnectionResourcesForChannelOutput>> = {}) {
    this.awsRegion = awsRegion ?? "";
    this.wssEndpointURL = wssEndpointURL ?? "";
    this.httpsEndpointURL = httpsEndpointURL ?? "";
    this.iceServerList = iceServerList ?? [];
  }

  convertUnexpectedNulls(): GetConnectionResourcesForChannelOutput {
    if (!this.iceServerList) {
      this.iceServerList = [];
    }
    for (let i0 = 0; i0 < this.iceServerList.length; i0 += 1) {
      this.iceServerList[i0] = this.iceServerList[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): GetConnectionResourcesForChannelOutput {
    for (let i0 = 0; i0 < this.iceServerList.length; i0 += 1) {
      this.iceServerList[i0] = new IceServer(this.iceServerList[i0]!).fixClasses();
    }
    return this;
  }
}

export class GetDetailsForPurchasedItem {
  organizationID: string;
  eventID: string;
  created: Date;
  updated: Date;
  amountCents: number;
  purchaseItemType: string;
  auctionID: string;
  lotID: string;
  inventoryAllocationID: string;
  itemID: string;
  fixedPriceItemID: string;
  transactionID: string;
  itemDetails: string;
  pickupPreference: string;
  isPickedUp: boolean;
  isReadyPickUp: boolean;
  fixedPriceItemName: string;
  fixedPriceItemDescription: string;
  fixedPriceItemPhotoURL: string;
  quantity: number;

  constructor({
    organizationID,
    eventID,
    created,
    updated,
    amountCents,
    purchaseItemType,
    auctionID,
    lotID,
    inventoryAllocationID,
    itemID,
    fixedPriceItemID,
    transactionID,
    itemDetails,
    pickupPreference,
    isPickedUp,
    isReadyPickUp,
    fixedPriceItemName,
    fixedPriceItemDescription,
    fixedPriceItemPhotoURL,
    quantity,
  }: Partial<TypeWithFieldsOf<GetDetailsForPurchasedItem>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.amountCents = amountCents ?? 0;
    this.purchaseItemType = purchaseItemType ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.inventoryAllocationID = inventoryAllocationID ?? "";
    this.itemID = itemID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.transactionID = transactionID ?? "";
    this.itemDetails = itemDetails ?? "";
    this.pickupPreference = pickupPreference ?? "";
    this.isPickedUp = isPickedUp ?? false;
    this.isReadyPickUp = isReadyPickUp ?? false;
    this.fixedPriceItemName = fixedPriceItemName ?? "";
    this.fixedPriceItemDescription = fixedPriceItemDescription ?? "";
    this.fixedPriceItemPhotoURL = fixedPriceItemPhotoURL ?? "";
    this.quantity = quantity ?? 0;
  }

  convertDates(): GetDetailsForPurchasedItem {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): GetDetailsForPurchasedItem {
    return this;
  }
}

export class GetDuringEventAnalyticsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<GetDuringEventAnalyticsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): GetDuringEventAnalyticsInput {
    return this;
  }
}

export class GetDuringEventAnalyticsOutput {
  highestRaisingTable: string;
  highestRaisingTableAmountCents: number;
  highestRaisingAttendee: string;

  constructor({
    highestRaisingTable,
    highestRaisingTableAmountCents,
    highestRaisingAttendee,
  }: Partial<TypeWithFieldsOf<GetDuringEventAnalyticsOutput>> = {}) {
    this.highestRaisingTable = highestRaisingTable ?? "";
    this.highestRaisingTableAmountCents = highestRaisingTableAmountCents ?? 0;
    this.highestRaisingAttendee = highestRaisingAttendee ?? "";
  }

  fixClasses(): GetDuringEventAnalyticsOutput {
    return this;
  }
}

export class GetEventNotificationTokenInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetEventNotificationTokenInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetEventNotificationTokenInput {
    return this;
  }
}

export class GetEventNotificationTokenOutput {
  token: string;
  notificationWebsocketURL: string;

  constructor({
    token,
    notificationWebsocketURL,
  }: Partial<TypeWithFieldsOf<GetEventNotificationTokenOutput>> = {}) {
    this.token = token ?? "";
    this.notificationWebsocketURL = notificationWebsocketURL ?? "";
  }

  fixClasses(): GetEventNotificationTokenOutput {
    return this;
  }
}

export class GetGuestMessagingRecipientsInput {
  organizationID: string;
  eventID: string;
  type: string;
  ticketTypeIDs: string;
  paging: PagingInputV3;
  includeEventURL: boolean;

  constructor({
    organizationID,
    eventID,
    type,
    ticketTypeIDs,
    paging,
    includeEventURL,
  }: Partial<TypeWithFieldsOf<GetGuestMessagingRecipientsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.type = type ?? "";
    this.ticketTypeIDs = ticketTypeIDs ?? "";
    this.paging = paging ?? new PagingInputV3();
    this.includeEventURL = includeEventURL ?? false;
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.type ? params.set('type', this.type.toString()) : params;
    params = this.ticketTypeIDs ? params.set('ticketTypeIDs', this.ticketTypeIDs.toString()) : params;
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    params = this.includeEventURL ? params.set('includeEventURL', this.includeEventURL.toString()) : params;
    return params;
  }

  fixClasses(): GetGuestMessagingRecipientsInput {
    return this;
  }
}

export class GetGuestMessagingRecipientsOutput {
  recipients: Recipient[];

  constructor({
    recipients,
  }: Partial<TypeWithFieldsOf<GetGuestMessagingRecipientsOutput>> = {}) {
    this.recipients = recipients ?? [];
  }

  convertUnexpectedNulls(): GetGuestMessagingRecipientsOutput {
    if (!this.recipients) {
      this.recipients = [];
    }
    for (let i0 = 0; i0 < this.recipients.length; i0 += 1) {
      this.recipients[i0] = this.recipients[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): GetGuestMessagingRecipientsOutput {
    for (let i0 = 0; i0 < this.recipients.length; i0 += 1) {
      this.recipients[i0] = new Recipient(this.recipients[i0]!).fixClasses();
    }
    return this;
  }
}

export class GetGuestMobilePhoneNumbersCountInput {
  organizationID: string;
  eventID: string;
  type: string;
  ticketTypeIDs: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    type,
    ticketTypeIDs,
    paging,
  }: Partial<TypeWithFieldsOf<GetGuestMobilePhoneNumbersCountInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.type = type ?? "";
    this.ticketTypeIDs = ticketTypeIDs ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.type ? params.set('type', this.type.toString()) : params;
    params = this.ticketTypeIDs ? params.set('ticketTypeIDs', this.ticketTypeIDs.toString()) : params;
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): GetGuestMobilePhoneNumbersCountInput {
    return this;
  }
}

export class GetGuestMobilePhoneNumbersCountOutput {
  count: number;

  constructor({
    count,
  }: Partial<TypeWithFieldsOf<GetGuestMobilePhoneNumbersCountOutput>> = {}) {
    this.count = count ?? 0;
  }

  fixClasses(): GetGuestMobilePhoneNumbersCountOutput {
    return this;
  }
}

export class GetGuestMobilePhoneNumbersInput {
  organizationID: string;
  eventID: string;
  type: string;
  ticketTypeIDs: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    type,
    ticketTypeIDs,
    paging,
  }: Partial<TypeWithFieldsOf<GetGuestMobilePhoneNumbersInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.type = type ?? "";
    this.ticketTypeIDs = ticketTypeIDs ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.type ? params.set('type', this.type.toString()) : params;
    params = this.ticketTypeIDs ? params.set('ticketTypeIDs', this.ticketTypeIDs.toString()) : params;
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): GetGuestMobilePhoneNumbersInput {
    return this;
  }
}

export class GetGuestMobilePhoneNumbersOutput {
  mobilePhoneNumbers: GuestMobilePhoneReportResult[];

  constructor({
    mobilePhoneNumbers,
  }: Partial<TypeWithFieldsOf<GetGuestMobilePhoneNumbersOutput>> = {}) {
    this.mobilePhoneNumbers = mobilePhoneNumbers ?? [];
  }

  convertUnexpectedNulls(): GetGuestMobilePhoneNumbersOutput {
    if (!this.mobilePhoneNumbers) {
      this.mobilePhoneNumbers = [];
    }
    return this;
  }

  fixClasses(): GetGuestMobilePhoneNumbersOutput {
    for (let i0 = 0; i0 < this.mobilePhoneNumbers.length; i0 += 1) {
      this.mobilePhoneNumbers[i0] = new GuestMobilePhoneReportResult(this.mobilePhoneNumbers[i0]!).fixClasses();
    }
    return this;
  }
}

export class GetGuestSessionSummaryByIDInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  guestSessionID: string;

  constructor({
    organizationID,
    eventID,
    ticketID,
    guestSessionID,
  }: Partial<TypeWithFieldsOf<GetGuestSessionSummaryByIDInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.guestSessionID = guestSessionID ?? "";
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.ticketID ? params.set('ticketID', this.ticketID.toString()) : params;
    params = this.guestSessionID ? params.set('guestSessionID', this.guestSessionID.toString()) : params;
    return params;
  }

  fixClasses(): GetGuestSessionSummaryByIDInput {
    return this;
  }
}

export class GetGuestSessionSummaryByIDOutput {
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  guestDisplayName: string;
  guestUserID: string;
  managingGuestID: string;
  isCheckInWithManagingGuest: boolean;
  tableName: string;
  tableNumber: string;
  bidderNumber: number;
  ticketID: string;
  ticketTypeID: string;
  ticketTypeName: string;
  ticketClaimedTime: (Date | null);
  ticketPurchaserFirstName: string;
  ticketPurchaserLastName: string;
  ticketPurchaserEmail: string;
  ticketManagerEmail: string;
  ticketManagerFirstName: string;
  ticketManagerLastName: string;
  ticketGroupID: string;
  checkedInTime: (Date | null);
  guestSessionID: string;
  totalProceedsAmountCents: number;
  guestMobilePhone: string;
  biddingAsGuestID: string;
  biddingAsFullName: string;
  biddingAsFirstName: string;
  biddingAsLastName: string;
  preEventQuestionnaireCompleteTime: (Date | null);

  constructor({
    guestID,
    firstName,
    lastName,
    email,
    guestDisplayName,
    guestUserID,
    managingGuestID,
    isCheckInWithManagingGuest,
    tableName,
    tableNumber,
    bidderNumber,
    ticketID,
    ticketTypeID,
    ticketTypeName,
    ticketClaimedTime,
    ticketPurchaserFirstName,
    ticketPurchaserLastName,
    ticketPurchaserEmail,
    ticketManagerEmail,
    ticketManagerFirstName,
    ticketManagerLastName,
    ticketGroupID,
    checkedInTime,
    guestSessionID,
    totalProceedsAmountCents,
    guestMobilePhone,
    biddingAsGuestID,
    biddingAsFullName,
    biddingAsFirstName,
    biddingAsLastName,
    preEventQuestionnaireCompleteTime,
  }: Partial<TypeWithFieldsOf<GetGuestSessionSummaryByIDOutput>> = {}) {
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.guestDisplayName = guestDisplayName ?? "";
    this.guestUserID = guestUserID ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? false;
    this.tableName = tableName ?? "";
    this.tableNumber = tableNumber ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.ticketID = ticketID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.ticketTypeName = ticketTypeName ?? "";
    this.ticketClaimedTime = ticketClaimedTime ?? null;
    this.ticketPurchaserFirstName = ticketPurchaserFirstName ?? "";
    this.ticketPurchaserLastName = ticketPurchaserLastName ?? "";
    this.ticketPurchaserEmail = ticketPurchaserEmail ?? "";
    this.ticketManagerEmail = ticketManagerEmail ?? "";
    this.ticketManagerFirstName = ticketManagerFirstName ?? "";
    this.ticketManagerLastName = ticketManagerLastName ?? "";
    this.ticketGroupID = ticketGroupID ?? "";
    this.checkedInTime = checkedInTime ?? null;
    this.guestSessionID = guestSessionID ?? "";
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.guestMobilePhone = guestMobilePhone ?? "";
    this.biddingAsGuestID = biddingAsGuestID ?? "";
    this.biddingAsFullName = biddingAsFullName ?? "";
    this.biddingAsFirstName = biddingAsFirstName ?? "";
    this.biddingAsLastName = biddingAsLastName ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
  }

  convertDates(): GetGuestSessionSummaryByIDOutput {
    if (this.ticketClaimedTime !== undefined && this.ticketClaimedTime !== null) {
      this.ticketClaimedTime = new Date(this.ticketClaimedTime!);
    }
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): GetGuestSessionSummaryByIDOutput {
    return this;
  }
}

export class GetGuestSessionSummaryInput {
  organizationID: string;
  eventID: string;
  ticketTicketID: string;

  constructor({
    organizationID,
    eventID,
    ticketTicketID,
  }: Partial<TypeWithFieldsOf<GetGuestSessionSummaryInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketTicketID = ticketTicketID ?? "";
  }

  fixClasses(): GetGuestSessionSummaryInput {
    return this;
  }
}

export class GetGuestSessionSummaryOutput {
  eventSessionID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  guestDisplayName: string;
  guestUserID: string;
  managingGuestID: string;
  isCheckInWithManagingGuest: boolean;
  tableName: string;
  tableNumber: string;
  bidderNumber: number;
  attendeeNumber: string;
  ticketID: string;
  ticketTypeID: string;
  ticketTypeName: string;
  ticketClaimedTime: (Date | null);
  ticketPurchaserFirstName: string;
  ticketPurchaserLastName: string;
  ticketPurchaserEmail: string;
  ticketManagerEmail: string;
  ticketManagerFirstName: string;
  ticketManagerLastName: string;
  ticketGroupID: string;
  checkedInTime: (Date | null);
  guestSessionID: string;
  totalProceedsAmountCents: number;
  guestMobilePhone: string;
  biddingAsGuestID: string;
  biddingAsFullName: string;
  biddingAsFirstName: string;
  biddingAsLastName: string;
  preEventQuestionnaireCompleteTime: (Date | null);

  constructor({
    eventSessionID,
    guestID,
    firstName,
    lastName,
    email,
    guestDisplayName,
    guestUserID,
    managingGuestID,
    isCheckInWithManagingGuest,
    tableName,
    tableNumber,
    bidderNumber,
    attendeeNumber,
    ticketID,
    ticketTypeID,
    ticketTypeName,
    ticketClaimedTime,
    ticketPurchaserFirstName,
    ticketPurchaserLastName,
    ticketPurchaserEmail,
    ticketManagerEmail,
    ticketManagerFirstName,
    ticketManagerLastName,
    ticketGroupID,
    checkedInTime,
    guestSessionID,
    totalProceedsAmountCents,
    guestMobilePhone,
    biddingAsGuestID,
    biddingAsFullName,
    biddingAsFirstName,
    biddingAsLastName,
    preEventQuestionnaireCompleteTime,
  }: Partial<TypeWithFieldsOf<GetGuestSessionSummaryOutput>> = {}) {
    this.eventSessionID = eventSessionID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.guestDisplayName = guestDisplayName ?? "";
    this.guestUserID = guestUserID ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? false;
    this.tableName = tableName ?? "";
    this.tableNumber = tableNumber ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.attendeeNumber = attendeeNumber ?? "";
    this.ticketID = ticketID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.ticketTypeName = ticketTypeName ?? "";
    this.ticketClaimedTime = ticketClaimedTime ?? null;
    this.ticketPurchaserFirstName = ticketPurchaserFirstName ?? "";
    this.ticketPurchaserLastName = ticketPurchaserLastName ?? "";
    this.ticketPurchaserEmail = ticketPurchaserEmail ?? "";
    this.ticketManagerEmail = ticketManagerEmail ?? "";
    this.ticketManagerFirstName = ticketManagerFirstName ?? "";
    this.ticketManagerLastName = ticketManagerLastName ?? "";
    this.ticketGroupID = ticketGroupID ?? "";
    this.checkedInTime = checkedInTime ?? null;
    this.guestSessionID = guestSessionID ?? "";
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.guestMobilePhone = guestMobilePhone ?? "";
    this.biddingAsGuestID = biddingAsGuestID ?? "";
    this.biddingAsFullName = biddingAsFullName ?? "";
    this.biddingAsFirstName = biddingAsFirstName ?? "";
    this.biddingAsLastName = biddingAsLastName ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
  }

  convertDates(): GetGuestSessionSummaryOutput {
    if (this.ticketClaimedTime !== undefined && this.ticketClaimedTime !== null) {
      this.ticketClaimedTime = new Date(this.ticketClaimedTime!);
    }
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): GetGuestSessionSummaryOutput {
    return this;
  }
}

export class GetGuestSummaryInput {
  organizationID: string;
  eventID: string;
  ticketTicketID: string;

  constructor({
    organizationID,
    eventID,
    ticketTicketID,
  }: Partial<TypeWithFieldsOf<GetGuestSummaryInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketTicketID = ticketTicketID ?? "";
  }

  fixClasses(): GetGuestSummaryInput {
    return this;
  }
}

export class GetGuestSummaryOutput {
  tableName: string;
  tableNumber: string;
  bidderNumber: number;
  ticketTypeName: string;
  checkedInTime: (Date | null);

  constructor({
    tableName,
    tableNumber,
    bidderNumber,
    ticketTypeName,
    checkedInTime,
  }: Partial<TypeWithFieldsOf<GetGuestSummaryOutput>> = {}) {
    this.tableName = tableName ?? "";
    this.tableNumber = tableNumber ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.ticketTypeName = ticketTypeName ?? "";
    this.checkedInTime = checkedInTime ?? null;
  }

  convertDates(): GetGuestSummaryOutput {
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    return this;
  }

  fixClasses(): GetGuestSummaryOutput {
    return this;
  }
}

export class GetMyCheckInStatusInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<GetMyCheckInStatusInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): GetMyCheckInStatusInput {
    return this;
  }
}

export class GetMyCheckInStatusOutput {
  checkInStatus: string;

  constructor({
    checkInStatus,
  }: Partial<TypeWithFieldsOf<GetMyCheckInStatusOutput>> = {}) {
    this.checkInStatus = checkInStatus ?? "";
  }

  fixClasses(): GetMyCheckInStatusOutput {
    return this;
  }
}

export class GetMyExperienceTypeInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetMyExperienceTypeInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetMyExperienceTypeInput {
    return this;
  }
}

export class GetMyExperienceTypeOutput {
  experienceType: string;

  constructor({
    experienceType,
  }: Partial<TypeWithFieldsOf<GetMyExperienceTypeOutput>> = {}) {
    this.experienceType = experienceType ?? "";
  }

  fixClasses(): GetMyExperienceTypeOutput {
    return this;
  }
}

export class GetMyGuestDemographicsInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetMyGuestDemographicsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetMyGuestDemographicsInput {
    return this;
  }
}

export class GetMyGuestDemographicsOutput {
  orgID: string;
  eventID: string;
  guestID: string;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  mobilePhone: string;

  constructor({
    orgID,
    eventID,
    guestID,
    userID,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    country,
    zip,
    mobilePhone,
  }: Partial<TypeWithFieldsOf<GetMyGuestDemographicsOutput>> = {}) {
    this.orgID = orgID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.userID = userID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.address = address ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.country = country ?? "";
    this.zip = zip ?? "";
    this.mobilePhone = mobilePhone ?? "";
  }

  fixClasses(): GetMyGuestDemographicsOutput {
    return this;
  }
}

export class GetMyGuestDemographicsPublicInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetMyGuestDemographicsPublicInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetMyGuestDemographicsPublicInput {
    return this;
  }
}

export class GetMyGuestDemographicsPublicOutput {
  orgID: string;
  eventID: string;
  guestID: string;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  mobilePhone: string;

  constructor({
    orgID,
    eventID,
    guestID,
    userID,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    country,
    zip,
    mobilePhone,
  }: Partial<TypeWithFieldsOf<GetMyGuestDemographicsPublicOutput>> = {}) {
    this.orgID = orgID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.userID = userID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.address = address ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.country = country ?? "";
    this.zip = zip ?? "";
    this.mobilePhone = mobilePhone ?? "";
  }

  fixClasses(): GetMyGuestDemographicsPublicOutput {
    return this;
  }
}

export class GetMyGuestSessionSummaryInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<GetMyGuestSessionSummaryInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): GetMyGuestSessionSummaryInput {
    return this;
  }
}

export class GetMyGuestSessionSummaryOutput {
  eventSessionID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  guestDisplayName: string;
  guestUserID: string;
  managingGuestID: string;
  isCheckInWithManagingGuest: boolean;
  tableName: string;
  tableNumber: string;
  bidderNumber: number;
  attendeeNumber: string;
  ticketID: string;
  ticketTypeID: string;
  ticketTypeName: string;
  ticketClaimedTime: (Date | null);
  ticketPurchaserFirstName: string;
  ticketPurchaserLastName: string;
  ticketPurchaserEmail: string;
  ticketManagerEmail: string;
  ticketManagerFirstName: string;
  ticketManagerLastName: string;
  ticketGroupID: string;
  checkedInTime: (Date | null);
  guestSessionID: string;
  totalProceedsAmountCents: number;
  guestMobilePhone: string;
  biddingAsGuestID: string;
  biddingAsFullName: string;
  biddingAsFirstName: string;
  biddingAsLastName: string;
  preEventQuestionnaireCompleteTime: (Date | null);

  constructor({
    eventSessionID,
    guestID,
    firstName,
    lastName,
    email,
    guestDisplayName,
    guestUserID,
    managingGuestID,
    isCheckInWithManagingGuest,
    tableName,
    tableNumber,
    bidderNumber,
    attendeeNumber,
    ticketID,
    ticketTypeID,
    ticketTypeName,
    ticketClaimedTime,
    ticketPurchaserFirstName,
    ticketPurchaserLastName,
    ticketPurchaserEmail,
    ticketManagerEmail,
    ticketManagerFirstName,
    ticketManagerLastName,
    ticketGroupID,
    checkedInTime,
    guestSessionID,
    totalProceedsAmountCents,
    guestMobilePhone,
    biddingAsGuestID,
    biddingAsFullName,
    biddingAsFirstName,
    biddingAsLastName,
    preEventQuestionnaireCompleteTime,
  }: Partial<TypeWithFieldsOf<GetMyGuestSessionSummaryOutput>> = {}) {
    this.eventSessionID = eventSessionID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.guestDisplayName = guestDisplayName ?? "";
    this.guestUserID = guestUserID ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? false;
    this.tableName = tableName ?? "";
    this.tableNumber = tableNumber ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.attendeeNumber = attendeeNumber ?? "";
    this.ticketID = ticketID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.ticketTypeName = ticketTypeName ?? "";
    this.ticketClaimedTime = ticketClaimedTime ?? null;
    this.ticketPurchaserFirstName = ticketPurchaserFirstName ?? "";
    this.ticketPurchaserLastName = ticketPurchaserLastName ?? "";
    this.ticketPurchaserEmail = ticketPurchaserEmail ?? "";
    this.ticketManagerEmail = ticketManagerEmail ?? "";
    this.ticketManagerFirstName = ticketManagerFirstName ?? "";
    this.ticketManagerLastName = ticketManagerLastName ?? "";
    this.ticketGroupID = ticketGroupID ?? "";
    this.checkedInTime = checkedInTime ?? null;
    this.guestSessionID = guestSessionID ?? "";
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.guestMobilePhone = guestMobilePhone ?? "";
    this.biddingAsGuestID = biddingAsGuestID ?? "";
    this.biddingAsFullName = biddingAsFullName ?? "";
    this.biddingAsFirstName = biddingAsFirstName ?? "";
    this.biddingAsLastName = biddingAsLastName ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
  }

  convertDates(): GetMyGuestSessionSummaryOutput {
    if (this.ticketClaimedTime !== undefined && this.ticketClaimedTime !== null) {
      this.ticketClaimedTime = new Date(this.ticketClaimedTime!);
    }
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): GetMyGuestSessionSummaryOutput {
    return this;
  }
}

export class GetOrgPickupItemOptionsInput {
  organizationID: string;
  eventID: string;
  fixedPricedItemIDs: string[];

  constructor({
    organizationID,
    eventID,
    fixedPricedItemIDs,
  }: Partial<TypeWithFieldsOf<GetOrgPickupItemOptionsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPricedItemIDs = fixedPricedItemIDs ?? [];
  }

  convertUnexpectedNulls(): GetOrgPickupItemOptionsInput {
    if (!this.fixedPricedItemIDs) {
      this.fixedPricedItemIDs = [];
    }
    return this;
  }

  fixClasses(): GetOrgPickupItemOptionsInput {
    return this;
  }
}

export class GetOrgPickupItemOptionsOutput {
  itemOptions: PickupItemOptionsOutput[];

  constructor({
    itemOptions,
  }: Partial<TypeWithFieldsOf<GetOrgPickupItemOptionsOutput>> = {}) {
    this.itemOptions = itemOptions ?? [];
  }

  convertUnexpectedNulls(): GetOrgPickupItemOptionsOutput {
    if (!this.itemOptions) {
      this.itemOptions = [];
    }
    return this;
  }

  fixClasses(): GetOrgPickupItemOptionsOutput {
    for (let i0 = 0; i0 < this.itemOptions.length; i0 += 1) {
      this.itemOptions[i0] = new PickupItemOptionsOutput(this.itemOptions[i0]!).fixClasses();
    }
    return this;
  }
}

export class GetPreEventAnalyticsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<GetPreEventAnalyticsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): GetPreEventAnalyticsInput {
    return this;
  }
}

export class GetPreEventAnalyticsOutput {
  attendeesSeatedCount: number;
  attendeesUnseatedCount: number;

  constructor({
    attendeesSeatedCount,
    attendeesUnseatedCount,
  }: Partial<TypeWithFieldsOf<GetPreEventAnalyticsOutput>> = {}) {
    this.attendeesSeatedCount = attendeesSeatedCount ?? 0;
    this.attendeesUnseatedCount = attendeesUnseatedCount ?? 0;
  }

  fixClasses(): GetPreEventAnalyticsOutput {
    return this;
  }
}

export class GetPrimaryBidderInput {
  organizationID: string;
  eventID: string;
  bidderNumber: string;

  constructor({
    organizationID,
    eventID,
    bidderNumber,
  }: Partial<TypeWithFieldsOf<GetPrimaryBidderInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.bidderNumber = bidderNumber ?? "";
  }

  fixClasses(): GetPrimaryBidderInput {
    return this;
  }
}

export class GetPrimaryBidderOutput {
  primaryGuest: GuestSummary;

  constructor({
    primaryGuest,
  }: Partial<TypeWithFieldsOf<GetPrimaryBidderOutput>> = {}) {
    this.primaryGuest = primaryGuest ?? new GuestSummary();
  }

  convertDates(): GetPrimaryBidderOutput {
    this.primaryGuest = this.primaryGuest!.convertDates();
    return this;
  }

  fixClasses(): GetPrimaryBidderOutput {
    this.primaryGuest = new GuestSummary(this.primaryGuest!).fixClasses();
    return this;
  }
}

export class GetPurchasedItemsUserSummaryInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<GetPurchasedItemsUserSummaryInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): GetPurchasedItemsUserSummaryInput {
    return this;
  }
}

export class GetPurchasedItemsUserSummaryOutput {
  items: GetDetailsForPurchasedItem[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<GetPurchasedItemsUserSummaryOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): GetPurchasedItemsUserSummaryOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): GetPurchasedItemsUserSummaryOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): GetPurchasedItemsUserSummaryOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new GetDetailsForPurchasedItem(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class GetSelectedPickupTimeSlotsInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetSelectedPickupTimeSlotsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetSelectedPickupTimeSlotsInput {
    return this;
  }
}

export class GetSelectedPickupTimeSlotsOutput {
  selectedPickupTimeSlots: PickupTimeSlot[];

  constructor({
    selectedPickupTimeSlots,
  }: Partial<TypeWithFieldsOf<GetSelectedPickupTimeSlotsOutput>> = {}) {
    this.selectedPickupTimeSlots = selectedPickupTimeSlots ?? [];
  }

  convertDates(): GetSelectedPickupTimeSlotsOutput {
    if (!this.selectedPickupTimeSlots) {
      this.selectedPickupTimeSlots = [];
    }
    
    for (let i0 = 0; i0 < this.selectedPickupTimeSlots.length; i0 += 1) {
      this.selectedPickupTimeSlots[i0] = this.selectedPickupTimeSlots[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): GetSelectedPickupTimeSlotsOutput {
    if (!this.selectedPickupTimeSlots) {
      this.selectedPickupTimeSlots = [];
    }
    return this;
  }

  fixClasses(): GetSelectedPickupTimeSlotsOutput {
    for (let i0 = 0; i0 < this.selectedPickupTimeSlots.length; i0 += 1) {
      this.selectedPickupTimeSlots[i0] = new PickupTimeSlot(this.selectedPickupTimeSlots[i0]!).fixClasses();
    }
    return this;
  }
}

export class GetSharedCartGuestListInput {
  organizationID: string;
  eventID: string;
  guestID: string;

  constructor({
    organizationID,
    eventID,
    guestID,
  }: Partial<TypeWithFieldsOf<GetSharedCartGuestListInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
  }

  fixClasses(): GetSharedCartGuestListInput {
    return this;
  }
}

export class GetSharedCartGuestListOutput {
  guests: GuestSummary[];

  constructor({
    guests,
  }: Partial<TypeWithFieldsOf<GetSharedCartGuestListOutput>> = {}) {
    this.guests = guests ?? [];
  }

  convertDates(): GetSharedCartGuestListOutput {
    if (!this.guests) {
      this.guests = [];
    }
    
    for (let i0 = 0; i0 < this.guests.length; i0 += 1) {
      this.guests[i0] = this.guests[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): GetSharedCartGuestListOutput {
    if (!this.guests) {
      this.guests = [];
    }
    return this;
  }

  fixClasses(): GetSharedCartGuestListOutput {
    for (let i0 = 0; i0 < this.guests.length; i0 += 1) {
      this.guests[i0] = new GuestSummary(this.guests[i0]!).fixClasses();
    }
    return this;
  }
}

export class GetTaxCentsForGuestPurchasedItemsInput {
  organizationID: string;
  eventID: string;
  itemIDs: string[];

  constructor({
    organizationID,
    eventID,
    itemIDs,
  }: Partial<TypeWithFieldsOf<GetTaxCentsForGuestPurchasedItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.itemIDs = itemIDs ?? [];
  }

  convertUnexpectedNulls(): GetTaxCentsForGuestPurchasedItemsInput {
    if (!this.itemIDs) {
      this.itemIDs = [];
    }
    return this;
  }

  fixClasses(): GetTaxCentsForGuestPurchasedItemsInput {
    return this;
  }
}

export class GetTaxCentsForGuestPurchasedItemsOutput {
  itemIDs: {[index: string]: number};

  constructor({
    itemIDs,
  }: Partial<TypeWithFieldsOf<GetTaxCentsForGuestPurchasedItemsOutput>> = {}) {
    this.itemIDs = itemIDs ?? {};
  }

  convertUnexpectedNulls(): GetTaxCentsForGuestPurchasedItemsOutput {
    if (!this.itemIDs) {
      this.itemIDs = {};
    }
    return this;
  }

  fixClasses(): GetTaxCentsForGuestPurchasedItemsOutput {
    return this;
  }
}

export class GetTaxCentsForPurchasedItemsInput {
  organizationID: string;
  eventID: string;
  itemIDs: string[];

  constructor({
    organizationID,
    eventID,
    itemIDs,
  }: Partial<TypeWithFieldsOf<GetTaxCentsForPurchasedItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.itemIDs = itemIDs ?? [];
  }

  convertUnexpectedNulls(): GetTaxCentsForPurchasedItemsInput {
    if (!this.itemIDs) {
      this.itemIDs = [];
    }
    return this;
  }

  fixClasses(): GetTaxCentsForPurchasedItemsInput {
    return this;
  }
}

export class GetTaxCentsForPurchasedItemsOutput {
  itemIDs: {[index: string]: number};

  constructor({
    itemIDs,
  }: Partial<TypeWithFieldsOf<GetTaxCentsForPurchasedItemsOutput>> = {}) {
    this.itemIDs = itemIDs ?? {};
  }

  convertUnexpectedNulls(): GetTaxCentsForPurchasedItemsOutput {
    if (!this.itemIDs) {
      this.itemIDs = {};
    }
    return this;
  }

  fixClasses(): GetTaxCentsForPurchasedItemsOutput {
    return this;
  }
}

export class GetUnprocessedItemsDataInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<GetUnprocessedItemsDataInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): GetUnprocessedItemsDataInput {
    return this;
  }
}

export class GetUnprocessedItemsDataOutput {
  unprocessedCartCount: number;
  unprocessedPaymentsAmountCents: number;

  constructor({
    unprocessedCartCount,
    unprocessedPaymentsAmountCents,
  }: Partial<TypeWithFieldsOf<GetUnprocessedItemsDataOutput>> = {}) {
    this.unprocessedCartCount = unprocessedCartCount ?? 0;
    this.unprocessedPaymentsAmountCents = unprocessedPaymentsAmountCents ?? 0;
  }

  fixClasses(): GetUnprocessedItemsDataOutput {
    return this;
  }
}

export class GreatfeatsParticipantDetails {
  id: string;
  name: string;

  constructor({
    id,
    name,
  }: Partial<TypeWithFieldsOf<GreatfeatsParticipantDetails>> = {}) {
    this.id = id ?? "";
    this.name = name ?? "";
  }

  fixClasses(): GreatfeatsParticipantDetails {
    return this;
  }
}

export class GuestCreditCardSummary {
  userCardID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  cardLastFour: string;
  cardType: string;

  constructor({
    userCardID,
    guestID,
    firstName,
    lastName,
    cardLastFour,
    cardType,
  }: Partial<TypeWithFieldsOf<GuestCreditCardSummary>> = {}) {
    this.userCardID = userCardID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.cardType = cardType ?? "";
  }

  fixClasses(): GuestCreditCardSummary {
    return this;
  }
}

export class GuestMobilePhoneReportResult {
  guestID: string;
  mobilePhone: string;

  constructor({
    guestID,
    mobilePhone,
  }: Partial<TypeWithFieldsOf<GuestMobilePhoneReportResult>> = {}) {
    this.guestID = guestID ?? "";
    this.mobilePhone = mobilePhone ?? "";
  }

  fixClasses(): GuestMobilePhoneReportResult {
    return this;
  }
}

export class GuestSessionSummary {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  id: string;
  guestID: string;
  isCheckInWithManagingGuest: boolean;
  checkedInTime: (Date | null);
  tableAttendeeID: string;
  ticketID: string;
  initialTableName: string;
  initialTableNumber: string;
  totalProceedsAmountCents: number;
  attendeeExperience: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    id,
    guestID,
    isCheckInWithManagingGuest,
    checkedInTime,
    tableAttendeeID,
    ticketID,
    initialTableName,
    initialTableNumber,
    totalProceedsAmountCents,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<GuestSessionSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.id = id ?? "";
    this.guestID = guestID ?? "";
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? false;
    this.checkedInTime = checkedInTime ?? null;
    this.tableAttendeeID = tableAttendeeID ?? "";
    this.ticketID = ticketID ?? "";
    this.initialTableName = initialTableName ?? "";
    this.initialTableNumber = initialTableNumber ?? "";
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.attendeeExperience = attendeeExperience ?? "";
  }

  convertDates(): GuestSessionSummary {
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    return this;
  }

  fixClasses(): GuestSessionSummary {
    return this;
  }
}

export class GuestSummary {
  organizationID: string;
  eventID: string;
  id: string;
  userID: string;
  registrationID: string;
  bidderNumber: number;
  attendeeNumber: string;
  managingGuestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  preEventQuestionnaireCompleteTime: (Date | null);
  preEventQuestionOneAnswer: boolean;
  preEventQuestionTwoAnswer: boolean;
  preEventQuestionThreeAnswer: boolean;
  preEventConsentSelfCompleted: boolean;
  biddingAsGuestID: string;
  purchasedItemPrimaryUserID: string;
  purchasedItemPrimaryGuestID: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  userCardID: string;

  constructor({
    organizationID,
    eventID,
    id,
    userID,
    registrationID,
    bidderNumber,
    attendeeNumber,
    managingGuestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    preEventQuestionnaireCompleteTime,
    preEventQuestionOneAnswer,
    preEventQuestionTwoAnswer,
    preEventQuestionThreeAnswer,
    preEventConsentSelfCompleted,
    biddingAsGuestID,
    purchasedItemPrimaryUserID,
    purchasedItemPrimaryGuestID,
    address,
    city,
    state,
    postalCode,
    country,
    userCardID,
  }: Partial<TypeWithFieldsOf<GuestSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.userID = userID ?? "";
    this.registrationID = registrationID ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.attendeeNumber = attendeeNumber ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.preEventQuestionOneAnswer = preEventQuestionOneAnswer ?? false;
    this.preEventQuestionTwoAnswer = preEventQuestionTwoAnswer ?? false;
    this.preEventQuestionThreeAnswer = preEventQuestionThreeAnswer ?? false;
    this.preEventConsentSelfCompleted = preEventConsentSelfCompleted ?? false;
    this.biddingAsGuestID = biddingAsGuestID ?? "";
    this.purchasedItemPrimaryUserID = purchasedItemPrimaryUserID ?? "";
    this.purchasedItemPrimaryGuestID = purchasedItemPrimaryGuestID ?? "";
    this.address = address ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.postalCode = postalCode ?? "";
    this.country = country ?? "";
    this.userCardID = userCardID ?? "";
  }

  convertDates(): GuestSummary {
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): GuestSummary {
    return this;
  }
}

export class GuestToUpdate {
  firstName: (string | null);
  lastName: (string | null);
  email: (string | null);
  mobilePhone: (string | null);
  ticketID: (string | null);
  managingGuestID: (string | null);

  constructor({
    firstName,
    lastName,
    email,
    mobilePhone,
    ticketID,
    managingGuestID,
  }: Partial<TypeWithFieldsOf<GuestToUpdate>> = {}) {
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
    this.ticketID = ticketID ?? null;
    this.managingGuestID = managingGuestID ?? null;
  }

  fixClasses(): GuestToUpdate {
    return this;
  }
}

export class HasAssignedTicketForTicketManagerTokenEmailInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  ticketManagerToken: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    ticketManagerToken,
  }: Partial<TypeWithFieldsOf<HasAssignedTicketForTicketManagerTokenEmailInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.ticketManagerToken = ticketManagerToken ?? "";
  }

  fixClasses(): HasAssignedTicketForTicketManagerTokenEmailInput {
    return this;
  }
}

export class HasAssignedTicketForTicketManagerTokenEmailOutput {
  hasAssignedTicketForTicketManagerTokenEmail: boolean;

  constructor({
    hasAssignedTicketForTicketManagerTokenEmail,
  }: Partial<TypeWithFieldsOf<HasAssignedTicketForTicketManagerTokenEmailOutput>> = {}) {
    this.hasAssignedTicketForTicketManagerTokenEmail = hasAssignedTicketForTicketManagerTokenEmail ?? false;
  }

  fixClasses(): HasAssignedTicketForTicketManagerTokenEmailOutput {
    return this;
  }
}

export class IceServer {
  userName: string;
  password: string;
  timeToLive: number;
  uris: string[];

  constructor({
    userName,
    password,
    timeToLive,
    uris,
  }: Partial<TypeWithFieldsOf<IceServer>> = {}) {
    this.userName = userName ?? "";
    this.password = password ?? "";
    this.timeToLive = timeToLive ?? 0;
    this.uris = uris ?? [];
  }

  convertUnexpectedNulls(): IceServer {
    if (!this.uris) {
      this.uris = [];
    }
    return this;
  }

  fixClasses(): IceServer {
    return this;
  }
}

export class ImpactPhotoSummary {
  organizationID: string;
  eventID: string;
  impactID: string;
  id: string;
  url: string;
  uploadToken: string;

  constructor({
    organizationID,
    eventID,
    impactID,
    id,
    url,
    uploadToken,
  }: Partial<TypeWithFieldsOf<ImpactPhotoSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
    this.id = id ?? "";
    this.url = url ?? "";
    this.uploadToken = uploadToken ?? "";
  }

  fixClasses(): ImpactPhotoSummary {
    return this;
  }
}

export class ImpactSummary {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  amountCents: number;
  descriptionMarkdown: string;
  totalPurchasesLimit: number;
  totalPurchasesCountDisplayThreshold: number;
  totalPurchasesAmountCentsDisplayThreshold: number;
  totalPurchasesCount: number;
  totalPurchasesAmountCents: number;
  goalType: string;
  goalPurchasesCount: number;
  goalPurchasesCountDisplayThreshold: number;
  goalPurchasesCountMetOn: (Date | null);
  goalPurchasesAmountCents: number;
  goalPurchasesAmountCentsDisplayThreshold: number;
  goalPurchasesAmountCentsMetOn: (Date | null);
  goalMetMessageSentOn: (Date | null);
  goalMetEmailSubject: string;
  goalMetEmailBodyMarkdown: string;
  goalMetSMSBody: string;
  hideFromFlow: boolean;
  fixedPriceItemID: string;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    amountCents,
    descriptionMarkdown,
    totalPurchasesLimit,
    totalPurchasesCountDisplayThreshold,
    totalPurchasesAmountCentsDisplayThreshold,
    totalPurchasesCount,
    totalPurchasesAmountCents,
    goalType,
    goalPurchasesCount,
    goalPurchasesCountDisplayThreshold,
    goalPurchasesCountMetOn,
    goalPurchasesAmountCents,
    goalPurchasesAmountCentsDisplayThreshold,
    goalPurchasesAmountCentsMetOn,
    goalMetMessageSentOn,
    goalMetEmailSubject,
    goalMetEmailBodyMarkdown,
    goalMetSMSBody,
    hideFromFlow,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<ImpactSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.amountCents = amountCents ?? 0;
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.totalPurchasesLimit = totalPurchasesLimit ?? 0;
    this.totalPurchasesCountDisplayThreshold = totalPurchasesCountDisplayThreshold ?? 0;
    this.totalPurchasesAmountCentsDisplayThreshold = totalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.totalPurchasesCount = totalPurchasesCount ?? 0;
    this.totalPurchasesAmountCents = totalPurchasesAmountCents ?? 0;
    this.goalType = goalType ?? "";
    this.goalPurchasesCount = goalPurchasesCount ?? 0;
    this.goalPurchasesCountDisplayThreshold = goalPurchasesCountDisplayThreshold ?? 0;
    this.goalPurchasesCountMetOn = goalPurchasesCountMetOn ?? null;
    this.goalPurchasesAmountCents = goalPurchasesAmountCents ?? 0;
    this.goalPurchasesAmountCentsDisplayThreshold = goalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.goalPurchasesAmountCentsMetOn = goalPurchasesAmountCentsMetOn ?? null;
    this.goalMetMessageSentOn = goalMetMessageSentOn ?? null;
    this.goalMetEmailSubject = goalMetEmailSubject ?? "";
    this.goalMetEmailBodyMarkdown = goalMetEmailBodyMarkdown ?? "";
    this.goalMetSMSBody = goalMetSMSBody ?? "";
    this.hideFromFlow = hideFromFlow ?? false;
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  convertDates(): ImpactSummary {
    if (this.goalPurchasesCountMetOn !== undefined && this.goalPurchasesCountMetOn !== null) {
      this.goalPurchasesCountMetOn = new Date(this.goalPurchasesCountMetOn!);
    }
    if (this.goalPurchasesAmountCentsMetOn !== undefined && this.goalPurchasesAmountCentsMetOn !== null) {
      this.goalPurchasesAmountCentsMetOn = new Date(this.goalPurchasesAmountCentsMetOn!);
    }
    if (this.goalMetMessageSentOn !== undefined && this.goalMetMessageSentOn !== null) {
      this.goalMetMessageSentOn = new Date(this.goalMetMessageSentOn!);
    }
    return this;
  }

  fixClasses(): ImpactSummary {
    return this;
  }
}

export class InitiateRecurringDonationInput {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  amountCents: number;
  cardName: string;
  expiration: string;
  cardType: string;
  cardLastFour: string;
  token: string;
  coverFee: boolean;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  payerState: string;
  payerZip: string;
  payerCountry: string;
  fakeTransactionResult: string;
  isAnonymous: boolean;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    email,
    mobilePhone,
    amountCents,
    cardName,
    expiration,
    cardType,
    cardLastFour,
    token,
    coverFee,
    payerName,
    payerStreet,
    payerCity,
    payerState,
    payerZip,
    payerCountry,
    fakeTransactionResult,
    isAnonymous,
  }: Partial<TypeWithFieldsOf<InitiateRecurringDonationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.amountCents = amountCents ?? 0;
    this.cardName = cardName ?? "";
    this.expiration = expiration ?? "";
    this.cardType = cardType ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.token = token ?? "";
    this.coverFee = coverFee ?? false;
    this.payerName = payerName ?? "";
    this.payerStreet = payerStreet ?? "";
    this.payerCity = payerCity ?? "";
    this.payerState = payerState ?? "";
    this.payerZip = payerZip ?? "";
    this.payerCountry = payerCountry ?? "";
    this.fakeTransactionResult = fakeTransactionResult ?? "";
    this.isAnonymous = isAnonymous ?? false;
  }

  fixClasses(): InitiateRecurringDonationInput {
    return this;
  }
}

export class InitiateRecurringDonationOutput {
  organizationID: string;
  purchaseID: string;
  paymentID: string;
  donationID: string;
  recurringDonationGroup: string;
  recurringDonationID: string;
  recurringDonationItemID: string;
  purchasedItemID: string;

  constructor({
    organizationID,
    purchaseID,
    paymentID,
    donationID,
    recurringDonationGroup,
    recurringDonationID,
    recurringDonationItemID,
    purchasedItemID,
  }: Partial<TypeWithFieldsOf<InitiateRecurringDonationOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.purchaseID = purchaseID ?? "";
    this.paymentID = paymentID ?? "";
    this.donationID = donationID ?? "";
    this.recurringDonationGroup = recurringDonationGroup ?? "";
    this.recurringDonationID = recurringDonationID ?? "";
    this.recurringDonationItemID = recurringDonationItemID ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
  }

  fixClasses(): InitiateRecurringDonationOutput {
    return this;
  }
}

export class InventoryItemPhotoUpdatedInput {
  eventID: string;
  auctionID: string;
  lotID: string;

  constructor({
    eventID,
    auctionID,
    lotID,
  }: Partial<TypeWithFieldsOf<InventoryItemPhotoUpdatedInput>> = {}) {
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
  }

  fixClasses(): InventoryItemPhotoUpdatedInput {
    return this;
  }
}

export class InvitationSummary {
  organizationID: string;
  eventID: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  sentEmail: (Date | null);
  sentSMS: (Date | null);
  registrationID: string;
  emailOpened: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    firstName,
    lastName,
    email,
    mobilePhone,
    sentEmail,
    sentSMS,
    registrationID,
    emailOpened,
  }: Partial<TypeWithFieldsOf<InvitationSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.sentEmail = sentEmail ?? null;
    this.sentSMS = sentSMS ?? null;
    this.registrationID = registrationID ?? "";
    this.emailOpened = emailOpened ?? false;
  }

  convertDates(): InvitationSummary {
    if (this.sentEmail !== undefined && this.sentEmail !== null) {
      this.sentEmail = new Date(this.sentEmail!);
    }
    if (this.sentSMS !== undefined && this.sentSMS !== null) {
      this.sentSMS = new Date(this.sentSMS!);
    }
    return this;
  }

  fixClasses(): InvitationSummary {
    return this;
  }
}

export class IsRegistrationCompedInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<IsRegistrationCompedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): IsRegistrationCompedInput {
    return this;
  }
}

export class IsRegistrationCompedOutput {
  comped: boolean;

  constructor({
    comped,
  }: Partial<TypeWithFieldsOf<IsRegistrationCompedOutput>> = {}) {
    this.comped = comped ?? false;
  }

  fixClasses(): IsRegistrationCompedOutput {
    return this;
  }
}

export class IsTicketManagerTokenEmailRegisteredInput {
  organizationID: string;
  eventID: string;
  ticketManagerToken: string;

  constructor({
    organizationID,
    eventID,
    ticketManagerToken,
  }: Partial<TypeWithFieldsOf<IsTicketManagerTokenEmailRegisteredInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketManagerToken = ticketManagerToken ?? "";
  }

  fixClasses(): IsTicketManagerTokenEmailRegisteredInput {
    return this;
  }
}

export class IsTicketManagerTokenEmailRegisteredOutput {
  isTicketManagerTokenEmailRegistered: boolean;

  constructor({
    isTicketManagerTokenEmailRegistered,
  }: Partial<TypeWithFieldsOf<IsTicketManagerTokenEmailRegisteredOutput>> = {}) {
    this.isTicketManagerTokenEmailRegistered = isTicketManagerTokenEmailRegistered ?? false;
  }

  fixClasses(): IsTicketManagerTokenEmailRegisteredOutput {
    return this;
  }
}

export class ItemforPaidSummary {
  item: string;
  purchasedItemID: string;
  paymentID: string;
  cardLastFour: string;
  paymentMethod: string;
  amountCents: number;
  coveredCostAmountCents: number;
  salesTaxAmountCents: number;
  paymentType: string;
  paymentTypeOtherNotes: string;
  checkNumber: string;

  constructor({
    item,
    purchasedItemID,
    paymentID,
    cardLastFour,
    paymentMethod,
    amountCents,
    coveredCostAmountCents,
    salesTaxAmountCents,
    paymentType,
    paymentTypeOtherNotes,
    checkNumber,
  }: Partial<TypeWithFieldsOf<ItemforPaidSummary>> = {}) {
    this.item = item ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
    this.paymentID = paymentID ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.paymentMethod = paymentMethod ?? "";
    this.amountCents = amountCents ?? 0;
    this.coveredCostAmountCents = coveredCostAmountCents ?? 0;
    this.salesTaxAmountCents = salesTaxAmountCents ?? 0;
    this.paymentType = paymentType ?? "";
    this.paymentTypeOtherNotes = paymentTypeOtherNotes ?? "";
    this.checkNumber = checkNumber ?? "";
  }

  fixClasses(): ItemforPaidSummary {
    return this;
  }
}

export class ItemforPendingSummary {
  transactionID: string;
  paymentID: string;
  created: Date;
  totalAmountCents: number;
  totalCoveredCostAmountCents: number;
  totalSalesTaxAmountCents: number;
  purchaseDetails: ItemforPaidSummary[];

  constructor({
    transactionID,
    paymentID,
    created,
    totalAmountCents,
    totalCoveredCostAmountCents,
    totalSalesTaxAmountCents,
    purchaseDetails,
  }: Partial<TypeWithFieldsOf<ItemforPendingSummary>> = {}) {
    this.transactionID = transactionID ?? "";
    this.paymentID = paymentID ?? "";
    this.created = created ?? new Date();
    this.totalAmountCents = totalAmountCents ?? 0;
    this.totalCoveredCostAmountCents = totalCoveredCostAmountCents ?? 0;
    this.totalSalesTaxAmountCents = totalSalesTaxAmountCents ?? 0;
    this.purchaseDetails = purchaseDetails ?? [];
  }

  convertDates(): ItemforPendingSummary {
    this.created = new Date(this.created!);
    return this;
  }

  convertUnexpectedNulls(): ItemforPendingSummary {
    if (!this.purchaseDetails) {
      this.purchaseDetails = [];
    }
    return this;
  }

  fixClasses(): ItemforPendingSummary {
    for (let i0 = 0; i0 < this.purchaseDetails.length; i0 += 1) {
      this.purchaseDetails[i0] = new ItemforPaidSummary(this.purchaseDetails[i0]!).fixClasses();
    }
    return this;
  }
}

export class ItemPurchaseDetails {
  emojiSelected: string;
  isAnonymous: boolean;
  itemName: string;
  quantity: number;
  auctionType: string;
  fromSpotterTool: boolean;
  sponsorAttachmentEventSaleID: string;
  greatfeatsParticipant: GreatfeatsParticipantDetails;

  constructor({
    emojiSelected,
    isAnonymous,
    itemName,
    quantity,
    auctionType,
    fromSpotterTool,
    sponsorAttachmentEventSaleID,
    greatfeatsParticipant,
  }: Partial<TypeWithFieldsOf<ItemPurchaseDetails>> = {}) {
    this.emojiSelected = emojiSelected ?? "";
    this.isAnonymous = isAnonymous ?? false;
    this.itemName = itemName ?? "";
    this.quantity = quantity ?? 0;
    this.auctionType = auctionType ?? "";
    this.fromSpotterTool = fromSpotterTool ?? false;
    this.sponsorAttachmentEventSaleID = sponsorAttachmentEventSaleID ?? "";
    this.greatfeatsParticipant = greatfeatsParticipant ?? new GreatfeatsParticipantDetails();
  }

  fixClasses(): ItemPurchaseDetails {
    this.greatfeatsParticipant = new GreatfeatsParticipantDetails(this.greatfeatsParticipant!).fixClasses();
    return this;
  }
}

export class ListAttendeesByGroupInput {
  organizationID: string;
  eventID: string;
  groupID: string;

  constructor({
    organizationID,
    eventID,
    groupID,
  }: Partial<TypeWithFieldsOf<ListAttendeesByGroupInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.groupID = groupID ?? "";
  }

  fixClasses(): ListAttendeesByGroupInput {
    return this;
  }
}

export class ListAttendeesByGroupOutput {
  attendeesByGroup: Attendee[];

  constructor({
    attendeesByGroup,
  }: Partial<TypeWithFieldsOf<ListAttendeesByGroupOutput>> = {}) {
    this.attendeesByGroup = attendeesByGroup ?? [];
  }

  convertDates(): ListAttendeesByGroupOutput {
    if (!this.attendeesByGroup) {
      this.attendeesByGroup = [];
    }
    
    for (let i0 = 0; i0 < this.attendeesByGroup.length; i0 += 1) {
      this.attendeesByGroup[i0] = this.attendeesByGroup[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListAttendeesByGroupOutput {
    if (!this.attendeesByGroup) {
      this.attendeesByGroup = [];
    }
    return this;
  }

  fixClasses(): ListAttendeesByGroupOutput {
    for (let i0 = 0; i0 < this.attendeesByGroup.length; i0 += 1) {
      this.attendeesByGroup[i0] = new Attendee(this.attendeesByGroup[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListAvailableCreditCardsInput {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
  }: Partial<TypeWithFieldsOf<ListAvailableCreditCardsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
  }

  fixClasses(): ListAvailableCreditCardsInput {
    return this;
  }
}

export class ListAvailableCreditCardsOutput {
  items: GuestCreditCardSummary[];

  constructor({
    items,
  }: Partial<TypeWithFieldsOf<ListAvailableCreditCardsOutput>> = {}) {
    this.items = items ?? [];
  }

  convertUnexpectedNulls(): ListAvailableCreditCardsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListAvailableCreditCardsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new GuestCreditCardSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListDonationMomentsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListDonationMomentsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListDonationMomentsInput {
    return this;
  }
}

export class ListDonationMomentsOutput {
  items: DonationMomentSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListDonationMomentsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListDonationMomentsOutput {
    if (!this.items) {
      this.items = [];
    }
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): ListDonationMomentsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new DonationMomentSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListDonationMomentsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListDonationMomentsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListDonationMomentsToCSVInput {
    return this;
  }
}

export class ListEventSessionsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSessionsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSessionsInput {
    return this;
  }
}

export class ListEventSessionsOutput {
  items: EventSessionSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSessionsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListEventSessionsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListEventSessionsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new EventSessionSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListEventSessionsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSessionsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSessionsToCSVInput {
    return this;
  }
}

export class ListEventSessionTicketConfigsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSessionTicketConfigsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSessionTicketConfigsInput {
    return this;
  }
}

export class ListEventSessionTicketConfigsOutput {
  items: EventSessionTicketConfigSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSessionTicketConfigsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListEventSessionTicketConfigsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListEventSessionTicketConfigsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new EventSessionTicketConfigSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListEventSessionTicketConfigsToCSVInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSessionTicketConfigsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSessionTicketConfigsToCSVInput {
    return this;
  }
}

export class ListEventsInput {
  organizationID: string;
  timezone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    timezone,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.timezone = timezone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventsInput {
    return this;
  }
}

export class ListEventSlideConfigsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSlideConfigsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSlideConfigsInput {
    return this;
  }
}

export class ListEventSlideConfigsOutput {
  items: EventSlideConfigSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSlideConfigsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListEventSlideConfigsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListEventSlideConfigsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new EventSlideConfigSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListEventSlideConfigsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSlideConfigsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSlideConfigsToCSVInput {
    return this;
  }
}

export class ListEventSlideshowsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSlideshowsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSlideshowsInput {
    return this;
  }
}

export class ListEventSlideshowsOutput {
  items: EventSlideshowSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSlideshowsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListEventSlideshowsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListEventSlideshowsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new EventSlideshowSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListEventSlideshowsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventSlideshowsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventSlideshowsToCSVInput {
    return this;
  }
}

export class ListEventsOutput {
  items: EventSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListEventsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListEventsOutput {
    if (!this.items) {
      this.items = [];
    }
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): ListEventsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new EventSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListEventsToCSVInput {
  organizationID: string;
  timezone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    timezone,
    paging,
  }: Partial<TypeWithFieldsOf<ListEventsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.timezone = timezone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListEventsToCSVInput {
    return this;
  }
}

export class ListFixedPriceItemPhotosInput {
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    fixedPriceItemID,
    paging,
  }: Partial<TypeWithFieldsOf<ListFixedPriceItemPhotosInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListFixedPriceItemPhotosInput {
    return this;
  }
}

export class ListFixedPriceItemPhotosOutput {
  items: FixedPriceItemPhotoSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListFixedPriceItemPhotosOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListFixedPriceItemPhotosOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListFixedPriceItemPhotosOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new FixedPriceItemPhotoSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListFixedPriceItemPhotosToCSVInput {
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    fixedPriceItemID,
    paging,
  }: Partial<TypeWithFieldsOf<ListFixedPriceItemPhotosToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListFixedPriceItemPhotosToCSVInput {
    return this;
  }
}

export class ListFixedPriceItemsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListFixedPriceItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListFixedPriceItemsInput {
    return this;
  }
}

export class ListFixedPriceItemsOutput {
  items: FixedPriceItemSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListFixedPriceItemsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListFixedPriceItemsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListFixedPriceItemsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new FixedPriceItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListFixedPriceItemsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListFixedPriceItemsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListFixedPriceItemsToCSVInput {
    return this;
  }
}

export class ListGuestSessionsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestSessionsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListGuestSessionsInput {
    return this;
  }
}

export class ListGuestSessionsOutput {
  items: GuestSessionSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestSessionsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListGuestSessionsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListGuestSessionsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListGuestSessionsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new GuestSessionSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListGuestSessionsToCSVInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestSessionsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListGuestSessionsToCSVInput {
    return this;
  }
}

export class ListGuestSessionSummariesForTicketsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<ListGuestSessionSummariesForTicketsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): ListGuestSessionSummariesForTicketsInput {
    return this;
  }
}

export class ListGuestSessionSummariesForTicketsOutput {
  items: ListGuestSessionSummary[];
  paging: (PagingOutputV3 | null);

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestSessionSummariesForTicketsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? null;
  }

  convertDates(): ListGuestSessionSummariesForTicketsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListGuestSessionSummariesForTicketsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListGuestSessionSummariesForTicketsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ListGuestSessionSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListGuestSessionSummariesInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestSessionSummariesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListGuestSessionSummariesInput {
    return this;
  }
}

export class ListGuestSessionSummariesOutput {
  items: ListGuestSessionSummary[];
  paging: (PagingOutputV3 | null);

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestSessionSummariesOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? null;
  }

  convertDates(): ListGuestSessionSummariesOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListGuestSessionSummariesOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListGuestSessionSummariesOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ListGuestSessionSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListGuestSessionSummary {
  guestSessionID: string;
  eventSessionID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  guestDisplayName: string;
  guestUserID: string;
  managingGuestID: string;
  isCheckInWithManagingGuest: boolean;
  bidderNumber: number;
  biddingAsGuestID: string;
  ticketID: string;
  ticketTypeName: string;
  ticketTypeID: string;
  ticketClaimedTime: (Date | null);
  ticketCreatedTime: (Date | null);
  ticketPurchaserFullName: string;
  ticketPurchaserFirstName: string;
  ticketPurchaserLastName: string;
  ticketPurchaserEmail: string;
  ticketManagerEmail: string;
  ticketManagerFirstName: string;
  ticketManagerLastName: string;
  ticketGroupID: string;
  checkedInTime: (Date | null);
  guestMobilePhone: string;
  sponsorAttachmentID: string;
  sponsorName: string;
  preEventQuestionnaireCompleteTime: (Date | null);
  attendeeExperience: string;

  constructor({
    guestSessionID,
    eventSessionID,
    guestID,
    firstName,
    lastName,
    email,
    guestDisplayName,
    guestUserID,
    managingGuestID,
    isCheckInWithManagingGuest,
    bidderNumber,
    biddingAsGuestID,
    ticketID,
    ticketTypeName,
    ticketTypeID,
    ticketClaimedTime,
    ticketCreatedTime,
    ticketPurchaserFullName,
    ticketPurchaserFirstName,
    ticketPurchaserLastName,
    ticketPurchaserEmail,
    ticketManagerEmail,
    ticketManagerFirstName,
    ticketManagerLastName,
    ticketGroupID,
    checkedInTime,
    guestMobilePhone,
    sponsorAttachmentID,
    sponsorName,
    preEventQuestionnaireCompleteTime,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<ListGuestSessionSummary>> = {}) {
    this.guestSessionID = guestSessionID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.guestDisplayName = guestDisplayName ?? "";
    this.guestUserID = guestUserID ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? false;
    this.bidderNumber = bidderNumber ?? 0;
    this.biddingAsGuestID = biddingAsGuestID ?? "";
    this.ticketID = ticketID ?? "";
    this.ticketTypeName = ticketTypeName ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.ticketClaimedTime = ticketClaimedTime ?? null;
    this.ticketCreatedTime = ticketCreatedTime ?? null;
    this.ticketPurchaserFullName = ticketPurchaserFullName ?? "";
    this.ticketPurchaserFirstName = ticketPurchaserFirstName ?? "";
    this.ticketPurchaserLastName = ticketPurchaserLastName ?? "";
    this.ticketPurchaserEmail = ticketPurchaserEmail ?? "";
    this.ticketManagerEmail = ticketManagerEmail ?? "";
    this.ticketManagerFirstName = ticketManagerFirstName ?? "";
    this.ticketManagerLastName = ticketManagerLastName ?? "";
    this.ticketGroupID = ticketGroupID ?? "";
    this.checkedInTime = checkedInTime ?? null;
    this.guestMobilePhone = guestMobilePhone ?? "";
    this.sponsorAttachmentID = sponsorAttachmentID ?? "";
    this.sponsorName = sponsorName ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.attendeeExperience = attendeeExperience ?? "";
  }

  convertDates(): ListGuestSessionSummary {
    if (this.ticketClaimedTime !== undefined && this.ticketClaimedTime !== null) {
      this.ticketClaimedTime = new Date(this.ticketClaimedTime!);
    }
    if (this.ticketCreatedTime !== undefined && this.ticketCreatedTime !== null) {
      this.ticketCreatedTime = new Date(this.ticketCreatedTime!);
    }
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): ListGuestSessionSummary {
    return this;
  }
}

export class ListGuestsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListGuestsInput {
    return this;
  }
}

export class ListGuestsOutput {
  items: GuestSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListGuestsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListGuestsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListGuestsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new GuestSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListGuestsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListGuestsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListGuestsToCSVInput {
    return this;
  }
}

export class ListImpactPhotosInput {
  organizationID: string;
  eventID: string;
  impactID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    impactID,
    paging,
  }: Partial<TypeWithFieldsOf<ListImpactPhotosInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListImpactPhotosInput {
    return this;
  }
}

export class ListImpactPhotosOutput {
  items: ImpactPhotoSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListImpactPhotosOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListImpactPhotosOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListImpactPhotosOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ImpactPhotoSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListImpactPhotosToCSVInput {
  organizationID: string;
  eventID: string;
  impactID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    impactID,
    paging,
  }: Partial<TypeWithFieldsOf<ListImpactPhotosToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListImpactPhotosToCSVInput {
    return this;
  }
}

export class ListImpactsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListImpactsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListImpactsInput {
    return this;
  }
}

export class ListImpactsOutput {
  items: ImpactSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListImpactsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListImpactsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListImpactsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListImpactsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ImpactSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListImpactsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListImpactsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListImpactsToCSVInput {
    return this;
  }
}

export class ListInvitationsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListInvitationsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListInvitationsInput {
    return this;
  }
}

export class ListInvitationsOutput {
  items: InvitationSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListInvitationsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListInvitationsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListInvitationsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListInvitationsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new InvitationSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListItemsForGuestInput {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
    paging,
  }: Partial<TypeWithFieldsOf<ListItemsForGuestInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.userID ? params.set('userID', this.userID.toString()) : params;
    params = this.guestID ? params.set('guestID', this.guestID.toString()) : params;
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListItemsForGuestInput {
    return this;
  }
}

export class ListItemsForGuestOutput {
  items: PurchasedItemSummary[];
  paging: (PagingOutputV3 | null);

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListItemsForGuestOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? null;
  }

  convertDates(): ListItemsForGuestOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListItemsForGuestOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListItemsForGuestOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new PurchasedItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListItemsForUserInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListItemsForUserInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListItemsForUserInput {
    return this;
  }
}

export class ListItemsForUserOutput {
  items: PurchasedItemSummary[];
  paging: (PagingOutputV3 | null);

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListItemsForUserOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? null;
  }

  convertDates(): ListItemsForUserOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListItemsForUserOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListItemsForUserOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new PurchasedItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListItemsForUserRefundInput {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
  }: Partial<TypeWithFieldsOf<ListItemsForUserRefundInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
  }

  fixClasses(): ListItemsForUserRefundInput {
    return this;
  }
}

export class ListItemsForUserRefundOutput {
  paid: ItemforPaidSummary[];
  pending: ItemforPendingSummary[];

  constructor({
    paid,
    pending,
  }: Partial<TypeWithFieldsOf<ListItemsForUserRefundOutput>> = {}) {
    this.paid = paid ?? [];
    this.pending = pending ?? [];
  }

  convertDates(): ListItemsForUserRefundOutput {
    if (!this.pending) {
      this.pending = [];
    }
    
    for (let i0 = 0; i0 < this.pending.length; i0 += 1) {
      this.pending[i0] = this.pending[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListItemsForUserRefundOutput {
    if (!this.paid) {
      this.paid = [];
    }
    if (!this.pending) {
      this.pending = [];
    }
    for (let i0 = 0; i0 < this.pending.length; i0 += 1) {
      this.pending[i0] = this.pending[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): ListItemsForUserRefundOutput {
    for (let i0 = 0; i0 < this.paid.length; i0 += 1) {
      this.paid[i0] = new ItemforPaidSummary(this.paid[i0]!).fixClasses();
    }
    for (let i0 = 0; i0 < this.pending.length; i0 += 1) {
      this.pending[i0] = new ItemforPendingSummary(this.pending[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListMyAvailableCreditCardsInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ListMyAvailableCreditCardsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ListMyAvailableCreditCardsInput {
    return this;
  }
}

export class ListMyAvailableCreditCardsOutput {
  items: GuestCreditCardSummary[];

  constructor({
    items,
  }: Partial<TypeWithFieldsOf<ListMyAvailableCreditCardsOutput>> = {}) {
    this.items = items ?? [];
  }

  convertUnexpectedNulls(): ListMyAvailableCreditCardsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListMyAvailableCreditCardsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new GuestCreditCardSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListMyGuestSessionSummariesInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    paging,
  }: Partial<TypeWithFieldsOf<ListMyGuestSessionSummariesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListMyGuestSessionSummariesInput {
    return this;
  }
}

export class ListMyGuestSessionSummariesOutput {
  items: ListGuestSessionSummary[];
  paging: (PagingOutputV3 | null);

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListMyGuestSessionSummariesOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? null;
  }

  convertDates(): ListMyGuestSessionSummariesOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListMyGuestSessionSummariesOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListMyGuestSessionSummariesOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ListGuestSessionSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListMyIncompleteGuestSessionIDsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<ListMyIncompleteGuestSessionIDsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): ListMyIncompleteGuestSessionIDsInput {
    return this;
  }
}

export class ListMyIncompleteGuestSessionIDsOutput {
  incompleteGuestSessionIDs: string[];

  constructor({
    incompleteGuestSessionIDs,
  }: Partial<TypeWithFieldsOf<ListMyIncompleteGuestSessionIDsOutput>> = {}) {
    this.incompleteGuestSessionIDs = incompleteGuestSessionIDs ?? [];
  }

  convertUnexpectedNulls(): ListMyIncompleteGuestSessionIDsOutput {
    if (!this.incompleteGuestSessionIDs) {
      this.incompleteGuestSessionIDs = [];
    }
    return this;
  }

  fixClasses(): ListMyIncompleteGuestSessionIDsOutput {
    return this;
  }
}

export class ListMyTicketsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListMyTicketsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListMyTicketsInput {
    return this;
  }
}

export class ListMyTicketsOutput {
  items: TicketSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListMyTicketsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListMyTicketsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListMyTicketsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListMyTicketsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new TicketSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListOverlaysInput {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    paging,
  }: Partial<TypeWithFieldsOf<ListOverlaysInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListOverlaysInput {
    return this;
  }
}

export class ListOverlaysOutput {
  items: OverlaySummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListOverlaysOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListOverlaysOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListOverlaysOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new OverlaySummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListOverlaysToCSVInput {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    paging,
  }: Partial<TypeWithFieldsOf<ListOverlaysToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListOverlaysToCSVInput {
    return this;
  }
}

export class ListPickupItemsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPickupItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPickupItemsInput {
    return this;
  }
}

export class ListPickupItemsOutput {
  items: PickupItemSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListPickupItemsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListPickupItemsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListPickupItemsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListPickupItemsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new PickupItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListPickupItemsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPickupItemsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPickupItemsToCSVInput {
    return this;
  }
}

export class ListPreEventQuestionConfigsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPreEventQuestionConfigsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPreEventQuestionConfigsInput {
    return this;
  }
}

export class ListPreEventQuestionConfigsOutput {
  items: PreEventQuestionConfigSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListPreEventQuestionConfigsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListPreEventQuestionConfigsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListPreEventQuestionConfigsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new PreEventQuestionConfigSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListPreEventQuestionConfigsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPreEventQuestionConfigsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPreEventQuestionConfigsToCSVInput {
    return this;
  }
}

export class ListPurchasedItemSalesTaxesInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPurchasedItemSalesTaxesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPurchasedItemSalesTaxesInput {
    return this;
  }
}

export class ListPurchasedItemSalesTaxesOutput {
  items: PurchasedItemSalesTaxSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListPurchasedItemSalesTaxesOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListPurchasedItemSalesTaxesOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListPurchasedItemSalesTaxesOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListPurchasedItemSalesTaxesOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new PurchasedItemSalesTaxSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListPurchasedItemSalesTaxesToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPurchasedItemSalesTaxesToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPurchasedItemSalesTaxesToCSVInput {
    return this;
  }
}

export class ListPurchasedItemsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPurchasedItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPurchasedItemsInput {
    return this;
  }
}

export class ListPurchasedItemsOutput {
  items: PurchasedItemSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListPurchasedItemsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListPurchasedItemsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListPurchasedItemsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListPurchasedItemsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new PurchasedItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListPurchasedItemsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListPurchasedItemsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListPurchasedItemsToCSVInput {
    return this;
  }
}

export class ListRafflesInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListRafflesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListRafflesInput {
    return this;
  }
}

export class ListRafflesOutput {
  raffles: RaffleSummary[];
  paging: PagingOutputV3;

  constructor({
    raffles,
    paging,
  }: Partial<TypeWithFieldsOf<ListRafflesOutput>> = {}) {
    this.raffles = raffles ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListRafflesOutput {
    if (!this.raffles) {
      this.raffles = [];
    }
    
    for (let i0 = 0; i0 < this.raffles.length; i0 += 1) {
      this.raffles[i0] = this.raffles[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListRafflesOutput {
    if (!this.raffles) {
      this.raffles = [];
    }
    return this;
  }

  fixClasses(): ListRafflesOutput {
    for (let i0 = 0; i0 < this.raffles.length; i0 += 1) {
      this.raffles[i0] = new RaffleSummary(this.raffles[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListRegistrationsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListRegistrationsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListRegistrationsInput {
    return this;
  }
}

export class ListRegistrationsOutput {
  items: RegistrationSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListRegistrationsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListRegistrationsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListRegistrationsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new RegistrationSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListRegistrationsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListRegistrationsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListRegistrationsToCSVInput {
    return this;
  }
}

export class ListScenesInput {
  organizationID: string;
  eventID: string;
  showID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    showID,
    paging,
  }: Partial<TypeWithFieldsOf<ListScenesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListScenesInput {
    return this;
  }
}

export class ListScenesOutput {
  items: SceneSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListScenesOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListScenesOutput {
    if (!this.items) {
      this.items = [];
    }
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): ListScenesOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new SceneSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListScenesToCSVInput {
  organizationID: string;
  eventID: string;
  showID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    showID,
    paging,
  }: Partial<TypeWithFieldsOf<ListScenesToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListScenesToCSVInput {
    return this;
  }
}

export class ListShowsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListShowsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListShowsInput {
    return this;
  }
}

export class ListShowsOutput {
  items: ShowSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListShowsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ListShowsOutput {
    if (!this.items) {
      this.items = [];
    }
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): ListShowsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ShowSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListShowsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListShowsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListShowsToCSVInput {
    return this;
  }
}

export class ListSpotterToolDonationsInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ListSpotterToolDonationsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ListSpotterToolDonationsInput {
    return this;
  }
}

export class ListSpotterToolDonationsOutput {
  items: SpotterToolDonationSummary[];

  constructor({
    items,
  }: Partial<TypeWithFieldsOf<ListSpotterToolDonationsOutput>> = {}) {
    this.items = items ?? [];
  }

  convertUnexpectedNulls(): ListSpotterToolDonationsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListSpotterToolDonationsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new SpotterToolDonationSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListVirtualChairsInput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    paging,
  }: Partial<TypeWithFieldsOf<ListVirtualChairsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListVirtualChairsInput {
    return this;
  }
}

export class ListVirtualChairsOutput {
  items: VirtualChairSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListVirtualChairsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListVirtualChairsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListVirtualChairsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListVirtualChairsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new VirtualChairSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListVirtualChairsToCSVInput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    paging,
  }: Partial<TypeWithFieldsOf<ListVirtualChairsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListVirtualChairsToCSVInput {
    return this;
  }
}

export class ListVirtualTablesInput {
  organizationID: string;
  virtualEventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListVirtualTablesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListVirtualTablesInput {
    return this;
  }
}

export class ListVirtualTablesOutput {
  items: VirtualTableSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ListVirtualTablesOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ListVirtualTablesOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ListVirtualTablesOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ListVirtualTablesOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new VirtualTableSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ListVirtualTablesToCSVInput {
  organizationID: string;
  virtualEventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    paging,
  }: Partial<TypeWithFieldsOf<ListVirtualTablesToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ListVirtualTablesToCSVInput {
    return this;
  }
}

export class LotListWithWinnerInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    auctionID,
    paging,
  }: Partial<TypeWithFieldsOf<LotListWithWinnerInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): LotListWithWinnerInput {
    return this;
  }
}

export class LotListWithWinnerOutput {
  items: LotSummaryWithWinner[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<LotListWithWinnerOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): LotListWithWinnerOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): LotListWithWinnerOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): LotListWithWinnerOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new LotSummaryWithWinner(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class LotSummaryWithWinner {
  organizationID: string;
  auctionID: string;
  id: string;
  lotNumber: string;
  lotOrder: number;
  bidMinimumCents: number;
  bidIncrementCents: number;
  buyNowPriceCents: number;
  isBuyNowActive: boolean;
  isBought: boolean;
  isBiddingPaused: boolean;
  openTime: (Date | null);
  closeTime: (Date | null);
  winningBidID: string;
  paidByPurchaseID: string;
  name: string;
  description: string;
  showInPreview: boolean;
  topBidID: string;
  topBidAmountCents: number;
  primaryPhotoURL: string;
  winner: string;

  constructor({
    organizationID,
    auctionID,
    id,
    lotNumber,
    lotOrder,
    bidMinimumCents,
    bidIncrementCents,
    buyNowPriceCents,
    isBuyNowActive,
    isBought,
    isBiddingPaused,
    openTime,
    closeTime,
    winningBidID,
    paidByPurchaseID,
    name,
    description,
    showInPreview,
    topBidID,
    topBidAmountCents,
    primaryPhotoURL,
    winner,
  }: Partial<TypeWithFieldsOf<LotSummaryWithWinner>> = {}) {
    this.organizationID = organizationID ?? "";
    this.auctionID = auctionID ?? "";
    this.id = id ?? "";
    this.lotNumber = lotNumber ?? "";
    this.lotOrder = lotOrder ?? 0;
    this.bidMinimumCents = bidMinimumCents ?? 0;
    this.bidIncrementCents = bidIncrementCents ?? 0;
    this.buyNowPriceCents = buyNowPriceCents ?? 0;
    this.isBuyNowActive = isBuyNowActive ?? false;
    this.isBought = isBought ?? false;
    this.isBiddingPaused = isBiddingPaused ?? false;
    this.openTime = openTime ?? null;
    this.closeTime = closeTime ?? null;
    this.winningBidID = winningBidID ?? "";
    this.paidByPurchaseID = paidByPurchaseID ?? "";
    this.name = name ?? "";
    this.description = description ?? "";
    this.showInPreview = showInPreview ?? false;
    this.topBidID = topBidID ?? "";
    this.topBidAmountCents = topBidAmountCents ?? 0;
    this.primaryPhotoURL = primaryPhotoURL ?? "";
    this.winner = winner ?? "";
  }

  convertDates(): LotSummaryWithWinner {
    if (this.openTime !== undefined && this.openTime !== null) {
      this.openTime = new Date(this.openTime!);
    }
    if (this.closeTime !== undefined && this.closeTime !== null) {
      this.closeTime = new Date(this.closeTime!);
    }
    return this;
  }

  fixClasses(): LotSummaryWithWinner {
    return this;
  }
}

export class MySupporterPurchasedItemsDetailsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<MySupporterPurchasedItemsDetailsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): MySupporterPurchasedItemsDetailsInput {
    return this;
  }
}

export class MySupporterPurchasedItemsDetailsOutput {
  items: ViewSupporterPurchasedItemDetailSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<MySupporterPurchasedItemsDetailsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): MySupporterPurchasedItemsDetailsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): MySupporterPurchasedItemsDetailsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): MySupporterPurchasedItemsDetailsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewSupporterPurchasedItemDetailSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class NoOrgListEventsInput {
  organizationID: string;
  timezone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    timezone,
    paging,
  }: Partial<TypeWithFieldsOf<NoOrgListEventsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.timezone = timezone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): NoOrgListEventsInput {
    return this;
  }
}

export class NoOrgListEventsOutput {
  items: EventSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<NoOrgListEventsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): NoOrgListEventsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): NoOrgListEventsOutput {
    if (!this.items) {
      this.items = [];
    }
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): NoOrgListEventsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new EventSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class NotifyLotUpdatedInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
  }: Partial<TypeWithFieldsOf<NotifyLotUpdatedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
  }

  fixClasses(): NotifyLotUpdatedInput {
    return this;
  }
}

export class NotifyLotUpdatedOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<NotifyLotUpdatedOutput>> = {}) {
  }

  fixClasses(): NotifyLotUpdatedOutput {
    return this;
  }
}

export class OpenBiddingInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
  }: Partial<TypeWithFieldsOf<OpenBiddingInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
  }

  fixClasses(): OpenBiddingInput {
    return this;
  }
}

export class OpenBiddingOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<OpenBiddingOutput>> = {}) {
  }

  fixClasses(): OpenBiddingOutput {
    return this;
  }
}

export class OpenCheckoutNotificationInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<OpenCheckoutNotificationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): OpenCheckoutNotificationInput {
    return this;
  }
}

export class OpenCheckoutNotificationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<OpenCheckoutNotificationOutput>> = {}) {
  }

  fixClasses(): OpenCheckoutNotificationOutput {
    return this;
  }
}

export class OpenLotAndAssignWinnerInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;
  auctionType: string;
  bidderID: string;
  bidderNumber: number;
  bidTime: Date;
  bidAmountCents: number;
  guestID: string;
  userID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
    auctionType,
    bidderID,
    bidderNumber,
    bidTime,
    bidAmountCents,
    guestID,
    userID,
  }: Partial<TypeWithFieldsOf<OpenLotAndAssignWinnerInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.auctionType = auctionType ?? "";
    this.bidderID = bidderID ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.bidTime = bidTime ?? new Date();
    this.bidAmountCents = bidAmountCents ?? 0;
    this.guestID = guestID ?? "";
    this.userID = userID ?? "";
  }

  convertDates(): OpenLotAndAssignWinnerInput {
    this.bidTime = new Date(this.bidTime!);
    return this;
  }

  fixClasses(): OpenLotAndAssignWinnerInput {
    return this;
  }
}

export class OpenLotAndAssignWinnerOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<OpenLotAndAssignWinnerOutput>> = {}) {
  }

  fixClasses(): OpenLotAndAssignWinnerOutput {
    return this;
  }
}

export class OverlayConfiguration {
  showNone: boolean;
  overlayIDs: string[];

  constructor({
    showNone,
    overlayIDs,
  }: Partial<TypeWithFieldsOf<OverlayConfiguration>> = {}) {
    this.showNone = showNone ?? false;
    this.overlayIDs = overlayIDs ?? [];
  }

  convertUnexpectedNulls(): OverlayConfiguration {
    if (!this.overlayIDs) {
      this.overlayIDs = [];
    }
    return this;
  }

  fixClasses(): OverlayConfiguration {
    return this;
  }
}

export class OverlaySummary {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  id: string;
  name: string;
  type: string;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    id,
    name,
    type,
  }: Partial<TypeWithFieldsOf<OverlaySummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.type = type ?? "";
  }

  fixClasses(): OverlaySummary {
    return this;
  }
}

export class PauseBiddingInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;
  isBiddingPaused: boolean;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
    isBiddingPaused,
  }: Partial<TypeWithFieldsOf<PauseBiddingInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.isBiddingPaused = isBiddingPaused ?? false;
  }

  fixClasses(): PauseBiddingInput {
    return this;
  }
}

export class PauseBiddingOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<PauseBiddingOutput>> = {}) {
  }

  fixClasses(): PauseBiddingOutput {
    return this;
  }
}

export class PayForAttendeeItemsInput {
  organizationID: string;
  eventID: string;
  attendeeUserID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  itemIDs: string[];
  paymentTotalAmountCents: number;
  cardID: string;
  cardHolderGuestID: string;
  paymentType: string;
  paymentTypeOtherNotes: string;
  checkNumber: string;
  cardName: string;
  expiration: string;
  cardType: string;
  cardLastFour: string;
  token: string;
  saveCardOnFile: boolean;
  coverFee: boolean;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  payerState: string;
  payerZip: string;
  payerCountry: string;
  fakeTransactionResult: string;

  constructor({
    organizationID,
    eventID,
    attendeeUserID,
    guestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    itemIDs,
    paymentTotalAmountCents,
    cardID,
    cardHolderGuestID,
    paymentType,
    paymentTypeOtherNotes,
    checkNumber,
    cardName,
    expiration,
    cardType,
    cardLastFour,
    token,
    saveCardOnFile,
    coverFee,
    payerName,
    payerStreet,
    payerCity,
    payerState,
    payerZip,
    payerCountry,
    fakeTransactionResult,
  }: Partial<TypeWithFieldsOf<PayForAttendeeItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.attendeeUserID = attendeeUserID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.itemIDs = itemIDs ?? [];
    this.paymentTotalAmountCents = paymentTotalAmountCents ?? 0;
    this.cardID = cardID ?? "";
    this.cardHolderGuestID = cardHolderGuestID ?? "";
    this.paymentType = paymentType ?? "";
    this.paymentTypeOtherNotes = paymentTypeOtherNotes ?? "";
    this.checkNumber = checkNumber ?? "";
    this.cardName = cardName ?? "";
    this.expiration = expiration ?? "";
    this.cardType = cardType ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.token = token ?? "";
    this.saveCardOnFile = saveCardOnFile ?? false;
    this.coverFee = coverFee ?? false;
    this.payerName = payerName ?? "";
    this.payerStreet = payerStreet ?? "";
    this.payerCity = payerCity ?? "";
    this.payerState = payerState ?? "";
    this.payerZip = payerZip ?? "";
    this.payerCountry = payerCountry ?? "";
    this.fakeTransactionResult = fakeTransactionResult ?? "";
  }

  convertUnexpectedNulls(): PayForAttendeeItemsInput {
    if (!this.itemIDs) {
      this.itemIDs = [];
    }
    return this;
  }

  fixClasses(): PayForAttendeeItemsInput {
    return this;
  }
}

export class PayForAttendeeItemsOutput {
  transactionID: string;

  constructor({
    transactionID,
  }: Partial<TypeWithFieldsOf<PayForAttendeeItemsOutput>> = {}) {
    this.transactionID = transactionID ?? "";
  }

  fixClasses(): PayForAttendeeItemsOutput {
    return this;
  }
}

export class PayForItemsInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  itemIDs: string[];
  cardID: string;
  cardName: string;
  expiration: string;
  cardType: string;
  cardLastFour: string;
  token: string;
  saveCardOnFile: boolean;
  coverFee: boolean;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  payerState: string;
  payerZip: string;
  payerCountry: string;
  fakeTransactionResult: string;
  paymentType: string;
  attendeeUserID: string;
  submittedByUserID: string;
  paymentTypeOtherNotes: string;
  checkNumber: string;
  cardHolderGuestID: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    itemIDs,
    cardID,
    cardName,
    expiration,
    cardType,
    cardLastFour,
    token,
    saveCardOnFile,
    coverFee,
    payerName,
    payerStreet,
    payerCity,
    payerState,
    payerZip,
    payerCountry,
    fakeTransactionResult,
    paymentType,
    attendeeUserID,
    submittedByUserID,
    paymentTypeOtherNotes,
    checkNumber,
    cardHolderGuestID,
  }: Partial<TypeWithFieldsOf<PayForItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.itemIDs = itemIDs ?? [];
    this.cardID = cardID ?? "";
    this.cardName = cardName ?? "";
    this.expiration = expiration ?? "";
    this.cardType = cardType ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.token = token ?? "";
    this.saveCardOnFile = saveCardOnFile ?? false;
    this.coverFee = coverFee ?? false;
    this.payerName = payerName ?? "";
    this.payerStreet = payerStreet ?? "";
    this.payerCity = payerCity ?? "";
    this.payerState = payerState ?? "";
    this.payerZip = payerZip ?? "";
    this.payerCountry = payerCountry ?? "";
    this.fakeTransactionResult = fakeTransactionResult ?? "";
    this.paymentType = paymentType ?? "";
    this.attendeeUserID = attendeeUserID ?? "";
    this.submittedByUserID = submittedByUserID ?? "";
    this.paymentTypeOtherNotes = paymentTypeOtherNotes ?? "";
    this.checkNumber = checkNumber ?? "";
    this.cardHolderGuestID = cardHolderGuestID ?? "";
  }

  convertUnexpectedNulls(): PayForItemsInput {
    if (!this.itemIDs) {
      this.itemIDs = [];
    }
    return this;
  }

  fixClasses(): PayForItemsInput {
    return this;
  }
}

export class PayForItemsOutput {
  transactionID: string;

  constructor({
    transactionID,
  }: Partial<TypeWithFieldsOf<PayForItemsOutput>> = {}) {
    this.transactionID = transactionID ?? "";
  }

  fixClasses(): PayForItemsOutput {
    return this;
  }
}

export class PickupItemOptionsOutput {
  itemID: string;
  isPickupTonight: boolean;
  isPickupLater: boolean;

  constructor({
    itemID,
    isPickupTonight,
    isPickupLater,
  }: Partial<TypeWithFieldsOf<PickupItemOptionsOutput>> = {}) {
    this.itemID = itemID ?? "";
    this.isPickupTonight = isPickupTonight ?? false;
    this.isPickupLater = isPickupLater ?? false;
  }

  fixClasses(): PickupItemOptionsOutput {
    return this;
  }
}

export class PickupItems {
  fixedPriceItemID: string;
  pickupPreference: string;
  pickupTimeSlot: (Date | null);
  itemID: string;
  lotID: string;
  allocationID: string;

  constructor({
    fixedPriceItemID,
    pickupPreference,
    pickupTimeSlot,
    itemID,
    lotID,
    allocationID,
  }: Partial<TypeWithFieldsOf<PickupItems>> = {}) {
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.pickupPreference = pickupPreference ?? "";
    this.pickupTimeSlot = pickupTimeSlot ?? null;
    this.itemID = itemID ?? "";
    this.lotID = lotID ?? "";
    this.allocationID = allocationID ?? "";
  }

  convertDates(): PickupItems {
    if (this.pickupTimeSlot !== undefined && this.pickupTimeSlot !== null) {
      this.pickupTimeSlot = new Date(this.pickupTimeSlot!);
    }
    return this;
  }

  fixClasses(): PickupItems {
    return this;
  }
}

export class PickupItemSummary {
  organizationID: string;
  eventID: string;
  id: string;
  userID: string;
  guestID: string;
  transactionID: string;
  pickupTimeSlot: (Date | null);
  isPickedUp: boolean;
  isReadyPickUp: boolean;
  pickedUpByGuestID: string;
  pickupPreference: string;
  purchasedItemID: string;
  itemID: string;
  inventoryLotID: string;
  inventoryAllocationID: string;
  fixedPriceItemID: string;

  constructor({
    organizationID,
    eventID,
    id,
    userID,
    guestID,
    transactionID,
    pickupTimeSlot,
    isPickedUp,
    isReadyPickUp,
    pickedUpByGuestID,
    pickupPreference,
    purchasedItemID,
    itemID,
    inventoryLotID,
    inventoryAllocationID,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<PickupItemSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.transactionID = transactionID ?? "";
    this.pickupTimeSlot = pickupTimeSlot ?? null;
    this.isPickedUp = isPickedUp ?? false;
    this.isReadyPickUp = isReadyPickUp ?? false;
    this.pickedUpByGuestID = pickedUpByGuestID ?? "";
    this.pickupPreference = pickupPreference ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
    this.itemID = itemID ?? "";
    this.inventoryLotID = inventoryLotID ?? "";
    this.inventoryAllocationID = inventoryAllocationID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  convertDates(): PickupItemSummary {
    if (this.pickupTimeSlot !== undefined && this.pickupTimeSlot !== null) {
      this.pickupTimeSlot = new Date(this.pickupTimeSlot!);
    }
    return this;
  }

  fixClasses(): PickupItemSummary {
    return this;
  }
}

export class PickupTimeSlot {
  pickupTimeSlot: Date;
  selectedPickupTimeSlotCount: number;

  constructor({
    pickupTimeSlot,
    selectedPickupTimeSlotCount,
  }: Partial<TypeWithFieldsOf<PickupTimeSlot>> = {}) {
    this.pickupTimeSlot = pickupTimeSlot ?? new Date();
    this.selectedPickupTimeSlotCount = selectedPickupTimeSlotCount ?? 0;
  }

  convertDates(): PickupTimeSlot {
    this.pickupTimeSlot = new Date(this.pickupTimeSlot!);
    return this;
  }

  fixClasses(): PickupTimeSlot {
    return this;
  }
}

export class PlaceBidInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;
  auctionType: string;
  bidderID: string;
  bidderNumber: number;
  bidTime: Date;
  bidAmountCents: number;
  guestID: string;
  userID: string;
  floorBid: boolean;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
    auctionType,
    bidderID,
    bidderNumber,
    bidTime,
    bidAmountCents,
    guestID,
    userID,
    floorBid,
  }: Partial<TypeWithFieldsOf<PlaceBidInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.auctionType = auctionType ?? "";
    this.bidderID = bidderID ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.bidTime = bidTime ?? new Date();
    this.bidAmountCents = bidAmountCents ?? 0;
    this.guestID = guestID ?? "";
    this.userID = userID ?? "";
    this.floorBid = floorBid ?? false;
  }

  convertDates(): PlaceBidInput {
    this.bidTime = new Date(this.bidTime!);
    return this;
  }

  fixClasses(): PlaceBidInput {
    return this;
  }
}

export class PlaceBidOutput {
  bidID: string;
  bidderUserID: string;
  topBidID: string;
  topBidderGuestID: string;
  topBidderUserID: string;
  topBidAmountCents: number;
  floorBid: boolean;

  constructor({
    bidID,
    bidderUserID,
    topBidID,
    topBidderGuestID,
    topBidderUserID,
    topBidAmountCents,
    floorBid,
  }: Partial<TypeWithFieldsOf<PlaceBidOutput>> = {}) {
    this.bidID = bidID ?? "";
    this.bidderUserID = bidderUserID ?? "";
    this.topBidID = topBidID ?? "";
    this.topBidderGuestID = topBidderGuestID ?? "";
    this.topBidderUserID = topBidderUserID ?? "";
    this.topBidAmountCents = topBidAmountCents ?? 0;
    this.floorBid = floorBid ?? false;
  }

  fixClasses(): PlaceBidOutput {
    return this;
  }
}

export class PreEventQuestionConfigSummary {
  organizationID: string;
  eventID: string;
  id: string;
  questionOnePrompt: string;
  questionOneActive: boolean;
  questionTwoPrompt: string;
  questionTwoActive: boolean;
  questionThreePrompt: string;
  questionThreeActive: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    questionOnePrompt,
    questionOneActive,
    questionTwoPrompt,
    questionTwoActive,
    questionThreePrompt,
    questionThreeActive,
  }: Partial<TypeWithFieldsOf<PreEventQuestionConfigSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.questionOnePrompt = questionOnePrompt ?? "";
    this.questionOneActive = questionOneActive ?? false;
    this.questionTwoPrompt = questionTwoPrompt ?? "";
    this.questionTwoActive = questionTwoActive ?? false;
    this.questionThreePrompt = questionThreePrompt ?? "";
    this.questionThreeActive = questionThreeActive ?? false;
  }

  fixClasses(): PreEventQuestionConfigSummary {
    return this;
  }
}

export class PurchasedItemSalesTaxSummary {
  organizationID: string;
  eventID: string;
  id: string;
  created: Date;
  userID: string;
  guestID: string;
  amountCents: number;
  salesTaxPercent: number;
  payAfterEvent: boolean;
  purchaseID: string;
  purchasedItemID: string;
  transactionStatus: string;
  transactionID: string;
  refundID: string;
  overriddenByUserID: string;
  proxyUserID: string;
  proxyGuestID: string;

  constructor({
    organizationID,
    eventID,
    id,
    created,
    userID,
    guestID,
    amountCents,
    salesTaxPercent,
    payAfterEvent,
    purchaseID,
    purchasedItemID,
    transactionStatus,
    transactionID,
    refundID,
    overriddenByUserID,
    proxyUserID,
    proxyGuestID,
  }: Partial<TypeWithFieldsOf<PurchasedItemSalesTaxSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.created = created ?? new Date();
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.amountCents = amountCents ?? 0;
    this.salesTaxPercent = salesTaxPercent ?? 0;
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.refundID = refundID ?? "";
    this.overriddenByUserID = overriddenByUserID ?? "";
    this.proxyUserID = proxyUserID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
  }

  convertDates(): PurchasedItemSalesTaxSummary {
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): PurchasedItemSalesTaxSummary {
    return this;
  }
}

export class PurchasedItemSummary {
  organizationID: string;
  eventID: string;
  id: string;
  created: Date;
  firstName: string;
  lastName: string;
  userID: string;
  guestID: string;
  email: string;
  mobilePhone: string;
  amountCents: number;
  type: string;
  impactID: string;
  auctionID: string;
  lotID: string;
  ticketID: string;
  fixedPriceItemID: string;
  raffleDetails: RafflePurchaseDetails;
  payAfterEvent: boolean;
  purchaseID: string;
  transactionStatus: string;
  transactionID: string;
  refundID: string;
  itemDetails: ItemPurchaseDetails;
  isExternal: boolean;
  isAnonymous: boolean;
  overriddenByUserID: string;
  proxyUserID: string;
  proxyGuestID: string;
  paidByGuestID: string;
  purchasedByDisplayName: string;
  paidByDisplayName: string;

  constructor({
    organizationID,
    eventID,
    id,
    created,
    firstName,
    lastName,
    userID,
    guestID,
    email,
    mobilePhone,
    amountCents,
    type,
    impactID,
    auctionID,
    lotID,
    ticketID,
    fixedPriceItemID,
    raffleDetails,
    payAfterEvent,
    purchaseID,
    transactionStatus,
    transactionID,
    refundID,
    itemDetails,
    isExternal,
    isAnonymous,
    overriddenByUserID,
    proxyUserID,
    proxyGuestID,
    paidByGuestID,
    purchasedByDisplayName,
    paidByDisplayName,
  }: Partial<TypeWithFieldsOf<PurchasedItemSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.created = created ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.amountCents = amountCents ?? 0;
    this.type = type ?? "";
    this.impactID = impactID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.ticketID = ticketID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.raffleDetails = raffleDetails ?? new RafflePurchaseDetails();
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.refundID = refundID ?? "";
    this.itemDetails = itemDetails ?? new ItemPurchaseDetails();
    this.isExternal = isExternal ?? false;
    this.isAnonymous = isAnonymous ?? false;
    this.overriddenByUserID = overriddenByUserID ?? "";
    this.proxyUserID = proxyUserID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
    this.paidByGuestID = paidByGuestID ?? "";
    this.purchasedByDisplayName = purchasedByDisplayName ?? "";
    this.paidByDisplayName = paidByDisplayName ?? "";
  }

  convertDates(): PurchasedItemSummary {
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): PurchasedItemSummary {
    this.raffleDetails = new RafflePurchaseDetails(this.raffleDetails!).fixClasses();
    this.itemDetails = new ItemPurchaseDetails(this.itemDetails!).fixClasses();
    return this;
  }
}

export class RafflePurchaseDetails {
  raffleID: string;
  purchaseLevelID: string;
  quantityTickets: number;
  raffleName: string;
  batchID: string;

  constructor({
    raffleID,
    purchaseLevelID,
    quantityTickets,
    raffleName,
    batchID,
  }: Partial<TypeWithFieldsOf<RafflePurchaseDetails>> = {}) {
    this.raffleID = raffleID ?? "";
    this.purchaseLevelID = purchaseLevelID ?? "";
    this.quantityTickets = quantityTickets ?? 0;
    this.raffleName = raffleName ?? "";
    this.batchID = batchID ?? "";
  }

  fixClasses(): RafflePurchaseDetails {
    return this;
  }
}

export class RaffleSummary {
  organizationID: string;
  id: string;
  name: string;
  description: string;
  startDate: (Date | null);
  endDate: (Date | null);
  hasSoldOut: boolean;
  maxTicketsCount: number;
  totalTicketsCount: number;
  totalTicketsAmountCents: number;

  constructor({
    organizationID,
    id,
    name,
    description,
    startDate,
    endDate,
    hasSoldOut,
    maxTicketsCount,
    totalTicketsCount,
    totalTicketsAmountCents,
  }: Partial<TypeWithFieldsOf<RaffleSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.description = description ?? "";
    this.startDate = startDate ?? null;
    this.endDate = endDate ?? null;
    this.hasSoldOut = hasSoldOut ?? false;
    this.maxTicketsCount = maxTicketsCount ?? 0;
    this.totalTicketsCount = totalTicketsCount ?? 0;
    this.totalTicketsAmountCents = totalTicketsAmountCents ?? 0;
  }

  convertDates(): RaffleSummary {
    if (this.startDate !== undefined && this.startDate !== null) {
      this.startDate = new Date(this.startDate!);
    }
    if (this.endDate !== undefined && this.endDate !== null) {
      this.endDate = new Date(this.endDate!);
    }
    return this;
  }

  fixClasses(): RaffleSummary {
    return this;
  }
}

export class RaiseVirtualEventProcessPendingPurchasedItemsInput {
  organizationID: string;
  eventID: string;
  fakeTransactionResult: string;

  constructor({
    organizationID,
    eventID,
    fakeTransactionResult,
  }: Partial<TypeWithFieldsOf<RaiseVirtualEventProcessPendingPurchasedItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fakeTransactionResult = fakeTransactionResult ?? "";
  }

  fixClasses(): RaiseVirtualEventProcessPendingPurchasedItemsInput {
    return this;
  }
}

export class RaiseVirtualEventProcessPendingPurchasedItemsOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<RaiseVirtualEventProcessPendingPurchasedItemsOutput>> = {}) {
  }

  fixClasses(): RaiseVirtualEventProcessPendingPurchasedItemsOutput {
    return this;
  }
}

export class ReadDefaultInvitationTemplateInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadDefaultInvitationTemplateInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadDefaultInvitationTemplateInput {
    return this;
  }
}

export class ReadDefaultInvitationTemplateOutput {
  templateMarkdown: string;

  constructor({
    templateMarkdown,
  }: Partial<TypeWithFieldsOf<ReadDefaultInvitationTemplateOutput>> = {}) {
    this.templateMarkdown = templateMarkdown ?? "";
  }

  fixClasses(): ReadDefaultInvitationTemplateOutput {
    return this;
  }
}

export class ReadDonationMomentInput {
  donationMomentID: string;
  organizationID: string;
  eventID: string;

  constructor({
    donationMomentID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadDonationMomentInput>> = {}) {
    this.donationMomentID = donationMomentID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadDonationMomentInput {
    return this;
  }
}

export class ReadDonationMomentOutput {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  type: string;
  donationLevelsAmountCents: number[];
  currentDonationTierAmountCents: number;
  disableOnlineDonations: boolean;
  giveAtAnyLevelEnabled: boolean;
  giveAtAnyLevelActive: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    type,
    donationLevelsAmountCents,
    currentDonationTierAmountCents,
    disableOnlineDonations,
    giveAtAnyLevelEnabled,
    giveAtAnyLevelActive,
  }: Partial<TypeWithFieldsOf<ReadDonationMomentOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.type = type ?? "";
    this.donationLevelsAmountCents = donationLevelsAmountCents ?? [];
    this.currentDonationTierAmountCents = currentDonationTierAmountCents ?? 0;
    this.disableOnlineDonations = disableOnlineDonations ?? false;
    this.giveAtAnyLevelEnabled = giveAtAnyLevelEnabled ?? false;
    this.giveAtAnyLevelActive = giveAtAnyLevelActive ?? false;
  }

  convertUnexpectedNulls(): ReadDonationMomentOutput {
    if (!this.donationLevelsAmountCents) {
      this.donationLevelsAmountCents = [];
    }
    return this;
  }

  fixClasses(): ReadDonationMomentOutput {
    return this;
  }
}

export class ReadEventInput {
  eventID: string;
  organizationID: string;
  timezone: string;

  constructor({
    eventID,
    organizationID,
    timezone,
  }: Partial<TypeWithFieldsOf<ReadEventInput>> = {}) {
    this.eventID = eventID ?? "";
    this.organizationID = organizationID ?? "";
    this.timezone = timezone ?? "";
  }

  fixClasses(): ReadEventInput {
    return this;
  }
}

export class ReadEventOutput {
  organizationID: string;
  id: string;
  name: string;
  descriptionMarkdown: string;
  eventType: string;
  impactBlurb: string;
  registrationBlurb: string;
  primaryDate: (Date | null);
  preEventScheduledNotificationID: string;
  preEventQuestionnaireNotificationID: string;
  primaryEndDate: (Date | null);
  timezone: string;
  storyMarkdown: string;
  shortURL: string;
  currencyCode: string;
  totalDonationsCount: number;
  totalDonationsAmountCents: number;
  totalAppealAmountCents: number;
  totalRegistrationsCount: number;
  goalTotalDonationsAmountCents: number;
  goalTotalDonationsAmountCentsMetOn: (Date | null);
  goalTotalDonationsSeedAmountCents: number;
  goalTrackerImageURL: string;
  goalTrackerImageUploadToken: string;
  donationSuggestedAmountsCents: number[];
  donationSuggestedAmountsShow: boolean;
  themePrimaryColor: string;
  themeSecondaryColor: string;
  bannerImageURL: string;
  bannerImageUploadToken: string;
  logoImageURL: string;
  logoImageUploadToken: string;
  registrationThankYouMessageMarkdown: string;
  registrationConfirmationMessageMarkdown: string;
  donationThankYouMessageMarkdown: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  merchantAccountID: string;
  showBillingAddress: boolean;
  requireBillingAddress: boolean;
  isCoverCostActive: boolean;
  coverCostPercent: number;
  coverCostCustomMessage: string;
  coverCostDefault: boolean;
  tags: string[];
  impactOrderIDs: string[];
  fixedPriceOrderIDs: string[];
  activeRaffleID: string;
  enableRaffles: boolean;
  enableFixedPrice: boolean;
  messageTemplateMarkdown: string;
  invitationEmailSubject: string;
  showSilentAuction: boolean;
  showSilentAuctionCountdown: boolean;
  activeSilentAuctionID: string;
  noticeEmailsSent: boolean;
  showThermometer: boolean;
  showStory: boolean;
  showLiveAuctionPreview: boolean;
  showRaffle: boolean;
  showGreatfeatsParticipantsLeaderboard: boolean;
  greatfeatsParticipantsLeaderboardTabTitle: string;
  greatfeatsParticipantsLeaderboardMessageMarkdown: string;
  storyTabTitle: string;
  liveAuctionPreviewTabTitle: string;
  silentAuctionTabTitle: string;
  raffleTabTitle: string;
  paymentsProcessed: boolean;
  checkoutMessageMarkdown: string;
  isVirtualTableActive: boolean;
  isChatOn: boolean;
  livestreamChatChannelCount: number;
  requireDonationDuringRegistration: boolean;
  requireDonationCompEmails: string[];
  showSocialAuth: boolean;
  showPhoneNumber: boolean;
  requirePhoneNumber: boolean;
  showAddress: boolean;
  requireAddress: boolean;
  enableTicketing: boolean;
  ticketTypeOrderIDs: string[];
  ticketOptionOrderIDs: string[];
  showGame: boolean;
  gameTabTitle: string;
  greatfeatsChallengeAccessKey: string;
  showRecurringDonationManagement: boolean;
  recurringDonationPeriod: string;
  recurringDonationSuggestedAmountsCents: number[];
  receiptMessageMarkdown: string;
  eventEndMessageMarkdown: string;
  receiptTaxMessage: string;
  hostURL: string;
  hostShortenedURL: string;
  donationTabTitle: string;
  fixedPriceItemTabTitle: string;
  supportSectionHeaderTitle: string;
  checkoutNotificationMessageMarkdown: string;
  readyPickupNotificationMessageMarkdown: string;
  pickupLaterNotificationMessageMarkdown: string;
  allowPickupLater: boolean;
  allowPickupTonight: boolean;
  isPickupWindowScheduled: boolean;
  pickupOpenTime: (Date | null);
  pickupCloseTime: (Date | null);
  isCheckoutOpen: boolean;
  thermometerConfig: ThermometerSetupConfig;
  totalDonationsProceedsAmountCents: number;
  totalOfflineDonationsAmountCents: number;
  totalLiveAuctionProceedsAmountCents: number;
  totalSilentAuctionProceedsAmountCents: number;
  totalFixedPriceProceedsAmountCents: number;
  totalTicketsProceedsAmountCents: number;
  totalRaffleProceedsAmountCents: number;
  totalSponsorAttachmentEventSalesProceedsAmountCents: number;
  totalMiscProceedsAmountCents: number;
  thermometerProceeds: number;
  testEvent: boolean;
  enableSelfCheckin: boolean;
  eventDayToolShortURL: string;
  shareableSlideshowShortURL: string;
  salesTaxPercent: number;
  salesTaxAppliesTo: string;
  salesTaxCoverageType: string;
  customTags: string[];
  externalID: string;
  enableBidderNumbers: boolean;
  enableAutomaticBidderNumbers: boolean;
  startingBidderNumber: (number | null);

  constructor({
    organizationID,
    id,
    name,
    descriptionMarkdown,
    eventType,
    impactBlurb,
    registrationBlurb,
    primaryDate,
    preEventScheduledNotificationID,
    preEventQuestionnaireNotificationID,
    primaryEndDate,
    timezone,
    storyMarkdown,
    shortURL,
    currencyCode,
    totalDonationsCount,
    totalDonationsAmountCents,
    totalAppealAmountCents,
    totalRegistrationsCount,
    goalTotalDonationsAmountCents,
    goalTotalDonationsAmountCentsMetOn,
    goalTotalDonationsSeedAmountCents,
    goalTrackerImageURL,
    goalTrackerImageUploadToken,
    donationSuggestedAmountsCents,
    donationSuggestedAmountsShow,
    themePrimaryColor,
    themeSecondaryColor,
    bannerImageURL,
    bannerImageUploadToken,
    logoImageURL,
    logoImageUploadToken,
    registrationThankYouMessageMarkdown,
    registrationConfirmationMessageMarkdown,
    donationThankYouMessageMarkdown,
    contactName,
    contactPhone,
    contactEmail,
    merchantAccountID,
    showBillingAddress,
    requireBillingAddress,
    isCoverCostActive,
    coverCostPercent,
    coverCostCustomMessage,
    coverCostDefault,
    tags,
    impactOrderIDs,
    fixedPriceOrderIDs,
    activeRaffleID,
    enableRaffles,
    enableFixedPrice,
    messageTemplateMarkdown,
    invitationEmailSubject,
    showSilentAuction,
    showSilentAuctionCountdown,
    activeSilentAuctionID,
    noticeEmailsSent,
    showThermometer,
    showStory,
    showLiveAuctionPreview,
    showRaffle,
    showGreatfeatsParticipantsLeaderboard,
    greatfeatsParticipantsLeaderboardTabTitle,
    greatfeatsParticipantsLeaderboardMessageMarkdown,
    storyTabTitle,
    liveAuctionPreviewTabTitle,
    silentAuctionTabTitle,
    raffleTabTitle,
    paymentsProcessed,
    checkoutMessageMarkdown,
    isVirtualTableActive,
    isChatOn,
    livestreamChatChannelCount,
    requireDonationDuringRegistration,
    requireDonationCompEmails,
    showSocialAuth,
    showPhoneNumber,
    requirePhoneNumber,
    showAddress,
    requireAddress,
    enableTicketing,
    ticketTypeOrderIDs,
    ticketOptionOrderIDs,
    showGame,
    gameTabTitle,
    greatfeatsChallengeAccessKey,
    showRecurringDonationManagement,
    recurringDonationPeriod,
    recurringDonationSuggestedAmountsCents,
    receiptMessageMarkdown,
    eventEndMessageMarkdown,
    receiptTaxMessage,
    hostURL,
    hostShortenedURL,
    donationTabTitle,
    fixedPriceItemTabTitle,
    supportSectionHeaderTitle,
    checkoutNotificationMessageMarkdown,
    readyPickupNotificationMessageMarkdown,
    pickupLaterNotificationMessageMarkdown,
    allowPickupLater,
    allowPickupTonight,
    isPickupWindowScheduled,
    pickupOpenTime,
    pickupCloseTime,
    isCheckoutOpen,
    thermometerConfig,
    totalDonationsProceedsAmountCents,
    totalOfflineDonationsAmountCents,
    totalLiveAuctionProceedsAmountCents,
    totalSilentAuctionProceedsAmountCents,
    totalFixedPriceProceedsAmountCents,
    totalTicketsProceedsAmountCents,
    totalRaffleProceedsAmountCents,
    totalSponsorAttachmentEventSalesProceedsAmountCents,
    totalMiscProceedsAmountCents,
    thermometerProceeds,
    testEvent,
    enableSelfCheckin,
    eventDayToolShortURL,
    shareableSlideshowShortURL,
    salesTaxPercent,
    salesTaxAppliesTo,
    salesTaxCoverageType,
    customTags,
    externalID,
    enableBidderNumbers,
    enableAutomaticBidderNumbers,
    startingBidderNumber,
  }: Partial<TypeWithFieldsOf<ReadEventOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.eventType = eventType ?? "";
    this.impactBlurb = impactBlurb ?? "";
    this.registrationBlurb = registrationBlurb ?? "";
    this.primaryDate = primaryDate ?? null;
    this.preEventScheduledNotificationID = preEventScheduledNotificationID ?? "";
    this.preEventQuestionnaireNotificationID = preEventQuestionnaireNotificationID ?? "";
    this.primaryEndDate = primaryEndDate ?? null;
    this.timezone = timezone ?? "";
    this.storyMarkdown = storyMarkdown ?? "";
    this.shortURL = shortURL ?? "";
    this.currencyCode = currencyCode ?? "";
    this.totalDonationsCount = totalDonationsCount ?? 0;
    this.totalDonationsAmountCents = totalDonationsAmountCents ?? 0;
    this.totalAppealAmountCents = totalAppealAmountCents ?? 0;
    this.totalRegistrationsCount = totalRegistrationsCount ?? 0;
    this.goalTotalDonationsAmountCents = goalTotalDonationsAmountCents ?? 0;
    this.goalTotalDonationsAmountCentsMetOn = goalTotalDonationsAmountCentsMetOn ?? null;
    this.goalTotalDonationsSeedAmountCents = goalTotalDonationsSeedAmountCents ?? 0;
    this.goalTrackerImageURL = goalTrackerImageURL ?? "";
    this.goalTrackerImageUploadToken = goalTrackerImageUploadToken ?? "";
    this.donationSuggestedAmountsCents = donationSuggestedAmountsCents ?? [];
    this.donationSuggestedAmountsShow = donationSuggestedAmountsShow ?? false;
    this.themePrimaryColor = themePrimaryColor ?? "";
    this.themeSecondaryColor = themeSecondaryColor ?? "";
    this.bannerImageURL = bannerImageURL ?? "";
    this.bannerImageUploadToken = bannerImageUploadToken ?? "";
    this.logoImageURL = logoImageURL ?? "";
    this.logoImageUploadToken = logoImageUploadToken ?? "";
    this.registrationThankYouMessageMarkdown = registrationThankYouMessageMarkdown ?? "";
    this.registrationConfirmationMessageMarkdown = registrationConfirmationMessageMarkdown ?? "";
    this.donationThankYouMessageMarkdown = donationThankYouMessageMarkdown ?? "";
    this.contactName = contactName ?? "";
    this.contactPhone = contactPhone ?? "";
    this.contactEmail = contactEmail ?? "";
    this.merchantAccountID = merchantAccountID ?? "";
    this.showBillingAddress = showBillingAddress ?? false;
    this.requireBillingAddress = requireBillingAddress ?? false;
    this.isCoverCostActive = isCoverCostActive ?? false;
    this.coverCostPercent = coverCostPercent ?? 0;
    this.coverCostCustomMessage = coverCostCustomMessage ?? "";
    this.coverCostDefault = coverCostDefault ?? false;
    this.tags = tags ?? [];
    this.impactOrderIDs = impactOrderIDs ?? [];
    this.fixedPriceOrderIDs = fixedPriceOrderIDs ?? [];
    this.activeRaffleID = activeRaffleID ?? "";
    this.enableRaffles = enableRaffles ?? false;
    this.enableFixedPrice = enableFixedPrice ?? false;
    this.messageTemplateMarkdown = messageTemplateMarkdown ?? "";
    this.invitationEmailSubject = invitationEmailSubject ?? "";
    this.showSilentAuction = showSilentAuction ?? false;
    this.showSilentAuctionCountdown = showSilentAuctionCountdown ?? false;
    this.activeSilentAuctionID = activeSilentAuctionID ?? "";
    this.noticeEmailsSent = noticeEmailsSent ?? false;
    this.showThermometer = showThermometer ?? false;
    this.showStory = showStory ?? false;
    this.showLiveAuctionPreview = showLiveAuctionPreview ?? false;
    this.showRaffle = showRaffle ?? false;
    this.showGreatfeatsParticipantsLeaderboard = showGreatfeatsParticipantsLeaderboard ?? false;
    this.greatfeatsParticipantsLeaderboardTabTitle = greatfeatsParticipantsLeaderboardTabTitle ?? "";
    this.greatfeatsParticipantsLeaderboardMessageMarkdown = greatfeatsParticipantsLeaderboardMessageMarkdown ?? "";
    this.storyTabTitle = storyTabTitle ?? "";
    this.liveAuctionPreviewTabTitle = liveAuctionPreviewTabTitle ?? "";
    this.silentAuctionTabTitle = silentAuctionTabTitle ?? "";
    this.raffleTabTitle = raffleTabTitle ?? "";
    this.paymentsProcessed = paymentsProcessed ?? false;
    this.checkoutMessageMarkdown = checkoutMessageMarkdown ?? "";
    this.isVirtualTableActive = isVirtualTableActive ?? false;
    this.isChatOn = isChatOn ?? false;
    this.livestreamChatChannelCount = livestreamChatChannelCount ?? 0;
    this.requireDonationDuringRegistration = requireDonationDuringRegistration ?? false;
    this.requireDonationCompEmails = requireDonationCompEmails ?? [];
    this.showSocialAuth = showSocialAuth ?? false;
    this.showPhoneNumber = showPhoneNumber ?? false;
    this.requirePhoneNumber = requirePhoneNumber ?? false;
    this.showAddress = showAddress ?? false;
    this.requireAddress = requireAddress ?? false;
    this.enableTicketing = enableTicketing ?? false;
    this.ticketTypeOrderIDs = ticketTypeOrderIDs ?? [];
    this.ticketOptionOrderIDs = ticketOptionOrderIDs ?? [];
    this.showGame = showGame ?? false;
    this.gameTabTitle = gameTabTitle ?? "";
    this.greatfeatsChallengeAccessKey = greatfeatsChallengeAccessKey ?? "";
    this.showRecurringDonationManagement = showRecurringDonationManagement ?? false;
    this.recurringDonationPeriod = recurringDonationPeriod ?? "";
    this.recurringDonationSuggestedAmountsCents = recurringDonationSuggestedAmountsCents ?? [];
    this.receiptMessageMarkdown = receiptMessageMarkdown ?? "";
    this.eventEndMessageMarkdown = eventEndMessageMarkdown ?? "";
    this.receiptTaxMessage = receiptTaxMessage ?? "";
    this.hostURL = hostURL ?? "";
    this.hostShortenedURL = hostShortenedURL ?? "";
    this.donationTabTitle = donationTabTitle ?? "";
    this.fixedPriceItemTabTitle = fixedPriceItemTabTitle ?? "";
    this.supportSectionHeaderTitle = supportSectionHeaderTitle ?? "";
    this.checkoutNotificationMessageMarkdown = checkoutNotificationMessageMarkdown ?? "";
    this.readyPickupNotificationMessageMarkdown = readyPickupNotificationMessageMarkdown ?? "";
    this.pickupLaterNotificationMessageMarkdown = pickupLaterNotificationMessageMarkdown ?? "";
    this.allowPickupLater = allowPickupLater ?? false;
    this.allowPickupTonight = allowPickupTonight ?? false;
    this.isPickupWindowScheduled = isPickupWindowScheduled ?? false;
    this.pickupOpenTime = pickupOpenTime ?? null;
    this.pickupCloseTime = pickupCloseTime ?? null;
    this.isCheckoutOpen = isCheckoutOpen ?? false;
    this.thermometerConfig = thermometerConfig ?? new ThermometerSetupConfig();
    this.totalDonationsProceedsAmountCents = totalDonationsProceedsAmountCents ?? 0;
    this.totalOfflineDonationsAmountCents = totalOfflineDonationsAmountCents ?? 0;
    this.totalLiveAuctionProceedsAmountCents = totalLiveAuctionProceedsAmountCents ?? 0;
    this.totalSilentAuctionProceedsAmountCents = totalSilentAuctionProceedsAmountCents ?? 0;
    this.totalFixedPriceProceedsAmountCents = totalFixedPriceProceedsAmountCents ?? 0;
    this.totalTicketsProceedsAmountCents = totalTicketsProceedsAmountCents ?? 0;
    this.totalRaffleProceedsAmountCents = totalRaffleProceedsAmountCents ?? 0;
    this.totalSponsorAttachmentEventSalesProceedsAmountCents = totalSponsorAttachmentEventSalesProceedsAmountCents ?? 0;
    this.totalMiscProceedsAmountCents = totalMiscProceedsAmountCents ?? 0;
    this.thermometerProceeds = thermometerProceeds ?? 0;
    this.testEvent = testEvent ?? false;
    this.enableSelfCheckin = enableSelfCheckin ?? false;
    this.eventDayToolShortURL = eventDayToolShortURL ?? "";
    this.shareableSlideshowShortURL = shareableSlideshowShortURL ?? "";
    this.salesTaxPercent = salesTaxPercent ?? 0;
    this.salesTaxAppliesTo = salesTaxAppliesTo ?? "";
    this.salesTaxCoverageType = salesTaxCoverageType ?? "";
    this.customTags = customTags ?? [];
    this.externalID = externalID ?? "";
    this.enableBidderNumbers = enableBidderNumbers ?? false;
    this.enableAutomaticBidderNumbers = enableAutomaticBidderNumbers ?? false;
    this.startingBidderNumber = startingBidderNumber ?? null;
  }

  convertDates(): ReadEventOutput {
    if (this.primaryDate !== undefined && this.primaryDate !== null) {
      this.primaryDate = new Date(this.primaryDate!);
    }
    if (this.primaryEndDate !== undefined && this.primaryEndDate !== null) {
      this.primaryEndDate = new Date(this.primaryEndDate!);
    }
    if (this.goalTotalDonationsAmountCentsMetOn !== undefined && this.goalTotalDonationsAmountCentsMetOn !== null) {
      this.goalTotalDonationsAmountCentsMetOn = new Date(this.goalTotalDonationsAmountCentsMetOn!);
    }
    if (this.pickupOpenTime !== undefined && this.pickupOpenTime !== null) {
      this.pickupOpenTime = new Date(this.pickupOpenTime!);
    }
    if (this.pickupCloseTime !== undefined && this.pickupCloseTime !== null) {
      this.pickupCloseTime = new Date(this.pickupCloseTime!);
    }
    return this;
  }

  convertUnexpectedNulls(): ReadEventOutput {
    if (!this.donationSuggestedAmountsCents) {
      this.donationSuggestedAmountsCents = [];
    }
    if (!this.tags) {
      this.tags = [];
    }
    if (!this.impactOrderIDs) {
      this.impactOrderIDs = [];
    }
    if (!this.fixedPriceOrderIDs) {
      this.fixedPriceOrderIDs = [];
    }
    if (!this.requireDonationCompEmails) {
      this.requireDonationCompEmails = [];
    }
    if (!this.ticketTypeOrderIDs) {
      this.ticketTypeOrderIDs = [];
    }
    if (!this.ticketOptionOrderIDs) {
      this.ticketOptionOrderIDs = [];
    }
    if (!this.recurringDonationSuggestedAmountsCents) {
      this.recurringDonationSuggestedAmountsCents = [];
    }
    if (!this.customTags) {
      this.customTags = [];
    }
    return this;
  }

  fixClasses(): ReadEventOutput {
    this.thermometerConfig = new ThermometerSetupConfig(this.thermometerConfig!).fixClasses();
    return this;
  }
}

export class ReadEventSessionInput {
  eventSessionID: string;
  organizationID: string;
  eventID: string;

  constructor({
    eventSessionID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadEventSessionInput>> = {}) {
    this.eventSessionID = eventSessionID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadEventSessionInput {
    return this;
  }
}

export class ReadEventSessionOutput {
  organizationID: string;
  eventID: string;
  id: string;
  tableImageURL: string;
  tableImageUploadToken: string;

  constructor({
    organizationID,
    eventID,
    id,
    tableImageURL,
    tableImageUploadToken,
  }: Partial<TypeWithFieldsOf<ReadEventSessionOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.tableImageURL = tableImageURL ?? "";
    this.tableImageUploadToken = tableImageUploadToken ?? "";
  }

  fixClasses(): ReadEventSessionOutput {
    return this;
  }
}

export class ReadEventSessionTicketConfigInput {
  eventSessionTicketConfigID: string;
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    eventSessionTicketConfigID,
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<ReadEventSessionTicketConfigInput>> = {}) {
    this.eventSessionTicketConfigID = eventSessionTicketConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): ReadEventSessionTicketConfigInput {
    return this;
  }
}

export class ReadEventSessionTicketConfigOutput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  id: string;
  ticketTypeID: string;
  attendeeExperience: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    id,
    ticketTypeID,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<ReadEventSessionTicketConfigOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.id = id ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.attendeeExperience = attendeeExperience ?? "";
  }

  fixClasses(): ReadEventSessionTicketConfigOutput {
    return this;
  }
}

export class ReadEventShowControlInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadEventShowControlInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadEventShowControlInput {
    return this;
  }
}

export class ReadEventShowControlOutput {
  organizationID: string;
  eventID: string;
  activeShowID: string;
  activeSceneID: string;
  activeSceneChanged: (Date | null);
  secondsSinceSceneChange: number;
  activeSceneType: string;
  activeSceneURL: string;

  constructor({
    organizationID,
    eventID,
    activeShowID,
    activeSceneID,
    activeSceneChanged,
    secondsSinceSceneChange,
    activeSceneType,
    activeSceneURL,
  }: Partial<TypeWithFieldsOf<ReadEventShowControlOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.activeShowID = activeShowID ?? "";
    this.activeSceneID = activeSceneID ?? "";
    this.activeSceneChanged = activeSceneChanged ?? null;
    this.secondsSinceSceneChange = secondsSinceSceneChange ?? 0;
    this.activeSceneType = activeSceneType ?? "";
    this.activeSceneURL = activeSceneURL ?? "";
  }

  convertDates(): ReadEventShowControlOutput {
    if (this.activeSceneChanged !== undefined && this.activeSceneChanged !== null) {
      this.activeSceneChanged = new Date(this.activeSceneChanged!);
    }
    return this;
  }

  fixClasses(): ReadEventShowControlOutput {
    return this;
  }
}

export class ReadEventSlideConfigInput {
  eventSlideConfigID: string;
  organizationID: string;
  eventID: string;

  constructor({
    eventSlideConfigID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadEventSlideConfigInput>> = {}) {
    this.eventSlideConfigID = eventSlideConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadEventSlideConfigInput {
    return this;
  }
}

export class ReadEventSlideConfigOutput {
  organizationID: string;
  eventID: string;
  id: string;
  slideType: string;
  detailsJSON: string;

  constructor({
    organizationID,
    eventID,
    id,
    slideType,
    detailsJSON,
  }: Partial<TypeWithFieldsOf<ReadEventSlideConfigOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.slideType = slideType ?? "";
    this.detailsJSON = detailsJSON ?? "";
  }

  fixClasses(): ReadEventSlideConfigOutput {
    return this;
  }
}

export class ReadEventSlideshowInput {
  eventSlideshowID: string;
  organizationID: string;
  eventID: string;

  constructor({
    eventSlideshowID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadEventSlideshowInput>> = {}) {
    this.eventSlideshowID = eventSlideshowID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadEventSlideshowInput {
    return this;
  }
}

export class ReadEventSlideshowOutput {
  organizationID: string;
  eventID: string;
  id: string;
  activeSlideURL: string;

  constructor({
    organizationID,
    eventID,
    id,
    activeSlideURL,
  }: Partial<TypeWithFieldsOf<ReadEventSlideshowOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.activeSlideURL = activeSlideURL ?? "";
  }

  fixClasses(): ReadEventSlideshowOutput {
    return this;
  }
}

export class ReadFixedPriceItemInput {
  itemID: string;
  organizationID: string;
  eventID: string;

  constructor({
    itemID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadFixedPriceItemInput>> = {}) {
    this.itemID = itemID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadFixedPriceItemInput {
    return this;
  }
}

export class ReadFixedPriceItemOutput {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  priceCents: number;
  valueCents: number;
  isTaxable: boolean;
  description: string;
  numPurchased: number;
  maxNumAvailable: number;
  numUnlimited: boolean;
  inPersonOnly: boolean;
  pickupLater: boolean;
  pickupTonight: boolean;
  isPickUpEventLevel: boolean;
  sortOrder: number;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    priceCents,
    valueCents,
    isTaxable,
    description,
    numPurchased,
    maxNumAvailable,
    numUnlimited,
    inPersonOnly,
    pickupLater,
    pickupTonight,
    isPickUpEventLevel,
    sortOrder,
  }: Partial<TypeWithFieldsOf<ReadFixedPriceItemOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.priceCents = priceCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.isTaxable = isTaxable ?? false;
    this.description = description ?? "";
    this.numPurchased = numPurchased ?? 0;
    this.maxNumAvailable = maxNumAvailable ?? 0;
    this.numUnlimited = numUnlimited ?? false;
    this.inPersonOnly = inPersonOnly ?? false;
    this.pickupLater = pickupLater ?? false;
    this.pickupTonight = pickupTonight ?? false;
    this.isPickUpEventLevel = isPickUpEventLevel ?? false;
    this.sortOrder = sortOrder ?? 0;
  }

  fixClasses(): ReadFixedPriceItemOutput {
    return this;
  }
}

export class ReadFixedPriceItemPhotoInput {
  photoID: string;
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;

  constructor({
    photoID,
    organizationID,
    eventID,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<ReadFixedPriceItemPhotoInput>> = {}) {
    this.photoID = photoID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  fixClasses(): ReadFixedPriceItemPhotoInput {
    return this;
  }
}

export class ReadFixedPriceItemPhotoOutput {
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  id: string;
  url: string;
  uploadToken: string;

  constructor({
    organizationID,
    eventID,
    fixedPriceItemID,
    id,
    url,
    uploadToken,
  }: Partial<TypeWithFieldsOf<ReadFixedPriceItemPhotoOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.id = id ?? "";
    this.url = url ?? "";
    this.uploadToken = uploadToken ?? "";
  }

  fixClasses(): ReadFixedPriceItemPhotoOutput {
    return this;
  }
}

export class ReadGuestInput {
  guestID: string;
  organizationID: string;
  eventID: string;

  constructor({
    guestID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadGuestInput>> = {}) {
    this.guestID = guestID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadGuestInput {
    return this;
  }
}

export class ReadGuestOutput {
  organizationID: string;
  eventID: string;
  id: string;
  userID: string;
  registrationID: string;
  bidderNumber: number;
  attendeeNumber: string;
  managingGuestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  preEventQuestionnaireCompleteTime: (Date | null);
  preEventQuestionOneAnswer: boolean;
  preEventQuestionTwoAnswer: boolean;
  preEventQuestionThreeAnswer: boolean;
  preEventConsentSelfCompleted: boolean;
  biddingAsGuestID: string;
  purchasedItemPrimaryUserID: string;
  purchasedItemPrimaryGuestID: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  userCardID: string;

  constructor({
    organizationID,
    eventID,
    id,
    userID,
    registrationID,
    bidderNumber,
    attendeeNumber,
    managingGuestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    preEventQuestionnaireCompleteTime,
    preEventQuestionOneAnswer,
    preEventQuestionTwoAnswer,
    preEventQuestionThreeAnswer,
    preEventConsentSelfCompleted,
    biddingAsGuestID,
    purchasedItemPrimaryUserID,
    purchasedItemPrimaryGuestID,
    address,
    city,
    state,
    postalCode,
    country,
    userCardID,
  }: Partial<TypeWithFieldsOf<ReadGuestOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.userID = userID ?? "";
    this.registrationID = registrationID ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.attendeeNumber = attendeeNumber ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.preEventQuestionOneAnswer = preEventQuestionOneAnswer ?? false;
    this.preEventQuestionTwoAnswer = preEventQuestionTwoAnswer ?? false;
    this.preEventQuestionThreeAnswer = preEventQuestionThreeAnswer ?? false;
    this.preEventConsentSelfCompleted = preEventConsentSelfCompleted ?? false;
    this.biddingAsGuestID = biddingAsGuestID ?? "";
    this.purchasedItemPrimaryUserID = purchasedItemPrimaryUserID ?? "";
    this.purchasedItemPrimaryGuestID = purchasedItemPrimaryGuestID ?? "";
    this.address = address ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.postalCode = postalCode ?? "";
    this.country = country ?? "";
    this.userCardID = userCardID ?? "";
  }

  convertDates(): ReadGuestOutput {
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): ReadGuestOutput {
    return this;
  }
}

export class ReadGuestSessionInput {
  guestSessionID: string;
  organizationID: string;
  eventID: string;
  eventSessionID: string;

  constructor({
    guestSessionID,
    organizationID,
    eventID,
    eventSessionID,
  }: Partial<TypeWithFieldsOf<ReadGuestSessionInput>> = {}) {
    this.guestSessionID = guestSessionID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
  }

  fixClasses(): ReadGuestSessionInput {
    return this;
  }
}

export class ReadGuestSessionOutput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  id: string;
  guestID: string;
  isCheckInWithManagingGuest: boolean;
  checkedInTime: (Date | null);
  tableAttendeeID: string;
  ticketID: string;
  initialTableName: string;
  initialTableNumber: string;
  totalProceedsAmountCents: number;
  attendeeExperience: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    id,
    guestID,
    isCheckInWithManagingGuest,
    checkedInTime,
    tableAttendeeID,
    ticketID,
    initialTableName,
    initialTableNumber,
    totalProceedsAmountCents,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<ReadGuestSessionOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.id = id ?? "";
    this.guestID = guestID ?? "";
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? false;
    this.checkedInTime = checkedInTime ?? null;
    this.tableAttendeeID = tableAttendeeID ?? "";
    this.ticketID = ticketID ?? "";
    this.initialTableName = initialTableName ?? "";
    this.initialTableNumber = initialTableNumber ?? "";
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.attendeeExperience = attendeeExperience ?? "";
  }

  convertDates(): ReadGuestSessionOutput {
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    return this;
  }

  fixClasses(): ReadGuestSessionOutput {
    return this;
  }
}

export class ReadImpactInput {
  impactID: string;
  organizationID: string;
  eventID: string;

  constructor({
    impactID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadImpactInput>> = {}) {
    this.impactID = impactID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadImpactInput {
    return this;
  }
}

export class ReadImpactOutput {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  amountCents: number;
  descriptionMarkdown: string;
  totalPurchasesLimit: number;
  totalPurchasesCountDisplayThreshold: number;
  totalPurchasesAmountCentsDisplayThreshold: number;
  totalPurchasesCount: number;
  totalPurchasesAmountCents: number;
  goalType: string;
  goalPurchasesCount: number;
  goalPurchasesCountDisplayThreshold: number;
  goalPurchasesCountMetOn: (Date | null);
  goalPurchasesAmountCents: number;
  goalPurchasesAmountCentsDisplayThreshold: number;
  goalPurchasesAmountCentsMetOn: (Date | null);
  goalMetMessageSentOn: (Date | null);
  goalMetEmailSubject: string;
  goalMetEmailBodyMarkdown: string;
  goalMetSMSBody: string;
  hideFromFlow: boolean;
  fixedPriceItemID: string;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    amountCents,
    descriptionMarkdown,
    totalPurchasesLimit,
    totalPurchasesCountDisplayThreshold,
    totalPurchasesAmountCentsDisplayThreshold,
    totalPurchasesCount,
    totalPurchasesAmountCents,
    goalType,
    goalPurchasesCount,
    goalPurchasesCountDisplayThreshold,
    goalPurchasesCountMetOn,
    goalPurchasesAmountCents,
    goalPurchasesAmountCentsDisplayThreshold,
    goalPurchasesAmountCentsMetOn,
    goalMetMessageSentOn,
    goalMetEmailSubject,
    goalMetEmailBodyMarkdown,
    goalMetSMSBody,
    hideFromFlow,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<ReadImpactOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.amountCents = amountCents ?? 0;
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.totalPurchasesLimit = totalPurchasesLimit ?? 0;
    this.totalPurchasesCountDisplayThreshold = totalPurchasesCountDisplayThreshold ?? 0;
    this.totalPurchasesAmountCentsDisplayThreshold = totalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.totalPurchasesCount = totalPurchasesCount ?? 0;
    this.totalPurchasesAmountCents = totalPurchasesAmountCents ?? 0;
    this.goalType = goalType ?? "";
    this.goalPurchasesCount = goalPurchasesCount ?? 0;
    this.goalPurchasesCountDisplayThreshold = goalPurchasesCountDisplayThreshold ?? 0;
    this.goalPurchasesCountMetOn = goalPurchasesCountMetOn ?? null;
    this.goalPurchasesAmountCents = goalPurchasesAmountCents ?? 0;
    this.goalPurchasesAmountCentsDisplayThreshold = goalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.goalPurchasesAmountCentsMetOn = goalPurchasesAmountCentsMetOn ?? null;
    this.goalMetMessageSentOn = goalMetMessageSentOn ?? null;
    this.goalMetEmailSubject = goalMetEmailSubject ?? "";
    this.goalMetEmailBodyMarkdown = goalMetEmailBodyMarkdown ?? "";
    this.goalMetSMSBody = goalMetSMSBody ?? "";
    this.hideFromFlow = hideFromFlow ?? false;
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  convertDates(): ReadImpactOutput {
    if (this.goalPurchasesCountMetOn !== undefined && this.goalPurchasesCountMetOn !== null) {
      this.goalPurchasesCountMetOn = new Date(this.goalPurchasesCountMetOn!);
    }
    if (this.goalPurchasesAmountCentsMetOn !== undefined && this.goalPurchasesAmountCentsMetOn !== null) {
      this.goalPurchasesAmountCentsMetOn = new Date(this.goalPurchasesAmountCentsMetOn!);
    }
    if (this.goalMetMessageSentOn !== undefined && this.goalMetMessageSentOn !== null) {
      this.goalMetMessageSentOn = new Date(this.goalMetMessageSentOn!);
    }
    return this;
  }

  fixClasses(): ReadImpactOutput {
    return this;
  }
}

export class ReadImpactPhotoInput {
  photoID: string;
  organizationID: string;
  eventID: string;
  impactID: string;

  constructor({
    photoID,
    organizationID,
    eventID,
    impactID,
  }: Partial<TypeWithFieldsOf<ReadImpactPhotoInput>> = {}) {
    this.photoID = photoID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
  }

  fixClasses(): ReadImpactPhotoInput {
    return this;
  }
}

export class ReadImpactPhotoOutput {
  organizationID: string;
  eventID: string;
  impactID: string;
  id: string;
  url: string;
  uploadToken: string;

  constructor({
    organizationID,
    eventID,
    impactID,
    id,
    url,
    uploadToken,
  }: Partial<TypeWithFieldsOf<ReadImpactPhotoOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
    this.id = id ?? "";
    this.url = url ?? "";
    this.uploadToken = uploadToken ?? "";
  }

  fixClasses(): ReadImpactPhotoOutput {
    return this;
  }
}

export class ReadInvitationInput {
  organizationID: string;
  eventID: string;
  invitationID: string;

  constructor({
    organizationID,
    eventID,
    invitationID,
  }: Partial<TypeWithFieldsOf<ReadInvitationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.invitationID = invitationID ?? "";
  }

  fixClasses(): ReadInvitationInput {
    return this;
  }
}

export class ReadInvitationOutput {
  organizationID: string;
  eventID: string;
  id: string;
  externalID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  sentEmail: (Date | null);
  sentSMS: (Date | null);
  registrationID: string;
  messageTemplateMarkdown: string;
  invitationEmailSubject: string;
  emailOpened: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    externalID,
    firstName,
    lastName,
    email,
    mobilePhone,
    sentEmail,
    sentSMS,
    registrationID,
    messageTemplateMarkdown,
    invitationEmailSubject,
    emailOpened,
  }: Partial<TypeWithFieldsOf<ReadInvitationOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.externalID = externalID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.sentEmail = sentEmail ?? null;
    this.sentSMS = sentSMS ?? null;
    this.registrationID = registrationID ?? "";
    this.messageTemplateMarkdown = messageTemplateMarkdown ?? "";
    this.invitationEmailSubject = invitationEmailSubject ?? "";
    this.emailOpened = emailOpened ?? false;
  }

  convertDates(): ReadInvitationOutput {
    if (this.sentEmail !== undefined && this.sentEmail !== null) {
      this.sentEmail = new Date(this.sentEmail!);
    }
    if (this.sentSMS !== undefined && this.sentSMS !== null) {
      this.sentSMS = new Date(this.sentSMS!);
    }
    return this;
  }

  fixClasses(): ReadInvitationOutput {
    return this;
  }
}

export class ReadMyRegistrationInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadMyRegistrationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadMyRegistrationInput {
    return this;
  }
}

export class ReadMyRegistrationOutput {
  found: boolean;
  organizationID: string;
  eventID: string;
  registrationID: string;
  userID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  wantEmailUpdates: boolean;
  wantSMSUpdates: boolean;
  paidStatus: string;
  passthroughInvitationID: string;
  greatfeatsParticipantID: string;
  detailsJSON: string;
  biddingAsUserID: string;
  biddingAsFirstName: string;
  biddingAsLastName: string;

  constructor({
    found,
    organizationID,
    eventID,
    registrationID,
    userID,
    firstName,
    lastName,
    email,
    mobilePhone,
    wantEmailUpdates,
    wantSMSUpdates,
    paidStatus,
    passthroughInvitationID,
    greatfeatsParticipantID,
    detailsJSON,
    biddingAsUserID,
    biddingAsFirstName,
    biddingAsLastName,
  }: Partial<TypeWithFieldsOf<ReadMyRegistrationOutput>> = {}) {
    this.found = found ?? false;
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.registrationID = registrationID ?? "";
    this.userID = userID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.wantEmailUpdates = wantEmailUpdates ?? false;
    this.wantSMSUpdates = wantSMSUpdates ?? false;
    this.paidStatus = paidStatus ?? "";
    this.passthroughInvitationID = passthroughInvitationID ?? "";
    this.greatfeatsParticipantID = greatfeatsParticipantID ?? "";
    this.detailsJSON = detailsJSON ?? "";
    this.biddingAsUserID = biddingAsUserID ?? "";
    this.biddingAsFirstName = biddingAsFirstName ?? "";
    this.biddingAsLastName = biddingAsLastName ?? "";
  }

  fixClasses(): ReadMyRegistrationOutput {
    return this;
  }
}

export class ReadMyTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;

  constructor({
    organizationID,
    eventID,
    ticketID,
  }: Partial<TypeWithFieldsOf<ReadMyTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
  }

  fixClasses(): ReadMyTicketInput {
    return this;
  }
}

export class ReadMyTicketOptionAnswersInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    ticketID,
    paging,
  }: Partial<TypeWithFieldsOf<ReadMyTicketOptionAnswersInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ReadMyTicketOptionAnswersInput {
    return this;
  }
}

export class ReadMyTicketOptionAnswersOutput {
  items: ViewTicketOptionAnswersForTicketSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ReadMyTicketOptionAnswersOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ReadMyTicketOptionAnswersOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ReadMyTicketOptionAnswersOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ReadMyTicketOptionAnswersOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewTicketOptionAnswersForTicketSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ReadMyTicketOutput {
  readTicketOutput: ReadTicketOutput;

  constructor({
    readTicketOutput,
  }: Partial<TypeWithFieldsOf<ReadMyTicketOutput>> = {}) {
    this.readTicketOutput = readTicketOutput ?? new ReadTicketOutput();
  }

  convertDates(): ReadMyTicketOutput {
    this.readTicketOutput = this.readTicketOutput!.convertDates();
    return this;
  }

  fixClasses(): ReadMyTicketOutput {
    this.readTicketOutput = new ReadTicketOutput(this.readTicketOutput!).fixClasses();
    return this;
  }
}

export class ReadMyVirtualChairInput {
  organizationID: string;
  virtualEventID: string;

  constructor({
    organizationID,
    virtualEventID,
  }: Partial<TypeWithFieldsOf<ReadMyVirtualChairInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
  }

  fixClasses(): ReadMyVirtualChairInput {
    return this;
  }
}

export class ReadMyVirtualChairOutput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  id: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userID: string;
  channelName: string;
  channelARN: string;
  created: Date;
  updated: Date;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    id,
    userEmail,
    userFirstName,
    userLastName,
    userID,
    channelName,
    channelARN,
    created,
    updated,
  }: Partial<TypeWithFieldsOf<ReadMyVirtualChairOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.id = id ?? "";
    this.userEmail = userEmail ?? "";
    this.userFirstName = userFirstName ?? "";
    this.userLastName = userLastName ?? "";
    this.userID = userID ?? "";
    this.channelName = channelName ?? "";
    this.channelARN = channelARN ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
  }

  convertDates(): ReadMyVirtualChairOutput {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): ReadMyVirtualChairOutput {
    return this;
  }
}

export class ReadMyVirtualTableInput {
  organizationID: string;
  virtualEventID: string;

  constructor({
    organizationID,
    virtualEventID,
  }: Partial<TypeWithFieldsOf<ReadMyVirtualTableInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
  }

  fixClasses(): ReadMyVirtualTableInput {
    return this;
  }
}

export class ReadMyVirtualTableOutput {
  virtualTable: Table;

  constructor({
    virtualTable,
  }: Partial<TypeWithFieldsOf<ReadMyVirtualTableOutput>> = {}) {
    this.virtualTable = virtualTable ?? new Table();
  }

  convertUnexpectedNulls(): ReadMyVirtualTableOutput {
    this.virtualTable = this.virtualTable!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): ReadMyVirtualTableOutput {
    this.virtualTable = new Table(this.virtualTable!).fixClasses();
    return this;
  }
}

export class ReadOverlayInput {
  overlayID: string;
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;

  constructor({
    overlayID,
    organizationID,
    eventID,
    showID,
    sceneID,
  }: Partial<TypeWithFieldsOf<ReadOverlayInput>> = {}) {
    this.overlayID = overlayID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
  }

  fixClasses(): ReadOverlayInput {
    return this;
  }
}

export class ReadOverlayOutput {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  id: string;
  name: string;
  type: string;
  config: string;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    id,
    name,
    type,
    config,
  }: Partial<TypeWithFieldsOf<ReadOverlayOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.type = type ?? "";
    this.config = config ?? "";
  }

  fixClasses(): ReadOverlayOutput {
    return this;
  }
}

export class ReadPeerUserInfoInput {
  organizationID: string;
  eventID: string;
  peerUserID: string;

  constructor({
    organizationID,
    eventID,
    peerUserID,
  }: Partial<TypeWithFieldsOf<ReadPeerUserInfoInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.peerUserID = peerUserID ?? "";
  }

  fixClasses(): ReadPeerUserInfoInput {
    return this;
  }
}

export class ReadPeerUserInfoOutput {
  firstName: string;
  lastName: string;

  constructor({
    firstName,
    lastName,
  }: Partial<TypeWithFieldsOf<ReadPeerUserInfoOutput>> = {}) {
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
  }

  fixClasses(): ReadPeerUserInfoOutput {
    return this;
  }
}

export class ReadPickupItemInput {
  id: string;
  organizationID: string;
  eventID: string;

  constructor({
    id,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadPickupItemInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadPickupItemInput {
    return this;
  }
}

export class ReadPickupItemOutput {
  organizationID: string;
  eventID: string;
  id: string;
  userID: string;
  guestID: string;
  transactionID: string;
  pickupTimeSlot: (Date | null);
  isPickedUp: boolean;
  isReadyPickUp: boolean;
  pickedUpByGuestID: string;
  pickupPreference: string;
  purchasedItemID: string;
  itemID: string;
  inventoryLotID: string;
  inventoryAllocationID: string;
  fixedPriceItemID: string;

  constructor({
    organizationID,
    eventID,
    id,
    userID,
    guestID,
    transactionID,
    pickupTimeSlot,
    isPickedUp,
    isReadyPickUp,
    pickedUpByGuestID,
    pickupPreference,
    purchasedItemID,
    itemID,
    inventoryLotID,
    inventoryAllocationID,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<ReadPickupItemOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.transactionID = transactionID ?? "";
    this.pickupTimeSlot = pickupTimeSlot ?? null;
    this.isPickedUp = isPickedUp ?? false;
    this.isReadyPickUp = isReadyPickUp ?? false;
    this.pickedUpByGuestID = pickedUpByGuestID ?? "";
    this.pickupPreference = pickupPreference ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
    this.itemID = itemID ?? "";
    this.inventoryLotID = inventoryLotID ?? "";
    this.inventoryAllocationID = inventoryAllocationID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  convertDates(): ReadPickupItemOutput {
    if (this.pickupTimeSlot !== undefined && this.pickupTimeSlot !== null) {
      this.pickupTimeSlot = new Date(this.pickupTimeSlot!);
    }
    return this;
  }

  fixClasses(): ReadPickupItemOutput {
    return this;
  }
}

export class ReadPreEventQuestionConfigInput {
  preEventQuestionConfigID: string;
  organizationID: string;
  eventID: string;

  constructor({
    preEventQuestionConfigID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadPreEventQuestionConfigInput>> = {}) {
    this.preEventQuestionConfigID = preEventQuestionConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadPreEventQuestionConfigInput {
    return this;
  }
}

export class ReadPreEventQuestionConfigOutput {
  organizationID: string;
  eventID: string;
  id: string;
  questionOnePrompt: string;
  questionOneActive: boolean;
  questionTwoPrompt: string;
  questionTwoActive: boolean;
  questionThreePrompt: string;
  questionThreeActive: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    questionOnePrompt,
    questionOneActive,
    questionTwoPrompt,
    questionTwoActive,
    questionThreePrompt,
    questionThreeActive,
  }: Partial<TypeWithFieldsOf<ReadPreEventQuestionConfigOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.questionOnePrompt = questionOnePrompt ?? "";
    this.questionOneActive = questionOneActive ?? false;
    this.questionTwoPrompt = questionTwoPrompt ?? "";
    this.questionTwoActive = questionTwoActive ?? false;
    this.questionThreePrompt = questionThreePrompt ?? "";
    this.questionThreeActive = questionThreeActive ?? false;
  }

  fixClasses(): ReadPreEventQuestionConfigOutput {
    return this;
  }
}

export class ReadPurchasedItemInput {
  id: string;
  organizationID: string;
  eventID: string;

  constructor({
    id,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadPurchasedItemInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadPurchasedItemInput {
    return this;
  }
}

export class ReadPurchasedItemOutput {
  organizationID: string;
  eventID: string;
  id: string;
  firstName: string;
  lastName: string;
  userID: string;
  guestID: string;
  email: string;
  mobilePhone: string;
  amountCents: number;
  type: string;
  impactID: string;
  auctionID: string;
  lotID: string;
  ticketID: string;
  fixedPriceItemID: string;
  raffleDetails: RafflePurchaseDetails;
  payAfterEvent: boolean;
  purchaseID: string;
  transactionStatus: string;
  transactionID: string;
  refundID: string;
  itemDetails: ItemPurchaseDetails;
  isExternal: boolean;
  isAnonymous: boolean;
  overriddenByUserID: string;
  proxyUserID: string;
  proxyGuestID: string;
  paidByGuestID: string;
  purchasedByDisplayName: string;
  paidByDisplayName: string;

  constructor({
    organizationID,
    eventID,
    id,
    firstName,
    lastName,
    userID,
    guestID,
    email,
    mobilePhone,
    amountCents,
    type,
    impactID,
    auctionID,
    lotID,
    ticketID,
    fixedPriceItemID,
    raffleDetails,
    payAfterEvent,
    purchaseID,
    transactionStatus,
    transactionID,
    refundID,
    itemDetails,
    isExternal,
    isAnonymous,
    overriddenByUserID,
    proxyUserID,
    proxyGuestID,
    paidByGuestID,
    purchasedByDisplayName,
    paidByDisplayName,
  }: Partial<TypeWithFieldsOf<ReadPurchasedItemOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.amountCents = amountCents ?? 0;
    this.type = type ?? "";
    this.impactID = impactID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.ticketID = ticketID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.raffleDetails = raffleDetails ?? new RafflePurchaseDetails();
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.refundID = refundID ?? "";
    this.itemDetails = itemDetails ?? new ItemPurchaseDetails();
    this.isExternal = isExternal ?? false;
    this.isAnonymous = isAnonymous ?? false;
    this.overriddenByUserID = overriddenByUserID ?? "";
    this.proxyUserID = proxyUserID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
    this.paidByGuestID = paidByGuestID ?? "";
    this.purchasedByDisplayName = purchasedByDisplayName ?? "";
    this.paidByDisplayName = paidByDisplayName ?? "";
  }

  fixClasses(): ReadPurchasedItemOutput {
    this.raffleDetails = new RafflePurchaseDetails(this.raffleDetails!).fixClasses();
    this.itemDetails = new ItemPurchaseDetails(this.itemDetails!).fixClasses();
    return this;
  }
}

export class ReadPurchasedItemSalesTaxInput {
  id: string;
  organizationID: string;
  eventID: string;

  constructor({
    id,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadPurchasedItemSalesTaxInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadPurchasedItemSalesTaxInput {
    return this;
  }
}

export class ReadPurchasedItemSalesTaxOutput {
  organizationID: string;
  eventID: string;
  id: string;
  userID: string;
  guestID: string;
  amountCents: number;
  salesTaxPercent: number;
  payAfterEvent: boolean;
  purchaseID: string;
  purchasedItemID: string;
  transactionStatus: string;
  transactionID: string;
  refundID: string;
  overriddenByUserID: string;
  proxyUserID: string;
  proxyGuestID: string;

  constructor({
    organizationID,
    eventID,
    id,
    userID,
    guestID,
    amountCents,
    salesTaxPercent,
    payAfterEvent,
    purchaseID,
    purchasedItemID,
    transactionStatus,
    transactionID,
    refundID,
    overriddenByUserID,
    proxyUserID,
    proxyGuestID,
  }: Partial<TypeWithFieldsOf<ReadPurchasedItemSalesTaxOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.amountCents = amountCents ?? 0;
    this.salesTaxPercent = salesTaxPercent ?? 0;
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.refundID = refundID ?? "";
    this.overriddenByUserID = overriddenByUserID ?? "";
    this.proxyUserID = proxyUserID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
  }

  fixClasses(): ReadPurchasedItemSalesTaxOutput {
    return this;
  }
}

export class ReadRecentAppealItemsInput {
  organizationID: string;
  eventID: string;
  useAnonymous: string;

  constructor({
    organizationID,
    eventID,
    useAnonymous,
  }: Partial<TypeWithFieldsOf<ReadRecentAppealItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.useAnonymous = useAnonymous ?? "";
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.useAnonymous ? params.set('useAnonymous', this.useAnonymous.toString()) : params;
    return params;
  }

  fixClasses(): ReadRecentAppealItemsInput {
    return this;
  }
}

export class ReadRecentAppealItemsOutput {
  organizationID: string;
  eventID: string;
  recentDonors: AppealRecentDonor[];

  constructor({
    organizationID,
    eventID,
    recentDonors,
  }: Partial<TypeWithFieldsOf<ReadRecentAppealItemsOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.recentDonors = recentDonors ?? [];
  }

  convertUnexpectedNulls(): ReadRecentAppealItemsOutput {
    if (!this.recentDonors) {
      this.recentDonors = [];
    }
    return this;
  }

  fixClasses(): ReadRecentAppealItemsOutput {
    for (let i0 = 0; i0 < this.recentDonors.length; i0 += 1) {
      this.recentDonors[i0] = new AppealRecentDonor(this.recentDonors[i0]!).fixClasses();
    }
    return this;
  }
}

export class ReadRegistrationInput {
  registrationID: string;
  organizationID: string;
  eventID: string;

  constructor({
    registrationID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadRegistrationInput>> = {}) {
    this.registrationID = registrationID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadRegistrationInput {
    return this;
  }
}

export class ReadRegistrationOutput {
  organizationID: string;
  eventID: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  userID: string;
  accessCode: string;
  wantEmailUpdates: boolean;
  wantSMSUpdates: boolean;
  passthroughInvitationID: string;
  paidStatus: string;
  greatfeatsParticipantID: string;
  detailsJSON: string;

  constructor({
    organizationID,
    eventID,
    id,
    firstName,
    lastName,
    email,
    mobilePhone,
    userID,
    accessCode,
    wantEmailUpdates,
    wantSMSUpdates,
    passthroughInvitationID,
    paidStatus,
    greatfeatsParticipantID,
    detailsJSON,
  }: Partial<TypeWithFieldsOf<ReadRegistrationOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.userID = userID ?? "";
    this.accessCode = accessCode ?? "";
    this.wantEmailUpdates = wantEmailUpdates ?? false;
    this.wantSMSUpdates = wantSMSUpdates ?? false;
    this.passthroughInvitationID = passthroughInvitationID ?? "";
    this.paidStatus = paidStatus ?? "";
    this.greatfeatsParticipantID = greatfeatsParticipantID ?? "";
    this.detailsJSON = detailsJSON ?? "";
  }

  fixClasses(): ReadRegistrationOutput {
    return this;
  }
}

export class ReadSceneInput {
  sceneID: string;
  organizationID: string;
  eventID: string;
  showID: string;

  constructor({
    sceneID,
    organizationID,
    eventID,
    showID,
  }: Partial<TypeWithFieldsOf<ReadSceneInput>> = {}) {
    this.sceneID = sceneID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
  }

  fixClasses(): ReadSceneInput {
    return this;
  }
}

export class ReadSceneOutput {
  organizationID: string;
  eventID: string;
  showID: string;
  id: string;
  streamID: string;
  name: string;
  descriptionMarkdown: string;
  plannedDurationMinutes: number;
  estimatedDurationSeconds: number;
  type: string;
  thumbnailURL: string;
  defaultOverlayConfig: OverlayConfiguration;
  activeOverlayConfig: OverlayConfiguration;
  isCurtain: boolean;
  inheritDefaultStream: boolean;
  url: string;

  constructor({
    organizationID,
    eventID,
    showID,
    id,
    streamID,
    name,
    descriptionMarkdown,
    plannedDurationMinutes,
    estimatedDurationSeconds,
    type,
    thumbnailURL,
    defaultOverlayConfig,
    activeOverlayConfig,
    isCurtain,
    inheritDefaultStream,
    url,
  }: Partial<TypeWithFieldsOf<ReadSceneOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.id = id ?? "";
    this.streamID = streamID ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.plannedDurationMinutes = plannedDurationMinutes ?? 0;
    this.estimatedDurationSeconds = estimatedDurationSeconds ?? 0;
    this.type = type ?? "";
    this.thumbnailURL = thumbnailURL ?? "";
    this.defaultOverlayConfig = defaultOverlayConfig ?? new OverlayConfiguration();
    this.activeOverlayConfig = activeOverlayConfig ?? new OverlayConfiguration();
    this.isCurtain = isCurtain ?? false;
    this.inheritDefaultStream = inheritDefaultStream ?? false;
    this.url = url ?? "";
  }

  convertUnexpectedNulls(): ReadSceneOutput {
    this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): ReadSceneOutput {
    this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    return this;
  }
}

export class ReadShowInput {
  showID: string;
  organizationID: string;
  eventID: string;

  constructor({
    showID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<ReadShowInput>> = {}) {
    this.showID = showID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): ReadShowInput {
    return this;
  }
}

export class ReadShowOutput {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  descriptionMarkdown: string;
  sceneOrderIDs: string[];
  totalDurationMinutes: number;
  activeSceneID: string;
  primaryFlowID: string;
  defaultOverlayConfig: OverlayConfiguration;
  overrideOverlayConfig: OverlayConfiguration;
  activeOverlayConfig: OverlayConfiguration;
  defaultStreamType: string;
  defaultStreamID: string;
  url: string;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    descriptionMarkdown,
    sceneOrderIDs,
    totalDurationMinutes,
    activeSceneID,
    primaryFlowID,
    defaultOverlayConfig,
    overrideOverlayConfig,
    activeOverlayConfig,
    defaultStreamType,
    defaultStreamID,
    url,
  }: Partial<TypeWithFieldsOf<ReadShowOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.sceneOrderIDs = sceneOrderIDs ?? [];
    this.totalDurationMinutes = totalDurationMinutes ?? 0;
    this.activeSceneID = activeSceneID ?? "";
    this.primaryFlowID = primaryFlowID ?? "";
    this.defaultOverlayConfig = defaultOverlayConfig ?? new OverlayConfiguration();
    this.overrideOverlayConfig = overrideOverlayConfig ?? new OverlayConfiguration();
    this.activeOverlayConfig = activeOverlayConfig ?? new OverlayConfiguration();
    this.defaultStreamType = defaultStreamType ?? "";
    this.defaultStreamID = defaultStreamID ?? "";
    this.url = url ?? "";
  }

  convertUnexpectedNulls(): ReadShowOutput {
    if (!this.sceneOrderIDs) {
      this.sceneOrderIDs = [];
    }
    this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    this.overrideOverlayConfig = this.overrideOverlayConfig!.convertUnexpectedNulls();
    this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): ReadShowOutput {
    this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    this.overrideOverlayConfig = new OverlayConfiguration(this.overrideOverlayConfig!).fixClasses();
    this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    return this;
  }
}

export class ReadTagsInput {
  organizationID: string;

  constructor({
    organizationID,
  }: Partial<TypeWithFieldsOf<ReadTagsInput>> = {}) {
    this.organizationID = organizationID ?? "";
  }

  fixClasses(): ReadTagsInput {
    return this;
  }
}

export class ReadTagsOutput {
  tags: string[];

  constructor({
    tags,
  }: Partial<TypeWithFieldsOf<ReadTagsOutput>> = {}) {
    this.tags = tags ?? [];
  }

  convertUnexpectedNulls(): ReadTagsOutput {
    if (!this.tags) {
      this.tags = [];
    }
    return this;
  }

  fixClasses(): ReadTagsOutput {
    return this;
  }
}

export class ReadTicketOutput {
  organizationID: string;
  id: string;
  source: string;
  ticketTypeID: string;
  groupID: string;
  purchaserUserID: string;
  purchaserFirstName: string;
  purchaserLastName: string;
  purchaserEmail: string;
  attendeeUserID: string;
  attendeeFirstName: string;
  attendeeLastName: string;
  attendeeEmail: string;
  attendeeMobilePhone: string;
  priceCents: number;
  valueCents: number;
  comped: boolean;
  managerFirstName: string;
  managerLastName: string;
  managerEmail: string;
  checkInTime: (Date | null);
  claimedTime: (Date | null);
  preEventQuestionnaireCompleteTime: (Date | null);
  isLocked: boolean;
  promoCodeUsedID: string;

  constructor({
    organizationID,
    id,
    source,
    ticketTypeID,
    groupID,
    purchaserUserID,
    purchaserFirstName,
    purchaserLastName,
    purchaserEmail,
    attendeeUserID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
    attendeeMobilePhone,
    priceCents,
    valueCents,
    comped,
    managerFirstName,
    managerLastName,
    managerEmail,
    checkInTime,
    claimedTime,
    preEventQuestionnaireCompleteTime,
    isLocked,
    promoCodeUsedID,
  }: Partial<TypeWithFieldsOf<ReadTicketOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
    this.source = source ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.groupID = groupID ?? "";
    this.purchaserUserID = purchaserUserID ?? "";
    this.purchaserFirstName = purchaserFirstName ?? "";
    this.purchaserLastName = purchaserLastName ?? "";
    this.purchaserEmail = purchaserEmail ?? "";
    this.attendeeUserID = attendeeUserID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? "";
    this.attendeeLastName = attendeeLastName ?? "";
    this.attendeeEmail = attendeeEmail ?? "";
    this.attendeeMobilePhone = attendeeMobilePhone ?? "";
    this.priceCents = priceCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.comped = comped ?? false;
    this.managerFirstName = managerFirstName ?? "";
    this.managerLastName = managerLastName ?? "";
    this.managerEmail = managerEmail ?? "";
    this.checkInTime = checkInTime ?? null;
    this.claimedTime = claimedTime ?? null;
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.isLocked = isLocked ?? false;
    this.promoCodeUsedID = promoCodeUsedID ?? "";
  }

  convertDates(): ReadTicketOutput {
    if (this.checkInTime !== undefined && this.checkInTime !== null) {
      this.checkInTime = new Date(this.checkInTime!);
    }
    if (this.claimedTime !== undefined && this.claimedTime !== null) {
      this.claimedTime = new Date(this.claimedTime!);
    }
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): ReadTicketOutput {
    return this;
  }
}

export class ReadVirtualChairInput {
  id: string;
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;

  constructor({
    id,
    organizationID,
    virtualEventID,
    virtualTableID,
  }: Partial<TypeWithFieldsOf<ReadVirtualChairInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
  }

  fixClasses(): ReadVirtualChairInput {
    return this;
  }
}

export class ReadVirtualChairOutput {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  id: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userID: string;
  channelName: string;
  channelARN: string;
  created: Date;
  updated: Date;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    id,
    userEmail,
    userFirstName,
    userLastName,
    userID,
    channelName,
    channelARN,
    created,
    updated,
  }: Partial<TypeWithFieldsOf<ReadVirtualChairOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.id = id ?? "";
    this.userEmail = userEmail ?? "";
    this.userFirstName = userFirstName ?? "";
    this.userLastName = userLastName ?? "";
    this.userID = userID ?? "";
    this.channelName = channelName ?? "";
    this.channelARN = channelARN ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
  }

  convertDates(): ReadVirtualChairOutput {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): ReadVirtualChairOutput {
    return this;
  }
}

export class ReadVirtualTableInput {
  id: string;
  organizationID: string;
  virtualEventID: string;

  constructor({
    id,
    organizationID,
    virtualEventID,
  }: Partial<TypeWithFieldsOf<ReadVirtualTableInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
  }

  fixClasses(): ReadVirtualTableInput {
    return this;
  }
}

export class ReadVirtualTableOutput {
  organizationID: string;
  virtualEventID: string;
  id: string;
  name: string;
  tableNumber: number;
  created: Date;
  updated: Date;

  constructor({
    organizationID,
    virtualEventID,
    id,
    name,
    tableNumber,
    created,
    updated,
  }: Partial<TypeWithFieldsOf<ReadVirtualTableOutput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.tableNumber = tableNumber ?? 0;
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
  }

  convertDates(): ReadVirtualTableOutput {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): ReadVirtualTableOutput {
    return this;
  }
}

export class Recipient {
  emails: string[];
  smsPhoneNumbers: string[];
  data: {[index: string]: any};

  constructor({
    emails,
    smsPhoneNumbers,
    data,
  }: Partial<TypeWithFieldsOf<Recipient>> = {}) {
    this.emails = emails ?? [];
    this.smsPhoneNumbers = smsPhoneNumbers ?? [];
    this.data = data ?? {};
  }

  convertUnexpectedNulls(): Recipient {
    if (!this.emails) {
      this.emails = [];
    }
    if (!this.smsPhoneNumbers) {
      this.smsPhoneNumbers = [];
    }
    if (!this.data) {
      this.data = {};
    }
    return this;
  }

  fixClasses(): Recipient {
    return this;
  }
}

export class RefundItemDetail {
  type: string;
  name: string;
  quantity: number;
  amountCents: number;
  valueCents: number;
  refundID: string;

  constructor({
    type,
    name,
    quantity,
    amountCents,
    valueCents,
    refundID,
  }: Partial<TypeWithFieldsOf<RefundItemDetail>> = {}) {
    this.type = type ?? "";
    this.name = name ?? "";
    this.quantity = quantity ?? 0;
    this.amountCents = amountCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.refundID = refundID ?? "";
  }

  fixClasses(): RefundItemDetail {
    return this;
  }
}

export class RefundPayment {
  paymentID: string;
  refundID: string;
  refundType: string;
  status: string;

  constructor({
    paymentID,
    refundID,
    refundType,
    status,
  }: Partial<TypeWithFieldsOf<RefundPayment>> = {}) {
    this.paymentID = paymentID ?? "";
    this.refundID = refundID ?? "";
    this.refundType = refundType ?? "";
    this.status = status ?? "";
  }

  fixClasses(): RefundPayment {
    return this;
  }
}

export class RefundPaymentInput {
  organizationID: string;
  eventID: string;
  purchasedItemIDs: string[];
  paymentIDs: string[];
  reasonForRefund: string;
  includeCoveredCost: boolean;

  constructor({
    organizationID,
    eventID,
    purchasedItemIDs,
    paymentIDs,
    reasonForRefund,
    includeCoveredCost,
  }: Partial<TypeWithFieldsOf<RefundPaymentInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.purchasedItemIDs = purchasedItemIDs ?? [];
    this.paymentIDs = paymentIDs ?? [];
    this.reasonForRefund = reasonForRefund ?? "";
    this.includeCoveredCost = includeCoveredCost ?? false;
  }

  convertUnexpectedNulls(): RefundPaymentInput {
    if (!this.purchasedItemIDs) {
      this.purchasedItemIDs = [];
    }
    if (!this.paymentIDs) {
      this.paymentIDs = [];
    }
    return this;
  }

  fixClasses(): RefundPaymentInput {
    return this;
  }
}

export class RefundPaymentOutput {
  refundPayments: RefundPayment[];

  constructor({
    refundPayments,
  }: Partial<TypeWithFieldsOf<RefundPaymentOutput>> = {}) {
    this.refundPayments = refundPayments ?? [];
  }

  convertUnexpectedNulls(): RefundPaymentOutput {
    if (!this.refundPayments) {
      this.refundPayments = [];
    }
    return this;
  }

  fixClasses(): RefundPaymentOutput {
    for (let i0 = 0; i0 < this.refundPayments.length; i0 += 1) {
      this.refundPayments[i0] = new RefundPayment(this.refundPayments[i0]!).fixClasses();
    }
    return this;
  }
}

export class RegistrationFreePayForItemsInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  itemIDs: string[];
  cardID: string;
  cardHolderGuestID: string;
  cardName: string;
  expiration: string;
  cardType: string;
  cardLastFour: string;
  token: string;
  saveCardOnFile: boolean;
  coverFee: boolean;
  payerName: string;
  payerStreet: string;
  payerCity: string;
  payerState: string;
  payerZip: string;
  payerCountry: string;
  fakeTransactionResult: string;
  paymentType: string;
  attendeeUserID: string;
  submittedByUserID: string;
  paymentTypeOtherNotes: string;
  checkNumber: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    itemIDs,
    cardID,
    cardHolderGuestID,
    cardName,
    expiration,
    cardType,
    cardLastFour,
    token,
    saveCardOnFile,
    coverFee,
    payerName,
    payerStreet,
    payerCity,
    payerState,
    payerZip,
    payerCountry,
    fakeTransactionResult,
    paymentType,
    attendeeUserID,
    submittedByUserID,
    paymentTypeOtherNotes,
    checkNumber,
  }: Partial<TypeWithFieldsOf<RegistrationFreePayForItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.itemIDs = itemIDs ?? [];
    this.cardID = cardID ?? "";
    this.cardHolderGuestID = cardHolderGuestID ?? "";
    this.cardName = cardName ?? "";
    this.expiration = expiration ?? "";
    this.cardType = cardType ?? "";
    this.cardLastFour = cardLastFour ?? "";
    this.token = token ?? "";
    this.saveCardOnFile = saveCardOnFile ?? false;
    this.coverFee = coverFee ?? false;
    this.payerName = payerName ?? "";
    this.payerStreet = payerStreet ?? "";
    this.payerCity = payerCity ?? "";
    this.payerState = payerState ?? "";
    this.payerZip = payerZip ?? "";
    this.payerCountry = payerCountry ?? "";
    this.fakeTransactionResult = fakeTransactionResult ?? "";
    this.paymentType = paymentType ?? "";
    this.attendeeUserID = attendeeUserID ?? "";
    this.submittedByUserID = submittedByUserID ?? "";
    this.paymentTypeOtherNotes = paymentTypeOtherNotes ?? "";
    this.checkNumber = checkNumber ?? "";
  }

  convertUnexpectedNulls(): RegistrationFreePayForItemsInput {
    if (!this.itemIDs) {
      this.itemIDs = [];
    }
    return this;
  }

  fixClasses(): RegistrationFreePayForItemsInput {
    return this;
  }
}

export class RegistrationFreePayForItemsOutput {
  transactionID: string;

  constructor({
    transactionID,
  }: Partial<TypeWithFieldsOf<RegistrationFreePayForItemsOutput>> = {}) {
    this.transactionID = transactionID ?? "";
  }

  fixClasses(): RegistrationFreePayForItemsOutput {
    return this;
  }
}

export class RegistrationSummary {
  organizationID: string;
  eventID: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  userID: string;
  accessCode: string;
  wantEmailUpdates: boolean;
  wantSMSUpdates: boolean;
  paidStatus: string;

  constructor({
    organizationID,
    eventID,
    id,
    firstName,
    lastName,
    email,
    mobilePhone,
    userID,
    accessCode,
    wantEmailUpdates,
    wantSMSUpdates,
    paidStatus,
  }: Partial<TypeWithFieldsOf<RegistrationSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.userID = userID ?? "";
    this.accessCode = accessCode ?? "";
    this.wantEmailUpdates = wantEmailUpdates ?? false;
    this.wantSMSUpdates = wantSMSUpdates ?? false;
    this.paidStatus = paidStatus ?? "";
  }

  fixClasses(): RegistrationSummary {
    return this;
  }
}

export class RemoveProxyGuestInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  proxyGuestID: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    proxyGuestID,
  }: Partial<TypeWithFieldsOf<RemoveProxyGuestInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
  }

  fixClasses(): RemoveProxyGuestInput {
    return this;
  }
}

export class RemoveProxyGuestOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<RemoveProxyGuestOutput>> = {}) {
  }

  fixClasses(): RemoveProxyGuestOutput {
    return this;
  }
}

export class RemoveProxyLinkInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  purchasedItemIDsToReassign: string[];

  constructor({
    organizationID,
    eventID,
    guestID,
    purchasedItemIDsToReassign,
  }: Partial<TypeWithFieldsOf<RemoveProxyLinkInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.purchasedItemIDsToReassign = purchasedItemIDsToReassign ?? [];
  }

  convertUnexpectedNulls(): RemoveProxyLinkInput {
    if (!this.purchasedItemIDsToReassign) {
      this.purchasedItemIDsToReassign = [];
    }
    return this;
  }

  fixClasses(): RemoveProxyLinkInput {
    return this;
  }
}

export class RemoveProxyLinkOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<RemoveProxyLinkOutput>> = {}) {
  }

  fixClasses(): RemoveProxyLinkOutput {
    return this;
  }
}

export class RescindHighestBidInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
  }: Partial<TypeWithFieldsOf<RescindHighestBidInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
  }

  fixClasses(): RescindHighestBidInput {
    return this;
  }
}

export class RescindHighestBidOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<RescindHighestBidOutput>> = {}) {
  }

  fixClasses(): RescindHighestBidOutput {
    return this;
  }
}

export class RescindPurchasedItemInput {
  organizationID: string;
  eventID: string;
  purchasedItemID: string;

  constructor({
    organizationID,
    eventID,
    purchasedItemID,
  }: Partial<TypeWithFieldsOf<RescindPurchasedItemInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.purchasedItemID = purchasedItemID ?? "";
  }

  fixClasses(): RescindPurchasedItemInput {
    return this;
  }
}

export class RescindPurchasedItemOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<RescindPurchasedItemOutput>> = {}) {
  }

  fixClasses(): RescindPurchasedItemOutput {
    return this;
  }
}

export class ResetMyTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  editTicketKey: string;

  constructor({
    organizationID,
    eventID,
    ticketID,
    editTicketKey,
  }: Partial<TypeWithFieldsOf<ResetMyTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.editTicketKey = editTicketKey ?? "";
  }

  fixClasses(): ResetMyTicketInput {
    return this;
  }
}

export class ResetMyTicketOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<ResetMyTicketOutput>> = {}) {
  }

  fixClasses(): ResetMyTicketOutput {
    return this;
  }
}

export class ResetTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  editTicketKey: string;

  constructor({
    organizationID,
    eventID,
    ticketID,
    editTicketKey,
  }: Partial<TypeWithFieldsOf<ResetTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.editTicketKey = editTicketKey ?? "";
  }

  fixClasses(): ResetTicketInput {
    return this;
  }
}

export class ResetTicketOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<ResetTicketOutput>> = {}) {
  }

  fixClasses(): ResetTicketOutput {
    return this;
  }
}

export class SceneSummary {
  organizationID: string;
  eventID: string;
  showID: string;
  id: string;
  streamID: string;
  name: string;
  descriptionMarkdown: string;
  plannedDurationMinutes: number;
  estimatedDurationSeconds: number;
  type: string;
  thumbnailURL: string;
  defaultOverlayConfig: OverlayConfiguration;
  activeOverlayConfig: OverlayConfiguration;
  isCurtain: boolean;
  inheritDefaultStream: boolean;
  url: string;

  constructor({
    organizationID,
    eventID,
    showID,
    id,
    streamID,
    name,
    descriptionMarkdown,
    plannedDurationMinutes,
    estimatedDurationSeconds,
    type,
    thumbnailURL,
    defaultOverlayConfig,
    activeOverlayConfig,
    isCurtain,
    inheritDefaultStream,
    url,
  }: Partial<TypeWithFieldsOf<SceneSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.id = id ?? "";
    this.streamID = streamID ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.plannedDurationMinutes = plannedDurationMinutes ?? 0;
    this.estimatedDurationSeconds = estimatedDurationSeconds ?? 0;
    this.type = type ?? "";
    this.thumbnailURL = thumbnailURL ?? "";
    this.defaultOverlayConfig = defaultOverlayConfig ?? new OverlayConfiguration();
    this.activeOverlayConfig = activeOverlayConfig ?? new OverlayConfiguration();
    this.isCurtain = isCurtain ?? false;
    this.inheritDefaultStream = inheritDefaultStream ?? false;
    this.url = url ?? "";
  }

  convertUnexpectedNulls(): SceneSummary {
    this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): SceneSummary {
    this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    return this;
  }
}

export class SearchGuestsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;
  query: string;
  reportVersion: string;

  constructor({
    organizationID,
    eventID,
    paging,
    query,
    reportVersion,
  }: Partial<TypeWithFieldsOf<SearchGuestsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
    this.query = query ?? "";
    this.reportVersion = reportVersion ?? "";
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    params = this.query ? params.set('query', this.query.toString()) : params;
    params = this.reportVersion ? params.set('reportVersion', this.reportVersion.toString()) : params;
    return params;
  }

  fixClasses(): SearchGuestsInput {
    return this;
  }
}

export class SearchGuestsOutput {
  guests: SearchGuestsSummary[];
  paging: (PagingOutputV3 | null);

  constructor({
    guests,
    paging,
  }: Partial<TypeWithFieldsOf<SearchGuestsOutput>> = {}) {
    this.guests = guests ?? [];
    this.paging = paging ?? null;
  }

  convertUnexpectedNulls(): SearchGuestsOutput {
    if (!this.guests) {
      this.guests = [];
    }
    return this;
  }

  fixClasses(): SearchGuestsOutput {
    for (let i0 = 0; i0 < this.guests.length; i0 += 1) {
      this.guests[i0] = new SearchGuestsSummary(this.guests[i0]!).fixClasses();
    }
    return this;
  }
}

export class SearchGuestsSummary {
  guestID: string;
  guestSessionID: string;
  eventSessionID: string;
  ticketID: string;
  guestUserID: string;
  guestFirstName: string;
  guestLastName: string;
  managingGuestID: string;
  isCheckinWithManagingGuest: number;
  managingGuestFirstName: string;
  managingGuestLastName: string;
  managingGuestEmail: string;
  guestEmail: string;
  guestMobilePhone: string;
  guestBidderNumber: number;
  guestAttendeeNumber: string;
  checkedInTime: (string | null);
  tableName: string;
  tableNumber: string;
  sponsorAttachmentID: string;
  sponsorName: string;
  ticketTypeID: string;
  ticketTypeName: string;
  ticketClaimedTime: (string | null);
  ticketCreatedTime: string;
  guestStatus: string;
  displayName: string;
  compedTicket: number;
  ticketGroupID: string;
  ticketPurchaserFirstName: string;
  ticketPurchaserLastName: string;
  ticketPurchaserFullName: string;
  ticketPurchaserUserID: string;
  ticketPurchaserEmail: string;
  ticketManagerFirstName: string;
  ticketManagerLastName: string;
  ticketManagerFullName: string;
  ticketManagerEmail: string;
  biddingAsGuestID: string;
  biddingAsFullName: string;
  biddingAsFirstName: string;
  biddingAsLastName: string;
  preEventQuestionnaireCompleteTime: (string | null);
  attendeeExperience: string;
  hasUserCard: number;
  hasUserAccount: number;

  constructor({
    guestID,
    guestSessionID,
    eventSessionID,
    ticketID,
    guestUserID,
    guestFirstName,
    guestLastName,
    managingGuestID,
    isCheckinWithManagingGuest,
    managingGuestFirstName,
    managingGuestLastName,
    managingGuestEmail,
    guestEmail,
    guestMobilePhone,
    guestBidderNumber,
    guestAttendeeNumber,
    checkedInTime,
    tableName,
    tableNumber,
    sponsorAttachmentID,
    sponsorName,
    ticketTypeID,
    ticketTypeName,
    ticketClaimedTime,
    ticketCreatedTime,
    guestStatus,
    displayName,
    compedTicket,
    ticketGroupID,
    ticketPurchaserFirstName,
    ticketPurchaserLastName,
    ticketPurchaserFullName,
    ticketPurchaserUserID,
    ticketPurchaserEmail,
    ticketManagerFirstName,
    ticketManagerLastName,
    ticketManagerFullName,
    ticketManagerEmail,
    biddingAsGuestID,
    biddingAsFullName,
    biddingAsFirstName,
    biddingAsLastName,
    preEventQuestionnaireCompleteTime,
    attendeeExperience,
    hasUserCard,
    hasUserAccount,
  }: Partial<TypeWithFieldsOf<SearchGuestsSummary>> = {}) {
    this.guestID = guestID ?? "";
    this.guestSessionID = guestSessionID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.ticketID = ticketID ?? "";
    this.guestUserID = guestUserID ?? "";
    this.guestFirstName = guestFirstName ?? "";
    this.guestLastName = guestLastName ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.isCheckinWithManagingGuest = isCheckinWithManagingGuest ?? 0;
    this.managingGuestFirstName = managingGuestFirstName ?? "";
    this.managingGuestLastName = managingGuestLastName ?? "";
    this.managingGuestEmail = managingGuestEmail ?? "";
    this.guestEmail = guestEmail ?? "";
    this.guestMobilePhone = guestMobilePhone ?? "";
    this.guestBidderNumber = guestBidderNumber ?? 0;
    this.guestAttendeeNumber = guestAttendeeNumber ?? "";
    this.checkedInTime = checkedInTime ?? null;
    this.tableName = tableName ?? "";
    this.tableNumber = tableNumber ?? "";
    this.sponsorAttachmentID = sponsorAttachmentID ?? "";
    this.sponsorName = sponsorName ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.ticketTypeName = ticketTypeName ?? "";
    this.ticketClaimedTime = ticketClaimedTime ?? null;
    this.ticketCreatedTime = ticketCreatedTime ?? "";
    this.guestStatus = guestStatus ?? "";
    this.displayName = displayName ?? "";
    this.compedTicket = compedTicket ?? 0;
    this.ticketGroupID = ticketGroupID ?? "";
    this.ticketPurchaserFirstName = ticketPurchaserFirstName ?? "";
    this.ticketPurchaserLastName = ticketPurchaserLastName ?? "";
    this.ticketPurchaserFullName = ticketPurchaserFullName ?? "";
    this.ticketPurchaserUserID = ticketPurchaserUserID ?? "";
    this.ticketPurchaserEmail = ticketPurchaserEmail ?? "";
    this.ticketManagerFirstName = ticketManagerFirstName ?? "";
    this.ticketManagerLastName = ticketManagerLastName ?? "";
    this.ticketManagerFullName = ticketManagerFullName ?? "";
    this.ticketManagerEmail = ticketManagerEmail ?? "";
    this.biddingAsGuestID = biddingAsGuestID ?? "";
    this.biddingAsFullName = biddingAsFullName ?? "";
    this.biddingAsFirstName = biddingAsFirstName ?? "";
    this.biddingAsLastName = biddingAsLastName ?? "";
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.attendeeExperience = attendeeExperience ?? "";
    this.hasUserCard = hasUserCard ?? 0;
    this.hasUserAccount = hasUserAccount ?? 0;
  }

  fixClasses(): SearchGuestsSummary {
    return this;
  }
}

export class SelfRegisterForEventInput {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  passthroughInvitationID: string;
  paidStatus: string;
  attendeeExperience: string;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    mobilePhone,
    street,
    city,
    state,
    zip,
    country,
    passthroughInvitationID,
    paidStatus,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<SelfRegisterForEventInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.street = street ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.zip = zip ?? "";
    this.country = country ?? "";
    this.passthroughInvitationID = passthroughInvitationID ?? "";
    this.paidStatus = paidStatus ?? "";
    this.attendeeExperience = attendeeExperience ?? "";
  }

  fixClasses(): SelfRegisterForEventInput {
    return this;
  }
}

export class SelfRegisterForEventOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SelfRegisterForEventOutput>> = {}) {
  }

  fixClasses(): SelfRegisterForEventOutput {
    return this;
  }
}

export class SendEmailInput {
  organizationID: string;
  eventID: string;
  bodyMarkdown: string;
  emailSubject: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    bodyMarkdown,
    emailSubject,
    paging,
  }: Partial<TypeWithFieldsOf<SendEmailInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.bodyMarkdown = bodyMarkdown ?? "";
    this.emailSubject = emailSubject ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): SendEmailInput {
    return this;
  }
}

export class SendEmailOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendEmailOutput>> = {}) {
  }

  fixClasses(): SendEmailOutput {
    return this;
  }
}

export class SendEventAuctionClosedInput {
  organizationID: string;
  auctionID: string;

  constructor({
    organizationID,
    auctionID,
  }: Partial<TypeWithFieldsOf<SendEventAuctionClosedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.auctionID = auctionID ?? "";
  }

  fixClasses(): SendEventAuctionClosedInput {
    return this;
  }
}

export class SendEventAuctionUpdatedInput {
  organizationID: string;
  auctionID: string;

  constructor({
    organizationID,
    auctionID,
  }: Partial<TypeWithFieldsOf<SendEventAuctionUpdatedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.auctionID = auctionID ?? "";
  }

  fixClasses(): SendEventAuctionUpdatedInput {
    return this;
  }
}

export class SendEventDailyInput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendEventDailyInput>> = {}) {
  }

  fixClasses(): SendEventDailyInput {
    return this;
  }
}

export class SendEventGuestSessionCreatedInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestSessionID: string;
  groupID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    guestSessionID,
    groupID,
  }: Partial<TypeWithFieldsOf<SendEventGuestSessionCreatedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestSessionID = guestSessionID ?? "";
    this.groupID = groupID ?? "";
  }

  fixClasses(): SendEventGuestSessionCreatedInput {
    return this;
  }
}

export class SendEventHourlyInput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendEventHourlyInput>> = {}) {
  }

  fixClasses(): SendEventHourlyInput {
    return this;
  }
}

export class SendEventInventoryItemPhotoUpdatedInput {
  organizationID: string;
  itemID: string;
  affectedLots: InventoryItemPhotoUpdatedInput[];

  constructor({
    organizationID,
    itemID,
    affectedLots,
  }: Partial<TypeWithFieldsOf<SendEventInventoryItemPhotoUpdatedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.itemID = itemID ?? "";
    this.affectedLots = affectedLots ?? [];
  }

  convertUnexpectedNulls(): SendEventInventoryItemPhotoUpdatedInput {
    if (!this.affectedLots) {
      this.affectedLots = [];
    }
    return this;
  }

  fixClasses(): SendEventInventoryItemPhotoUpdatedInput {
    for (let i0 = 0; i0 < this.affectedLots.length; i0 += 1) {
      this.affectedLots[i0] = new InventoryItemPhotoUpdatedInput(this.affectedLots[i0]!).fixClasses();
    }
    return this;
  }
}

export class SendEventLotClosedInput {
  organizationID: string;
  auctionID: string;
  lotID: string;
  closeTime: Date;
  winningBidID: string;
  winningUserID: string;
  winningGuestID: string;
  dollarAmountCents: number;
  auctionSource: string;
  isAssignLater: boolean;

  constructor({
    organizationID,
    auctionID,
    lotID,
    closeTime,
    winningBidID,
    winningUserID,
    winningGuestID,
    dollarAmountCents,
    auctionSource,
    isAssignLater,
  }: Partial<TypeWithFieldsOf<SendEventLotClosedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.closeTime = closeTime ?? new Date();
    this.winningBidID = winningBidID ?? "";
    this.winningUserID = winningUserID ?? "";
    this.winningGuestID = winningGuestID ?? "";
    this.dollarAmountCents = dollarAmountCents ?? 0;
    this.auctionSource = auctionSource ?? "";
    this.isAssignLater = isAssignLater ?? false;
  }

  convertDates(): SendEventLotClosedInput {
    this.closeTime = new Date(this.closeTime!);
    return this;
  }

  fixClasses(): SendEventLotClosedInput {
    return this;
  }
}

export class SendEventNightlyInput {
  organizationID: string;

  constructor({
    organizationID,
  }: Partial<TypeWithFieldsOf<SendEventNightlyInput>> = {}) {
    this.organizationID = organizationID ?? "";
  }

  fixClasses(): SendEventNightlyInput {
    return this;
  }
}

export class SendEventRafflePurchaseLevelCreatedInput {
  organizationID: string;
  raffleID: string;
  id: string;

  constructor({
    organizationID,
    raffleID,
    id,
  }: Partial<TypeWithFieldsOf<SendEventRafflePurchaseLevelCreatedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.raffleID = raffleID ?? "";
    this.id = id ?? "";
  }

  fixClasses(): SendEventRafflePurchaseLevelCreatedInput {
    return this;
  }
}

export class SendEventRafflePurchaseLevelDeletedInput {
  organizationID: string;
  raffleID: string;
  id: string;

  constructor({
    organizationID,
    raffleID,
    id,
  }: Partial<TypeWithFieldsOf<SendEventRafflePurchaseLevelDeletedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.raffleID = raffleID ?? "";
    this.id = id ?? "";
  }

  fixClasses(): SendEventRafflePurchaseLevelDeletedInput {
    return this;
  }
}

export class SendEventRafflePurchaseLevelUpdatedInput {
  organizationID: string;
  raffleID: string;
  id: string;

  constructor({
    organizationID,
    raffleID,
    id,
  }: Partial<TypeWithFieldsOf<SendEventRafflePurchaseLevelUpdatedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.raffleID = raffleID ?? "";
    this.id = id ?? "";
  }

  fixClasses(): SendEventRafflePurchaseLevelUpdatedInput {
    return this;
  }
}

export class SendEventRaffleRaffleDeletedInput {
  organizationID: string;
  id: string;

  constructor({
    organizationID,
    id,
  }: Partial<TypeWithFieldsOf<SendEventRaffleRaffleDeletedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
  }

  fixClasses(): SendEventRaffleRaffleDeletedInput {
    return this;
  }
}

export class SendEventRaffleRaffleUpdatedInput {
  organizationID: string;
  id: string;

  constructor({
    organizationID,
    id,
  }: Partial<TypeWithFieldsOf<SendEventRaffleRaffleUpdatedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
  }

  fixClasses(): SendEventRaffleRaffleUpdatedInput {
    return this;
  }
}

export class SendEventSendCheckedInNotifcationInput {
  organizationID: string;
  source: string;
  ticketID: string;
  attendeeFirstName: string;
  attendeeLastName: string;
  attendeeEmail: string;
  attendeeMobilePhone: string;
  attendeeUserID: string;

  constructor({
    organizationID,
    source,
    ticketID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
    attendeeMobilePhone,
    attendeeUserID,
  }: Partial<TypeWithFieldsOf<SendEventSendCheckedInNotifcationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.source = source ?? "";
    this.ticketID = ticketID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? "";
    this.attendeeLastName = attendeeLastName ?? "";
    this.attendeeEmail = attendeeEmail ?? "";
    this.attendeeMobilePhone = attendeeMobilePhone ?? "";
    this.attendeeUserID = attendeeUserID ?? "";
  }

  fixClasses(): SendEventSendCheckedInNotifcationInput {
    return this;
  }
}

export class SendEventSendCheckInMessageInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<SendEventSendCheckInMessageInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): SendEventSendCheckInMessageInput {
    return this;
  }
}

export class SendEventSendPreEventQuestionnaireMessageInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<SendEventSendPreEventQuestionnaireMessageInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): SendEventSendPreEventQuestionnaireMessageInput {
    return this;
  }
}

export class SendEventSendTicketInviteEmailInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  purchaserFirstName: string;
  purchaserLastName: string;
  purchaserEmail: string;
  purchaserUserID: string;
  suppressEmailToPurchaser: boolean;

  constructor({
    organizationID,
    eventID,
    ticketID,
    purchaserFirstName,
    purchaserLastName,
    purchaserEmail,
    purchaserUserID,
    suppressEmailToPurchaser,
  }: Partial<TypeWithFieldsOf<SendEventSendTicketInviteEmailInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.purchaserFirstName = purchaserFirstName ?? "";
    this.purchaserLastName = purchaserLastName ?? "";
    this.purchaserEmail = purchaserEmail ?? "";
    this.purchaserUserID = purchaserUserID ?? "";
    this.suppressEmailToPurchaser = suppressEmailToPurchaser ?? false;
  }

  fixClasses(): SendEventSendTicketInviteEmailInput {
    return this;
  }
}

export class SendEventSendTicketManagerEmailInput {
  organizationID: string;
  eventID: string;
  purchaserFirstName: string;
  purchaserEmail: string;
  purchaserUserID: string;
  ticketManagementToken: string;

  constructor({
    organizationID,
    eventID,
    purchaserFirstName,
    purchaserEmail,
    purchaserUserID,
    ticketManagementToken,
  }: Partial<TypeWithFieldsOf<SendEventSendTicketManagerEmailInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.purchaserFirstName = purchaserFirstName ?? "";
    this.purchaserEmail = purchaserEmail ?? "";
    this.purchaserUserID = purchaserUserID ?? "";
    this.ticketManagementToken = ticketManagementToken ?? "";
  }

  fixClasses(): SendEventSendTicketManagerEmailInput {
    return this;
  }
}

export class SendEventSupporterUserUpdatedInput {
  supporterUserID: string;
  organizationID: string;
  userID: string;
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phoneNumber: string;

  constructor({
    supporterUserID,
    organizationID,
    userID,
    email,
    firstName,
    lastName,
    address,
    city,
    state,
    postalCode,
    country,
    phoneNumber,
  }: Partial<TypeWithFieldsOf<SendEventSupporterUserUpdatedInput>> = {}) {
    this.supporterUserID = supporterUserID ?? "";
    this.organizationID = organizationID ?? "";
    this.userID = userID ?? "";
    this.email = email ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.address = address ?? "";
    this.city = city ?? "";
    this.state = state ?? "";
    this.postalCode = postalCode ?? "";
    this.country = country ?? "";
    this.phoneNumber = phoneNumber ?? "";
  }

  fixClasses(): SendEventSupporterUserUpdatedInput {
    return this;
  }
}

export class SendEventVirtualEventImpactGoalMetInput {
  organizationID: string;
  eventID: string;
  impactID: string;

  constructor({
    organizationID,
    eventID,
    impactID,
  }: Partial<TypeWithFieldsOf<SendEventVirtualEventImpactGoalMetInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
  }

  fixClasses(): SendEventVirtualEventImpactGoalMetInput {
    return this;
  }
}

export class SendEventVirtualEventProcessPendingPurchasedItemsInput {
  organizationID: string;
  eventID: string;
  fakeTransactionResult: string;

  constructor({
    organizationID,
    eventID,
    fakeTransactionResult,
  }: Partial<TypeWithFieldsOf<SendEventVirtualEventProcessPendingPurchasedItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fakeTransactionResult = fakeTransactionResult ?? "";
  }

  fixClasses(): SendEventVirtualEventProcessPendingPurchasedItemsInput {
    return this;
  }
}

export class SendEventVirtualEventPurchaseSuccessfulInput {
  organizationID: string;
  eventID: string;
  purchaseID: string;

  constructor({
    organizationID,
    eventID,
    purchaseID,
  }: Partial<TypeWithFieldsOf<SendEventVirtualEventPurchaseSuccessfulInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.purchaseID = purchaseID ?? "";
  }

  fixClasses(): SendEventVirtualEventPurchaseSuccessfulInput {
    return this;
  }
}

export class SendEventVirtualEventPurchaseSuccessfulWithoutPaymentInput {
  organizationID: string;
  eventID: string;
  itemIDs: string[];
  isExternalPayment: boolean;

  constructor({
    organizationID,
    eventID,
    itemIDs,
    isExternalPayment,
  }: Partial<TypeWithFieldsOf<SendEventVirtualEventPurchaseSuccessfulWithoutPaymentInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.itemIDs = itemIDs ?? [];
    this.isExternalPayment = isExternalPayment ?? false;
  }

  convertUnexpectedNulls(): SendEventVirtualEventPurchaseSuccessfulWithoutPaymentInput {
    if (!this.itemIDs) {
      this.itemIDs = [];
    }
    return this;
  }

  fixClasses(): SendEventVirtualEventPurchaseSuccessfulWithoutPaymentInput {
    return this;
  }
}

export class SendEventVirtualEventRegistrationCreatedInput {
  registrationID: string;
  organizationID: string;
  eventID: string;

  constructor({
    registrationID,
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<SendEventVirtualEventRegistrationCreatedInput>> = {}) {
    this.registrationID = registrationID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): SendEventVirtualEventRegistrationCreatedInput {
    return this;
  }
}

export class SendMyTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;

  constructor({
    organizationID,
    eventID,
    ticketID,
  }: Partial<TypeWithFieldsOf<SendMyTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
  }

  fixClasses(): SendMyTicketInput {
    return this;
  }
}

export class SendMyTicketOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendMyTicketOutput>> = {}) {
  }

  fixClasses(): SendMyTicketOutput {
    return this;
  }
}

export class SendPickupReadyNotificationInput {
  organizationID: string;
  eventID: string;
  userID: string;
  email: string;
  transactionID: string;
  updateIsReadyPickupDetails: UpdateIsReadyPickupDetails[];

  constructor({
    organizationID,
    eventID,
    userID,
    email,
    transactionID,
    updateIsReadyPickupDetails,
  }: Partial<TypeWithFieldsOf<SendPickupReadyNotificationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.email = email ?? "";
    this.transactionID = transactionID ?? "";
    this.updateIsReadyPickupDetails = updateIsReadyPickupDetails ?? [];
  }

  convertUnexpectedNulls(): SendPickupReadyNotificationInput {
    if (!this.updateIsReadyPickupDetails) {
      this.updateIsReadyPickupDetails = [];
    }
    return this;
  }

  fixClasses(): SendPickupReadyNotificationInput {
    for (let i0 = 0; i0 < this.updateIsReadyPickupDetails.length; i0 += 1) {
      this.updateIsReadyPickupDetails[i0] = new UpdateIsReadyPickupDetails(this.updateIsReadyPickupDetails[i0]!).fixClasses();
    }
    return this;
  }
}

export class SendPickupReadyNotificationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendPickupReadyNotificationOutput>> = {}) {
  }

  fixClasses(): SendPickupReadyNotificationOutput {
    return this;
  }
}

export class SendSilentAuctionReminderAlertsInput {
  organizationID: string;
  eventID: string;
  auctionID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
  }: Partial<TypeWithFieldsOf<SendSilentAuctionReminderAlertsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
  }

  fixClasses(): SendSilentAuctionReminderAlertsInput {
    return this;
  }
}

export class SendSilentAuctionReminderAlertsOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendSilentAuctionReminderAlertsOutput>> = {}) {
  }

  fixClasses(): SendSilentAuctionReminderAlertsOutput {
    return this;
  }
}

export class SendSingleEmailInput {
  organizationID: string;
  eventID: string;
  invitationID: string;
  bodyMarkdown: string;
  emailSubject: string;

  constructor({
    organizationID,
    eventID,
    invitationID,
    bodyMarkdown,
    emailSubject,
  }: Partial<TypeWithFieldsOf<SendSingleEmailInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.invitationID = invitationID ?? "";
    this.bodyMarkdown = bodyMarkdown ?? "";
    this.emailSubject = emailSubject ?? "";
  }

  fixClasses(): SendSingleEmailInput {
    return this;
  }
}

export class SendSingleEmailOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendSingleEmailOutput>> = {}) {
  }

  fixClasses(): SendSingleEmailOutput {
    return this;
  }
}

export class SendTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;

  constructor({
    organizationID,
    eventID,
    ticketID,
  }: Partial<TypeWithFieldsOf<SendTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
  }

  fixClasses(): SendTicketInput {
    return this;
  }
}

export class SendTicketOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SendTicketOutput>> = {}) {
  }

  fixClasses(): SendTicketOutput {
    return this;
  }
}

export class SetActiveLotInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  activeLotID: string;

  constructor({
    organizationID,
    eventID,
    auctionID,
    activeLotID,
  }: Partial<TypeWithFieldsOf<SetActiveLotInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.activeLotID = activeLotID ?? "";
  }

  fixClasses(): SetActiveLotInput {
    return this;
  }
}

export class SetActiveLotOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SetActiveLotOutput>> = {}) {
  }

  fixClasses(): SetActiveLotOutput {
    return this;
  }
}

export class SetMyPreEventQuestionnaireCompleteTimeInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<SetMyPreEventQuestionnaireCompleteTimeInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): SetMyPreEventQuestionnaireCompleteTimeInput {
    return this;
  }
}

export class SetMyPreEventQuestionnaireCompleteTimeOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SetMyPreEventQuestionnaireCompleteTimeOutput>> = {}) {
  }

  fixClasses(): SetMyPreEventQuestionnaireCompleteTimeOutput {
    return this;
  }
}

export class ShowSummary {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  descriptionMarkdown: string;
  totalDurationMinutes: number;
  activeSceneID: string;
  defaultOverlayConfig: OverlayConfiguration;
  overrideOverlayConfig: OverlayConfiguration;
  activeOverlayConfig: OverlayConfiguration;
  defaultStreamType: string;
  defaultStreamID: string;
  url: string;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    descriptionMarkdown,
    totalDurationMinutes,
    activeSceneID,
    defaultOverlayConfig,
    overrideOverlayConfig,
    activeOverlayConfig,
    defaultStreamType,
    defaultStreamID,
    url,
  }: Partial<TypeWithFieldsOf<ShowSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.totalDurationMinutes = totalDurationMinutes ?? 0;
    this.activeSceneID = activeSceneID ?? "";
    this.defaultOverlayConfig = defaultOverlayConfig ?? new OverlayConfiguration();
    this.overrideOverlayConfig = overrideOverlayConfig ?? new OverlayConfiguration();
    this.activeOverlayConfig = activeOverlayConfig ?? new OverlayConfiguration();
    this.defaultStreamType = defaultStreamType ?? "";
    this.defaultStreamID = defaultStreamID ?? "";
    this.url = url ?? "";
  }

  convertUnexpectedNulls(): ShowSummary {
    this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    this.overrideOverlayConfig = this.overrideOverlayConfig!.convertUnexpectedNulls();
    this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    return this;
  }

  fixClasses(): ShowSummary {
    this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    this.overrideOverlayConfig = new OverlayConfiguration(this.overrideOverlayConfig!).fixClasses();
    this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    return this;
  }
}

export class SignSignalingClientConnectionInput {
  organizationID: string;
  virtualEventID: string;
  wssEndpointURL: string;

  constructor({
    organizationID,
    virtualEventID,
    wssEndpointURL,
  }: Partial<TypeWithFieldsOf<SignSignalingClientConnectionInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.wssEndpointURL = wssEndpointURL ?? "";
  }

  fixClasses(): SignSignalingClientConnectionInput {
    return this;
  }
}

export class SignSignalingClientConnectionOutput {
  signedURL: string;
  header: {[index: string]: string[]};

  constructor({
    signedURL,
    header,
  }: Partial<TypeWithFieldsOf<SignSignalingClientConnectionOutput>> = {}) {
    this.signedURL = signedURL ?? "";
    this.header = header ?? {};
  }

  convertUnexpectedNulls(): SignSignalingClientConnectionOutput {
    if (!this.header) {
      this.header = {};
    }
    for (let key0 of Object.keys(this.header)) {
      if (!this.header[key0]) {
        this.header[key0] = [];
      }
    }
    return this;
  }

  fixClasses(): SignSignalingClientConnectionOutput {
    return this;
  }
}

export class SpotterToolDonationSummary {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;
  id: string;
  bidderNumber: number;
  firstName: string;
  lastName: string;
  amountCents: number;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
    id,
    bidderNumber,
    firstName,
    lastName,
    amountCents,
  }: Partial<TypeWithFieldsOf<SpotterToolDonationSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.id = id ?? "";
    this.bidderNumber = bidderNumber ?? 0;
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.amountCents = amountCents ?? 0;
  }

  fixClasses(): SpotterToolDonationSummary {
    return this;
  }
}

export class SupporterPurchasedItemsDetailsInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    guestID,
    paging,
  }: Partial<TypeWithFieldsOf<SupporterPurchasedItemsDetailsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): SupporterPurchasedItemsDetailsInput {
    return this;
  }
}

export class SupporterPurchasedItemsDetailsOutput {
  items: ViewSupporterPurchasedItemDetailSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<SupporterPurchasedItemsDetailsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): SupporterPurchasedItemsDetailsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): SupporterPurchasedItemsDetailsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): SupporterPurchasedItemsDetailsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewSupporterPurchasedItemDetailSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class SyncGuestSessionsAndAttendeesInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<SyncGuestSessionsAndAttendeesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): SyncGuestSessionsAndAttendeesInput {
    return this;
  }
}

export class SyncGuestSessionsAndAttendeesOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<SyncGuestSessionsAndAttendeesOutput>> = {}) {
  }

  fixClasses(): SyncGuestSessionsAndAttendeesOutput {
    return this;
  }
}

export class Table {
  tableNumber: number;
  name: string;
  chairs: Chair[];

  constructor({
    tableNumber,
    name,
    chairs,
  }: Partial<TypeWithFieldsOf<Table>> = {}) {
    this.tableNumber = tableNumber ?? 0;
    this.name = name ?? "";
    this.chairs = chairs ?? [];
  }

  convertUnexpectedNulls(): Table {
    if (!this.chairs) {
      this.chairs = [];
    }
    return this;
  }

  fixClasses(): Table {
    for (let i0 = 0; i0 < this.chairs.length; i0 += 1) {
      this.chairs[i0] = new Chair(this.chairs[i0]!).fixClasses();
    }
    return this;
  }
}

export class TextPaymentLinkInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  guestSessionID: string;
  mobilePhone: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    guestSessionID,
    mobilePhone,
  }: Partial<TypeWithFieldsOf<TextPaymentLinkInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.guestSessionID = guestSessionID ?? "";
    this.mobilePhone = mobilePhone ?? "";
  }

  fixClasses(): TextPaymentLinkInput {
    return this;
  }
}

export class TextPaymentLinkOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<TextPaymentLinkOutput>> = {}) {
  }

  fixClasses(): TextPaymentLinkOutput {
    return this;
  }
}

export class ThermometerConfigDetails {
  includeTotals: boolean;

  constructor({
    includeTotals,
  }: Partial<TypeWithFieldsOf<ThermometerConfigDetails>> = {}) {
    this.includeTotals = includeTotals ?? false;
  }

  fixClasses(): ThermometerConfigDetails {
    return this;
  }
}

export class ThermometerSetupConfig {
  seedAmount: ThermometerConfigDetails;
  donations: ThermometerConfigDetails;
  offline: ThermometerConfigDetails;
  liveAuction: ThermometerConfigDetails;
  silentAuction: ThermometerConfigDetails;
  fixedPrice: ThermometerConfigDetails;
  tickets: ThermometerConfigDetails;
  raffle: ThermometerConfigDetails;
  sponsorAttachmentEventSale: ThermometerConfigDetails;
  misc: ThermometerConfigDetails;

  constructor({
    seedAmount,
    donations,
    offline,
    liveAuction,
    silentAuction,
    fixedPrice,
    tickets,
    raffle,
    sponsorAttachmentEventSale,
    misc,
  }: Partial<TypeWithFieldsOf<ThermometerSetupConfig>> = {}) {
    this.seedAmount = seedAmount ?? new ThermometerConfigDetails();
    this.donations = donations ?? new ThermometerConfigDetails();
    this.offline = offline ?? new ThermometerConfigDetails();
    this.liveAuction = liveAuction ?? new ThermometerConfigDetails();
    this.silentAuction = silentAuction ?? new ThermometerConfigDetails();
    this.fixedPrice = fixedPrice ?? new ThermometerConfigDetails();
    this.tickets = tickets ?? new ThermometerConfigDetails();
    this.raffle = raffle ?? new ThermometerConfigDetails();
    this.sponsorAttachmentEventSale = sponsorAttachmentEventSale ?? new ThermometerConfigDetails();
    this.misc = misc ?? new ThermometerConfigDetails();
  }

  fixClasses(): ThermometerSetupConfig {
    this.seedAmount = new ThermometerConfigDetails(this.seedAmount!).fixClasses();
    this.donations = new ThermometerConfigDetails(this.donations!).fixClasses();
    this.offline = new ThermometerConfigDetails(this.offline!).fixClasses();
    this.liveAuction = new ThermometerConfigDetails(this.liveAuction!).fixClasses();
    this.silentAuction = new ThermometerConfigDetails(this.silentAuction!).fixClasses();
    this.fixedPrice = new ThermometerConfigDetails(this.fixedPrice!).fixClasses();
    this.tickets = new ThermometerConfigDetails(this.tickets!).fixClasses();
    this.raffle = new ThermometerConfigDetails(this.raffle!).fixClasses();
    this.sponsorAttachmentEventSale = new ThermometerConfigDetails(this.sponsorAttachmentEventSale!).fixClasses();
    this.misc = new ThermometerConfigDetails(this.misc!).fixClasses();
    return this;
  }
}

export class TicketOptionAnswer {
  ticketOptionID: string;
  ticketOptionPromptChoiceID: string;
  ticketOptionAnswer: string;

  constructor({
    ticketOptionID,
    ticketOptionPromptChoiceID,
    ticketOptionAnswer,
  }: Partial<TypeWithFieldsOf<TicketOptionAnswer>> = {}) {
    this.ticketOptionID = ticketOptionID ?? "";
    this.ticketOptionPromptChoiceID = ticketOptionPromptChoiceID ?? "";
    this.ticketOptionAnswer = ticketOptionAnswer ?? "";
  }

  fixClasses(): TicketOptionAnswer {
    return this;
  }
}

export class TicketPromoCodeSummary {
  organizationID: string;
  source: string;
  id: string;
  code: string;
  description: string;
  type: string;
  amountCentsOff: number;
  hundredthPercentOff: number;
  active: boolean;
  ticketTypes: TicketType[];
  promoUsedCount: number;

  constructor({
    organizationID,
    source,
    id,
    code,
    description,
    type,
    amountCentsOff,
    hundredthPercentOff,
    active,
    ticketTypes,
    promoUsedCount,
  }: Partial<TypeWithFieldsOf<TicketPromoCodeSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.source = source ?? "";
    this.id = id ?? "";
    this.code = code ?? "";
    this.description = description ?? "";
    this.type = type ?? "";
    this.amountCentsOff = amountCentsOff ?? 0;
    this.hundredthPercentOff = hundredthPercentOff ?? 0;
    this.active = active ?? false;
    this.ticketTypes = ticketTypes ?? [];
    this.promoUsedCount = promoUsedCount ?? 0;
  }

  convertUnexpectedNulls(): TicketPromoCodeSummary {
    if (!this.ticketTypes) {
      this.ticketTypes = [];
    }
    return this;
  }

  fixClasses(): TicketPromoCodeSummary {
    for (let i0 = 0; i0 < this.ticketTypes.length; i0 += 1) {
      this.ticketTypes[i0] = new TicketType(this.ticketTypes[i0]!).fixClasses();
    }
    return this;
  }
}

export class TicketSummary {
  organizationID: string;
  id: string;
  created: Date;
  source: string;
  ticketTypeID: string;
  groupID: string;
  purchaserUserID: string;
  purchaserFirstName: string;
  purchaserLastName: string;
  purchaserEmail: string;
  attendeeUserID: string;
  attendeeFirstName: string;
  attendeeLastName: string;
  attendeeEmail: string;
  attendeeMobilePhone: string;
  priceCents: number;
  valueCents: number;
  comped: boolean;
  managerFirstName: string;
  managerLastName: string;
  managerEmail: string;
  checkInTime: (Date | null);
  claimedTime: (Date | null);
  preEventQuestionnaireCompleteTime: (Date | null);
  isLocked: boolean;
  promoCodeUsedID: string;

  constructor({
    organizationID,
    id,
    created,
    source,
    ticketTypeID,
    groupID,
    purchaserUserID,
    purchaserFirstName,
    purchaserLastName,
    purchaserEmail,
    attendeeUserID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
    attendeeMobilePhone,
    priceCents,
    valueCents,
    comped,
    managerFirstName,
    managerLastName,
    managerEmail,
    checkInTime,
    claimedTime,
    preEventQuestionnaireCompleteTime,
    isLocked,
    promoCodeUsedID,
  }: Partial<TypeWithFieldsOf<TicketSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
    this.created = created ?? new Date();
    this.source = source ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.groupID = groupID ?? "";
    this.purchaserUserID = purchaserUserID ?? "";
    this.purchaserFirstName = purchaserFirstName ?? "";
    this.purchaserLastName = purchaserLastName ?? "";
    this.purchaserEmail = purchaserEmail ?? "";
    this.attendeeUserID = attendeeUserID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? "";
    this.attendeeLastName = attendeeLastName ?? "";
    this.attendeeEmail = attendeeEmail ?? "";
    this.attendeeMobilePhone = attendeeMobilePhone ?? "";
    this.priceCents = priceCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.comped = comped ?? false;
    this.managerFirstName = managerFirstName ?? "";
    this.managerLastName = managerLastName ?? "";
    this.managerEmail = managerEmail ?? "";
    this.checkInTime = checkInTime ?? null;
    this.claimedTime = claimedTime ?? null;
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.isLocked = isLocked ?? false;
    this.promoCodeUsedID = promoCodeUsedID ?? "";
  }

  convertDates(): TicketSummary {
    this.created = new Date(this.created!);
    if (this.checkInTime !== undefined && this.checkInTime !== null) {
      this.checkInTime = new Date(this.checkInTime!);
    }
    if (this.claimedTime !== undefined && this.claimedTime !== null) {
      this.claimedTime = new Date(this.claimedTime!);
    }
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): TicketSummary {
    return this;
  }
}

export class TicketType {
  ticketTypeID: string;
  name: string;

  constructor({
    ticketTypeID,
    name,
  }: Partial<TypeWithFieldsOf<TicketType>> = {}) {
    this.ticketTypeID = ticketTypeID ?? "";
    this.name = name ?? "";
  }

  fixClasses(): TicketType {
    return this;
  }
}

export class TrackInvitationEmailOpenedInput {
  organizationID: string;
  eventID: string;
  invitationID: string;

  constructor({
    organizationID,
    eventID,
    invitationID,
  }: Partial<TypeWithFieldsOf<TrackInvitationEmailOpenedInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.invitationID = invitationID ?? "";
  }

  fixClasses(): TrackInvitationEmailOpenedInput {
    return this;
  }
}

export class UnbanFromLivestreamChatInput {
  organizationID: string;
  eventID: string;
  userID: string;

  constructor({
    organizationID,
    eventID,
    userID,
  }: Partial<TypeWithFieldsOf<UnbanFromLivestreamChatInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
  }

  fixClasses(): UnbanFromLivestreamChatInput {
    return this;
  }
}

export class UnbanFromLivestreamChatOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UnbanFromLivestreamChatOutput>> = {}) {
  }

  fixClasses(): UnbanFromLivestreamChatOutput {
    return this;
  }
}

export class UndoCheckInGuestSessionInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestSessionID: string;

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    guestSessionID,
  }: Partial<TypeWithFieldsOf<UndoCheckInGuestSessionInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestSessionID = guestSessionID ?? "";
  }

  fixClasses(): UndoCheckInGuestSessionInput {
    return this;
  }
}

export class UndoCheckInGuestSessionOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UndoCheckInGuestSessionOutput>> = {}) {
  }

  fixClasses(): UndoCheckInGuestSessionOutput {
    return this;
  }
}

export class UpdateActiveOverlayConfigInput {
  sceneID: string;
  organizationID: string;
  eventID: string;
  showID: string;
  config: (OverlayConfiguration | null);

  constructor({
    sceneID,
    organizationID,
    eventID,
    showID,
    config,
  }: Partial<TypeWithFieldsOf<UpdateActiveOverlayConfigInput>> = {}) {
    this.sceneID = sceneID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.config = config ?? null;
  }

  convertUnexpectedNulls(): UpdateActiveOverlayConfigInput {
    if (this.config !== null) {
      this.config = this.config!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): UpdateActiveOverlayConfigInput {
    if (this.config !== null) {
      this.config = new OverlayConfiguration(this.config!).fixClasses();
    }
    return this;
  }
}

export class UpdateActiveOverlayConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateActiveOverlayConfigOutput>> = {}) {
  }

  fixClasses(): UpdateActiveOverlayConfigOutput {
    return this;
  }
}

export class UpdateAttendeeExperienceInput {
  organizationID: string;
  eventID: string;
  guestID: string;
  guestSessionID: string;
  experience: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    guestSessionID,
    experience,
  }: Partial<TypeWithFieldsOf<UpdateAttendeeExperienceInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.guestSessionID = guestSessionID ?? "";
    this.experience = experience ?? "";
  }

  fixClasses(): UpdateAttendeeExperienceInput {
    return this;
  }
}

export class UpdateAttendeeExperienceOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateAttendeeExperienceOutput>> = {}) {
  }

  fixClasses(): UpdateAttendeeExperienceOutput {
    return this;
  }
}

export class UpdateDonationMomentInput {
  donationMomentID: string;
  organizationID: string;
  eventID: string;
  name: (string | null);
  type: (string | null);
  donationLevelsAmountCents: (number[] | null);
  currentDonationTierAmountCents: (number | null);
  disableOnlineDonations: (boolean | null);
  giveAtAnyLevelEnabled: (boolean | null);
  giveAtAnyLevelActive: (boolean | null);

  constructor({
    donationMomentID,
    organizationID,
    eventID,
    name,
    type,
    donationLevelsAmountCents,
    currentDonationTierAmountCents,
    disableOnlineDonations,
    giveAtAnyLevelEnabled,
    giveAtAnyLevelActive,
  }: Partial<TypeWithFieldsOf<UpdateDonationMomentInput>> = {}) {
    this.donationMomentID = donationMomentID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? null;
    this.type = type ?? null;
    this.donationLevelsAmountCents = donationLevelsAmountCents ?? null;
    this.currentDonationTierAmountCents = currentDonationTierAmountCents ?? null;
    this.disableOnlineDonations = disableOnlineDonations ?? null;
    this.giveAtAnyLevelEnabled = giveAtAnyLevelEnabled ?? null;
    this.giveAtAnyLevelActive = giveAtAnyLevelActive ?? null;
  }

  fixClasses(): UpdateDonationMomentInput {
    return this;
  }
}

export class UpdateDonationMomentOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateDonationMomentOutput>> = {}) {
  }

  fixClasses(): UpdateDonationMomentOutput {
    return this;
  }
}

export class UpdateEventInput {
  eventID: string;
  organizationID: string;
  name: (string | null);
  descriptionMarkdown: (string | null);
  eventType: (string | null);
  impactBlurb: (string | null);
  registrationBlurb: (string | null);
  primaryDate: (Date | null);
  primaryEndDate: (Date | null);
  timezone: (string | null);
  storyMarkdown: (string | null);
  currencyCode: (string | null);
  goalTotalDonationsAmountCents: (number | null);
  goalTotalDonationsSeedAmountCents: (number | null);
  goalTrackerImageURL: (string | null);
  donationSuggestedAmountsCents: (number[] | null);
  donationSuggestedAmountsShow: (boolean | null);
  themePrimaryColor: (string | null);
  themeSecondaryColor: (string | null);
  bannerImageURL: (string | null);
  logoImageURL: (string | null);
  registrationThankYouMessageMarkdown: (string | null);
  registrationConfirmationMessageMarkdown: (string | null);
  donationThankYouMessageMarkdown: (string | null);
  contactName: (string | null);
  contactPhone: (string | null);
  contactEmail: (string | null);
  merchantAccountID: (string | null);
  showBillingAddress: (boolean | null);
  requireBillingAddress: (boolean | null);
  isCoverCostActive: (boolean | null);
  coverCostPercent: (number | null);
  coverCostCustomMessage: (string | null);
  coverCostDefault: (boolean | null);
  tags: (string[] | null);
  impactOrderIDs: (string[] | null);
  fixedPriceOrderIDs: (string[] | null);
  activeRaffleID: (string | null);
  enableRaffles: (boolean | null);
  enableFixedPrice: (boolean | null);
  messageTemplateMarkdown: (string | null);
  invitationEmailSubject: (string | null);
  showSilentAuction: (boolean | null);
  showSilentAuctionCountdown: (boolean | null);
  activeSilentAuctionID: (string | null);
  showThermometer: (boolean | null);
  showStory: (boolean | null);
  showLiveAuctionPreview: (boolean | null);
  showRaffle: (boolean | null);
  showGreatfeatsParticipantsLeaderboard: (boolean | null);
  greatfeatsParticipantsLeaderboardTabTitle: (string | null);
  greatfeatsParticipantsLeaderboardMessageMarkdown: (string | null);
  storyTabTitle: (string | null);
  liveAuctionPreviewTabTitle: (string | null);
  silentAuctionTabTitle: (string | null);
  raffleTabTitle: (string | null);
  checkoutMessageMarkdown: (string | null);
  isVirtualTableActive: (boolean | null);
  requireDonationDuringRegistration: (boolean | null);
  requireDonationCompEmails: (string[] | null);
  showSocialAuth: (boolean | null);
  showPhoneNumber: (boolean | null);
  requirePhoneNumber: (boolean | null);
  showAddress: (boolean | null);
  requireAddress: (boolean | null);
  enableTicketing: (boolean | null);
  ticketTypeOrderIDs: (string[] | null);
  ticketOptionOrderIDs: (string[] | null);
  showGame: (boolean | null);
  gameTabTitle: (string | null);
  greatfeatsChallengeAccessKey: (string | null);
  showRecurringDonationManagement: (boolean | null);
  recurringDonationPeriod: (string | null);
  recurringDonationSuggestedAmountsCents: (number[] | null);
  receiptMessageMarkdown: (string | null);
  eventEndMessageMarkdown: (string | null);
  receiptTaxMessage: (string | null);
  hostURL: (string | null);
  hostShortenedURL: (string | null);
  donationTabTitle: (string | null);
  fixedPriceItemTabTitle: (string | null);
  supportSectionHeaderTitle: (string | null);
  checkoutNotificationMessageMarkdown: (string | null);
  readyPickupNotificationMessageMarkdown: (string | null);
  pickupLaterNotificationMessageMarkdown: (string | null);
  allowPickupLater: (boolean | null);
  allowPickupTonight: (boolean | null);
  isPickupWindowScheduled: (boolean | null);
  pickupOpenTime: (Date | null);
  pickupCloseTime: (Date | null);
  isCheckoutOpen: (boolean | null);
  thermometerConfig: (ThermometerSetupConfig | null);
  totalDonationsProceedsAmountCents: (number | null);
  totalOfflineDonationsAmountCents: (number | null);
  totalLiveAuctionProceedsAmountCents: (number | null);
  totalSilentAuctionProceedsAmountCents: (number | null);
  totalFixedPriceProceedsAmountCents: (number | null);
  totalTicketsProceedsAmountCents: (number | null);
  totalRaffleProceedsAmountCents: (number | null);
  totalSponsorAttachmentEventSalesProceedsAmountCents: (number | null);
  totalMiscProceedsAmountCents: (number | null);
  testEvent: (boolean | null);
  enableSelfCheckin: (boolean | null);
  salesTaxPercent: (number | null);
  salesTaxAppliesTo: (string | null);
  salesTaxCoverageType: (string | null);
  customTags: (string[] | null);
  externalID: (string | null);
  enableBidderNumbers: (boolean | null);
  enableAutomaticBidderNumbers: (boolean | null);
  startingBidderNumber: (number | null);
  newEventExperienceFF: (boolean | null);

  constructor({
    eventID,
    organizationID,
    name,
    descriptionMarkdown,
    eventType,
    impactBlurb,
    registrationBlurb,
    primaryDate,
    primaryEndDate,
    timezone,
    storyMarkdown,
    currencyCode,
    goalTotalDonationsAmountCents,
    goalTotalDonationsSeedAmountCents,
    goalTrackerImageURL,
    donationSuggestedAmountsCents,
    donationSuggestedAmountsShow,
    themePrimaryColor,
    themeSecondaryColor,
    bannerImageURL,
    logoImageURL,
    registrationThankYouMessageMarkdown,
    registrationConfirmationMessageMarkdown,
    donationThankYouMessageMarkdown,
    contactName,
    contactPhone,
    contactEmail,
    merchantAccountID,
    showBillingAddress,
    requireBillingAddress,
    isCoverCostActive,
    coverCostPercent,
    coverCostCustomMessage,
    coverCostDefault,
    tags,
    impactOrderIDs,
    fixedPriceOrderIDs,
    activeRaffleID,
    enableRaffles,
    enableFixedPrice,
    messageTemplateMarkdown,
    invitationEmailSubject,
    showSilentAuction,
    showSilentAuctionCountdown,
    activeSilentAuctionID,
    showThermometer,
    showStory,
    showLiveAuctionPreview,
    showRaffle,
    showGreatfeatsParticipantsLeaderboard,
    greatfeatsParticipantsLeaderboardTabTitle,
    greatfeatsParticipantsLeaderboardMessageMarkdown,
    storyTabTitle,
    liveAuctionPreviewTabTitle,
    silentAuctionTabTitle,
    raffleTabTitle,
    checkoutMessageMarkdown,
    isVirtualTableActive,
    requireDonationDuringRegistration,
    requireDonationCompEmails,
    showSocialAuth,
    showPhoneNumber,
    requirePhoneNumber,
    showAddress,
    requireAddress,
    enableTicketing,
    ticketTypeOrderIDs,
    ticketOptionOrderIDs,
    showGame,
    gameTabTitle,
    greatfeatsChallengeAccessKey,
    showRecurringDonationManagement,
    recurringDonationPeriod,
    recurringDonationSuggestedAmountsCents,
    receiptMessageMarkdown,
    eventEndMessageMarkdown,
    receiptTaxMessage,
    hostURL,
    hostShortenedURL,
    donationTabTitle,
    fixedPriceItemTabTitle,
    supportSectionHeaderTitle,
    checkoutNotificationMessageMarkdown,
    readyPickupNotificationMessageMarkdown,
    pickupLaterNotificationMessageMarkdown,
    allowPickupLater,
    allowPickupTonight,
    isPickupWindowScheduled,
    pickupOpenTime,
    pickupCloseTime,
    isCheckoutOpen,
    thermometerConfig,
    totalDonationsProceedsAmountCents,
    totalOfflineDonationsAmountCents,
    totalLiveAuctionProceedsAmountCents,
    totalSilentAuctionProceedsAmountCents,
    totalFixedPriceProceedsAmountCents,
    totalTicketsProceedsAmountCents,
    totalRaffleProceedsAmountCents,
    totalSponsorAttachmentEventSalesProceedsAmountCents,
    totalMiscProceedsAmountCents,
    testEvent,
    enableSelfCheckin,
    salesTaxPercent,
    salesTaxAppliesTo,
    salesTaxCoverageType,
    customTags,
    externalID,
    enableBidderNumbers,
    enableAutomaticBidderNumbers,
    startingBidderNumber,
    newEventExperienceFF,
  }: Partial<TypeWithFieldsOf<UpdateEventInput>> = {}) {
    this.eventID = eventID ?? "";
    this.organizationID = organizationID ?? "";
    this.name = name ?? null;
    this.descriptionMarkdown = descriptionMarkdown ?? null;
    this.eventType = eventType ?? null;
    this.impactBlurb = impactBlurb ?? null;
    this.registrationBlurb = registrationBlurb ?? null;
    this.primaryDate = primaryDate ?? null;
    this.primaryEndDate = primaryEndDate ?? null;
    this.timezone = timezone ?? null;
    this.storyMarkdown = storyMarkdown ?? null;
    this.currencyCode = currencyCode ?? null;
    this.goalTotalDonationsAmountCents = goalTotalDonationsAmountCents ?? null;
    this.goalTotalDonationsSeedAmountCents = goalTotalDonationsSeedAmountCents ?? null;
    this.goalTrackerImageURL = goalTrackerImageURL ?? null;
    this.donationSuggestedAmountsCents = donationSuggestedAmountsCents ?? null;
    this.donationSuggestedAmountsShow = donationSuggestedAmountsShow ?? null;
    this.themePrimaryColor = themePrimaryColor ?? null;
    this.themeSecondaryColor = themeSecondaryColor ?? null;
    this.bannerImageURL = bannerImageURL ?? null;
    this.logoImageURL = logoImageURL ?? null;
    this.registrationThankYouMessageMarkdown = registrationThankYouMessageMarkdown ?? null;
    this.registrationConfirmationMessageMarkdown = registrationConfirmationMessageMarkdown ?? null;
    this.donationThankYouMessageMarkdown = donationThankYouMessageMarkdown ?? null;
    this.contactName = contactName ?? null;
    this.contactPhone = contactPhone ?? null;
    this.contactEmail = contactEmail ?? null;
    this.merchantAccountID = merchantAccountID ?? null;
    this.showBillingAddress = showBillingAddress ?? null;
    this.requireBillingAddress = requireBillingAddress ?? null;
    this.isCoverCostActive = isCoverCostActive ?? null;
    this.coverCostPercent = coverCostPercent ?? null;
    this.coverCostCustomMessage = coverCostCustomMessage ?? null;
    this.coverCostDefault = coverCostDefault ?? null;
    this.tags = tags ?? null;
    this.impactOrderIDs = impactOrderIDs ?? null;
    this.fixedPriceOrderIDs = fixedPriceOrderIDs ?? null;
    this.activeRaffleID = activeRaffleID ?? null;
    this.enableRaffles = enableRaffles ?? null;
    this.enableFixedPrice = enableFixedPrice ?? null;
    this.messageTemplateMarkdown = messageTemplateMarkdown ?? null;
    this.invitationEmailSubject = invitationEmailSubject ?? null;
    this.showSilentAuction = showSilentAuction ?? null;
    this.showSilentAuctionCountdown = showSilentAuctionCountdown ?? null;
    this.activeSilentAuctionID = activeSilentAuctionID ?? null;
    this.showThermometer = showThermometer ?? null;
    this.showStory = showStory ?? null;
    this.showLiveAuctionPreview = showLiveAuctionPreview ?? null;
    this.showRaffle = showRaffle ?? null;
    this.showGreatfeatsParticipantsLeaderboard = showGreatfeatsParticipantsLeaderboard ?? null;
    this.greatfeatsParticipantsLeaderboardTabTitle = greatfeatsParticipantsLeaderboardTabTitle ?? null;
    this.greatfeatsParticipantsLeaderboardMessageMarkdown = greatfeatsParticipantsLeaderboardMessageMarkdown ?? null;
    this.storyTabTitle = storyTabTitle ?? null;
    this.liveAuctionPreviewTabTitle = liveAuctionPreviewTabTitle ?? null;
    this.silentAuctionTabTitle = silentAuctionTabTitle ?? null;
    this.raffleTabTitle = raffleTabTitle ?? null;
    this.checkoutMessageMarkdown = checkoutMessageMarkdown ?? null;
    this.isVirtualTableActive = isVirtualTableActive ?? null;
    this.requireDonationDuringRegistration = requireDonationDuringRegistration ?? null;
    this.requireDonationCompEmails = requireDonationCompEmails ?? null;
    this.showSocialAuth = showSocialAuth ?? null;
    this.showPhoneNumber = showPhoneNumber ?? null;
    this.requirePhoneNumber = requirePhoneNumber ?? null;
    this.showAddress = showAddress ?? null;
    this.requireAddress = requireAddress ?? null;
    this.enableTicketing = enableTicketing ?? null;
    this.ticketTypeOrderIDs = ticketTypeOrderIDs ?? null;
    this.ticketOptionOrderIDs = ticketOptionOrderIDs ?? null;
    this.showGame = showGame ?? null;
    this.gameTabTitle = gameTabTitle ?? null;
    this.greatfeatsChallengeAccessKey = greatfeatsChallengeAccessKey ?? null;
    this.showRecurringDonationManagement = showRecurringDonationManagement ?? null;
    this.recurringDonationPeriod = recurringDonationPeriod ?? null;
    this.recurringDonationSuggestedAmountsCents = recurringDonationSuggestedAmountsCents ?? null;
    this.receiptMessageMarkdown = receiptMessageMarkdown ?? null;
    this.eventEndMessageMarkdown = eventEndMessageMarkdown ?? null;
    this.receiptTaxMessage = receiptTaxMessage ?? null;
    this.hostURL = hostURL ?? null;
    this.hostShortenedURL = hostShortenedURL ?? null;
    this.donationTabTitle = donationTabTitle ?? null;
    this.fixedPriceItemTabTitle = fixedPriceItemTabTitle ?? null;
    this.supportSectionHeaderTitle = supportSectionHeaderTitle ?? null;
    this.checkoutNotificationMessageMarkdown = checkoutNotificationMessageMarkdown ?? null;
    this.readyPickupNotificationMessageMarkdown = readyPickupNotificationMessageMarkdown ?? null;
    this.pickupLaterNotificationMessageMarkdown = pickupLaterNotificationMessageMarkdown ?? null;
    this.allowPickupLater = allowPickupLater ?? null;
    this.allowPickupTonight = allowPickupTonight ?? null;
    this.isPickupWindowScheduled = isPickupWindowScheduled ?? null;
    this.pickupOpenTime = pickupOpenTime ?? null;
    this.pickupCloseTime = pickupCloseTime ?? null;
    this.isCheckoutOpen = isCheckoutOpen ?? null;
    this.thermometerConfig = thermometerConfig ?? null;
    this.totalDonationsProceedsAmountCents = totalDonationsProceedsAmountCents ?? null;
    this.totalOfflineDonationsAmountCents = totalOfflineDonationsAmountCents ?? null;
    this.totalLiveAuctionProceedsAmountCents = totalLiveAuctionProceedsAmountCents ?? null;
    this.totalSilentAuctionProceedsAmountCents = totalSilentAuctionProceedsAmountCents ?? null;
    this.totalFixedPriceProceedsAmountCents = totalFixedPriceProceedsAmountCents ?? null;
    this.totalTicketsProceedsAmountCents = totalTicketsProceedsAmountCents ?? null;
    this.totalRaffleProceedsAmountCents = totalRaffleProceedsAmountCents ?? null;
    this.totalSponsorAttachmentEventSalesProceedsAmountCents = totalSponsorAttachmentEventSalesProceedsAmountCents ?? null;
    this.totalMiscProceedsAmountCents = totalMiscProceedsAmountCents ?? null;
    this.testEvent = testEvent ?? null;
    this.enableSelfCheckin = enableSelfCheckin ?? null;
    this.salesTaxPercent = salesTaxPercent ?? null;
    this.salesTaxAppliesTo = salesTaxAppliesTo ?? null;
    this.salesTaxCoverageType = salesTaxCoverageType ?? null;
    this.customTags = customTags ?? null;
    this.externalID = externalID ?? null;
    this.enableBidderNumbers = enableBidderNumbers ?? null;
    this.enableAutomaticBidderNumbers = enableAutomaticBidderNumbers ?? null;
    this.startingBidderNumber = startingBidderNumber ?? null;
    this.newEventExperienceFF = newEventExperienceFF ?? null;
  }

  convertDates(): UpdateEventInput {
    if (this.primaryDate !== undefined && this.primaryDate !== null) {
      this.primaryDate = new Date(this.primaryDate!);
    }
    if (this.primaryEndDate !== undefined && this.primaryEndDate !== null) {
      this.primaryEndDate = new Date(this.primaryEndDate!);
    }
    if (this.pickupOpenTime !== undefined && this.pickupOpenTime !== null) {
      this.pickupOpenTime = new Date(this.pickupOpenTime!);
    }
    if (this.pickupCloseTime !== undefined && this.pickupCloseTime !== null) {
      this.pickupCloseTime = new Date(this.pickupCloseTime!);
    }
    return this;
  }

  fixClasses(): UpdateEventInput {
    if (this.thermometerConfig !== null) {
      this.thermometerConfig = new ThermometerSetupConfig(this.thermometerConfig!).fixClasses();
    }
    return this;
  }
}

export class UpdateEventOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateEventOutput>> = {}) {
  }

  fixClasses(): UpdateEventOutput {
    return this;
  }
}

export class UpdateEventSessionInput {
  eventSessionID: string;
  organizationID: string;
  eventID: string;
  tableImageURL: (string | null);

  constructor({
    eventSessionID,
    organizationID,
    eventID,
    tableImageURL,
  }: Partial<TypeWithFieldsOf<UpdateEventSessionInput>> = {}) {
    this.eventSessionID = eventSessionID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.tableImageURL = tableImageURL ?? null;
  }

  fixClasses(): UpdateEventSessionInput {
    return this;
  }
}

export class UpdateEventSessionOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateEventSessionOutput>> = {}) {
  }

  fixClasses(): UpdateEventSessionOutput {
    return this;
  }
}

export class UpdateEventSessionTicketConfigInput {
  eventSessionTicketConfigID: string;
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  ticketTypeID: (string | null);
  attendeeExperience: (string | null);

  constructor({
    eventSessionTicketConfigID,
    organizationID,
    eventID,
    eventSessionID,
    ticketTypeID,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<UpdateEventSessionTicketConfigInput>> = {}) {
    this.eventSessionTicketConfigID = eventSessionTicketConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.ticketTypeID = ticketTypeID ?? null;
    this.attendeeExperience = attendeeExperience ?? null;
  }

  fixClasses(): UpdateEventSessionTicketConfigInput {
    return this;
  }
}

export class UpdateEventSessionTicketConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateEventSessionTicketConfigOutput>> = {}) {
  }

  fixClasses(): UpdateEventSessionTicketConfigOutput {
    return this;
  }
}

export class UpdateEventShowControlInput {
  organizationID: string;
  eventID: string;
  activeShowID: (string | null);
  activeSceneID: (string | null);

  constructor({
    organizationID,
    eventID,
    activeShowID,
    activeSceneID,
  }: Partial<TypeWithFieldsOf<UpdateEventShowControlInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.activeShowID = activeShowID ?? null;
    this.activeSceneID = activeSceneID ?? null;
  }

  fixClasses(): UpdateEventShowControlInput {
    return this;
  }
}

export class UpdateEventShowControlOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateEventShowControlOutput>> = {}) {
  }

  fixClasses(): UpdateEventShowControlOutput {
    return this;
  }
}

export class UpdateEventSlideConfigInput {
  eventSlideConfigID: string;
  organizationID: string;
  eventID: string;
  slideType: (string | null);
  detailsJSON: (string | null);

  constructor({
    eventSlideConfigID,
    organizationID,
    eventID,
    slideType,
    detailsJSON,
  }: Partial<TypeWithFieldsOf<UpdateEventSlideConfigInput>> = {}) {
    this.eventSlideConfigID = eventSlideConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.slideType = slideType ?? null;
    this.detailsJSON = detailsJSON ?? null;
  }

  fixClasses(): UpdateEventSlideConfigInput {
    return this;
  }
}

export class UpdateEventSlideConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateEventSlideConfigOutput>> = {}) {
  }

  fixClasses(): UpdateEventSlideConfigOutput {
    return this;
  }
}

export class UpdateEventSlideshowInput {
  eventSlideshowID: string;
  organizationID: string;
  eventID: string;
  activeSlideURL: (string | null);

  constructor({
    eventSlideshowID,
    organizationID,
    eventID,
    activeSlideURL,
  }: Partial<TypeWithFieldsOf<UpdateEventSlideshowInput>> = {}) {
    this.eventSlideshowID = eventSlideshowID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.activeSlideURL = activeSlideURL ?? null;
  }

  fixClasses(): UpdateEventSlideshowInput {
    return this;
  }
}

export class UpdateEventSlideshowOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateEventSlideshowOutput>> = {}) {
  }

  fixClasses(): UpdateEventSlideshowOutput {
    return this;
  }
}

export class UpdateFixedPriceItemInput {
  itemID: string;
  organizationID: string;
  eventID: string;
  name: (string | null);
  priceCents: (number | null);
  valueCents: (number | null);
  isTaxable: (boolean | null);
  description: (string | null);
  numPurchased: (number | null);
  maxNumAvailable: (number | null);
  numUnlimited: (boolean | null);
  inPersonOnly: (boolean | null);
  pickupLater: (boolean | null);
  pickupTonight: (boolean | null);
  isPickUpEventLevel: (boolean | null);
  sortOrder: (number | null);

  constructor({
    itemID,
    organizationID,
    eventID,
    name,
    priceCents,
    valueCents,
    isTaxable,
    description,
    numPurchased,
    maxNumAvailable,
    numUnlimited,
    inPersonOnly,
    pickupLater,
    pickupTonight,
    isPickUpEventLevel,
    sortOrder,
  }: Partial<TypeWithFieldsOf<UpdateFixedPriceItemInput>> = {}) {
    this.itemID = itemID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? null;
    this.priceCents = priceCents ?? null;
    this.valueCents = valueCents ?? null;
    this.isTaxable = isTaxable ?? null;
    this.description = description ?? null;
    this.numPurchased = numPurchased ?? null;
    this.maxNumAvailable = maxNumAvailable ?? null;
    this.numUnlimited = numUnlimited ?? null;
    this.inPersonOnly = inPersonOnly ?? null;
    this.pickupLater = pickupLater ?? null;
    this.pickupTonight = pickupTonight ?? null;
    this.isPickUpEventLevel = isPickUpEventLevel ?? null;
    this.sortOrder = sortOrder ?? null;
  }

  fixClasses(): UpdateFixedPriceItemInput {
    return this;
  }
}

export class UpdateFixedPriceItemOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateFixedPriceItemOutput>> = {}) {
  }

  fixClasses(): UpdateFixedPriceItemOutput {
    return this;
  }
}

export class UpdateFixedPriceItemPhotoInput {
  photoID: string;
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  url: (string | null);

  constructor({
    photoID,
    organizationID,
    eventID,
    fixedPriceItemID,
    url,
  }: Partial<TypeWithFieldsOf<UpdateFixedPriceItemPhotoInput>> = {}) {
    this.photoID = photoID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.url = url ?? null;
  }

  fixClasses(): UpdateFixedPriceItemPhotoInput {
    return this;
  }
}

export class UpdateFixedPriceItemPhotoOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateFixedPriceItemPhotoOutput>> = {}) {
  }

  fixClasses(): UpdateFixedPriceItemPhotoOutput {
    return this;
  }
}

export class UpdateFixedPricePickedUpInput {
  organizationID: string;
  eventID: string;
  fixedPriceItemID: string;
  isPickedUp: boolean;
  guestID: string;

  constructor({
    organizationID,
    eventID,
    fixedPriceItemID,
    isPickedUp,
    guestID,
  }: Partial<TypeWithFieldsOf<UpdateFixedPricePickedUpInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.isPickedUp = isPickedUp ?? false;
    this.guestID = guestID ?? "";
  }

  fixClasses(): UpdateFixedPricePickedUpInput {
    return this;
  }
}

export class UpdateFixedPricePickedUpOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateFixedPricePickedUpOutput>> = {}) {
  }

  fixClasses(): UpdateFixedPricePickedUpOutput {
    return this;
  }
}

export class UpdateFixedPriceSortOrderInput {
  organizationID: string;
  eventID: string;
  fixedPriceItemIDs: string[];

  constructor({
    organizationID,
    eventID,
    fixedPriceItemIDs,
  }: Partial<TypeWithFieldsOf<UpdateFixedPriceSortOrderInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.fixedPriceItemIDs = fixedPriceItemIDs ?? [];
  }

  convertUnexpectedNulls(): UpdateFixedPriceSortOrderInput {
    if (!this.fixedPriceItemIDs) {
      this.fixedPriceItemIDs = [];
    }
    return this;
  }

  fixClasses(): UpdateFixedPriceSortOrderInput {
    return this;
  }
}

export class UpdateFixedPriceSortOrderOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateFixedPriceSortOrderOutput>> = {}) {
  }

  fixClasses(): UpdateFixedPriceSortOrderOutput {
    return this;
  }
}

export class UpdateGuestInput {
  guestID: string;
  organizationID: string;
  eventID: string;
  userID: (string | null);
  registrationID: (string | null);
  bidderNumber: (number | null);
  attendeeNumber: (string | null);
  managingGuestID: (string | null);
  firstName: (string | null);
  lastName: (string | null);
  email: (string | null);
  mobilePhone: (string | null);
  preEventQuestionnaireCompleteTime: (Date | null);
  preEventQuestionOneAnswer: (boolean | null);
  preEventQuestionTwoAnswer: (boolean | null);
  preEventQuestionThreeAnswer: (boolean | null);
  preEventConsentSelfCompleted: (boolean | null);
  biddingAsGuestID: (string | null);
  purchasedItemPrimaryUserID: (string | null);
  purchasedItemPrimaryGuestID: (string | null);
  address: (string | null);
  city: (string | null);
  state: (string | null);
  postalCode: (string | null);
  country: (string | null);
  userCardID: (string | null);

  constructor({
    guestID,
    organizationID,
    eventID,
    userID,
    registrationID,
    bidderNumber,
    attendeeNumber,
    managingGuestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    preEventQuestionnaireCompleteTime,
    preEventQuestionOneAnswer,
    preEventQuestionTwoAnswer,
    preEventQuestionThreeAnswer,
    preEventConsentSelfCompleted,
    biddingAsGuestID,
    purchasedItemPrimaryUserID,
    purchasedItemPrimaryGuestID,
    address,
    city,
    state,
    postalCode,
    country,
    userCardID,
  }: Partial<TypeWithFieldsOf<UpdateGuestInput>> = {}) {
    this.guestID = guestID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? null;
    this.registrationID = registrationID ?? null;
    this.bidderNumber = bidderNumber ?? null;
    this.attendeeNumber = attendeeNumber ?? null;
    this.managingGuestID = managingGuestID ?? null;
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.preEventQuestionOneAnswer = preEventQuestionOneAnswer ?? null;
    this.preEventQuestionTwoAnswer = preEventQuestionTwoAnswer ?? null;
    this.preEventQuestionThreeAnswer = preEventQuestionThreeAnswer ?? null;
    this.preEventConsentSelfCompleted = preEventConsentSelfCompleted ?? null;
    this.biddingAsGuestID = biddingAsGuestID ?? null;
    this.purchasedItemPrimaryUserID = purchasedItemPrimaryUserID ?? null;
    this.purchasedItemPrimaryGuestID = purchasedItemPrimaryGuestID ?? null;
    this.address = address ?? null;
    this.city = city ?? null;
    this.state = state ?? null;
    this.postalCode = postalCode ?? null;
    this.country = country ?? null;
    this.userCardID = userCardID ?? null;
  }

  convertDates(): UpdateGuestInput {
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): UpdateGuestInput {
    return this;
  }
}

export class UpdateGuestOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateGuestOutput>> = {}) {
  }

  fixClasses(): UpdateGuestOutput {
    return this;
  }
}

export class UpdateGuestSessionInput {
  guestSessionID: string;
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestID: (string | null);
  isCheckInWithManagingGuest: (boolean | null);
  checkedInTime: (Date | null);
  tableAttendeeID: (string | null);
  ticketID: (string | null);
  initialTableName: (string | null);
  initialTableNumber: (string | null);
  totalProceedsAmountCents: (number | null);
  attendeeExperience: (string | null);

  constructor({
    guestSessionID,
    organizationID,
    eventID,
    eventSessionID,
    guestID,
    isCheckInWithManagingGuest,
    checkedInTime,
    tableAttendeeID,
    ticketID,
    initialTableName,
    initialTableNumber,
    totalProceedsAmountCents,
    attendeeExperience,
  }: Partial<TypeWithFieldsOf<UpdateGuestSessionInput>> = {}) {
    this.guestSessionID = guestSessionID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestID = guestID ?? null;
    this.isCheckInWithManagingGuest = isCheckInWithManagingGuest ?? null;
    this.checkedInTime = checkedInTime ?? null;
    this.tableAttendeeID = tableAttendeeID ?? null;
    this.ticketID = ticketID ?? null;
    this.initialTableName = initialTableName ?? null;
    this.initialTableNumber = initialTableNumber ?? null;
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? null;
    this.attendeeExperience = attendeeExperience ?? null;
  }

  convertDates(): UpdateGuestSessionInput {
    if (this.checkedInTime !== undefined && this.checkedInTime !== null) {
      this.checkedInTime = new Date(this.checkedInTime!);
    }
    return this;
  }

  fixClasses(): UpdateGuestSessionInput {
    return this;
  }
}

export class UpdateGuestSessionOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateGuestSessionOutput>> = {}) {
  }

  fixClasses(): UpdateGuestSessionOutput {
    return this;
  }
}

export class UpdateGuestsForTicketsInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  guestsToUpdate: GuestToUpdate[];

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    guestsToUpdate,
  }: Partial<TypeWithFieldsOf<UpdateGuestsForTicketsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.guestsToUpdate = guestsToUpdate ?? [];
  }

  convertUnexpectedNulls(): UpdateGuestsForTicketsInput {
    if (!this.guestsToUpdate) {
      this.guestsToUpdate = [];
    }
    return this;
  }

  fixClasses(): UpdateGuestsForTicketsInput {
    for (let i0 = 0; i0 < this.guestsToUpdate.length; i0 += 1) {
      this.guestsToUpdate[i0] = new GuestToUpdate(this.guestsToUpdate[i0]!).fixClasses();
    }
    return this;
  }
}

export class UpdateGuestsForTicketsOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateGuestsForTicketsOutput>> = {}) {
  }

  fixClasses(): UpdateGuestsForTicketsOutput {
    return this;
  }
}

export class UpdateImpactInput {
  impactID: string;
  organizationID: string;
  eventID: string;
  name: (string | null);
  amountCents: (number | null);
  descriptionMarkdown: (string | null);
  totalPurchasesLimit: (number | null);
  totalPurchasesCountDisplayThreshold: (number | null);
  totalPurchasesAmountCentsDisplayThreshold: (number | null);
  goalType: (string | null);
  goalPurchasesCount: (number | null);
  goalPurchasesCountDisplayThreshold: (number | null);
  goalPurchasesAmountCents: (number | null);
  goalPurchasesAmountCentsDisplayThreshold: (number | null);
  goalMetEmailSubject: (string | null);
  goalMetEmailBodyMarkdown: (string | null);
  goalMetSMSBody: (string | null);
  hideFromFlow: (boolean | null);
  fixedPriceItemID: (string | null);

  constructor({
    impactID,
    organizationID,
    eventID,
    name,
    amountCents,
    descriptionMarkdown,
    totalPurchasesLimit,
    totalPurchasesCountDisplayThreshold,
    totalPurchasesAmountCentsDisplayThreshold,
    goalType,
    goalPurchasesCount,
    goalPurchasesCountDisplayThreshold,
    goalPurchasesAmountCents,
    goalPurchasesAmountCentsDisplayThreshold,
    goalMetEmailSubject,
    goalMetEmailBodyMarkdown,
    goalMetSMSBody,
    hideFromFlow,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<UpdateImpactInput>> = {}) {
    this.impactID = impactID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? null;
    this.amountCents = amountCents ?? null;
    this.descriptionMarkdown = descriptionMarkdown ?? null;
    this.totalPurchasesLimit = totalPurchasesLimit ?? null;
    this.totalPurchasesCountDisplayThreshold = totalPurchasesCountDisplayThreshold ?? null;
    this.totalPurchasesAmountCentsDisplayThreshold = totalPurchasesAmountCentsDisplayThreshold ?? null;
    this.goalType = goalType ?? null;
    this.goalPurchasesCount = goalPurchasesCount ?? null;
    this.goalPurchasesCountDisplayThreshold = goalPurchasesCountDisplayThreshold ?? null;
    this.goalPurchasesAmountCents = goalPurchasesAmountCents ?? null;
    this.goalPurchasesAmountCentsDisplayThreshold = goalPurchasesAmountCentsDisplayThreshold ?? null;
    this.goalMetEmailSubject = goalMetEmailSubject ?? null;
    this.goalMetEmailBodyMarkdown = goalMetEmailBodyMarkdown ?? null;
    this.goalMetSMSBody = goalMetSMSBody ?? null;
    this.hideFromFlow = hideFromFlow ?? null;
    this.fixedPriceItemID = fixedPriceItemID ?? null;
  }

  fixClasses(): UpdateImpactInput {
    return this;
  }
}

export class UpdateImpactOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateImpactOutput>> = {}) {
  }

  fixClasses(): UpdateImpactOutput {
    return this;
  }
}

export class UpdateImpactPhotoInput {
  photoID: string;
  organizationID: string;
  eventID: string;
  impactID: string;
  url: (string | null);

  constructor({
    photoID,
    organizationID,
    eventID,
    impactID,
    url,
  }: Partial<TypeWithFieldsOf<UpdateImpactPhotoInput>> = {}) {
    this.photoID = photoID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.impactID = impactID ?? "";
    this.url = url ?? null;
  }

  fixClasses(): UpdateImpactPhotoInput {
    return this;
  }
}

export class UpdateImpactPhotoOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateImpactPhotoOutput>> = {}) {
  }

  fixClasses(): UpdateImpactPhotoOutput {
    return this;
  }
}

export class UpdateInvitationInput {
  organizationID: string;
  eventID: string;
  invitationID: string;
  externalID: (string | null);
  firstName: (string | null);
  lastName: (string | null);
  email: (string | null);
  mobilePhone: (string | null);
  messageTemplateMarkdown: (string | null);
  invitationEmailSubject: (string | null);

  constructor({
    organizationID,
    eventID,
    invitationID,
    externalID,
    firstName,
    lastName,
    email,
    mobilePhone,
    messageTemplateMarkdown,
    invitationEmailSubject,
  }: Partial<TypeWithFieldsOf<UpdateInvitationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.invitationID = invitationID ?? "";
    this.externalID = externalID ?? null;
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
    this.messageTemplateMarkdown = messageTemplateMarkdown ?? null;
    this.invitationEmailSubject = invitationEmailSubject ?? null;
  }

  fixClasses(): UpdateInvitationInput {
    return this;
  }
}

export class UpdateInvitationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateInvitationOutput>> = {}) {
  }

  fixClasses(): UpdateInvitationOutput {
    return this;
  }
}

export class UpdateIsReadyPickupDetails {
  itemID: string;
  lotID: string;
  allocationID: string;
  fixedPriceItemID: string;
  isReadyPickUp: boolean;

  constructor({
    itemID,
    lotID,
    allocationID,
    fixedPriceItemID,
    isReadyPickUp,
  }: Partial<TypeWithFieldsOf<UpdateIsReadyPickupDetails>> = {}) {
    this.itemID = itemID ?? "";
    this.lotID = lotID ?? "";
    this.allocationID = allocationID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.isReadyPickUp = isReadyPickUp ?? false;
  }

  fixClasses(): UpdateIsReadyPickupDetails {
    return this;
  }
}

export class UpdateLiveAppealConfigInput {
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  overlayID: string;
  config: string;

  constructor({
    organizationID,
    eventID,
    showID,
    sceneID,
    overlayID,
    config,
  }: Partial<TypeWithFieldsOf<UpdateLiveAppealConfigInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.overlayID = overlayID ?? "";
    this.config = config ?? "";
  }

  fixClasses(): UpdateLiveAppealConfigInput {
    return this;
  }
}

export class UpdateLiveAppealConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateLiveAppealConfigOutput>> = {}) {
  }

  fixClasses(): UpdateLiveAppealConfigOutput {
    return this;
  }
}

export class UpdateLotIncrementInput {
  organizationID: string;
  eventID: string;
  auctionID: string;
  lotID: string;
  bidIncrementCents: number;

  constructor({
    organizationID,
    eventID,
    auctionID,
    lotID,
    bidIncrementCents,
  }: Partial<TypeWithFieldsOf<UpdateLotIncrementInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.bidIncrementCents = bidIncrementCents ?? 0;
  }

  fixClasses(): UpdateLotIncrementInput {
    return this;
  }
}

export class UpdateLotIncrementOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateLotIncrementOutput>> = {}) {
  }

  fixClasses(): UpdateLotIncrementOutput {
    return this;
  }
}

export class UpdateMyExperienceTypeInput {
  organizationID: string;
  eventID: string;
  experienceType: string;

  constructor({
    organizationID,
    eventID,
    experienceType,
  }: Partial<TypeWithFieldsOf<UpdateMyExperienceTypeInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.experienceType = experienceType ?? "";
  }

  fixClasses(): UpdateMyExperienceTypeInput {
    return this;
  }
}

export class UpdateMyExperienceTypeOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateMyExperienceTypeOutput>> = {}) {
  }

  fixClasses(): UpdateMyExperienceTypeOutput {
    return this;
  }
}

export class UpdateMyGuestInput {
  organizationID: string;
  eventID: string;
  registrationID: (string | null);
  bidderNumber: (number | null);
  managingGuestID: (string | null);
  firstName: (string | null);
  lastName: (string | null);
  email: (string | null);
  mobilePhone: (string | null);
  address: (string | null);
  city: (string | null);
  state: (string | null);
  postalCode: (string | null);
  country: (string | null);
  preEventQuestionnaireCompleteTime: (Date | null);
  preEventQuestionOneAnswer: (boolean | null);
  preEventQuestionTwoAnswer: (boolean | null);
  preEventQuestionThreeAnswer: (boolean | null);
  preEventConsentSelfCompleted: (boolean | null);
  biddingAsGuestID: (string | null);

  constructor({
    organizationID,
    eventID,
    registrationID,
    bidderNumber,
    managingGuestID,
    firstName,
    lastName,
    email,
    mobilePhone,
    address,
    city,
    state,
    postalCode,
    country,
    preEventQuestionnaireCompleteTime,
    preEventQuestionOneAnswer,
    preEventQuestionTwoAnswer,
    preEventQuestionThreeAnswer,
    preEventConsentSelfCompleted,
    biddingAsGuestID,
  }: Partial<TypeWithFieldsOf<UpdateMyGuestInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.registrationID = registrationID ?? null;
    this.bidderNumber = bidderNumber ?? null;
    this.managingGuestID = managingGuestID ?? null;
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
    this.address = address ?? null;
    this.city = city ?? null;
    this.state = state ?? null;
    this.postalCode = postalCode ?? null;
    this.country = country ?? null;
    this.preEventQuestionnaireCompleteTime = preEventQuestionnaireCompleteTime ?? null;
    this.preEventQuestionOneAnswer = preEventQuestionOneAnswer ?? null;
    this.preEventQuestionTwoAnswer = preEventQuestionTwoAnswer ?? null;
    this.preEventQuestionThreeAnswer = preEventQuestionThreeAnswer ?? null;
    this.preEventConsentSelfCompleted = preEventConsentSelfCompleted ?? null;
    this.biddingAsGuestID = biddingAsGuestID ?? null;
  }

  convertDates(): UpdateMyGuestInput {
    if (this.preEventQuestionnaireCompleteTime !== undefined && this.preEventQuestionnaireCompleteTime !== null) {
      this.preEventQuestionnaireCompleteTime = new Date(this.preEventQuestionnaireCompleteTime!);
    }
    return this;
  }

  fixClasses(): UpdateMyGuestInput {
    return this;
  }
}

export class UpdateMyGuestOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateMyGuestOutput>> = {}) {
  }

  fixClasses(): UpdateMyGuestOutput {
    return this;
  }
}

export class UpdateMyProfileAndRegistrationInput {
  organizationID: string;
  eventID: string;
  userID: string;
  firstName: (string | null);
  lastName: (string | null);
  street: (string | null);
  city: (string | null);
  state: (string | null);
  zip: (string | null);
  country: (string | null);
  email: (string | null);
  mobilePhone: (string | null);

  constructor({
    organizationID,
    eventID,
    userID,
    firstName,
    lastName,
    street,
    city,
    state,
    zip,
    country,
    email,
    mobilePhone,
  }: Partial<TypeWithFieldsOf<UpdateMyProfileAndRegistrationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.street = street ?? null;
    this.city = city ?? null;
    this.state = state ?? null;
    this.zip = zip ?? null;
    this.country = country ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
  }

  fixClasses(): UpdateMyProfileAndRegistrationInput {
    return this;
  }
}

export class UpdateMyProfileAndRegistrationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateMyProfileAndRegistrationOutput>> = {}) {
  }

  fixClasses(): UpdateMyProfileAndRegistrationOutput {
    return this;
  }
}

export class UpdateMyProfileInput {
  organizationID: string;
  eventID: string;
  firstName: (string | null);
  lastName: (string | null);
  Street: (string | null);
  city: (string | null);
  state: (string | null);
  zip: (string | null);
  country: (string | null);
  updateAllEvent: (boolean | null);
  email: (string | null);
  mobilePhone: (string | null);

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    Street,
    city,
    state,
    zip,
    country,
    updateAllEvent,
    email,
    mobilePhone,
  }: Partial<TypeWithFieldsOf<UpdateMyProfileInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.Street = Street ?? null;
    this.city = city ?? null;
    this.state = state ?? null;
    this.zip = zip ?? null;
    this.country = country ?? null;
    this.updateAllEvent = updateAllEvent ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
  }

  fixClasses(): UpdateMyProfileInput {
    return this;
  }
}

export class UpdateMyProfileOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateMyProfileOutput>> = {}) {
  }

  fixClasses(): UpdateMyProfileOutput {
    return this;
  }
}

export class UpdateMyRegistrationInput {
  organizationID: string;
  eventID: string;
  firstName: (string | null);
  lastName: (string | null);
  email: (string | null);
  mobilePhone: (string | null);
  wantEmailUpdates: (boolean | null);
  wantSMSUpdates: (boolean | null);

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    email,
    mobilePhone,
    wantEmailUpdates,
    wantSMSUpdates,
  }: Partial<TypeWithFieldsOf<UpdateMyRegistrationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
    this.wantEmailUpdates = wantEmailUpdates ?? null;
    this.wantSMSUpdates = wantSMSUpdates ?? null;
  }

  fixClasses(): UpdateMyRegistrationInput {
    return this;
  }
}

export class UpdateMyRegistrationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateMyRegistrationOutput>> = {}) {
  }

  fixClasses(): UpdateMyRegistrationOutput {
    return this;
  }
}

export class UpdateMyTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  ticketTypeID: string;
  attendeeFirstName: (string | null);
  attendeeLastName: (string | null);
  attendeeEmail: (string | null);
  attendeeMobilePhone: (string | null);
  ticketOptionAnswers: CreateOrUpdateTicketOptionAnswer[];

  constructor({
    organizationID,
    eventID,
    ticketID,
    ticketTypeID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
    attendeeMobilePhone,
    ticketOptionAnswers,
  }: Partial<TypeWithFieldsOf<UpdateMyTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? null;
    this.attendeeLastName = attendeeLastName ?? null;
    this.attendeeEmail = attendeeEmail ?? null;
    this.attendeeMobilePhone = attendeeMobilePhone ?? null;
    this.ticketOptionAnswers = ticketOptionAnswers ?? [];
  }

  convertUnexpectedNulls(): UpdateMyTicketInput {
    if (!this.ticketOptionAnswers) {
      this.ticketOptionAnswers = [];
    }
    return this;
  }

  fixClasses(): UpdateMyTicketInput {
    for (let i0 = 0; i0 < this.ticketOptionAnswers.length; i0 += 1) {
      this.ticketOptionAnswers[i0] = new CreateOrUpdateTicketOptionAnswer(this.ticketOptionAnswers[i0]!).fixClasses();
    }
    return this;
  }
}

export class UpdateMyTicketOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateMyTicketOutput>> = {}) {
  }

  fixClasses(): UpdateMyTicketOutput {
    return this;
  }
}

export class UpdateOverlayInput {
  overlayID: string;
  organizationID: string;
  eventID: string;
  showID: string;
  sceneID: string;
  name: (string | null);
  type: (string | null);
  config: (string | null);

  constructor({
    overlayID,
    organizationID,
    eventID,
    showID,
    sceneID,
    name,
    type,
    config,
  }: Partial<TypeWithFieldsOf<UpdateOverlayInput>> = {}) {
    this.overlayID = overlayID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.sceneID = sceneID ?? "";
    this.name = name ?? null;
    this.type = type ?? null;
    this.config = config ?? null;
  }

  fixClasses(): UpdateOverlayInput {
    return this;
  }
}

export class UpdateOverlayOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateOverlayOutput>> = {}) {
  }

  fixClasses(): UpdateOverlayOutput {
    return this;
  }
}

export class UpdatePickedupDetails {
  itemID: string;
  lotID: string;
  allocationID: string;
  fixedPriceItemID: string;
  isPickedUp: boolean;
  pickedUpByGuestID: string;

  constructor({
    itemID,
    lotID,
    allocationID,
    fixedPriceItemID,
    isPickedUp,
    pickedUpByGuestID,
  }: Partial<TypeWithFieldsOf<UpdatePickedupDetails>> = {}) {
    this.itemID = itemID ?? "";
    this.lotID = lotID ?? "";
    this.allocationID = allocationID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.isPickedUp = isPickedUp ?? false;
    this.pickedUpByGuestID = pickedUpByGuestID ?? "";
  }

  fixClasses(): UpdatePickedupDetails {
    return this;
  }
}

export class UpdatePickedUpInput {
  organizationID: string;
  eventID: string;
  userID: string;
  transactionID: string;
  updatePickedupDetails: UpdatePickedupDetails[];

  constructor({
    organizationID,
    eventID,
    userID,
    transactionID,
    updatePickedupDetails,
  }: Partial<TypeWithFieldsOf<UpdatePickedUpInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.transactionID = transactionID ?? "";
    this.updatePickedupDetails = updatePickedupDetails ?? [];
  }

  convertUnexpectedNulls(): UpdatePickedUpInput {
    if (!this.updatePickedupDetails) {
      this.updatePickedupDetails = [];
    }
    return this;
  }

  fixClasses(): UpdatePickedUpInput {
    for (let i0 = 0; i0 < this.updatePickedupDetails.length; i0 += 1) {
      this.updatePickedupDetails[i0] = new UpdatePickedupDetails(this.updatePickedupDetails[i0]!).fixClasses();
    }
    return this;
  }
}

export class UpdatePickedUpOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdatePickedUpOutput>> = {}) {
  }

  fixClasses(): UpdatePickedUpOutput {
    return this;
  }
}

export class UpdatePickupItemInput {
  id: string;
  organizationID: string;
  eventID: string;
  pickupTimeSlot: (Date | null);
  isPickedUp: (boolean | null);
  isReadyPickUp: (boolean | null);
  pickedUpByGuestID: (string | null);
  pickupPreference: (string | null);

  constructor({
    id,
    organizationID,
    eventID,
    pickupTimeSlot,
    isPickedUp,
    isReadyPickUp,
    pickedUpByGuestID,
    pickupPreference,
  }: Partial<TypeWithFieldsOf<UpdatePickupItemInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.pickupTimeSlot = pickupTimeSlot ?? null;
    this.isPickedUp = isPickedUp ?? null;
    this.isReadyPickUp = isReadyPickUp ?? null;
    this.pickedUpByGuestID = pickedUpByGuestID ?? null;
    this.pickupPreference = pickupPreference ?? null;
  }

  convertDates(): UpdatePickupItemInput {
    if (this.pickupTimeSlot !== undefined && this.pickupTimeSlot !== null) {
      this.pickupTimeSlot = new Date(this.pickupTimeSlot!);
    }
    return this;
  }

  fixClasses(): UpdatePickupItemInput {
    return this;
  }
}

export class UpdatePickupItemOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdatePickupItemOutput>> = {}) {
  }

  fixClasses(): UpdatePickupItemOutput {
    return this;
  }
}

export class UpdatePickupPreferenceInput {
  organizationID: string;
  eventID: string;
  transactionID: string;
  pickupItems: PickupItems[];

  constructor({
    organizationID,
    eventID,
    transactionID,
    pickupItems,
  }: Partial<TypeWithFieldsOf<UpdatePickupPreferenceInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.transactionID = transactionID ?? "";
    this.pickupItems = pickupItems ?? [];
  }

  convertDates(): UpdatePickupPreferenceInput {
    if (!this.pickupItems) {
      this.pickupItems = [];
    }
    
    for (let i0 = 0; i0 < this.pickupItems.length; i0 += 1) {
      this.pickupItems[i0] = this.pickupItems[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): UpdatePickupPreferenceInput {
    if (!this.pickupItems) {
      this.pickupItems = [];
    }
    return this;
  }

  fixClasses(): UpdatePickupPreferenceInput {
    for (let i0 = 0; i0 < this.pickupItems.length; i0 += 1) {
      this.pickupItems[i0] = new PickupItems(this.pickupItems[i0]!).fixClasses();
    }
    return this;
  }
}

export class UpdatePickupPreferenceOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdatePickupPreferenceOutput>> = {}) {
  }

  fixClasses(): UpdatePickupPreferenceOutput {
    return this;
  }
}

export class UpdatePreEventQuestionConfigInput {
  preEventQuestionConfigID: string;
  organizationID: string;
  eventID: string;
  questionOnePrompt: (string | null);
  questionOneActive: (boolean | null);
  questionTwoPrompt: (string | null);
  questionTwoActive: (boolean | null);
  questionThreePrompt: (string | null);
  questionThreeActive: (boolean | null);

  constructor({
    preEventQuestionConfigID,
    organizationID,
    eventID,
    questionOnePrompt,
    questionOneActive,
    questionTwoPrompt,
    questionTwoActive,
    questionThreePrompt,
    questionThreeActive,
  }: Partial<TypeWithFieldsOf<UpdatePreEventQuestionConfigInput>> = {}) {
    this.preEventQuestionConfigID = preEventQuestionConfigID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.questionOnePrompt = questionOnePrompt ?? null;
    this.questionOneActive = questionOneActive ?? null;
    this.questionTwoPrompt = questionTwoPrompt ?? null;
    this.questionTwoActive = questionTwoActive ?? null;
    this.questionThreePrompt = questionThreePrompt ?? null;
    this.questionThreeActive = questionThreeActive ?? null;
  }

  fixClasses(): UpdatePreEventQuestionConfigInput {
    return this;
  }
}

export class UpdatePreEventQuestionConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdatePreEventQuestionConfigOutput>> = {}) {
  }

  fixClasses(): UpdatePreEventQuestionConfigOutput {
    return this;
  }
}

export class UpdatePurchasedItemInput {
  id: string;
  organizationID: string;
  eventID: string;
  userID: (string | null);
  guestID: (string | null);
  amountCents: (number | null);

  constructor({
    id,
    organizationID,
    eventID,
    userID,
    guestID,
    amountCents,
  }: Partial<TypeWithFieldsOf<UpdatePurchasedItemInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? null;
    this.guestID = guestID ?? null;
    this.amountCents = amountCents ?? null;
  }

  fixClasses(): UpdatePurchasedItemInput {
    return this;
  }
}

export class UpdatePurchasedItemOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdatePurchasedItemOutput>> = {}) {
  }

  fixClasses(): UpdatePurchasedItemOutput {
    return this;
  }
}

export class UpdatePurchasedItemSalesTaxInput {
  id: string;
  organizationID: string;
  eventID: string;
  userID: (string | null);
  guestID: (string | null);

  constructor({
    id,
    organizationID,
    eventID,
    userID,
    guestID,
  }: Partial<TypeWithFieldsOf<UpdatePurchasedItemSalesTaxInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? null;
    this.guestID = guestID ?? null;
  }

  fixClasses(): UpdatePurchasedItemSalesTaxInput {
    return this;
  }
}

export class UpdatePurchasedItemSalesTaxOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdatePurchasedItemSalesTaxOutput>> = {}) {
  }

  fixClasses(): UpdatePurchasedItemSalesTaxOutput {
    return this;
  }
}

export class UpdateRegistrationInput {
  registrationID: string;
  organizationID: string;
  eventID: string;
  firstName: (string | null);
  lastName: (string | null);
  email: (string | null);
  mobilePhone: (string | null);
  wantEmailUpdates: (boolean | null);
  wantSMSUpdates: (boolean | null);
  greatfeatsParticipantID: (string | null);
  detailsJSON: (string | null);

  constructor({
    registrationID,
    organizationID,
    eventID,
    firstName,
    lastName,
    email,
    mobilePhone,
    wantEmailUpdates,
    wantSMSUpdates,
    greatfeatsParticipantID,
    detailsJSON,
  }: Partial<TypeWithFieldsOf<UpdateRegistrationInput>> = {}) {
    this.registrationID = registrationID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? null;
    this.lastName = lastName ?? null;
    this.email = email ?? null;
    this.mobilePhone = mobilePhone ?? null;
    this.wantEmailUpdates = wantEmailUpdates ?? null;
    this.wantSMSUpdates = wantSMSUpdates ?? null;
    this.greatfeatsParticipantID = greatfeatsParticipantID ?? null;
    this.detailsJSON = detailsJSON ?? null;
  }

  fixClasses(): UpdateRegistrationInput {
    return this;
  }
}

export class UpdateRegistrationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateRegistrationOutput>> = {}) {
  }

  fixClasses(): UpdateRegistrationOutput {
    return this;
  }
}

export class UpdateSceneInput {
  sceneID: string;
  organizationID: string;
  eventID: string;
  showID: string;
  streamID: (string | null);
  name: (string | null);
  descriptionMarkdown: (string | null);
  plannedDurationMinutes: (number | null);
  estimatedDurationSeconds: (number | null);
  type: (string | null);
  thumbnailURL: (string | null);
  defaultOverlayConfig: (OverlayConfiguration | null);
  activeOverlayConfig: (OverlayConfiguration | null);
  isCurtain: (boolean | null);
  inheritDefaultStream: (boolean | null);
  streamType: (string | null);
  zoomMeetingNumber: (string | null);
  zoomPassWord: (string | null);
  url: (string | null);

  constructor({
    sceneID,
    organizationID,
    eventID,
    showID,
    streamID,
    name,
    descriptionMarkdown,
    plannedDurationMinutes,
    estimatedDurationSeconds,
    type,
    thumbnailURL,
    defaultOverlayConfig,
    activeOverlayConfig,
    isCurtain,
    inheritDefaultStream,
    streamType,
    zoomMeetingNumber,
    zoomPassWord,
    url,
  }: Partial<TypeWithFieldsOf<UpdateSceneInput>> = {}) {
    this.sceneID = sceneID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.showID = showID ?? "";
    this.streamID = streamID ?? null;
    this.name = name ?? null;
    this.descriptionMarkdown = descriptionMarkdown ?? null;
    this.plannedDurationMinutes = plannedDurationMinutes ?? null;
    this.estimatedDurationSeconds = estimatedDurationSeconds ?? null;
    this.type = type ?? null;
    this.thumbnailURL = thumbnailURL ?? null;
    this.defaultOverlayConfig = defaultOverlayConfig ?? null;
    this.activeOverlayConfig = activeOverlayConfig ?? null;
    this.isCurtain = isCurtain ?? null;
    this.inheritDefaultStream = inheritDefaultStream ?? null;
    this.streamType = streamType ?? null;
    this.zoomMeetingNumber = zoomMeetingNumber ?? null;
    this.zoomPassWord = zoomPassWord ?? null;
    this.url = url ?? null;
  }

  convertUnexpectedNulls(): UpdateSceneInput {
    if (this.defaultOverlayConfig !== null) {
      this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    }
    if (this.activeOverlayConfig !== null) {
      this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): UpdateSceneInput {
    if (this.defaultOverlayConfig !== null) {
      this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    }
    if (this.activeOverlayConfig !== null) {
      this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    }
    return this;
  }
}

export class UpdateSceneOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateSceneOutput>> = {}) {
  }

  fixClasses(): UpdateSceneOutput {
    return this;
  }
}

export class UpdateShowInput {
  showID: string;
  organizationID: string;
  eventID: string;
  name: (string | null);
  descriptionMarkdown: (string | null);
  sceneOrderIDs: (string[] | null);
  activeSceneID: (string | null);
  defaultOverlayConfig: (OverlayConfiguration | null);
  overrideOverlayConfig: (OverlayConfiguration | null);
  activeOverlayConfig: (OverlayConfiguration | null);
  defaultStreamType: (string | null);
  defaultStreamID: (string | null);
  zoomMeetingNumber: (string | null);
  zoomPassword: (string | null);
  url: (string | null);
  isChangingScene: (boolean | null);

  constructor({
    showID,
    organizationID,
    eventID,
    name,
    descriptionMarkdown,
    sceneOrderIDs,
    activeSceneID,
    defaultOverlayConfig,
    overrideOverlayConfig,
    activeOverlayConfig,
    defaultStreamType,
    defaultStreamID,
    zoomMeetingNumber,
    zoomPassword,
    url,
    isChangingScene,
  }: Partial<TypeWithFieldsOf<UpdateShowInput>> = {}) {
    this.showID = showID ?? "";
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? null;
    this.descriptionMarkdown = descriptionMarkdown ?? null;
    this.sceneOrderIDs = sceneOrderIDs ?? null;
    this.activeSceneID = activeSceneID ?? null;
    this.defaultOverlayConfig = defaultOverlayConfig ?? null;
    this.overrideOverlayConfig = overrideOverlayConfig ?? null;
    this.activeOverlayConfig = activeOverlayConfig ?? null;
    this.defaultStreamType = defaultStreamType ?? null;
    this.defaultStreamID = defaultStreamID ?? null;
    this.zoomMeetingNumber = zoomMeetingNumber ?? null;
    this.zoomPassword = zoomPassword ?? null;
    this.url = url ?? null;
    this.isChangingScene = isChangingScene ?? null;
  }

  convertUnexpectedNulls(): UpdateShowInput {
    if (this.defaultOverlayConfig !== null) {
      this.defaultOverlayConfig = this.defaultOverlayConfig!.convertUnexpectedNulls();
    }
    if (this.overrideOverlayConfig !== null) {
      this.overrideOverlayConfig = this.overrideOverlayConfig!.convertUnexpectedNulls();
    }
    if (this.activeOverlayConfig !== null) {
      this.activeOverlayConfig = this.activeOverlayConfig!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): UpdateShowInput {
    if (this.defaultOverlayConfig !== null) {
      this.defaultOverlayConfig = new OverlayConfiguration(this.defaultOverlayConfig!).fixClasses();
    }
    if (this.overrideOverlayConfig !== null) {
      this.overrideOverlayConfig = new OverlayConfiguration(this.overrideOverlayConfig!).fixClasses();
    }
    if (this.activeOverlayConfig !== null) {
      this.activeOverlayConfig = new OverlayConfiguration(this.activeOverlayConfig!).fixClasses();
    }
    return this;
  }
}

export class UpdateShowOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateShowOutput>> = {}) {
  }

  fixClasses(): UpdateShowOutput {
    return this;
  }
}

export class UpdateTicketAndInviteProxyGuestInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  guestID: string;
  proxyGuestID: string;
  attendeeFirstName: (string | null);
  attendeeLastName: (string | null);
  attendeeEmail: (string | null);

  constructor({
    organizationID,
    eventID,
    ticketID,
    guestID,
    proxyGuestID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
  }: Partial<TypeWithFieldsOf<UpdateTicketAndInviteProxyGuestInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.guestID = guestID ?? "";
    this.proxyGuestID = proxyGuestID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? null;
    this.attendeeLastName = attendeeLastName ?? null;
    this.attendeeEmail = attendeeEmail ?? null;
  }

  fixClasses(): UpdateTicketAndInviteProxyGuestInput {
    return this;
  }
}

export class UpdateTicketAndInviteProxyGuestOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateTicketAndInviteProxyGuestOutput>> = {}) {
  }

  fixClasses(): UpdateTicketAndInviteProxyGuestOutput {
    return this;
  }
}

export class UpdateTicketInput {
  organizationID: string;
  eventID: string;
  ticketID: string;
  ticketTypeID: string;
  attendeeFirstName: (string | null);
  attendeeLastName: (string | null);
  attendeeEmail: (string | null);
  attendeeMobilePhone: (string | null);
  editTicketKey: (string | null);
  ticketOptionAnswers: CreateOrUpdateTicketOptionAnswer[];

  constructor({
    organizationID,
    eventID,
    ticketID,
    ticketTypeID,
    attendeeFirstName,
    attendeeLastName,
    attendeeEmail,
    attendeeMobilePhone,
    editTicketKey,
    ticketOptionAnswers,
  }: Partial<TypeWithFieldsOf<UpdateTicketInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.ticketID = ticketID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.attendeeFirstName = attendeeFirstName ?? null;
    this.attendeeLastName = attendeeLastName ?? null;
    this.attendeeEmail = attendeeEmail ?? null;
    this.attendeeMobilePhone = attendeeMobilePhone ?? null;
    this.editTicketKey = editTicketKey ?? null;
    this.ticketOptionAnswers = ticketOptionAnswers ?? [];
  }

  convertUnexpectedNulls(): UpdateTicketInput {
    if (!this.ticketOptionAnswers) {
      this.ticketOptionAnswers = [];
    }
    return this;
  }

  fixClasses(): UpdateTicketInput {
    for (let i0 = 0; i0 < this.ticketOptionAnswers.length; i0 += 1) {
      this.ticketOptionAnswers[i0] = new CreateOrUpdateTicketOptionAnswer(this.ticketOptionAnswers[i0]!).fixClasses();
    }
    return this;
  }
}

export class UpdateTicketOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateTicketOutput>> = {}) {
  }

  fixClasses(): UpdateTicketOutput {
    return this;
  }
}

export class UpdateTicketTypeAndConfigInput {
  organizationID: string;
  eventID: string;
  eventSessionID: string;
  eventSessionTicketConfigID: string;
  ticketTypeID: string;
  name: (string | null);
  description: (string | null);
  maxTickets: (number | null);
  quantityPerGroup: (number | null);
  priceCents: (number | null);
  valueCents: (number | null);
  active: (boolean | null);
  attendanceType: (string | null);
  ticketOptions: (string[] | null);

  constructor({
    organizationID,
    eventID,
    eventSessionID,
    eventSessionTicketConfigID,
    ticketTypeID,
    name,
    description,
    maxTickets,
    quantityPerGroup,
    priceCents,
    valueCents,
    active,
    attendanceType,
    ticketOptions,
  }: Partial<TypeWithFieldsOf<UpdateTicketTypeAndConfigInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventSessionID = eventSessionID ?? "";
    this.eventSessionTicketConfigID = eventSessionTicketConfigID ?? "";
    this.ticketTypeID = ticketTypeID ?? "";
    this.name = name ?? null;
    this.description = description ?? null;
    this.maxTickets = maxTickets ?? null;
    this.quantityPerGroup = quantityPerGroup ?? null;
    this.priceCents = priceCents ?? null;
    this.valueCents = valueCents ?? null;
    this.active = active ?? null;
    this.attendanceType = attendanceType ?? null;
    this.ticketOptions = ticketOptions ?? null;
  }

  fixClasses(): UpdateTicketTypeAndConfigInput {
    return this;
  }
}

export class UpdateTicketTypeAndConfigOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateTicketTypeAndConfigOutput>> = {}) {
  }

  fixClasses(): UpdateTicketTypeAndConfigOutput {
    return this;
  }
}

export class UpdateVirtualChairInput {
  id: string;
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  userEmail: (string | null);
  userFirstName: (string | null);
  userLastName: (string | null);
  channelName: (string | null);
  channelARN: (string | null);

  constructor({
    id,
    organizationID,
    virtualEventID,
    virtualTableID,
    userEmail,
    userFirstName,
    userLastName,
    channelName,
    channelARN,
  }: Partial<TypeWithFieldsOf<UpdateVirtualChairInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.userEmail = userEmail ?? null;
    this.userFirstName = userFirstName ?? null;
    this.userLastName = userLastName ?? null;
    this.channelName = channelName ?? null;
    this.channelARN = channelARN ?? null;
  }

  fixClasses(): UpdateVirtualChairInput {
    return this;
  }
}

export class UpdateVirtualChairOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateVirtualChairOutput>> = {}) {
  }

  fixClasses(): UpdateVirtualChairOutput {
    return this;
  }
}

export class UpdateVirtualTableInput {
  id: string;
  organizationID: string;
  virtualEventID: string;
  name: (string | null);
  tableNumber: (number | null);

  constructor({
    id,
    organizationID,
    virtualEventID,
    name,
    tableNumber,
  }: Partial<TypeWithFieldsOf<UpdateVirtualTableInput>> = {}) {
    this.id = id ?? "";
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.name = name ?? null;
    this.tableNumber = tableNumber ?? null;
  }

  fixClasses(): UpdateVirtualTableInput {
    return this;
  }
}

export class UpdateVirtualTableOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<UpdateVirtualTableOutput>> = {}) {
  }

  fixClasses(): UpdateVirtualTableOutput {
    return this;
  }
}

export class UploadInvitationsInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<UploadInvitationsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): UploadInvitationsInput {
    return this;
  }
}

export class UploadInvitationsOutput {
  numDuplicates: number;
  numErrors: number;
  numSuccess: number;

  constructor({
    numDuplicates,
    numErrors,
    numSuccess,
  }: Partial<TypeWithFieldsOf<UploadInvitationsOutput>> = {}) {
    this.numDuplicates = numDuplicates ?? 0;
    this.numErrors = numErrors ?? 0;
    this.numSuccess = numSuccess ?? 0;
  }

  fixClasses(): UploadInvitationsOutput {
    return this;
  }
}

export class UploadTableDataCSVInput {
  organizationID: string;
  eventID: string;

  constructor({
    organizationID,
    eventID,
  }: Partial<TypeWithFieldsOf<UploadTableDataCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
  }

  fixClasses(): UploadTableDataCSVInput {
    return this;
  }
}

export class UploadTableDataCSVOutput {
  numErrors: number;

  constructor({
    numErrors,
  }: Partial<TypeWithFieldsOf<UploadTableDataCSVOutput>> = {}) {
    this.numErrors = numErrors ?? 0;
  }

  fixClasses(): UploadTableDataCSVOutput {
    return this;
  }
}

export class ValidateVirtualTableCreationInput {
  organizationID: string;
  virtualEventID: string;
  tables: Table[];

  constructor({
    organizationID,
    virtualEventID,
    tables,
  }: Partial<TypeWithFieldsOf<ValidateVirtualTableCreationInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.tables = tables ?? [];
  }

  convertUnexpectedNulls(): ValidateVirtualTableCreationInput {
    if (!this.tables) {
      this.tables = [];
    }
    for (let i0 = 0; i0 < this.tables.length; i0 += 1) {
      this.tables[i0] = this.tables[i0]!.convertUnexpectedNulls();
    }
    return this;
  }

  fixClasses(): ValidateVirtualTableCreationInput {
    for (let i0 = 0; i0 < this.tables.length; i0 += 1) {
      this.tables[i0] = new Table(this.tables[i0]!).fixClasses();
    }
    return this;
  }
}

export class ValidateVirtualTableCreationOutput {

  constructor({
  }: Partial<TypeWithFieldsOf<ValidateVirtualTableCreationOutput>> = {}) {
  }

  fixClasses(): ValidateVirtualTableCreationOutput {
    return this;
  }
}

export class ViewBannedLivestreamChatUserRegistrationsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewBannedLivestreamChatUserRegistrationsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewBannedLivestreamChatUserRegistrationsInput {
    return this;
  }
}

export class ViewBannedLivestreamChatUserRegistrationsOutput {
  items: ViewBannedLivestreamChatUserRegistrationsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewBannedLivestreamChatUserRegistrationsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ViewBannedLivestreamChatUserRegistrationsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewBannedLivestreamChatUserRegistrationsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewBannedLivestreamChatUserRegistrationsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewBannedLivestreamChatUserRegistrationsSummary {
  organizationID: string;
  eventID: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  userID: string;
  accessCode: string;
  wantEmailUpdates: boolean;
  wantSMSUpdates: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    firstName,
    lastName,
    email,
    mobilePhone,
    userID,
    accessCode,
    wantEmailUpdates,
    wantSMSUpdates,
  }: Partial<TypeWithFieldsOf<ViewBannedLivestreamChatUserRegistrationsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.userID = userID ?? "";
    this.accessCode = accessCode ?? "";
    this.wantEmailUpdates = wantEmailUpdates ?? false;
    this.wantSMSUpdates = wantSMSUpdates ?? false;
  }

  fixClasses(): ViewBannedLivestreamChatUserRegistrationsSummary {
    return this;
  }
}

export class ViewBannedLivestreamChatUserRegistrationsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewBannedLivestreamChatUserRegistrationsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewBannedLivestreamChatUserRegistrationsToCSVInput {
    return this;
  }
}

export class ViewDonationItemInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationItemInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewDonationItemInput {
    return this;
  }
}

export class ViewDonationItemOutput {
  items: ViewDonationItemSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationItemOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewDonationItemOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewDonationItemOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewDonationItemOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewDonationItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewDonationItemSummary {
  organizationID: string;
  eventID: string;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  paddleNumber: number;
  amountCents: number;
  amountDollars: string;
  impactName: string;
  type: string;
  transactionID: string;
  transactionStatus: string;
  purchaseID: string;
  paidBy: string;
  sharedCart: string;
  sharedCartPrimary: string;

  constructor({
    organizationID,
    eventID,
    created,
    updated,
    firstName,
    lastName,
    email,
    mobilePhone,
    paddleNumber,
    amountCents,
    amountDollars,
    impactName,
    type,
    transactionID,
    transactionStatus,
    purchaseID,
    paidBy,
    sharedCart,
    sharedCartPrimary,
  }: Partial<TypeWithFieldsOf<ViewDonationItemSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.paddleNumber = paddleNumber ?? 0;
    this.amountCents = amountCents ?? 0;
    this.amountDollars = amountDollars ?? "";
    this.impactName = impactName ?? "";
    this.type = type ?? "";
    this.transactionID = transactionID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.purchaseID = purchaseID ?? "";
    this.paidBy = paidBy ?? "";
    this.sharedCart = sharedCart ?? "";
    this.sharedCartPrimary = sharedCartPrimary ?? "";
  }

  convertDates(): ViewDonationItemSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): ViewDonationItemSummary {
    return this;
  }
}

export class ViewDonationItemToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationItemToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewDonationItemToCSVInput {
    return this;
  }
}

export class ViewDonationsByDayInput {
  organizationID: string;
  eventID: string;
  timeZone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    timeZone,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationsByDayInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.timeZone = timeZone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewDonationsByDayInput {
    return this;
  }
}

export class ViewDonationsByDayOutput {
  items: ViewDonationsByDaySummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationsByDayOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewDonationsByDayOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewDonationsByDayOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewDonationsByDayOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewDonationsByDaySummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewDonationsByDaySummary {
  organizationID: string;
  eventID: string;
  oneTimeDonationsCount: number;
  oneTimeDonationsAmountCents: string;
  groupDate: Date;

  constructor({
    organizationID,
    eventID,
    oneTimeDonationsCount,
    oneTimeDonationsAmountCents,
    groupDate,
  }: Partial<TypeWithFieldsOf<ViewDonationsByDaySummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.oneTimeDonationsCount = oneTimeDonationsCount ?? 0;
    this.oneTimeDonationsAmountCents = oneTimeDonationsAmountCents ?? "";
    this.groupDate = groupDate ?? new Date();
  }

  convertDates(): ViewDonationsByDaySummary {
    this.groupDate = new Date(this.groupDate!);
    return this;
  }

  fixClasses(): ViewDonationsByDaySummary {
    return this;
  }
}

export class ViewDonationsByDayToCSVInput {
  organizationID: string;
  eventID: string;
  timeZone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    timeZone,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationsByDayToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.timeZone = timeZone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewDonationsByDayToCSVInput {
    return this;
  }
}

export class ViewDonationSumPerDonorInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationSumPerDonorInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewDonationSumPerDonorInput {
    return this;
  }
}

export class ViewDonationSumPerDonorOutput {
  items: ViewDonationSumPerDonorSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationSumPerDonorOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewDonationSumPerDonorOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewDonationSumPerDonorOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewDonationSumPerDonorOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewDonationSumPerDonorSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewDonationSumPerDonorSummary {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  donatedAmountCents: string;
  created: Date;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    donatedAmountCents,
    created,
  }: Partial<TypeWithFieldsOf<ViewDonationSumPerDonorSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.donatedAmountCents = donatedAmountCents ?? "";
    this.created = created ?? new Date();
  }

  convertDates(): ViewDonationSumPerDonorSummary {
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): ViewDonationSumPerDonorSummary {
    return this;
  }
}

export class ViewDonationSumPerDonorToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewDonationSumPerDonorToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewDonationSumPerDonorToCSVInput {
    return this;
  }
}

export class ViewEventRegistrationPurchasesInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationPurchasesInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewEventRegistrationPurchasesInput {
    return this;
  }
}

export class ViewEventRegistrationPurchasesOutput {
  items: ViewEventRegistrationPurchasesSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationPurchasesOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewEventRegistrationPurchasesOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewEventRegistrationPurchasesOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewEventRegistrationPurchasesOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewEventRegistrationPurchasesSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewEventRegistrationPurchasesSummary {
  organizationID: string;
  eventID: string;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  fullName: string;
  userID: string;
  email: string;
  mobilePhone: string;
  ticketCount: number;
  totalProceedsAmountCents: number;
  cartCount: number;
  purchasedItemCount: number;

  constructor({
    organizationID,
    eventID,
    created,
    updated,
    firstName,
    lastName,
    fullName,
    userID,
    email,
    mobilePhone,
    ticketCount,
    totalProceedsAmountCents,
    cartCount,
    purchasedItemCount,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationPurchasesSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.fullName = fullName ?? "";
    this.userID = userID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.ticketCount = ticketCount ?? 0;
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.cartCount = cartCount ?? 0;
    this.purchasedItemCount = purchasedItemCount ?? 0;
  }

  convertDates(): ViewEventRegistrationPurchasesSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): ViewEventRegistrationPurchasesSummary {
    return this;
  }
}

export class ViewEventRegistrationPurchasesToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationPurchasesToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewEventRegistrationPurchasesToCSVInput {
    return this;
  }
}

export class ViewEventRegistrationsByDayInput {
  organizationID: string;
  eventID: string;
  timeZone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    timeZone,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsByDayInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.timeZone = timeZone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewEventRegistrationsByDayInput {
    return this;
  }
}

export class ViewEventRegistrationsByDayOutput {
  items: ViewEventRegistrationsByDaySummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsByDayOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewEventRegistrationsByDayOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewEventRegistrationsByDayOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewEventRegistrationsByDayOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewEventRegistrationsByDaySummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewEventRegistrationsByDaySummary {
  organizationID: string;
  eventID: string;
  eventRegistrationsCount: number;
  groupDate: Date;

  constructor({
    organizationID,
    eventID,
    eventRegistrationsCount,
    groupDate,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsByDaySummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.eventRegistrationsCount = eventRegistrationsCount ?? 0;
    this.groupDate = groupDate ?? new Date();
  }

  convertDates(): ViewEventRegistrationsByDaySummary {
    this.groupDate = new Date(this.groupDate!);
    return this;
  }

  fixClasses(): ViewEventRegistrationsByDaySummary {
    return this;
  }
}

export class ViewEventRegistrationsByDayToCSVInput {
  organizationID: string;
  eventID: string;
  timeZone: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    timeZone,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsByDayToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.timeZone = timeZone ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewEventRegistrationsByDayToCSVInput {
    return this;
  }
}

export class ViewEventRegistrationsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewEventRegistrationsInput {
    return this;
  }
}

export class ViewEventRegistrationsOutput {
  items: ViewEventRegistrationsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewEventRegistrationsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewEventRegistrationsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewEventRegistrationsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewEventRegistrationsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewEventRegistrationsSummary {
  organizationID: string;
  eventID: string;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  paidStatus: string;

  constructor({
    organizationID,
    eventID,
    created,
    updated,
    firstName,
    lastName,
    email,
    mobilePhone,
    paidStatus,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.paidStatus = paidStatus ?? "";
  }

  convertDates(): ViewEventRegistrationsSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): ViewEventRegistrationsSummary {
    return this;
  }
}

export class ViewEventRegistrationsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewEventRegistrationsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewEventRegistrationsToCSVInput {
    return this;
  }
}

export class ViewFixedPriceDetailsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewFixedPriceDetailsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewFixedPriceDetailsInput {
    return this;
  }
}

export class ViewFixedPriceDetailsOutput {
  items: ViewFixedPriceDetailsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewFixedPriceDetailsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ViewFixedPriceDetailsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewFixedPriceDetailsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewFixedPriceDetailsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewFixedPriceDetailsSummary {
  organizationID: string;
  eventID: string;
  id: string;
  name: string;
  priceCents: number;
  valueCents: number;
  isTaxable: boolean;
  description: string;
  numPurchased: number;
  maxNumAvailable: number;
  numUnlimited: boolean;
  inPersonOnly: boolean;
  pickupLater: boolean;
  pickupTonight: boolean;
  isPickUpEventLevel: boolean;
  sortOrder: number;
  impactID: string;
  hideFromFlow: boolean;
  photoID: string;
  url: string;

  constructor({
    organizationID,
    eventID,
    id,
    name,
    priceCents,
    valueCents,
    isTaxable,
    description,
    numPurchased,
    maxNumAvailable,
    numUnlimited,
    inPersonOnly,
    pickupLater,
    pickupTonight,
    isPickUpEventLevel,
    sortOrder,
    impactID,
    hideFromFlow,
    photoID,
    url,
  }: Partial<TypeWithFieldsOf<ViewFixedPriceDetailsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.priceCents = priceCents ?? 0;
    this.valueCents = valueCents ?? 0;
    this.isTaxable = isTaxable ?? false;
    this.description = description ?? "";
    this.numPurchased = numPurchased ?? 0;
    this.maxNumAvailable = maxNumAvailable ?? 0;
    this.numUnlimited = numUnlimited ?? false;
    this.inPersonOnly = inPersonOnly ?? false;
    this.pickupLater = pickupLater ?? false;
    this.pickupTonight = pickupTonight ?? false;
    this.isPickUpEventLevel = isPickUpEventLevel ?? false;
    this.sortOrder = sortOrder ?? 0;
    this.impactID = impactID ?? "";
    this.hideFromFlow = hideFromFlow ?? false;
    this.photoID = photoID ?? "";
    this.url = url ?? "";
  }

  fixClasses(): ViewFixedPriceDetailsSummary {
    return this;
  }
}

export class ViewFixedPriceDetailsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewFixedPriceDetailsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewFixedPriceDetailsToCSVInput {
    return this;
  }
}

export class ViewGuestsWithPurchasedItemCountsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewGuestsWithPurchasedItemCountsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewGuestsWithPurchasedItemCountsInput {
    return this;
  }
}

export class ViewGuestsWithPurchasedItemCountsOutput {
  items: ViewGuestsWithPurchasedItemCountsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewGuestsWithPurchasedItemCountsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewGuestsWithPurchasedItemCountsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewGuestsWithPurchasedItemCountsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewGuestsWithPurchasedItemCountsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewGuestsWithPurchasedItemCountsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewGuestsWithPurchasedItemCountsSummary {
  organizationID: string;
  eventID: string;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  fullName: string;
  userID: string;
  guestID: string;
  email: string;
  mobilePhone: string;
  cartCount: number;
  purchasedItemCount: number;

  constructor({
    organizationID,
    eventID,
    created,
    updated,
    firstName,
    lastName,
    fullName,
    userID,
    guestID,
    email,
    mobilePhone,
    cartCount,
    purchasedItemCount,
  }: Partial<TypeWithFieldsOf<ViewGuestsWithPurchasedItemCountsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.fullName = fullName ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.cartCount = cartCount ?? 0;
    this.purchasedItemCount = purchasedItemCount ?? 0;
  }

  convertDates(): ViewGuestsWithPurchasedItemCountsSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): ViewGuestsWithPurchasedItemCountsSummary {
    return this;
  }
}

export class ViewGuestsWithPurchasedItemCountsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewGuestsWithPurchasedItemCountsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewGuestsWithPurchasedItemCountsToCSVInput {
    return this;
  }
}

export class ViewImpactsForOrgInput {
  organizationID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewImpactsForOrgInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewImpactsForOrgInput {
    return this;
  }
}

export class ViewImpactsForOrgOutput {
  items: ViewImpactsForOrgSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewImpactsForOrgOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewImpactsForOrgOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewImpactsForOrgOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewImpactsForOrgOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewImpactsForOrgSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewImpactsForOrgSummary {
  organizationID: string;
  id: string;
  eventID: string;
  name: string;
  amountCents: number;
  descriptionMarkdown: string;
  totalPurchasesLimit: number;
  totalPurchasesCountDisplayThreshold: number;
  totalPurchasesAmountCentsDisplayThreshold: number;
  totalPurchasesCount: number;
  totalPurchasesAmountCents: number;
  goalType: string;
  goalPurchasesCount: number;
  goalPurchasesCountDisplayThreshold: number;
  goalPurchasesCountMetOn: (Date | null);
  goalPurchasesAmountCents: number;
  goalPurchasesAmountCentsDisplayThreshold: number;
  goalPurchasesAmountCentsMetOn: (Date | null);
  goalMetMessageSentOn: (Date | null);
  goalMetEmailSubject: string;
  goalMetEmailBodyMarkdown: string;
  goalMetSMSBody: string;
  hideFromFlow: boolean;
  fixedPriceItemID: string;

  constructor({
    organizationID,
    id,
    eventID,
    name,
    amountCents,
    descriptionMarkdown,
    totalPurchasesLimit,
    totalPurchasesCountDisplayThreshold,
    totalPurchasesAmountCentsDisplayThreshold,
    totalPurchasesCount,
    totalPurchasesAmountCents,
    goalType,
    goalPurchasesCount,
    goalPurchasesCountDisplayThreshold,
    goalPurchasesCountMetOn,
    goalPurchasesAmountCents,
    goalPurchasesAmountCentsDisplayThreshold,
    goalPurchasesAmountCentsMetOn,
    goalMetMessageSentOn,
    goalMetEmailSubject,
    goalMetEmailBodyMarkdown,
    goalMetSMSBody,
    hideFromFlow,
    fixedPriceItemID,
  }: Partial<TypeWithFieldsOf<ViewImpactsForOrgSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.id = id ?? "";
    this.eventID = eventID ?? "";
    this.name = name ?? "";
    this.amountCents = amountCents ?? 0;
    this.descriptionMarkdown = descriptionMarkdown ?? "";
    this.totalPurchasesLimit = totalPurchasesLimit ?? 0;
    this.totalPurchasesCountDisplayThreshold = totalPurchasesCountDisplayThreshold ?? 0;
    this.totalPurchasesAmountCentsDisplayThreshold = totalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.totalPurchasesCount = totalPurchasesCount ?? 0;
    this.totalPurchasesAmountCents = totalPurchasesAmountCents ?? 0;
    this.goalType = goalType ?? "";
    this.goalPurchasesCount = goalPurchasesCount ?? 0;
    this.goalPurchasesCountDisplayThreshold = goalPurchasesCountDisplayThreshold ?? 0;
    this.goalPurchasesCountMetOn = goalPurchasesCountMetOn ?? null;
    this.goalPurchasesAmountCents = goalPurchasesAmountCents ?? 0;
    this.goalPurchasesAmountCentsDisplayThreshold = goalPurchasesAmountCentsDisplayThreshold ?? 0;
    this.goalPurchasesAmountCentsMetOn = goalPurchasesAmountCentsMetOn ?? null;
    this.goalMetMessageSentOn = goalMetMessageSentOn ?? null;
    this.goalMetEmailSubject = goalMetEmailSubject ?? "";
    this.goalMetEmailBodyMarkdown = goalMetEmailBodyMarkdown ?? "";
    this.goalMetSMSBody = goalMetSMSBody ?? "";
    this.hideFromFlow = hideFromFlow ?? false;
    this.fixedPriceItemID = fixedPriceItemID ?? "";
  }

  convertDates(): ViewImpactsForOrgSummary {
    if (this.goalPurchasesCountMetOn !== undefined && this.goalPurchasesCountMetOn !== null) {
      this.goalPurchasesCountMetOn = new Date(this.goalPurchasesCountMetOn!);
    }
    if (this.goalPurchasesAmountCentsMetOn !== undefined && this.goalPurchasesAmountCentsMetOn !== null) {
      this.goalPurchasesAmountCentsMetOn = new Date(this.goalPurchasesAmountCentsMetOn!);
    }
    if (this.goalMetMessageSentOn !== undefined && this.goalMetMessageSentOn !== null) {
      this.goalMetMessageSentOn = new Date(this.goalMetMessageSentOn!);
    }
    return this;
  }

  fixClasses(): ViewImpactsForOrgSummary {
    return this;
  }
}

export class ViewPurchasedItemAnonymousInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemAnonymousInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewPurchasedItemAnonymousInput {
    return this;
  }
}

export class ViewPurchasedItemAnonymousOutput {
  items: ViewPurchasedItemAnonymousSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemAnonymousOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewPurchasedItemAnonymousOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewPurchasedItemAnonymousOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewPurchasedItemAnonymousOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewPurchasedItemAnonymousSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewPurchasedItemAnonymousSummary {
  organizationID: string;
  eventID: string;
  id: string;
  created: Date;
  firstName: string;
  lastName: string;
  userID: string;
  guestID: string;
  email: string;
  mobilePhone: string;
  amountCents: number;
  amountDollars: string;
  type: string;
  impactID: string;
  auctionID: string;
  lotID: string;
  ticketID: string;
  payAfterEvent: boolean;
  purchaseID: string;
  transactionStatus: string;
  transactionID: string;
  isPaid: boolean;
  refundID: string;
  isExternal: boolean;
  isAnonymous: boolean;

  constructor({
    organizationID,
    eventID,
    id,
    created,
    firstName,
    lastName,
    userID,
    guestID,
    email,
    mobilePhone,
    amountCents,
    amountDollars,
    type,
    impactID,
    auctionID,
    lotID,
    ticketID,
    payAfterEvent,
    purchaseID,
    transactionStatus,
    transactionID,
    isPaid,
    refundID,
    isExternal,
    isAnonymous,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemAnonymousSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.created = created ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.amountCents = amountCents ?? 0;
    this.amountDollars = amountDollars ?? "";
    this.type = type ?? "";
    this.impactID = impactID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.ticketID = ticketID ?? "";
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.isPaid = isPaid ?? false;
    this.refundID = refundID ?? "";
    this.isExternal = isExternal ?? false;
    this.isAnonymous = isAnonymous ?? false;
  }

  convertDates(): ViewPurchasedItemAnonymousSummary {
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): ViewPurchasedItemAnonymousSummary {
    return this;
  }
}

export class ViewPurchasedItemAnonymousToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemAnonymousToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewPurchasedItemAnonymousToCSVInput {
    return this;
  }
}

export class ViewPurchasedItemInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewPurchasedItemInput {
    return this;
  }
}

export class ViewPurchasedItemOutput {
  items: ViewPurchasedItemSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewPurchasedItemOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewPurchasedItemOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewPurchasedItemOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewPurchasedItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewPurchasedItemSummary {
  organizationID: string;
  eventID: string;
  id: string;
  created: Date;
  deleted: boolean;
  firstName: string;
  lastName: string;
  userID: string;
  guestID: string;
  email: string;
  mobilePhone: string;
  amountCents: number;
  amountDollars: string;
  type: string;
  impactID: string;
  auctionID: string;
  lotID: string;
  ticketID: string;
  fixedPriceItemID: string;
  payAfterEvent: boolean;
  purchaseID: string;
  transactionStatus: string;
  transactionID: string;
  isPaid: boolean;
  refundID: string;
  isExternal: boolean;
  isAnonymous: boolean;
  bidderNumber: number;
  purchasedItemSalesTaxID: string;
  salesTaxPercent: number;
  salesTaxAmountCents: number;
  salesTaxAmountDollars: string;
  salesTaxPurchaseID: string;
  salesTaxTransactionStatus: string;
  salesTaxTransactionID: string;
  salesTaxRefundID: string;
  paidByGuestID: string;

  constructor({
    organizationID,
    eventID,
    id,
    created,
    deleted,
    firstName,
    lastName,
    userID,
    guestID,
    email,
    mobilePhone,
    amountCents,
    amountDollars,
    type,
    impactID,
    auctionID,
    lotID,
    ticketID,
    fixedPriceItemID,
    payAfterEvent,
    purchaseID,
    transactionStatus,
    transactionID,
    isPaid,
    refundID,
    isExternal,
    isAnonymous,
    bidderNumber,
    purchasedItemSalesTaxID,
    salesTaxPercent,
    salesTaxAmountCents,
    salesTaxAmountDollars,
    salesTaxPurchaseID,
    salesTaxTransactionStatus,
    salesTaxTransactionID,
    salesTaxRefundID,
    paidByGuestID,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.id = id ?? "";
    this.created = created ?? new Date();
    this.deleted = deleted ?? false;
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.amountCents = amountCents ?? 0;
    this.amountDollars = amountDollars ?? "";
    this.type = type ?? "";
    this.impactID = impactID ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.ticketID = ticketID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.payAfterEvent = payAfterEvent ?? false;
    this.purchaseID = purchaseID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.transactionID = transactionID ?? "";
    this.isPaid = isPaid ?? false;
    this.refundID = refundID ?? "";
    this.isExternal = isExternal ?? false;
    this.isAnonymous = isAnonymous ?? false;
    this.bidderNumber = bidderNumber ?? 0;
    this.purchasedItemSalesTaxID = purchasedItemSalesTaxID ?? "";
    this.salesTaxPercent = salesTaxPercent ?? 0;
    this.salesTaxAmountCents = salesTaxAmountCents ?? 0;
    this.salesTaxAmountDollars = salesTaxAmountDollars ?? "";
    this.salesTaxPurchaseID = salesTaxPurchaseID ?? "";
    this.salesTaxTransactionStatus = salesTaxTransactionStatus ?? "";
    this.salesTaxTransactionID = salesTaxTransactionID ?? "";
    this.salesTaxRefundID = salesTaxRefundID ?? "";
    this.paidByGuestID = paidByGuestID ?? "";
  }

  convertDates(): ViewPurchasedItemSummary {
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): ViewPurchasedItemSummary {
    return this;
  }
}

export class ViewPurchasedItemToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewPurchasedItemToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewPurchasedItemToCSVInput {
    return this;
  }
}

export class ViewRecentDonorsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewRecentDonorsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewRecentDonorsInput {
    return this;
  }
}

export class ViewRecentDonorsOutput {
  items: ViewRecentDonorsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewRecentDonorsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewRecentDonorsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewRecentDonorsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewRecentDonorsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewRecentDonorsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewRecentDonorsSummary {
  organizationID: string;
  eventID: string;
  firstName: string;
  lastName: string;
  donatedAmountCents: string;
  itemDetails: string;
  created: Date;
  deleted: boolean;

  constructor({
    organizationID,
    eventID,
    firstName,
    lastName,
    donatedAmountCents,
    itemDetails,
    created,
    deleted,
  }: Partial<TypeWithFieldsOf<ViewRecentDonorsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.donatedAmountCents = donatedAmountCents ?? "";
    this.itemDetails = itemDetails ?? "";
    this.created = created ?? new Date();
    this.deleted = deleted ?? false;
  }

  convertDates(): ViewRecentDonorsSummary {
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): ViewRecentDonorsSummary {
    return this;
  }
}

export class ViewRecentDonorsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewRecentDonorsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewRecentDonorsToCSVInput {
    return this;
  }
}

export class ViewSearchGuestsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSearchGuestsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSearchGuestsInput {
    return this;
  }
}

export class ViewSearchGuestsOutput {
  items: ViewSearchGuestsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSearchGuestsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ViewSearchGuestsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewSearchGuestsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewSearchGuestsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewSearchGuestsSummary {
  organizationID: string;
  eventID: string;
  guestID: string;
  guestUserID: string;
  guestFirstName: string;
  guestLastName: string;
  guestEmail: string;
  guestMobilePhone: string;
  managingGuestID: string;
  managingGuestFirstName: string;
  managingGuestLastName: string;

  constructor({
    organizationID,
    eventID,
    guestID,
    guestUserID,
    guestFirstName,
    guestLastName,
    guestEmail,
    guestMobilePhone,
    managingGuestID,
    managingGuestFirstName,
    managingGuestLastName,
  }: Partial<TypeWithFieldsOf<ViewSearchGuestsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.guestID = guestID ?? "";
    this.guestUserID = guestUserID ?? "";
    this.guestFirstName = guestFirstName ?? "";
    this.guestLastName = guestLastName ?? "";
    this.guestEmail = guestEmail ?? "";
    this.guestMobilePhone = guestMobilePhone ?? "";
    this.managingGuestID = managingGuestID ?? "";
    this.managingGuestFirstName = managingGuestFirstName ?? "";
    this.managingGuestLastName = managingGuestLastName ?? "";
  }

  fixClasses(): ViewSearchGuestsSummary {
    return this;
  }
}

export class ViewSearchGuestsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSearchGuestsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSearchGuestsToCSVInput {
    return this;
  }
}

export class ViewStatusSumItemsInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewStatusSumItemsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewStatusSumItemsInput {
    return this;
  }
}

export class ViewStatusSumItemsOutput {
  items: ViewStatusSumItemsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewStatusSumItemsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertUnexpectedNulls(): ViewStatusSumItemsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewStatusSumItemsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewStatusSumItemsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewStatusSumItemsSummary {
  organizationID: string;
  eventID: string;
  type: string;
  sumPaid: number;
  sumPending: number;
  sumFailed: number;
  sumRefunded: number;

  constructor({
    organizationID,
    eventID,
    type,
    sumPaid,
    sumPending,
    sumFailed,
    sumRefunded,
  }: Partial<TypeWithFieldsOf<ViewStatusSumItemsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.type = type ?? "";
    this.sumPaid = sumPaid ?? 0;
    this.sumPending = sumPending ?? 0;
    this.sumFailed = sumFailed ?? 0;
    this.sumRefunded = sumRefunded ?? 0;
  }

  fixClasses(): ViewStatusSumItemsSummary {
    return this;
  }
}

export class ViewStatusSumItemsToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewStatusSumItemsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewStatusSumItemsToCSVInput {
    return this;
  }
}

export class ViewSupporterPurchasedItemByPickupTimeInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemByPickupTimeInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSupporterPurchasedItemByPickupTimeInput {
    return this;
  }
}

export class ViewSupporterPurchasedItemByPickupTimeOutput {
  items: ViewSupporterPurchasedItemByPickupTimeSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemByPickupTimeOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewSupporterPurchasedItemByPickupTimeOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewSupporterPurchasedItemByPickupTimeOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewSupporterPurchasedItemByPickupTimeOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewSupporterPurchasedItemByPickupTimeSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewSupporterPurchasedItemByPickupTimeSummary {
  organizationID: string;
  eventID: string;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  fullName: string;
  cartFullNameList: string;
  userID: string;
  guestID: string;
  email: string;
  mobilePhone: string;
  ticketCount: number;
  totalProceedsAmountCents: number;
  cartCount: number;
  purchasedItemCount: number;
  purchasedCheckoutItemCount: number;
  checkoutItemCount: number;
  isPaid: number;
  notPickedUpCount: number;
  pickedUpCount: number;
  pickupTimeframe: (Date | null);

  constructor({
    organizationID,
    eventID,
    created,
    updated,
    firstName,
    lastName,
    fullName,
    cartFullNameList,
    userID,
    guestID,
    email,
    mobilePhone,
    ticketCount,
    totalProceedsAmountCents,
    cartCount,
    purchasedItemCount,
    purchasedCheckoutItemCount,
    checkoutItemCount,
    isPaid,
    notPickedUpCount,
    pickedUpCount,
    pickupTimeframe,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemByPickupTimeSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.fullName = fullName ?? "";
    this.cartFullNameList = cartFullNameList ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.ticketCount = ticketCount ?? 0;
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.cartCount = cartCount ?? 0;
    this.purchasedItemCount = purchasedItemCount ?? 0;
    this.purchasedCheckoutItemCount = purchasedCheckoutItemCount ?? 0;
    this.checkoutItemCount = checkoutItemCount ?? 0;
    this.isPaid = isPaid ?? 0;
    this.notPickedUpCount = notPickedUpCount ?? 0;
    this.pickedUpCount = pickedUpCount ?? 0;
    this.pickupTimeframe = pickupTimeframe ?? null;
  }

  convertDates(): ViewSupporterPurchasedItemByPickupTimeSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    if (this.pickupTimeframe !== undefined && this.pickupTimeframe !== null) {
      this.pickupTimeframe = new Date(this.pickupTimeframe!);
    }
    return this;
  }

  fixClasses(): ViewSupporterPurchasedItemByPickupTimeSummary {
    return this;
  }
}

export class ViewSupporterPurchasedItemByPickupTimeToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemByPickupTimeToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSupporterPurchasedItemByPickupTimeToCSVInput {
    return this;
  }
}

export class ViewSupporterPurchasedItemDetailInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemDetailInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSupporterPurchasedItemDetailInput {
    return this;
  }
}

export class ViewSupporterPurchasedItemDetailOutput {
  items: ViewSupporterPurchasedItemDetailSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemDetailOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewSupporterPurchasedItemDetailOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewSupporterPurchasedItemDetailOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewSupporterPurchasedItemDetailOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewSupporterPurchasedItemDetailSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewSupporterPurchasedItemDetailSummary {
  organizationID: string;
  eventID: string;
  userID: string;
  guestID: string;
  purchasedItemPrimaryGuestID: string;
  email: string;
  created: Date;
  updated: Date;
  amountCents: number;
  purchaseItemType: string;
  auctionID: string;
  lotID: string;
  inventoryAllocationID: string;
  itemID: string;
  fixedPriceItemID: string;
  transactionID: string;
  transactionStatus: string;
  purchaseID: string;
  itemDetails: string;
  pickupPreference: string;
  isPickedUp: boolean;
  pickupTimeframe: (Date | null);
  isReadyPickUp: boolean;
  fixedPriceItemName: string;
  fixedPriceItemDescription: string;
  fixedPriceItemPhotoURL: string;
  quantity: number;
  paidByGuestID: string;
  paidByFirstName: string;
  paidByLastName: string;
  pickedUpByGuestID: string;
  pickedUpByFirstName: string;
  pickedUpByLastName: string;

  constructor({
    organizationID,
    eventID,
    userID,
    guestID,
    purchasedItemPrimaryGuestID,
    email,
    created,
    updated,
    amountCents,
    purchaseItemType,
    auctionID,
    lotID,
    inventoryAllocationID,
    itemID,
    fixedPriceItemID,
    transactionID,
    transactionStatus,
    purchaseID,
    itemDetails,
    pickupPreference,
    isPickedUp,
    pickupTimeframe,
    isReadyPickUp,
    fixedPriceItemName,
    fixedPriceItemDescription,
    fixedPriceItemPhotoURL,
    quantity,
    paidByGuestID,
    paidByFirstName,
    paidByLastName,
    pickedUpByGuestID,
    pickedUpByFirstName,
    pickedUpByLastName,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemDetailSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.purchasedItemPrimaryGuestID = purchasedItemPrimaryGuestID ?? "";
    this.email = email ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.amountCents = amountCents ?? 0;
    this.purchaseItemType = purchaseItemType ?? "";
    this.auctionID = auctionID ?? "";
    this.lotID = lotID ?? "";
    this.inventoryAllocationID = inventoryAllocationID ?? "";
    this.itemID = itemID ?? "";
    this.fixedPriceItemID = fixedPriceItemID ?? "";
    this.transactionID = transactionID ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.purchaseID = purchaseID ?? "";
    this.itemDetails = itemDetails ?? "";
    this.pickupPreference = pickupPreference ?? "";
    this.isPickedUp = isPickedUp ?? false;
    this.pickupTimeframe = pickupTimeframe ?? null;
    this.isReadyPickUp = isReadyPickUp ?? false;
    this.fixedPriceItemName = fixedPriceItemName ?? "";
    this.fixedPriceItemDescription = fixedPriceItemDescription ?? "";
    this.fixedPriceItemPhotoURL = fixedPriceItemPhotoURL ?? "";
    this.quantity = quantity ?? 0;
    this.paidByGuestID = paidByGuestID ?? "";
    this.paidByFirstName = paidByFirstName ?? "";
    this.paidByLastName = paidByLastName ?? "";
    this.pickedUpByGuestID = pickedUpByGuestID ?? "";
    this.pickedUpByFirstName = pickedUpByFirstName ?? "";
    this.pickedUpByLastName = pickedUpByLastName ?? "";
  }

  convertDates(): ViewSupporterPurchasedItemDetailSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    if (this.pickupTimeframe !== undefined && this.pickupTimeframe !== null) {
      this.pickupTimeframe = new Date(this.pickupTimeframe!);
    }
    return this;
  }

  fixClasses(): ViewSupporterPurchasedItemDetailSummary {
    return this;
  }
}

export class ViewSupporterPurchasedItemDetailToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemDetailToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSupporterPurchasedItemDetailToCSVInput {
    return this;
  }
}

export class ViewSupporterPurchasedItemInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSupporterPurchasedItemInput {
    return this;
  }
}

export class ViewSupporterPurchasedItemOutput {
  items: ViewSupporterPurchasedItemSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewSupporterPurchasedItemOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewSupporterPurchasedItemOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewSupporterPurchasedItemOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewSupporterPurchasedItemSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewSupporterPurchasedItemSummary {
  organizationID: string;
  eventID: string;
  created: Date;
  updated: Date;
  firstName: string;
  lastName: string;
  fullName: string;
  cartFullNameList: string;
  userID: string;
  guestID: string;
  email: string;
  transactionId: string;
  transactionStatus: string;
  purchaseID: string;
  mobilePhone: string;
  ticketCount: number;
  totalProceedsAmountCents: number;
  cartCount: number;
  purchasedItemCount: number;
  purchasedCheckoutItemCount: number;
  checkoutItemCount: number;
  isPurchasedCheckoutItem: number;
  isPickedUp: boolean;
  pickupTimeframe: (Date | null);
  pickupPreference: string;

  constructor({
    organizationID,
    eventID,
    created,
    updated,
    firstName,
    lastName,
    fullName,
    cartFullNameList,
    userID,
    guestID,
    email,
    transactionId,
    transactionStatus,
    purchaseID,
    mobilePhone,
    ticketCount,
    totalProceedsAmountCents,
    cartCount,
    purchasedItemCount,
    purchasedCheckoutItemCount,
    checkoutItemCount,
    isPurchasedCheckoutItem,
    isPickedUp,
    pickupTimeframe,
    pickupPreference,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
    this.firstName = firstName ?? "";
    this.lastName = lastName ?? "";
    this.fullName = fullName ?? "";
    this.cartFullNameList = cartFullNameList ?? "";
    this.userID = userID ?? "";
    this.guestID = guestID ?? "";
    this.email = email ?? "";
    this.transactionId = transactionId ?? "";
    this.transactionStatus = transactionStatus ?? "";
    this.purchaseID = purchaseID ?? "";
    this.mobilePhone = mobilePhone ?? "";
    this.ticketCount = ticketCount ?? 0;
    this.totalProceedsAmountCents = totalProceedsAmountCents ?? 0;
    this.cartCount = cartCount ?? 0;
    this.purchasedItemCount = purchasedItemCount ?? 0;
    this.purchasedCheckoutItemCount = purchasedCheckoutItemCount ?? 0;
    this.checkoutItemCount = checkoutItemCount ?? 0;
    this.isPurchasedCheckoutItem = isPurchasedCheckoutItem ?? 0;
    this.isPickedUp = isPickedUp ?? false;
    this.pickupTimeframe = pickupTimeframe ?? null;
    this.pickupPreference = pickupPreference ?? "";
  }

  convertDates(): ViewSupporterPurchasedItemSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    if (this.pickupTimeframe !== undefined && this.pickupTimeframe !== null) {
      this.pickupTimeframe = new Date(this.pickupTimeframe!);
    }
    return this;
  }

  fixClasses(): ViewSupporterPurchasedItemSummary {
    return this;
  }
}

export class ViewSupporterPurchasedItemToCSVInput {
  organizationID: string;
  eventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    eventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewSupporterPurchasedItemToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.eventID = eventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewSupporterPurchasedItemToCSVInput {
    return this;
  }
}

export class ViewTicketOptionAnswersForTicketSummary {
  organizationID: string;
  source: string;
  ticketID: string;
  ticketOptionID: string;
  ticketOptionPromptChoiceID: string;
  ticketOptionAnswerID: string;
  answer: string;
  created: Date;
  deleted: boolean;

  constructor({
    organizationID,
    source,
    ticketID,
    ticketOptionID,
    ticketOptionPromptChoiceID,
    ticketOptionAnswerID,
    answer,
    created,
    deleted,
  }: Partial<TypeWithFieldsOf<ViewTicketOptionAnswersForTicketSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.source = source ?? "";
    this.ticketID = ticketID ?? "";
    this.ticketOptionID = ticketOptionID ?? "";
    this.ticketOptionPromptChoiceID = ticketOptionPromptChoiceID ?? "";
    this.ticketOptionAnswerID = ticketOptionAnswerID ?? "";
    this.answer = answer ?? "";
    this.created = created ?? new Date();
    this.deleted = deleted ?? false;
  }

  convertDates(): ViewTicketOptionAnswersForTicketSummary {
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): ViewTicketOptionAnswersForTicketSummary {
    return this;
  }
}

export class ViewVirtualChairsInput {
  organizationID: string;
  virtualEventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewVirtualChairsInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewVirtualChairsInput {
    return this;
  }
}

export class ViewVirtualChairsOutput {
  items: ViewVirtualChairsSummary[];
  paging: PagingOutputV3;

  constructor({
    items,
    paging,
  }: Partial<TypeWithFieldsOf<ViewVirtualChairsOutput>> = {}) {
    this.items = items ?? [];
    this.paging = paging ?? new PagingOutputV3();
  }

  convertDates(): ViewVirtualChairsOutput {
    if (!this.items) {
      this.items = [];
    }
    
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = this.items[i0]!.convertDates();
    }
    return this;
  }

  convertUnexpectedNulls(): ViewVirtualChairsOutput {
    if (!this.items) {
      this.items = [];
    }
    return this;
  }

  fixClasses(): ViewVirtualChairsOutput {
    for (let i0 = 0; i0 < this.items.length; i0 += 1) {
      this.items[i0] = new ViewVirtualChairsSummary(this.items[i0]!).fixClasses();
    }
    return this;
  }
}

export class ViewVirtualChairsSummary {
  organizationID: string;
  virtualEventID: string;
  id: string;
  name: string;
  tableNumber: number;
  chairID: string;
  chairUserEmail: string;
  chairUserFirstName: string;
  chairUserLastName: string;
  chairUserID: string;
  chairChannelName: string;
  chairChannelARN: string;
  chairCreated: Date;
  chairUpdated: Date;
  created: Date;

  constructor({
    organizationID,
    virtualEventID,
    id,
    name,
    tableNumber,
    chairID,
    chairUserEmail,
    chairUserFirstName,
    chairUserLastName,
    chairUserID,
    chairChannelName,
    chairChannelARN,
    chairCreated,
    chairUpdated,
    created,
  }: Partial<TypeWithFieldsOf<ViewVirtualChairsSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.tableNumber = tableNumber ?? 0;
    this.chairID = chairID ?? "";
    this.chairUserEmail = chairUserEmail ?? "";
    this.chairUserFirstName = chairUserFirstName ?? "";
    this.chairUserLastName = chairUserLastName ?? "";
    this.chairUserID = chairUserID ?? "";
    this.chairChannelName = chairChannelName ?? "";
    this.chairChannelARN = chairChannelARN ?? "";
    this.chairCreated = chairCreated ?? new Date();
    this.chairUpdated = chairUpdated ?? new Date();
    this.created = created ?? new Date();
  }

  convertDates(): ViewVirtualChairsSummary {
    this.chairCreated = new Date(this.chairCreated!);
    this.chairUpdated = new Date(this.chairUpdated!);
    this.created = new Date(this.created!);
    return this;
  }

  fixClasses(): ViewVirtualChairsSummary {
    return this;
  }
}

export class ViewVirtualChairsToCSVInput {
  organizationID: string;
  virtualEventID: string;
  paging: PagingInputV3;

  constructor({
    organizationID,
    virtualEventID,
    paging,
  }: Partial<TypeWithFieldsOf<ViewVirtualChairsToCSVInput>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.paging = paging ?? new PagingInputV3();
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    params = this.paging ? params.set('paging', this.paging.stringify()) : params;
    return params;
  }

  fixClasses(): ViewVirtualChairsToCSVInput {
    return this;
  }
}

export class VirtualChairSummary {
  organizationID: string;
  virtualEventID: string;
  virtualTableID: string;
  id: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userID: string;
  channelName: string;
  channelARN: string;
  created: Date;
  updated: Date;

  constructor({
    organizationID,
    virtualEventID,
    virtualTableID,
    id,
    userEmail,
    userFirstName,
    userLastName,
    userID,
    channelName,
    channelARN,
    created,
    updated,
  }: Partial<TypeWithFieldsOf<VirtualChairSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.virtualTableID = virtualTableID ?? "";
    this.id = id ?? "";
    this.userEmail = userEmail ?? "";
    this.userFirstName = userFirstName ?? "";
    this.userLastName = userLastName ?? "";
    this.userID = userID ?? "";
    this.channelName = channelName ?? "";
    this.channelARN = channelARN ?? "";
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
  }

  convertDates(): VirtualChairSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): VirtualChairSummary {
    return this;
  }
}

export class VirtualTableSummary {
  organizationID: string;
  virtualEventID: string;
  id: string;
  name: string;
  tableNumber: number;
  created: Date;
  updated: Date;

  constructor({
    organizationID,
    virtualEventID,
    id,
    name,
    tableNumber,
    created,
    updated,
  }: Partial<TypeWithFieldsOf<VirtualTableSummary>> = {}) {
    this.organizationID = organizationID ?? "";
    this.virtualEventID = virtualEventID ?? "";
    this.id = id ?? "";
    this.name = name ?? "";
    this.tableNumber = tableNumber ?? 0;
    this.created = created ?? new Date();
    this.updated = updated ?? new Date();
  }

  convertDates(): VirtualTableSummary {
    this.created = new Date(this.created!);
    this.updated = new Date(this.updated!);
    return this;
  }

  fixClasses(): VirtualTableSummary {
    return this;
  }
}
