import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';

import { PreviewService } from '../../services/preview.service';
import { FlowComponent } from '../flow-components.model';
import { CurrentStateService } from '../../services/current-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class CustomTextConfig {
  text: string;
  fontSize: string;
  textAlign: string;
}

// @dynamic - this prevents build error due to using Document interface
@Component({
  selector: 'flow-custom-text',
  templateUrl: './custom-text.component.html',
  styleUrls: ['./custom-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomTextComponent implements OnInit, OnDestroy, FlowComponent<CustomTextConfig, void> {
  @Input() config: CustomTextConfig;

  @ViewChild('customText', { static: true }) customTextContainer: ElementRef<HTMLDivElement>;
  theme: string;

  private destroyTriggered = new Subject<void>();

  constructor(
    private previewService: PreviewService,
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    this.currentState.flow.pipe(takeUntil(this.destroyTriggered)).subscribe((flow) => {
      this.theme = flow.theme;
    });

    if (this.previewService.isPreviewMode() && this.config.text === '') {
      this.config.text = 'Custom Text';
      this.scaleText();
    } else {
      this.scaleText();
    }
  }

  ngOnDestroy() {
    this.destroyTriggered.next();
  }

  scaleText() {
    this.setTextAlign();
  }

  setFontStyling() {
    const styles = {};
    styles[`flow-custom-text__font-size_${this.config.fontSize}`] = true;
    styles[`flow-custom-text__text__theme_${this.theme}`] = true;
    return styles;
  }

  setTextAlign() {
    let alignment = '';
    switch (this.config.textAlign) {
      case 'center': {
        alignment = 'center';
        break;
      }
      case 'left': {
        alignment = 'left';
        break;
      }
      default: {
        alignment = 'left';
      }
    }
    this.customTextContainer.nativeElement.style.textAlign = `${alignment}`;
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }
}
