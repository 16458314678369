import { Component, HostListener, ViewEncapsulation } from '@angular/core';

import { PageWithComponentList, TabSection } from '../../../models/page.model';
import { ElementService } from '../../../services/element.service';
import { PageExecutionService } from '../../../services/page-execution.service';
import { PrimaryColorService } from '../../../services/primary-color.service';
import { CurrentStateService } from '../../../services/services-exports';
import { PageOrchDirective } from '../page-orch';
import { ComponentDefinition, ComponentPlacementTwoColumns } from '../../../models/component.model';

@Component({
  selector: 'flow-two-column-page-orchestration',
  templateUrl: './two-column-page-orchestration.component.html',
  styleUrls: ['./two-column-page-orchestration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TwoColumnPageOrchestrationComponent extends PageOrchDirective {

  isMobile = false;
  leftColumnItems: (ComponentDefinition | TabSection)[] = [];
  rightColumnItems: (ComponentDefinition | TabSection)[] = [];
  mobilePageItems: (ComponentDefinition | TabSection)[] = [];

  constructor(
    elementService: ElementService,
    pageExecutionService: PageExecutionService,
    primaryColorService: PrimaryColorService,
    currentState: CurrentStateService,
  ) {
    super(elementService, pageExecutionService, primaryColorService, currentState);
  }

  protected init() {
    this.isMobile = this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const wasMobile = this.isMobile;
    this.isMobile = this.checkIfMobile();

    // reload components if changing view from mobile to desktop or vice versa
    if (this.isMobile && !wasMobile || !this.isMobile && wasMobile) {
      window.location.reload(); // not ideal implementation for UX but resized window isn't going to happen often
    }
  }

  updateTabComponent({
    tabSectionNumber,
    added,
    removed
  }: { tabSectionNumber: number, added: ComponentDefinition, removed: ComponentDefinition }): void {

    if (removed) {
      this.unloadComponent(removed);
    }

    if (added) {
      this.loadComponent(added, 'tab-section' + tabSectionNumber, true);
    }
  }

  protected loadPageContent(page: PageWithComponentList): void {
    const components = page.components;
    const tabSections = page.layoutOptions ? page.layoutOptions.tabSections || [] : [];

    if (this.isMobile) {
      this.mobilePageItems = this.getMobilePageItems(components, tabSections);
      this.loadComponentsOnPage(this.mobilePageItems);
    } else {
      const mappedTabSections = tabSections.map((tabSection: TabSection, index: number) => {
        tabSection.sectionNumber = index;
        tabSection.components = this.getComponentsForDesktopTabSection(index, components);
        return tabSection;
      });

      this.leftColumnItems = this.getDesktopPageItems(components, mappedTabSections, 'left');
      this.rightColumnItems = this.getDesktopPageItems(components, mappedTabSections, 'right');

      this.loadComponentsOnPage(this.leftColumnItems);
      this.loadComponentsOnPage(this.rightColumnItems);
    }
  }

  private checkIfMobile(): boolean {
    return window.innerWidth < 800;
  }

  private getMobilePageItems(components: ComponentDefinition[], tabSections: TabSection[]): (ComponentDefinition | TabSection)[] {
    tabSections.map((tabSection: TabSection, index: number) => {
      tabSection.sectionNumber = index;
      tabSection.components = this.getComponentsForMobileTabSection(index, components);
      return tabSection;
    });

    return []
      .concat(components, tabSections)
      .filter(pageItem => {
        const placement = this.getPlacement(pageItem);
        return placement.mobile && placement.mobile.position > 0 && !placement.mobile.tabDisplay;
      })
      .sort((a: ComponentDefinition | TabSection, b: ComponentDefinition | TabSection) => {
        return this.getPlacement(a).mobile.position > this.getPlacement(b).mobile.position ? 1 : -1;
      });
  }

  private getComponentsForMobileTabSection(tabSection: number, components: ComponentDefinition[]): ComponentDefinition[] {
    return components
      .filter(component => {
        const placement = this.getPlacement(component);
        return placement.mobile && placement.mobile.tabDisplay && placement.mobile.tabDisplay.tabSection === tabSection;
      })
      .sort((a: ComponentDefinition, b: ComponentDefinition) => {
        return this.getPlacement(a).mobile.tabDisplay.tabIndex > this.getPlacement(b).mobile.tabDisplay.tabIndex ? 1 : -1;
      });
  }

  private getDesktopPageItems(
    components: ComponentDefinition[],
    tabSections: TabSection[],
    column: string
  ): (ComponentDefinition | TabSection)[] {
    return []
      .concat(components, tabSections)
      .filter(pageItem => {
        const placement = this.getPlacement(pageItem);
        return placement.desktop && placement.desktop.column === column && placement.desktop.position > 0;
      })
      .sort((a: ComponentDefinition | TabSection, b: ComponentDefinition | TabSection) => {
        return this.getPlacement(a).desktop.position > this.getPlacement(b).desktop.position ? 1 : -1;
      });
  }

  private getComponentsForDesktopTabSection(tabSection: number, components: ComponentDefinition[]): ComponentDefinition[] {
    return components
      .filter(component => {
        const placement = this.getPlacement(component);
        return placement.desktop && placement.desktop.column === 'tabs' && placement.desktop.tabDisplay &&
          placement.desktop.tabDisplay.tabSection === tabSection;
      })
      .sort((a: ComponentDefinition, b: ComponentDefinition) => {
        return this.getPlacement(a).desktop.tabDisplay.tabIndex > this.getPlacement(b).desktop.tabDisplay.tabIndex ? 1 : -1;
      });
  }

  private loadComponentsOnPage(pageItems: (ComponentDefinition | TabSection)[]): void {
    pageItems.forEach(item => {
      const component = item as ComponentDefinition;
      if (component.componentID) {
        this.loadComponent(component, component.componentID);
      }
    });
  }

  private getPlacement(component: ComponentDefinition | TabSection): ComponentPlacementTwoColumns {
    return JSON.parse(component.position.placement) as ComponentPlacementTwoColumns;
  }
}
