import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

// import { GivingRestService } from './giving-rest.service';
// import { IframeService } from './iframe.service';
import { CreditCardComponent } from './creditcard/creditcard.component';
import { PaymentApiJsComponent } from './payment-api-js/payment-api-js';
import { PaymentComponent } from './payment.component';
// import { GetItemCategoryOutput } from 'src/app/services/inventory-client.service';

export * from './payment.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule
    ],
    declarations: [
        PaymentComponent,
        CreditCardComponent,
        PaymentApiJsComponent,
    ],
})
export class PaymentModule { }
