import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { TicketType, AttendeeCountUpdate } from '../../../models/event-api.model';
import { CurrencyUtil } from '../../../utils/currency.util';
import { FormControl } from '@angular/forms';
import { NumbersOnlyFormatter } from '../../../formatters/numbers-only.formatter';

@Component({
  selector: 'flow-ticket-incrementor',
  templateUrl: './ticket-incrementor.component.html',
  styleUrls: ['./ticket-incrementor.component.scss']
})
export class TicketIncrementorComponent implements OnInit {
  @Input() ticketType: TicketType;
  @Output() attendeeCountUpdated = new EventEmitter<AttendeeCountUpdate>();

  reservationCount = 0;
  dollars: number;
  cents: number;
  incrementorMax = 99;
  numberInput: FormControl;

  constructor() {
    this.numberInput = new FormControl(this.reservationCount);
  }

  ngOnInit() {
    NumbersOnlyFormatter.initialize(this.numberInput);

    this.numberInput.valueChanges.subscribe((selectedTickets: number) => {
      this.reservationCount = selectedTickets;
    });

    this.cents = CurrencyUtil.getCents(this.ticketType.price);
    this.dollars = CurrencyUtil.getDollars(this.ticketType.price);
  }

  canDecrease() {
    return this.reservationCount > 0;
  }

  decreaseCount() {
    if (!this.canDecrease()) {
      return;
    }

    this.reservationCount = this.reservationCount - 1;
    this.numberInput.setValue(this.reservationCount);
    this.numberInput.markAsDirty();

    const attendeeCountUpdate = new AttendeeCountUpdate();
    attendeeCountUpdate.ticketTypeID = this.ticketType.ticketTypeID;
    this.attendeeCountUpdated.emit(attendeeCountUpdate);
  }

  canIncrease() {
    const max = Math.min(this.ticketType.ticketsLeft, this.incrementorMax);
    return this.reservationCount < max;
  }

  increaseCount() {
    if (!this.canIncrease()) {
      return;
    }

    this.reservationCount = this.reservationCount + 1;
    this.numberInput.setValue(this.reservationCount);
    this.numberInput.markAsDirty();

    const attendeeCountUpdate = new AttendeeCountUpdate();
    attendeeCountUpdate.addTicket = true;
    attendeeCountUpdate.ticketTypeID = this.ticketType.ticketTypeID;
    this.attendeeCountUpdated.emit(attendeeCountUpdate);
  }
}
