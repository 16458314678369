import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NumberUtility } from '@onecause/core';

import { FlowComponent } from '../flow-components.model';
import { FlowSessionService } from '../../services/flow-session.service';

export class DonationAmountConfig {
  presetAmounts: number[];
}

export class DonationAmountOutput {
  amount: number;

  constructor(amount: number) {
    this.amount = amount;
  }
}

@Component({
  selector: 'flow-donation-amount',
  templateUrl: './donation-amount.component.html',
  styleUrls: ['./donation-amount.component.scss']
})
export class DonationAmountComponent implements OnInit, FlowComponent {

  @Input() config: DonationAmountConfig;

  @ViewChild('otherInput') otherInput: ElementRef<HTMLInputElement>;

  presetAmounts: number[];

  isOtherActive = false;
  selectedAmount: number;

  customAmount: FormControl;

  valid = false;

  constructor(
    private flowSession: FlowSessionService,
  ) { }

  ngOnInit() {
    this.init();
    this.prepopulateDonationAmount();
  }

  onAmountSelected(amount: number) {
    const roundedAmount = NumberUtility.roundHundredths(amount);
    this.isOtherActive = false;
    this.updateSelectedAmount(roundedAmount);
  }

  onOtherAmountClicked() {
    this.customAmount.reset();
    this.isOtherActive = true;
    setTimeout(() => {
      if (this.otherInput) {
        this.otherInput.nativeElement.focus();
      }
    });
  }

  @Input()
  isValid = (): boolean => {
    if (this.isOtherActive) {
      return !!this.selectedAmount && this.customAmount.valid;
    } else {
      return !!this.selectedAmount;
    }
  }

  @Input()
  markAsTouched = (): void => {
    this.customAmount.markAsTouched();
  }

  @Input()
  getValue = (): DonationAmountOutput => {
    return new DonationAmountOutput(this.selectedAmount);
  }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

  get errorVisible(): boolean {
    if (this.isValid()) {
      return false;
    }
    if (this.customAmount.touched) {
      return true;
    }
    return false;
  }

  private updateSelectedAmount(amount?: number) {
    this.selectedAmount = amount;
  }

  private init() {
    this.presetAmounts = this.config.presetAmounts || [];

    if (this.presetAmounts.length > 5) {
      console.error(`The donation amount component supports up to 5 preset amounts. `
        + `There were ${this.config.presetAmounts.length} provided.`);
    }

    this.customAmount = new FormControl('', [Validators.required, Validators.pattern('\\d+(\\.\\d{1,2})?')]);
    this.customAmount.valueChanges.subscribe((amountString: string) => {
      const amount = parseFloat(amountString);
      this.updateSelectedAmount(amount);
    });
  }

  private prepopulateDonationAmount() {
    const sessionData = this.flowSession.sessionData as {
      donationAmount: number;
    };

    if (!sessionData || !sessionData.donationAmount) {
      return;
    }

    const donation = sessionData.donationAmount;
    if (this.presetAmounts.includes(donation)) {
      this.selectedAmount = donation;
    } else {
      this.isOtherActive = true;
      this.customAmount.setValue(donation);
    }
  }
}
