import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomTextComponent } from './custom-text.component';

export * from './custom-text.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CustomTextComponent,
  ],
})
export class CustomTextModule { }
