import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhaasResponse } from '@onecause/core';
import { map } from 'rxjs/operators';
import { GetCardInfoInput, GetCardInfoOutput } from './creditcard/creditcard.component';

@Injectable()
export class GivingRestService {
  private givingEnvironment = 'env:phaas-giving-api';

  constructor(
    private httpClient: HttpClient,
  ) { }

  getCardInfo(input: GetCardInfoInput): Observable<GetCardInfoOutput> {
    const url = `${this.givingEnvironment}/organizations/${input.organizationID}` +
      `/merchant-accounts/${input.merchantAccountID}/get-card-info`;
    return this.httpClient.post(url, input).pipe(map((res: PhaasResponse) => {
      return res.payload;
    }));
  }
}
