import { Observable } from 'rxjs';

export class FlowComponentUtils {
  static isFlowComponent(component: any): boolean {
    return component.isValid
      && component.getValue
      && component.markAsTouched
      && component.handleExecutionError
      && component.listenForSubmit;
  }
}

export class ComponentIdentity {
  organizationID: string;
  flowID: string;
  pageID: string;
  componentID: string;

  constructor(
    organizationID: string,
    flowID: string,
    pageID: string,
    componentID: string,
  ) {
    this.organizationID = organizationID;
    this.flowID = flowID;
    this.pageID = pageID;
    this.componentID = componentID;
  }
}

/**
 * IMPORTANT: add '@Input()' to all properties of this interface in the components.
 * This is a workaround for a current Angular Elements issue.
 * https://github.com/angular/angular/issues/22114
 */
export interface FlowComponent<ConfigType = any, ValueType = any> {
  /**
   * Identity data for the component. (e.g. componentID and orgID)
   */
  identity?: ComponentIdentity;
  /**
   * The config that will be passed into the flow component
   */
  config: ConfigType;
  /**
   * Function for checking if a component is valid
   * @return boolean of the validity of the component
   */
  isValid: () => boolean;
  /**
   * Function for getting the value of a component
   * @return the output value of the component
   */
  getValue: () => ValueType;
  /**
   * Function for marking the component as touched. When called,
   * if any part of the component is invalid, error states should be shown
   */
  markAsTouched: () => void;
  /**
   * Function for handling errors that occurred while executing the component.
   * The component should handle them in whatever way is appropriate for the specific
   * component (e.g. display a snackbar message or show inline errors).
   * @param error the error that occurred
   */
  handleExecutionError: (error: any) => void;
  /**
   * Function for listening for the component to submit. If the component
   * can trigger page submission, an observable that fires when submit is triggered
   * should be returned, otherwise this can be empty.
   * @return observable that fires when submit is triggered or nothing
   */
  listenForSubmit: () => Observable<void> | void;
}
