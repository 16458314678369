import { NgModule } from '@angular/core';
import { SponsorsComponent } from './sponsors.component';
import { CommonModule } from '@angular/common';
import { ModulesModule } from '../../modules/modules.module';

export * from './sponsors.component';

@NgModule({
    imports: [
        CommonModule,
        ModulesModule
    ],
    declarations: [
        SponsorsComponent,
    ],
})
export class SponsorsModule { }
