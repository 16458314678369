import { Component } from '@angular/core';

import { CurrentStateService } from '../../../services/current-state.service';
import { FlowComponentService } from '../../../services/flow-component.service';
import { PreviewService } from '../../../services/preview.service';
import { ProgressComponentDirective } from '../progress-component';

export class EventProgressExternalData {
  totalDonationsCount: number;
  totalDonationsAmountCents: number;

  constructor(totalDonationsCount?: number, totalDonationsAmountCents?: number) {
    this.totalDonationsCount = totalDonationsCount;
    this.totalDonationsAmountCents = totalDonationsAmountCents;
  }
}

export class EventProgressConfig {
  eventID: string;
  showProgress: boolean;
  showDonationCount: boolean;
  goalTotalDonationsAmountCents: number;
  goalTotalDonationsSeedAmountCents: number;

  constructor(
    eventID?: string,
    showProgress?: boolean,
    showDonationCount?: boolean,
    goalTotalDonationsAmountCents?: number,
    goalTotalDonationsSeedAmountCents?: number
  ) {
    this.eventID = eventID;
    this.showProgress = showProgress;
    this.showDonationCount = showDonationCount;
    this.goalTotalDonationsAmountCents = goalTotalDonationsAmountCents;
    this.goalTotalDonationsSeedAmountCents = goalTotalDonationsSeedAmountCents;
  }
}

@Component({
  selector: 'flow-event-progress',
  templateUrl: '../progress-component.html',
  styleUrls: ['../progress-component.scss']
})
export class EventProgressComponent extends ProgressComponentDirective {

  constructor(
    private flowComponentService: FlowComponentService,
    currentState: CurrentStateService,
    private previewMode: PreviewService,
  ) {
    super(currentState, previewMode);
  }

  loadRaisedAmount() {
    if (this.previewMode.isPreviewMode()) {
      this.raisedAmount = 14500;
    } else {
      const orgID = this.identity.organizationID;
      const flowID = this.identity.flowID;
      const pageID = this.identity.pageID;
      const componentID = this.identity.componentID;
      this.flowComponentService.getExternalData<EventProgressExternalData>(orgID, flowID, pageID, componentID)
        .subscribe((output) => {
          this.raisedAmount = (output.data.totalDonationsAmountCents + this.config.goalTotalDonationsSeedAmountCents) / 100;
          this.totalDonations = output.data.totalDonationsCount;
        });
    }
  }

  loadGoalAmount() {
    this.goalAmount = this.config.goalTotalDonationsAmountCents / 100;
  }

  getGoalAmount() {
    return this.config.goalTotalDonationsAmountCents / 100;
  }
}
