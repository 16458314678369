import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OrgLogoComponent } from './org-logo.component';

export * from './org-logo.component';

@NgModule({
  declarations: [
    OrgLogoComponent
  ],
})
export class OrgLogoModule { }
