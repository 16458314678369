import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { ComponentDefinition } from '../../../models/component.model';
import { PageWithComponentList } from '../../../models/page.model';
import { CurrentStateService } from '../../../services/current-state.service';
import { ElementService } from '../../../services/element.service';
import { PageExecutionService } from '../../../services/page-execution.service';
import { PreviewService } from '../../../services/preview.service';
import { PrimaryColorService } from '../../../services/primary-color.service';
import { PageOrchDirective } from '../page-orch';

class SlideComponentPosition {
  top: number;
  left: number;
  height: number;
  width: number;

  constructor(component: ComponentDefinition) {
    const placement = component.position.placement.split(',');
    const size = component.position.size.split(',');
    this.left = parseInt(placement[0] || '0', 10);
    this.top = parseInt(placement[1] || '0', 10);
    this.width = parseInt(size[0] || '0', 10);
    this.height = parseInt(size[1] || '0', 10);
  }
}

@Component({
  selector: 'flow-slide-orch',
  templateUrl: './slide-orch.component.html',
  styleUrls: ['./slide-orch.component.scss']
})
export class SlideOrchComponent extends PageOrchDirective implements OnDestroy {

  @ViewChild('slide', { static: true }) slide: ElementRef<HTMLDivElement>;
  slideScale: { [key: string]: string } = {};

  components: ComponentDefinition[];

  pageSelected = false;
  selectedComponent = '';

  readonly HEIGHT_PX = 1024;
  readonly WIDTH_PX = 1820;
  readonly RATIO = this.WIDTH_PX / this.HEIGHT_PX;

  constructor(
    elementService: ElementService,
    pageExecutionService: PageExecutionService,
    primaryColorService: PrimaryColorService,
    currentState: CurrentStateService,
    private previewService: PreviewService,
  ) {
    super(elementService, pageExecutionService, primaryColorService, currentState);
  }

  protected init() {
    this.calculateScale();
    if (this.previewService.isPreviewMode()) {
      this.previewService.getSelectedPageID().pipe(takeUntil(this.destroyTriggered)).subscribe((pageID) => {
        this.pageSelected = pageID === this.currentPage.pageID;
      });
      this.previewService.getSelectedComponentID().pipe(takeUntil(this.destroyTriggered)).subscribe((componentID) => {
        if (this.pageSelected) {
          this.selectedComponent = componentID;
        }
      });
    }
  }

  protected loadPageContent(page: PageWithComponentList) {
    this.components = page.components;
    this.components.forEach((c) => {
      this.loadComponent(c, this.getComponentContainerClass(c));
    });
  }

  getComponentContainerClass(component: ComponentDefinition): string {
    return `component-container__${component.componentID}`;
  }

  getComponentContainerClasses(component: ComponentDefinition): { [key: string]: boolean } {
    return {
      [this.getComponentContainerClass(component)]: true,
      ['component-container_selected']: this.selectedComponent === component.componentID,
    };
  }

  getComponentPositionStyles(component: ComponentDefinition): { [key: string]: any } {
    const position = new SlideComponentPosition(component);
    const styles: { [key: string]: any } = {};
    styles['top.px'] = position.top;
    styles['left.px'] = position.left;
    styles['height.px'] = position.height;
    styles['width.px'] = position.width;
    return styles;
  }

  getSlideStyles(): { [key: string]: boolean } {
    return {
      ['slide-orch_selected']: this.pageSelected,
      [`slide-orch__slide_${this.theme}`]: true,
    };
  }

  private calculateScale() {
    const height = this.slide.nativeElement.clientHeight;
    const width = this.slide.nativeElement.clientWidth;
    const ratio = width / height;

    let scaledHeight: number;
    let scaledWidth: number;
    if (ratio < this.RATIO) {
      // too tall
      scaledHeight = (width / this.RATIO);
      scaledWidth = width;
    } else {
      // too wide or just right
      scaledHeight = height;
      scaledWidth = this.RATIO * height;
    }

    const scale = scaledHeight / this.HEIGHT_PX;
    this.slideScale.transform = `scale(${scale})`;

    const slideTopPadding = (height - scaledHeight) / 2;
    this.slideScale.top = `${slideTopPadding}px`;

    const slideLeftPadding = (width - scaledWidth) / 2;
    this.slideScale.left = `${slideLeftPadding}px`;
  }
}
