import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';

import { CurrentStateService } from '../../services/current-state.service';
import { PreviewService } from '../../services/preview.service';
import { FlowComponent } from '../flow-components.model';

export class TimerConfig {
  milliseconds: number;
}

@Component({
  selector: 'flow-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, FlowComponent<TimerConfig> {

  @Input() config: TimerConfig;

  private submitPage = new Subject<void>();
  time: number;

  constructor(
    private currentState: CurrentStateService,
    private previewService: PreviewService,
  ) { }

  ngOnInit() {
    if (this.previewService.isPreviewMode()) {
      this.disableTimerForPreview();
    } else {
      this.init();
    }
  }

  init() {
    this.currentState.flow.subscribe((flow) => {
      if (flow.slideTiming) {
        this.time = flow.slideTiming;
      } else {
        this.time = this.config.milliseconds;
      }
      this.countdownTimer(this.time);
    });
  }

  countdownTimer(time) {
    timer(time).subscribe(() => {
      this.submitPage.next();
    });
  }

  disableTimerForPreview() {
    this.time = 0;
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (): void => { }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitPage.asObservable();
  }
}
