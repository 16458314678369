import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Organization } from '../../models/org-api.model';
import { OrgService } from '../../services/org.service';

@Component({
  selector: 'flow-org-footer',
  templateUrl: './org-footer.component.html',
  styleUrls: ['./org-footer.component.scss']
})
export class OrgFooterComponent implements OnInit {

  org: Organization;
  website: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private orgService: OrgService,
  ) { }

  ngOnInit() {
    const orgID = this.activatedRoute.snapshot.paramMap.get('organizationID');
    this.orgService.getOrganizationSummary(orgID).subscribe((org) => {
      this.org = org;
      this.website = this.org.website;
      if (this.website && !/^https?:\/\//i.test(this.website)) {
        this.website = `http://${this.website}`;
      }
    });
  }
}
