import { Component } from '@angular/core';

import { CurrentStateService } from '../../../services/current-state.service';
import { FlowComponentService } from '../../../services/flow-component.service';
import { PreviewService } from '../../../services/preview.service';
import { ProgressComponentDirective } from '../progress-component';

export class TagProgressExternalData {
  aggregate: {
    definition: {
      tags: string[]
    },
    amount: number,
  };
  donationCount: number;

  constructor(amount?: number, tags?: string[], donationCount?: number) {
    this.aggregate = {
      amount: amount,
      definition: {
        tags: tags,
      },
    };
    this.donationCount = donationCount;
  }
}

export class TagProgressConfig {
  tags: string[];
  showProgress: boolean;
  goal: number;
  showDonationCount: boolean;

  constructor(tags?: string[], showProgress?: boolean, goal?: number, showDonationCount?: boolean) {
    this.tags = tags;
    this.showProgress = showProgress;
    this.goal = goal;
    this.showDonationCount = showDonationCount;
  }
}

@Component({
  selector: 'flow-tag-progress',
  templateUrl: '../progress-component.html',
  styleUrls: ['../progress-component.scss']
})
export class TagProgressComponent extends ProgressComponentDirective {

  constructor(
    private flowComponentService: FlowComponentService,
    currentState: CurrentStateService,
    private previewMode: PreviewService,
  ) {
    super(currentState, previewMode);
  }

  loadRaisedAmount() {
    if (this.previewMode.isPreviewMode()) {
      this.raisedAmount = 14500;
    } else {
      const orgID = this.identity.organizationID;
      const flowID = this.identity.flowID;
      const pageID = this.identity.pageID;
      const componentID = this.identity.componentID;
      this.flowComponentService.getExternalData<TagProgressExternalData>(orgID, flowID, pageID, componentID).subscribe((output) => {
        this.raisedAmount = output.data.aggregate.amount;
        this.totalDonations = output.data.donationCount;
      });
    }
  }

  loadGoalAmount() {
    this.goalAmount = this.config.goal;
  }

  getGoalAmount() {
    return this.config.goal;
  }
}
