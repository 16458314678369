import { ComponentType } from '../models/component.model';
import { AttendeeDetailsComponent } from './attendee-details/attendee-details.module';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { CustomHtmlComponent } from './custom-html/custom-html.component';
import { CustomTextComponent } from './custom-text/custom-text.component';
import { DonationAmountComponent } from './donation-amount/donation-amount.component';
import { DonorInfoComponent } from './donor-info/donor-info.component';
import {
    EventDescriptionRegistrationComponent,
} from './event-description-registration/event-description-registration.module';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EventStoryComponent } from './event-story/event-story.module';
import { ForTestingComponent } from './for-testing/for-testing.component';
import { HeadlineComponent } from './headline/headline.component';
import { IframeComponent } from './iframe/iframe.module';
import { ImageComponent } from './image/image.component';
import { LogoWithTextComponent } from './logo-with-text/logo-with-text.module';
import { NextPageButtonComponent } from './next-page-button/next-page-button.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { OrgLogoComponent } from './org-logo/org-logo.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { PaymentButtonComponent } from './payment-button/payment-button.component';
import { PaymentComponent } from './payment/payment.component';
import { FundraiserProgressComponent } from './progress/fundraiser-progress/fundraiser-progress.component';
import { EventProgressComponent } from './progress/progress.module';
import { TagProgressComponent } from './progress/tag-progress/tag-progress.component';
import { ReceiptRecipientComponent } from './receipt-recipient/receipt-recipient.component';
import { RecentDonorsComponent } from './recent-donors/recent-donors.component';
import { RedirectComponent } from './redirect/redirect.module';
import { SponsorsComponent } from './sponsors/sponsors.module';
import { TicketSelectionComponent } from './ticket-selection/ticket-selection.component';
import { TimerComponent } from './timer/timer.component';

export const FlowComponentSelectorMap = new Map<ComponentType, any>([
    [ComponentType.ForTesting, ForTestingComponent],
    [ComponentType.NextPageButton, NextPageButtonComponent],
    [ComponentType.Headline, HeadlineComponent],
    [ComponentType.DonorInfo, DonorInfoComponent],
    [ComponentType.DonationAmount, DonationAmountComponent],
    [ComponentType.ReceiptRecipient, ReceiptRecipientComponent],
    [ComponentType.Image, ImageComponent],
    [ComponentType.Payment, PaymentComponent],
    [ComponentType.Paragraph, ParagraphComponent],
    [ComponentType.PaymentButton, PaymentButtonComponent],
    [ComponentType.FundraiserProgress, FundraiserProgressComponent],
    [ComponentType.TagProgress, TagProgressComponent],
    [ComponentType.EventProgress, EventProgressComponent],
    [ComponentType.FundraiserRecentDonors, RecentDonorsComponent],
    [ComponentType.TagsRecentDonors, RecentDonorsComponent],
    [ComponentType.Timer, TimerComponent],
    [ComponentType.CustomText, CustomTextComponent],
    [ComponentType.OrgLogo, OrgLogoComponent],
    [ComponentType.TicketSelection, TicketSelectionComponent],
    [ComponentType.EventDetails, EventDetailsComponent],
    [ComponentType.AttendeeDetails, AttendeeDetailsComponent],
    [ComponentType.OrderSummary, OrderSummaryComponent],
    [ComponentType.ContactInfo, ContactInfoComponent],
    [ComponentType.CustomHTML, CustomHtmlComponent],
    [ComponentType.EventDescriptionRegistration, EventDescriptionRegistrationComponent],
    [ComponentType.Iframe, IframeComponent],
    [ComponentType.EventStory, EventStoryComponent],
    [ComponentType.Redirect, RedirectComponent],
    [ComponentType.Sponsors, SponsorsComponent],
    [ComponentType.LogoWithText, LogoWithTextComponent],
]);
