import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@onecause/core';
import { map } from 'rxjs/operators';

@Injectable()
export class IframeService {

  constructor(
    private environmentService: EnvironmentService,
  ) { }

  getIframeURL(organizationID: string, merchantAccountID: string): Observable<string> {
    const urlPath = `organizations/${organizationID}/merchant-accounts/${merchantAccountID}/iframe`;
    return this.environmentService.getURL('phaas-giving-api', urlPath).pipe(map(url => {
      return url;
    }));
  }
}
