export class ComponentDefinition<T = any> {
    componentID: string;
    pageID: string;
    flowID: string;
    organizationID: string;
    type: ComponentType;
    position: ComponentPosition;
    config: Config<T>;
    created: string;
    updated: string;
}

export class Config<T = any> {
    type: ComponentType;
    data: T;

    constructor(type?: ComponentType, data?: T) {
        this.type = type;
        this.data = data;
    }
}

export class ComponentPosition {
    placement: string;
    size: string;

    constructor(placement?: string, size?: string) {
        this.placement = placement || '';
        this.size = size || '';
    }
}

export class ComponentPlacementTwoColumns {
    desktop: ComponentColumnDefinition;
    mobile: ComponentColumnDefinition;
}

export class ComponentColumnDefinition {
    column: string;
    position: number;
    tabDisplay: ComponentTabDisplay;

    constructor(column?: string, position?: number, tabDisplay?: ComponentTabDisplay) {
        this.column = column || '';
        this.position = position || 0;
        this.tabDisplay = tabDisplay || new ComponentTabDisplay();
    }
}

export class ComponentTabDisplay {
    tabSection: number;
    title: string;
    tabIndex: number;
}

export class GetExternalDataOutput<T = any> {
    data: T;

    constructor(data?: T) {
        this.data = data;
    }
}

export class ExecuteStandaloneActionInput<T = any> {
    organizationID: string;
    flowID: string;
    pageID: string;
    componentID: string;
    sessionData: any;
    actionData: T;
}

export class ExecuteStandaloneActionOutput<T = any> {
    sessionData: T;

    constructor(sessionData?: T) {
        this.sessionData = sessionData;
    }
}

export enum ComponentType {
    ForTesting = 'flow-for-testing',
    NextPageButton = 'flow-next-page-button',
    Headline = 'flow-headline',
    DonorInfo = 'flow-donor-info',
    DonationAmount = 'flow-donation-amount',
    ReceiptRecipient = 'flow-receipt-recipient',
    Image = 'flow-image',
    Payment = 'flow-payment',
    Paragraph = 'flow-paragraph',
    PaymentButton = 'flow-payment-button',
    FundraiserProgress = 'flow-fundraiser-progress',
    TagProgress = 'flow-tag-progress',
    EventProgress = 'flow-event-progress',
    FundraiserRecentDonors = 'flow-fundraiser-recent-donors',
    TagsRecentDonors = 'flow-tags-recent-donors',
    Timer = 'flow-timer',
    CustomText = 'flow-custom-text',
    OrgLogo = 'flow-org-logo',
    TicketSelection = 'flow-ticket-selection',
    EventDetails = 'flow-event-details',
    AttendeeDetails = 'flow-attendee-details',
    OrderSummary = 'flow-order-summary',
    ContactInfo = 'flow-contact-info',
    CustomHTML = 'flow-custom-html',
    EventDescriptionRegistration = 'flow-event-description-registration',
    Iframe = 'flow-iframe',
    EventStory = 'flow-event-story',
    Redirect = 'flow-redirect',
    Sponsors = 'flow-sponsors',
    LogoWithText = 'flow-logo-with-text',
}
