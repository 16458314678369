import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageComponent } from './image.component';

export * from './image.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ImageComponent
    ]
})
export class ImageModule { }
