import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';

import { EventDescriptionRegistrationComponent } from './event-description-registration.component';
import { MatInputModule } from '@angular/material/input';
import { ModulesModule } from '../../modules/modules.module';

export * from './event-description-registration.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        ModulesModule
    ],
    declarations: [
        EventDescriptionRegistrationComponent,
    ],
})
export class EventDescriptionRegistrationModule { }
