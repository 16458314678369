import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { TicketIncrementorComponent } from './ticket-incrementor/ticket-incrementor.component';
import { TicketSelectionComponent } from './ticket-selection.component';

export * from './ticket-selection.component';
export * from './ticket-incrementor/ticket-incrementor.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule
    ],
    declarations: [
        TicketSelectionComponent,
        TicketIncrementorComponent,
    ],
})
export class TicketSelectionModule { }
