import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';

import { FlowComponent, ComponentIdentity } from '../flow-components.model';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import {
  PhoneNumberValidator,
  PhoneNumberFormatter,
  EmailValidator,
  SnackBarComponent,
  SnackBarConfig,
  SnackBarIcon,
} from '@onecause/core';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlowComponentService } from '../../services/flow-component.service';
import { ExecuteStandaloneActionInput } from '../../models/component.model';
import { FlowSessionService } from '../../services/flow-session.service';

export class EventDescriptionRegistrationConfig {
  text: string;
  presetDonationAmounts: number[];
  showRecurringDonationManagement: boolean;
  recurringDonationPeriod: string;
}

export class EventDescriptionRegistrationOutput {
  registration: Registration;
  donationAmountCents: number;

  constructor() {
    this.registration = new Registration();
    this.donationAmountCents = 0;
  }
}

export class EventDescriptionRegistrationExecuteOutput {
  eventRegistrationID: string;
}

export class Registration {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
  }
}

@Component({
  selector: 'flow-event-description-registration',
  templateUrl: './event-description-registration.component.html',
  styleUrls: ['./event-description-registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventDescriptionRegistrationComponent implements FlowComponent<EventDescriptionRegistrationConfig, void>, OnInit {

  @Input() config: EventDescriptionRegistrationConfig;
  @Input() identity: ComponentIdentity;

  isRegistering = false;
  registrationForm: FormGroup;
  presetAmounts: number[];
  isRecurringDonation: boolean;

  private submitPage = new Subject<void>();

  constructor(
    private flowSession: FlowSessionService,
    private flowComponentService: FlowComponentService,
    private formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
  ) { }

  @Input()
  isValid = (): boolean => {
    if (!this.isRegistering) { return true; }
    return this.registrationForm.valid;
  }

  @Input()
  getValue = (): EventDescriptionRegistrationOutput => {
    if (!this.isRegistering) { return; }

    const output = new EventDescriptionRegistrationOutput();
    output.registration = this.getRegistration();
    output.donationAmountCents = this.donationAmountControl.value || 0;

    return output;
  }

  @Input()
  markAsTouched = () => {
    Object.keys(this.registrationForm.controls).forEach((key) => {
      const control = this.registrationForm.get(key);
      if (control) { control.markAsTouched(); }
    });
  }

  @Input()
  handleExecutionError = (error: any): void => {
    this.snackbar.openFromComponent(SnackBarComponent, new SnackBarConfig(error.message, SnackBarIcon.Error, false));
  }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitPage.asObservable();
  }

  ngOnInit() {
    this.init();
  }

  getButtonText() {
    if (!this.isRegistering) {
      return 'REGISTER';
    }

    return this.isDonating() ? 'PROCEED TO PAYMENT' : 'SUBMIT';
  }

  register() {
    this.isRegistering = true;
  }

  submit() {
    if (!this.isValid()) {
      this.markAsTouched();
      return;
    }

    const orgID = this.identity.organizationID;
    const flowID = this.identity.flowID;
    const pageID = this.identity.pageID;
    const componentID = this.identity.componentID;

    const input: ExecuteStandaloneActionInput<Registration> = {
      organizationID: orgID,
      flowID: flowID,
      pageID: pageID,
      componentID: componentID,
      actionData: this.getRegistration(),
      sessionData: this.flowSession.sessionData,
    };

    this.flowComponentService.executeStandaloneAction<EventDescriptionRegistrationExecuteOutput>(orgID, flowID, pageID, componentID, input)
      .subscribe(
        _ => {
          this.snackbar.openFromComponent(SnackBarComponent, new SnackBarConfig(
            'Thank you for registering! You will receive an email shortly.',
            SnackBarIcon.Success,
            false
          ));

          // reset form
          this.isRegistering = false;
          this.registrationForm.reset();
          this.registrationForm.updateValueAndValidity();
        },
        error => {
          this.snackbar.openFromComponent(SnackBarComponent, new SnackBarConfig(error.message, SnackBarIcon.Error, false));
        }
      );
  }

  proceedToPayment() {
    if (!this.isValid()) {
      this.markAsTouched();
      return;
    }
    this.submitPage.next();
  }

  setDonationValue(donationAmountCents: number) {
    this.donationAmountControl.setValue(donationAmountCents);
  }

  isDonating(): boolean {
    return !!this.donationAmountControl.value;
  }

  private init() {
    this.presetAmounts = this.config.presetDonationAmounts || [];
    this.registrationForm = this.buildForm();
  }

  private buildForm(): FormGroup {
    const phoneNumber = this.formBuilder.control('', [PhoneNumberValidator()]);
    PhoneNumberFormatter.initializePhoneFormatter(phoneNumber);

    return this.formBuilder.group({
      firstName: this.formBuilder.control('', Validators.required),
      lastName: this.formBuilder.control('', Validators.required),
      email: this.formBuilder.control('', [Validators.required, EmailValidator()]),
      phone: phoneNumber,
      donationAmount: this.formBuilder.control(''),
    });
  }

  get donationAmountControl(): AbstractControl {
    return this.registrationForm.get('donationAmount');
  }

  private getRegistration(): Registration {
    const registration = new Registration();
    registration.firstName = this.registrationForm.get('firstName').value;
    registration.lastName = this.registrationForm.get('lastName').value;
    registration.email = this.registrationForm.get('email').value;
    registration.phone = this.registrationForm.get('phone').value;
    return registration;
  }
}
