import { NgModule } from '@angular/core';

import { AttendeeDetailsComponent } from './attendee-details.component';
import { AttendeeFormComponent } from './attendee-form/attendee-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';

export * from './attendee-details.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
  ],
  declarations: [
    AttendeeDetailsComponent,
    AttendeeFormComponent
  ],
})
export class AttendeeDetailsModule { }
