import { ComponentDefinition, ComponentPosition } from './component.model';

export class Page {
    pageID: string;
    flowID: string;
    organizationID: string;
    name: string;
    displayOrder: number;
    componentExecutionOrderByID: string[];
    primaryColor: string;
    layout: PageLayout;
    layoutOptions: PageLayoutOptions;
    created: Date;
    updated: Date;
}

export enum PageLayout {
    Vertical = 'vertical',
    Grid16x9 = 'grid-16x9',
    Slide = 'slide',
    TwoColumn = 'two-column',
}

export class PageLayoutOptions {
    tabSections: TabSection[];
}

export class TabSection {
    sectionNumber: number;
    position: ComponentPosition;
    components: ComponentDefinition[];
}

export class PageWithComponentList extends Page {
    components: ComponentDefinition[];
}
