import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IframeComponent } from './iframe.component';
import { SanitizeModule } from '@onecause/core';

export * from './iframe.component';

@NgModule({
    imports: [
        CommonModule,
        SanitizeModule,
    ],
    declarations: [
        IframeComponent
    ]
})
export class IframeModule { }
