import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NumberUtility, SnackBarComponent, SnackBarConfig, SnackBarIcon } from '@onecause/core';
import { Observable, Subject } from 'rxjs';

import { FlowSessionService } from '../../services/flow-session.service';
import { FlowComponent } from '../flow-components.model';
import { ReservedTicket, TicketSelectionOutput } from '../ticket-selection/ticket-selection.module';
import { CardInfo } from './creditcard/creditcard.component';

export class PaymentConfig {
  organizationID: string;
  merchantAccountID: string;
}

export class PaymentOutput {
  purchaseID: string;
  organizationID: string;
  merchantAccountID: string;
  cardName: string;
  expiration: string;
  cardType: string;
  cardLastFour: string;
  token: string;
  amount: number;
}

type Donation = {
  amount: number;
  [key: string]: any;
};

type PaymentInputSessionData = {
  purchase: {
    purchase: {
      id: string,
      total: number,
    },
    donations: Donation[],
  },
  ticketSelection: TicketSelectionOutput,
};

@Component({
  selector: 'flow-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentComponent implements OnInit, OnDestroy, FlowComponent<PaymentConfig, PaymentOutput> {

  paymentForm: FormGroup;
  cardName: FormControl;
  cardInfo: FormControl;

  hideComponent = false;

  @Input() config: PaymentConfig;
  @Input() alwaysShow: boolean;

  private paymentTotal = 0;
  private destroyTriggered = new Subject<void>();
  private submitPage = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private flowSession: FlowSessionService,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroyTriggered.next();
  }

  setCardInfo(cardInfo: CardInfo) {
    this.cardInfo.setValue(!cardInfo ? new CardInfo() : cardInfo, { emitEvent: true });
  }

  submit() {
    this.submitPage.next();
  }

  @Input()
  isValid = (): boolean => {
    if (!this.hideComponent) {
      return this.paymentForm.valid;
    }
    return true;
  }

  @Input()
  markAsTouched = (): void => {
    Object.keys(this.paymentForm.controls).forEach((key) => {
      this.paymentForm.get(key).markAsTouched();
    });
  }

  @Input()
  getValue = (): PaymentOutput => {
    const sessionData = this.flowSession.sessionData as PaymentInputSessionData;
    const purchaseID = sessionData?.purchase?.purchase?.id || '';
    const paymentOutput: PaymentOutput = {
      // Session Data Properties
      purchaseID: purchaseID,
      amount: this.paymentTotal,

      // Config Properties
      organizationID: this.config.organizationID,
      merchantAccountID: this.config.merchantAccountID,

      // Active Data Properties - Empty State
      cardName: this.getDonorName(),
      expiration: '',
      cardType: '',
      cardLastFour: '',
      token: '',
    };
    Object.keys(this.paymentForm.controls).forEach((key: string) => {
      if (key === 'cardInfo') {
        const cardInfoValue = this.paymentForm.get(key).value as CardInfo;
        paymentOutput.expiration = cardInfoValue.expiration || '';
        paymentOutput.cardType = cardInfoValue.cardType || '';
        paymentOutput.cardLastFour = cardInfoValue.lastFourDigits || '';
        paymentOutput.token = cardInfoValue.nonce || '';
      } else {
        paymentOutput[key] = this.paymentForm.get(key).value;
      }
    });
    return paymentOutput;
  }

  @Input()
  handleExecutionError = (error: any) => {
    this.snackbar.openFromComponent(SnackBarComponent, new SnackBarConfig(error.message, SnackBarIcon.Error, false));
  }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitPage.asObservable();
  }

  private initForm() {
    this.paymentTotal = this.getPaymentTotal();
    this.hideComponent = !this.alwaysShow ? this.paymentTotal <= 0 : false;
    const donorName = this.getDonorName();
    this.cardName = new FormControl(donorName, Validators.required);
    this.cardInfo = new FormControl(new CardInfo(), creditCardRequired);

    this.paymentForm = this.formBuilder.group({
      cardName: this.cardName,
      cardInfo: this.cardInfo,
    });
  }

  private getPaymentTotal(): number {
    const sessionData = this.flowSession.sessionData as PaymentInputSessionData;
    let paymentTotal = 0;
    if (sessionData && sessionData.purchase?.donations?.length) {
      sessionData.purchase.donations.forEach((donation: Donation) => {
        paymentTotal += donation.amount || 0;
      });
    }

    if (sessionData && sessionData.ticketSelection?.reservedTickets?.length) {
      sessionData.ticketSelection.reservedTickets.forEach((ticket: ReservedTicket) => {
        paymentTotal += (ticket.ticketType.price * ticket.tickets.length) || 0;
      });
    }
    return NumberUtility.roundHundredths(paymentTotal);
  }

  private getDonorName(): string {
    const sessionData = this.flowSession.sessionData as PaymentInputSessionData;
    if (sessionData && sessionData.purchase?.donations?.length) {
      const donation = sessionData.purchase.donations[0];
      return `${donation?.donorFirstName || ''} ${donation?.donorLastName || ''}`.trim();
    }
    return '';
  }
}

export function creditCardRequired(control: AbstractControl) {
  if (!control.value || Object.keys(control.value).length === 0) {
    return { creditCardRequired: 'Credit card is required.' };
  }

  return null;
}
