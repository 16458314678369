import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IframeService } from '../iframe.service';

@Component({
  selector: 'flow-payment-api-js',
  template: ''
})
export class PaymentApiJsComponent implements AfterViewInit, OnChanges {

  @Input() organizationID: string;
  @Input() merchantAccountID: string;
  @Output() paymentJsLoaded = new EventEmitter();

  private loaded = false;

  constructor(
    private iframeService: IframeService,
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.loadPaymentJs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.merchantAccountID) {
      this.loadPaymentJs();
    }
  }

  private loadPaymentJs() {
    if (!this.loaded && this.merchantAccountID) {
      const self = this;
      self.loaded = true;
      const script = document.createElement('script');
      script.type = 'text/javascript';

      const style = document.createElement('style');
      style.innerHTML = `
        .StripeElement {
          box-sizing: border-box;

          height: 40px;

          padding: 10px 12px;

          border: 1px solid transparent;
          border-radius: 4px;
          background-color: white;

          box-shadow: 0 1px 3px 0 #e6ebf1;
          -webkit-transition: box-shadow 150ms ease;
          transition: box-shadow 150ms ease;
        }

        .StripeElement--focus {
          box-shadow: 0 1px 3px 0 #cfd7df;
        }

        .StripeElement--invalid {
          border-color: #c84026;
        }
      `;

      script.onload = () => self.scriptLoaded();
      this.iframeService.getIframeURL(this.organizationID, this.merchantAccountID).subscribe(url => {
        script.src = url;
        this.elementRef.nativeElement.appendChild(script);
        this.elementRef.nativeElement.appendChild(style);
      });
    }
  }

  private scriptLoaded() {
    this.paymentJsLoaded.emit();
  }
}
