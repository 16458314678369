
export class CurrencyUtil {
  static getDollars(money: number): number {
    return Math.floor(money);
  }

  static getCents(money: number): number {
    return Math.round((money - this.getDollars(money)) * 100);
  }
}
