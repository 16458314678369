export class TicketType {
  ticketTypeID: string;
  organizationId: string;
  eventId: string;
  name: string;
  description: string;
  maxTickets: number;
  price: number;
  value: number;
  active: boolean;
  ticketsLeft: number;

  constructor(obj?: any) {
    if (obj == null) {
      obj = {};
    }
    const or = (def: any, val?: any) => val == null || typeof val !== typeof def ? def : val;

    this.ticketTypeID = or('', obj.ticketTypeID);
    this.organizationId = or('', obj.organizationId);
    this.eventId = or('', obj.eventId);
    this.name = or('', obj.name);
    this.description = or('', obj.description);
    this.maxTickets = or(0, obj.maxTickets);
    this.price = or(0, obj.price);
    this.value = or(0, obj.value);
    this.active = or(true, obj.active);
    this.ticketsLeft = or(0, obj.ticketsLeft);
  }
}

export class TicketTypeLimit {
  ticketTypeID: string;
  maxTickets: number;
  currentTicketCount: number;
}

export class TextTicketOption {
  textTicketOptionID: string;
  organizationID: string;
  eventID: string;
  prompt: string;
  answer: string;
  assignedTicketTypeIDs: string[];
  created: string;
  updated: string;

  constructor(obj: any) {
    if (obj == null) {
      obj = {};
    }
    const or = (def: any, val?: any) => val == null || typeof val !== typeof def ? def : val;

    this.textTicketOptionID = or('', obj.textTicketOptionID);
    this.organizationID = or('', obj.organizationID);
    this.eventID = or('', obj.eventID);
    this.prompt = or('', obj.prompt);
    this.answer = or('', obj.answer);
    this.created = or('', obj.created);
    this.updated = or('', obj.updated);

    this.assignedTicketTypeIDs = [];
    if (!(obj.assignedTicketTypeIDs instanceof Array)) {
      return;
    }

    for (const id of obj.assignedTicketTypeIDs) {
      this.assignedTicketTypeIDs.push(or('', id));
    }
  }
}

export class SingleSelectTicketOption {
  singleSelectTicketOptionID: string;
  organizationID: string;
  eventID: string;
  prompt: string;
  promptAnswers: SingleSelectTicketOptionPromptAnswer[];
  userSelectedPromptAnswerID: string;
  assignedTicketTypeIDs: string[];
  created: string;
  updated: string;

  constructor(obj: any) {
    if (obj == null) {
      obj = {};
    }
    const or = (def: any, val?: any) => val == null || typeof val !== typeof def ? def : val;

    this.singleSelectTicketOptionID = or('', obj.singleSelectTicketOptionID);
    this.organizationID = or('', obj.organizationID);
    this.eventID = or('', obj.eventID);
    this.prompt = or('', obj.prompt);
    this.promptAnswers = [];
    this.userSelectedPromptAnswerID = or('', obj.userSelectedPromptAnswerID);
    this.assignedTicketTypeIDs = [];
    this.created = or('', obj.created);
    this.updated = or('', obj.updated);

    if (obj.promptAnswers instanceof Array) {
      for (const answer of obj.promptAnswers) {
        this.promptAnswers.push(new SingleSelectTicketOptionPromptAnswer(answer));
      }
    }
    if (obj.assignedTicketTypeIDs instanceof Array) {
      for (const id of obj.assignedTicketTypeIDs) {
        this.assignedTicketTypeIDs.push(or('', id));
      }
    }
  }
}

export class SingleSelectTicketOptionPromptAnswer {
  singleSelectTicketOptionPromptAnswerID: string;
  singleSelectTicketOptionID: string;
  answerText: string;
  answerIndex: number;
  created: string;
  updated: string;
  constructor(obj?: any) {
    if (obj == null) {
      obj = {};
    }
    const or = (def: any, val?: any) => val == null || typeof val !== typeof def ? def : val;

    this.singleSelectTicketOptionPromptAnswerID = or('', obj.singleSelectTicketOptionPromptAnswerID);
    this.singleSelectTicketOptionID = or('', obj.singleSelectTicketOptionID);
    this.answerText = or('', obj.answerText);
    this.answerIndex = or('', obj.answerIndex);
    this.created = or('', obj.created);
    this.updated = or('', obj.updated);
  }
}

export class MultiSelectTicketOption {
  multiSelectTicketOptionID: string;
  organizationID: string;
  eventID: string;
  prompt: string;
  promptAnswers: MultiSelectTicketOptionPromptAnswer[];
  userSelectedPromptAnswerIDs: string[];
  assignedTicketTypeIDs: string[];
  created: string;
  updated: string;

  constructor(obj: any) {
    if (obj == null) {
      obj = {};
    }
    const or = (def: any, val?: any) => val == null || typeof val !== typeof def ? def : val;

    this.multiSelectTicketOptionID = or('', obj.multiSelectTicketOptionID);
    this.organizationID = or('', obj.organizationID);
    this.eventID = or('', obj.eventID);
    this.prompt = or('', obj.prompt);
    this.promptAnswers = [];
    this.userSelectedPromptAnswerIDs = [];
    this.assignedTicketTypeIDs = [];
    this.created = or('', obj.created);
    this.updated = or('', obj.updated);

    if (obj.promptAnswers instanceof Array) {
      for (const answer of obj.promptAnswers) {
        this.promptAnswers.push(new MultiSelectTicketOptionPromptAnswer(answer));
      }
    }
    if (obj.userSelectedPromptAnswerIDs instanceof Array) {
      for (const id of obj.userSelectedPromptAnswerIDs) {
        this.userSelectedPromptAnswerIDs.push(or('', id));
      }
    }
    if (obj.assignedTicketTypeIDs instanceof Array) {
      for (const id of obj.assignedTicketTypeIDs) {
        this.assignedTicketTypeIDs.push(or('', id));
      }
    }
  }
}

export class MultiSelectTicketOptionPromptAnswer {
  multiSelectTicketOptionPromptAnswerID: string;
  multiSelectTicketOptionID: string;
  answerText: string;
  answerIndex: number;
  created: string;
  updated: string;
  constructor(obj?: any) {
    if (obj == null) {
      obj = {};
    }
    const or = (def: any, val?: any) => val == null || typeof val !== typeof def ? def : val;

    this.multiSelectTicketOptionPromptAnswerID = or('', obj.multiSelectTicketOptionPromptAnswerID);
    this.multiSelectTicketOptionID = or('', obj.multiSelectTicketOptionID);
    this.answerText = or('', obj.answerText);
    this.answerIndex = or('', obj.answerIndex);
    this.created = or('', obj.created);
    this.updated = or('', obj.updated);
  }
}

export class TicketOptions {
  textTicketOptions: TextTicketOption[];
  singleSelectTicketOptions: SingleSelectTicketOption[];
  multiSelectTicketOptions: MultiSelectTicketOption[];

  constructor(obj?: any) {
    this.textTicketOptions = [];
    this.singleSelectTicketOptions = [];
    this.multiSelectTicketOptions = [];

    if (obj == null) {
      return;
    }

    if (obj.textTicketOptions instanceof Array) {
      for (const option of obj.textTicketOptions) {
        this.textTicketOptions.push(new TextTicketOption(option));
      }
    }
    if (obj.singleSelectTicketOptions instanceof Array) {
      for (const option of obj.singleSelectTicketOptions) {
        this.singleSelectTicketOptions.push(new SingleSelectTicketOption(option));
      }
    }
    if (obj.multiSelectTicketOptions instanceof Array) {
      for (const option of obj.multiSelectTicketOptions) {
        this.multiSelectTicketOptions.push(new MultiSelectTicketOption(option));
      }
    }
  }
}

export enum TicketOptionTypes {
  TEXT = 'text',
  SINGLE_SELECT = 'single-select',
  MULTI_SELECT = 'multi-select',
}

export class Ticket {
  ticketID: string;
  organizationID: string;
  eventID: string;
  purchaseID: string;
  name: string;
  locationName: string;
  ticketTypeID: string;
  ticketTypeName: string;
  attendeeEmail: string;
  attendeeMobilePhone: string;
  attendeeFirstName: string;
  attendeeLastName: string;
  purchaserEmail: string;
  purchaserFirstName: string;
  purchaserLastName: string;
  sponsorName: string;
  ticketStatusID: string;
  ticketStatusName: string;
  price: number;
  value: number;
  complimentary: boolean;
  admissionCode: string;
  checkInStatus: boolean;
  ticketOptions: TicketOptions;
  checkInTime: string;
  created: string;

  constructor(obj?: any) {
    if (obj == null) {
      obj = {};
    }
    const or = (def: any, val?: any) => val == null || typeof val !== typeof def ? def : val;

    this.ticketID = or('', obj.ticketID);
    this.organizationID = or('', obj.organizationID);
    this.eventID = or('', obj.eventID);
    this.attendeeEmail = or('', obj.attendeeEmail);
    this.attendeeMobilePhone = or('', obj.attendeeMobilePhone);
    this.attendeeFirstName = or('', obj.attendeeFirstName);
    this.attendeeLastName = or('', obj.attendeeLastName);
    this.sponsorName = or('', obj.sponsorName);
    this.checkInStatus = or(false, obj.checkInStatus);
    this.ticketOptions = new TicketOptions(obj.ticketOptions);
  }
}

export class TicketTypeGroup {
  ticketType: TicketType;
  tickets: Ticket[];

  constructor(obj?: any) {
    this.tickets = [];
    if (obj == null) {
      this.ticketType = new TicketType();
    }

    this.ticketType = new TicketType(obj.ticketType);

    if (!(obj.tickets instanceof Array)) {
      return;
    }

    for (const ticket of obj.tickets) {
      this.tickets.push(new Ticket(ticket));
    }
  }
}

export class AttendeeCountUpdate {
  ticketTypeID: string;
  addTicket: boolean;
}

export class EventEventAPIModel {
  eventID: string;
  organizationID: string;
  source: string;
  name: string;
  description: string;
  fundraiserID: string;
  eventDateDisplay: string;
  startTimeDisplay: string;
  endTimeDisplay: string;
  locationName: string;
  locationStreet: string;
  locationCity: string;
  locationState: string;
  locationZip: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  logoURL: string;
  logoUploadToken: string;
}
