import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { Flow } from '../models/flow.model';
import { PageWithComponentList } from '../models/page.model';

@Injectable({
  providedIn: 'root'
})
export class CurrentStateService {

  flow: Observable<Flow>;
  page: Observable<PageWithComponentList>;

  private flowSubject = new ReplaySubject<Flow>(1);
  private pageSubject = new ReplaySubject<PageWithComponentList>(1);
  private submittingPageSubject = new BehaviorSubject(false);

  constructor() {
    this.flow = this.flowSubject.asObservable();
    this.page = this.pageSubject.asObservable();
  }

  updateFlow(flow: Flow) {
    this.flowSubject.next(flow);
  }

  updatePage(page: PageWithComponentList) {
    this.pageSubject.next(page);
  }

  isSubmittingPage(): Observable<boolean> {
    return this.submittingPageSubject.asObservable();
  }

  updateSubmittingPageStatus(submitting: boolean) {
    this.submittingPageSubject.next(submitting);
  }
}
