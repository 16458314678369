import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { FlowComponent } from '../flow-components.model';

export class SponsorsConfig {
  sponsors: Sponsor[];
}

export class Sponsor {
  name: string;
  logoURL: string;
}

@Component({
  selector: 'flow-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SponsorsComponent implements FlowComponent<SponsorsConfig, void>, OnInit {

  @Input() config: SponsorsConfig;

  sponsorImages: string[];

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

  ngOnInit() {
    this.init();
  }

  private init() {
    const filteredSponsors = (this.config.sponsors || []).filter(sponsor => sponsor.logoURL);
    this.sponsorImages = filteredSponsors.map(sponsor => sponsor.logoURL);
  }
}
