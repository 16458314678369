import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '@onecause/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'flow-onecause-footer',
  templateUrl: './onecause-footer.component.html',
  styleUrls: ['./onecause-footer.component.scss']
})
export class OnecauseFooterComponent implements OnInit {

  logo: Observable<string>;
  year: string;

  constructor(private env: EnvironmentService) { }

  ngOnInit() {
    this.year = new Date().getFullYear().toFixed();
    this.logo = this.env.getURL('assets', 'onecause/one-cause-no-tagline.svg');
  }

}
