import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhaasResponse } from '@onecause/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GetExternalDataOutput, ExecuteStandaloneActionInput, ExecuteStandaloneActionOutput } from '../models/component.model';
import { FlowSessionService } from './flow-session.service';

@Injectable({
  providedIn: 'root'
})
export class FlowComponentService {

  constructor(
    private flowSession: FlowSessionService,
    private httpClient: HttpClient,
  ) { }

  getExternalData<T = any>(orgID: string, flowID: string, pageID: string, componentID: string): Observable<GetExternalDataOutput<T>> {
    const url = `env:phaas-flow-api/organizations/${orgID}/flows/${flowID}/pages/${pageID}/components/${componentID}/external-data`;
    return this.httpClient.get<PhaasResponse<GetExternalDataOutput<T>>>(url).pipe(map((res) => {
      return res.payload;
    }));
  }

  executeStandaloneAction<T = any>(orgID: string, flowID: string, pageID: string,
                                   componentID: string, input: ExecuteStandaloneActionInput): Observable<ExecuteStandaloneActionOutput<T>> {
    const url = `env:phaas-flow-api/organizations/${orgID}/flows/${flowID}/pages/${pageID}/components/${componentID}/execute-standalone-action`;
    return this.httpClient.post<PhaasResponse<ExecuteStandaloneActionOutput<T>>>(url, input).pipe(map((res) => {
      this.flowSession.sessionData = res.payload.sessionData;
      return res.payload;
    }));
  }
}
