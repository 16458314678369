import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { EnvironmentService, LoggerService } from '@onecause/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagRedirectGuard implements CanActivate {

  constructor(
    private env: EnvironmentService,
    private loggerService: LoggerService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // redirect happens in ticket manager routes, let through this guard
    if (state.url.indexOf('ticket-manager/admin') > -1) {
      this.loggerService.warn('ticketManagerAdmin allowed through');
      return of(true);
    }

    this.env.getServiceURL('my').subscribe((url) => {
      const mappedStateURL = this.mapStateURL(state.url);
      this.assignLocation(`${url}${mappedStateURL}`);
    });
    return of(false);
  }

  assignLocation(url: string): void {
    window.location.assign(url);
  }

  mapStateURL(url: string) {
    // these keys come from the virtual-event-routing module
    // values come from es-ui event-routing module
    // subject to change as es-ui gets developed
    const fragmentMap = new Map<string, string>([
      ['chat', 'chat'],
      ['checkout', 'home'],
      ['donate', 'give'],
      ['fixed-price', 'shop'],
      ['game', 'game'],
      ['home', 'home'],
      ['live-appeal', 'give'],
      ['live-auction', 'auctions'],
      ['live-auction-preview', 'auctions'],
      ['log-in', 'home'],
      ['pay', 'home'],
      ['pickup', 'home'],
      ['profile', 'menu'],
      ['raffle', 'shop'],
      ['register', 'home'],
      ['sign-up', 'home'],
      ['silent-auction', 'auctions'],
      ['story', 'home'],
      ['support-our-cause', 'give'],
      ['thank-you', 'home'],
    ]);

    const urlSegments = url
      .replace('virtual-events', 'events')
      .replace('in-person', '')
      .split('/');

    // ticket-manager has a complex routing structure that we want to maintain
    // the rest of these fragments are simple today
    if (!urlSegments.includes('ticket-manager')) {
      const lastFragment = urlSegments.pop();
      const lastFragmentParamSplitArr = lastFragment.split('?');

      // map fragments while maintaining query params
      lastFragmentParamSplitArr[0] = fragmentMap.get(lastFragmentParamSplitArr[0]) || lastFragmentParamSplitArr[0];

      urlSegments.push(lastFragmentParamSplitArr.join('?'));
    }
    return '/event' + urlSegments.join('/');
  }
}
