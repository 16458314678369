import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarConfig, SnackBarIcon } from '@onecause/core';
import { Observable, Subject } from 'rxjs';

import {
  MultiSelectTicketOption,
  SingleSelectTicketOption,
  TextTicketOption,
  TicketOptions,
  TicketOptionTypes,
  TicketTypeGroup,
} from '../../models/event-api.model';
import { FlowComponentService } from '../../services/flow-component.service';
import { FlowSessionService } from '../../services/flow-session.service';
import { ComponentIdentity, FlowComponent } from '../flow-components.model';

export class AttendeeDetailsExternalData {
  ticketOptions: TicketOptions;

  constructor(ticketOptions?: TicketOptions) {
    this.ticketOptions = ticketOptions;
  }
}

export class AttendeeDetailsConfig {
  eventID: string;

  constructor(eventID?: string) {
    this.eventID = eventID;
  }
}

export class AttendeeDetailsOutput {
  reservedTickets: TicketTypeGroup[];
}

@Component({
  selector: 'flow-attendee-details',
  templateUrl: './attendee-details.component.html',
  styleUrls: ['./attendee-details.component.scss']
})

export class AttendeeDetailsComponent implements OnInit, FlowComponent<AttendeeDetailsConfig> {

  @Input() config: AttendeeDetailsConfig;
  @Input() identity: ComponentIdentity;

  private submitPage = new Subject<void>();

  reservedTickets: TicketTypeGroup[];
  ticketOptions: TicketOptions;
  ticketsWithOptions: TicketTypeGroup[];

  constructor(
    private flowComponentService: FlowComponentService,
    private flowSession: FlowSessionService,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.grabReservedTicketsFromSessionData();
    this.getTicketOptions();
  }

  submit() {
    this.submitPage.next();
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): AttendeeDetailsOutput => {
    // reservedTickets have been updated with attendee details set in the attendee-form
    const attendeeDetailsOutput: AttendeeDetailsOutput = { reservedTickets: this.reservedTickets };
    return attendeeDetailsOutput;
  }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any) => {
    this.snackbar.openFromComponent(SnackBarComponent, new SnackBarConfig(error.message, SnackBarIcon.Error, false));
  }

  @Input()
  listenForSubmit = (): Observable<void> => {
    return this.submitPage.asObservable();
  }

  private grabReservedTicketsFromSessionData() {
    const sessionData = this.flowSession.sessionData as {
      ticketSelection: {
        reservedTickets: TicketTypeGroup[],
      }
    };

    if (!sessionData || !sessionData.ticketSelection || !sessionData.ticketSelection.reservedTickets) {
      return;
    }

    const sessionTickets = sessionData.ticketSelection.reservedTickets as TicketTypeGroup[];
    this.reservedTickets = sessionTickets;
  }

  getTicketOptions() {
    const orgID = this.identity.organizationID;
    const flowID = this.identity.flowID;
    const pageID = this.identity.pageID;
    const componentID = this.identity.componentID;
    this.flowComponentService.getExternalData<AttendeeDetailsExternalData>(orgID, flowID, pageID, componentID)
      .subscribe((output) => {
        this.ticketOptions = output.data.ticketOptions;
        this.addOptionsToTickets();
      }, null);
  }

  addOptionsToTickets() {
    this.addOptionToTicket(this.ticketOptions.textTicketOptions || [], TicketOptionTypes.TEXT);
    this.addOptionToTicket(this.ticketOptions.singleSelectTicketOptions || [], TicketOptionTypes.SINGLE_SELECT);
    this.addOptionToTicket(this.ticketOptions.multiSelectTicketOptions || [], TicketOptionTypes.MULTI_SELECT);
    this.ticketsWithOptions = this.reservedTickets;
  }

  addOptionToTicket(options: any[], type: string) {
    for (const option of options) {
      const filteredTickets = this.reservedTickets.filter(reservedTicket => {
        return option.assignedTicketTypeIDs.includes(reservedTicket.ticketType.ticketTypeID);
      });

      for (const reservedTicket of filteredTickets) {
        for (const ticket of reservedTicket.tickets) {
          let existingOptions = [];
          let idKey = '';
          switch (type) {
            case TicketOptionTypes.TEXT:
              existingOptions = ticket.ticketOptions.textTicketOptions;
              idKey = 'textTicketOptionID';
              break;
            case TicketOptionTypes.SINGLE_SELECT:
              existingOptions = ticket.ticketOptions.singleSelectTicketOptions;
              idKey = 'singleSelectTicketOptionID';
              break;
            case TicketOptionTypes.MULTI_SELECT:
              existingOptions = ticket.ticketOptions.multiSelectTicketOptions;
              idKey = 'multiSelectTicketOptionID';
              break;
          }

          if (!existingOptions.find(existingOption => existingOption[idKey] === option[idKey])) {
            existingOptions.push(this.deepCopy(option, type));
          }
        }
      }
    }
  }

  private deepCopy(option: any, type: string) {
    switch (type) {
      case TicketOptionTypes.TEXT:
        return {
          textTicketOptionID: option.textTicketOptionID,
          organizationID: option.organizationID,
          eventID: option.eventID,
          prompt: option.prompt,
          answer: option.answer,
          assignedTicketTypeIDs: option.assignedTicketTypeIDs,
          created: option.created,
          updated: option.updated,
        } as TextTicketOption;
      case TicketOptionTypes.SINGLE_SELECT:
        return {
          singleSelectTicketOptionID: option.singleSelectTicketOptionID,
          organizationID: option.organizationID,
          eventID: option.eventID,
          prompt: option.prompt,
          promptAnswers: option.promptAnswers,
          userSelectedPromptAnswerID: option.userSelectedPromptAnswerID,
          assignedTicketTypeIDs: option.assignedTicketTypeIDs,
          created: option.created,
          updated: option.updated,
        } as SingleSelectTicketOption;
      case TicketOptionTypes.MULTI_SELECT:
        return {
          multiSelectTicketOptionID: option.multiSelectTicketOptionID,
          organizationID: option.organizationID,
          eventID: option.eventID,
          prompt: option.prompt,
          promptAnswers: option.promptAnswers,
          userSelectedPromptAnswerIDs: option.userSelectedPromptAnswerIDs,
          assignedTicketTypeIDs: option.assignedTicketTypeIDs,
          created: option.created,
          updated: option.updated,
        } as MultiSelectTicketOption;
    }
  }
}
