import { Component } from '@angular/core';

import { PageWithComponentList } from '../../../models/page.model';
import { ElementService } from '../../../services/element.service';
import { PageExecutionService } from '../../../services/page-execution.service';
import { PrimaryColorService } from '../../../services/primary-color.service';
import { CurrentStateService } from '../../../services/services-exports';
import { PageOrchDirective } from '../page-orch';


@Component({
  selector: 'flow-vertical-page-orchestration',
  templateUrl: './vertical-page-orchestration.component.html',
  styleUrls: ['./vertical-page-orchestration.component.scss']
})
export class VerticalPageOrchestrationComponent extends PageOrchDirective {

  constructor(
    elementService: ElementService,
    pageExecutionService: PageExecutionService,
    primaryColorService: PrimaryColorService,
    currentState: CurrentStateService,
  ) {
    super(elementService, pageExecutionService, primaryColorService, currentState);
  }

  protected init() {
    // nothing to do...
  }

  protected loadPageContent(page: PageWithComponentList) {
    page.components.sort((a, b) => (a.position.placement > b.position.placement ? 1 : -1));
    page.components.forEach((component) => {
      this.loadComponent(component, 'component-container__components');
    });
  }
}
