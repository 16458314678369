import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

import { ContactInfoComponent } from './contact-info.component';
import { MatFormFieldModule } from '@angular/material/form-field';

export * from './contact-info.component';

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
    ],
    declarations: [
        ContactInfoComponent
    ]
})
export class ContactInfoModule { }
