import { Injectable } from '@angular/core';
import { PhaasStyling } from '@onecause/core';

@Injectable({
    providedIn: 'root'
})
export class PrimaryColorService {

    constructor() { }

    loadPrimaryColorStylesheet(primaryColor: string): void {
        const rgb = PhaasStyling.hexToRgb(primaryColor);

        const style = document.createElement('style');
        // tslint:disable-next-line: deprecation
        style.type = 'text/css';

        const setPrimaryColor = `.primary-color {color: ${primaryColor};}`;
        style.appendChild(document.createTextNode(setPrimaryColor));

        const setPrimaryColorBackground = `.primary-color-background {background: ${primaryColor};}`;
        style.appendChild(document.createTextNode(setPrimaryColorBackground));

        const setPrimaryColorTransparentBackground = `.primary-color-transparent-background {background-color: rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, .12)}`;
        style.appendChild(document.createTextNode(setPrimaryColorTransparentBackground));

        const setPrimaryColorBorder = `.primary-color-border {border-color: ${primaryColor};}`;
        style.appendChild(document.createTextNode(setPrimaryColorBorder));

        const setPrimaryColorBottomBorder = `.primary-color-bottom-border {
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12),
            0 4px 4px 0 rgba(0, 0, 0, 0.24),
            inset 0 -4px 0 0 ${primaryColor};
        }`;
        style.appendChild(document.createTextNode(setPrimaryColorBottomBorder));

        const setPrimaryColorLeftBorder = `.primary-color-left-border {
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12),
            0 4px 4px 0 rgba(0, 0, 0, 0.24),
            inset 8px 0 0 0 ${primaryColor};
        }`;
        style.appendChild(document.createTextNode(setPrimaryColorLeftBorder));

        const matProgressPrimaryColor = `
            .primary-color-mat-progress .mat-progress-bar-fill::after { background-color: ${primaryColor} }
            .primary-color-mat-progress .mat-progress-bar-buffer { background-color: rgb(${rgb.r}, ${rgb.g}, ${rgb.b}, .25) }
            .primary-color-mat-progress .mat-progress-bar-background { fill: white; background-color: white; }
        `;
        style.appendChild(document.createTextNode(matProgressPrimaryColor));

        const contrastColor = PhaasStyling.getContrastingTextColor(primaryColor);
        const setContrastingTextColor = `.contrasting-text-color {color: ${contrastColor};}`;
        style.appendChild(document.createTextNode(setContrastingTextColor));

        const matTabsPrimaryColor = `
            .primary-color-tabs .mat-tab-group .mat-ink-bar { background-color: ${primaryColor} }
            .primary-color-tabs .mat-tab-label.mat-tab-label-active { opacity: 1 }
            .primary-color-tabs .mat-tab-label-active .mat-tab-label-content { color: ${primaryColor} }
        `;
        style.appendChild(document.createTextNode(matTabsPrimaryColor));

        const matCheckboxPrimaryColor = `
            .primary-color-checkbox.mat-checkbox.mat-accent .mat-checkbox-ripple .mat-ripple-element,
            .primary-color-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element { background: ${primaryColor}}
            .primary-color-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background,
            .primary-color-checkbox.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {background-color: ${primaryColor}}
        `;
        style.appendChild(document.createTextNode(matCheckboxPrimaryColor));

        const matRadioPrimaryColor = `
            .primary-color-radio.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle  {border-color: ${primaryColor}}
            .primary-color-radio.mat-radio-button.mat-accent .mat-radio-inner-circle {color: ${primaryColor}; background-color: ${primaryColor}}
            .primary-color-radio.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {background-color: ${primaryColor}}
        `;
        style.appendChild(document.createTextNode(matRadioPrimaryColor));

        const head = document.getElementsByTagName('head')[0];
        head.appendChild(style);
    }
}
