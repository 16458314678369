import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlowComponentService } from '../../services/flow-component.service';
import { PreviewService } from '../../services/preview.service';
import { ComponentIdentity, FlowComponent } from '../flow-components.model';
import { Donor } from './recent-donors.model';
import { CurrentStateService } from '../../services/current-state.service';
import { FlowType } from '../../models/flow.model';

export class RecentDonorsExternalData {
  donors: Donor[];

  constructor(donors?: Donor[]) {
    this.donors = donors;
  }
}

export class RecentDonorsConfig {
  fundraiserID: string;
  showDonors: boolean;
  hideWhenNoDonors: boolean;

  constructor(fundraiserID?: string, showDonors?: boolean, hideWhenNoDonors?: boolean) {
    this.fundraiserID = fundraiserID;
    this.showDonors = showDonors;
    this.hideWhenNoDonors = hideWhenNoDonors;
  }
}

@Component({
  selector: 'flow-recent-donors',
  templateUrl: './recent-donors.component.html',
  styleUrls: ['./recent-donors.component.scss']
})
export class RecentDonorsComponent implements OnInit, OnDestroy, FlowComponent<RecentDonorsConfig> {

  @Input() config: RecentDonorsConfig;
  @Input() identity: ComponentIdentity;
  @Input() mode: PreviewService;

  donors: Donor[];
  theme: string;
  type: FlowType;

  private destroyTriggered = new Subject<void>();

  constructor(
    private flowComponentService: FlowComponentService,
    private previewService: PreviewService,
    private currentState: CurrentStateService,
  ) { }

  ngOnInit() {
    this.currentState.flow.pipe(takeUntil(this.destroyTriggered)).subscribe((flow) => {
      this.theme = flow.theme;
      this.type = flow.type;
    });

    if (this.previewService.isPreviewMode()) {
      this.loadPreviewNames();
    } else {
      this.getDonorNames();
    }
  }

  ngOnDestroy() {
    this.destroyTriggered.next();
  }

  @Input()
  isValid = (): boolean => {
    return true;
  }

  @Input()
  getValue = (): void => { }

  @Input()
  markAsTouched = (): void => { }

  @Input()
  handleExecutionError = (error: any): void => { }

  @Input()
  listenForSubmit = (): void => { }

  getDonorNames() {
    timer(0, 3000).pipe(takeUntil(this.destroyTriggered)).subscribe(() => {
      const orgID = this.identity.organizationID;
      const flowID = this.identity.flowID;
      const pageID = this.identity.pageID;
      const componentID = this.identity.componentID;
      this.flowComponentService.getExternalData<RecentDonorsExternalData>(orgID, flowID, pageID, componentID)
        .subscribe((output) => {
          this.donors = output.data.donors;
        });
    });
  }

  loadPreviewNames() {
    const previewDonorName1 = new Donor();
    const previewDonorName2 = new Donor();
    const previewDonorName3 = new Donor();

    previewDonorName1.name = 'Donor Danny';
    previewDonorName2.name = 'Supporter Sally';
    previewDonorName3.name = 'Giving Gavin';

    const previewDonorNames = [previewDonorName1, previewDonorName2, previewDonorName3];
    this.donors = previewDonorNames;
  }

  shouldShow() {
    if (!this.config.showDonors ||
      this.config.hideWhenNoDonors && (!this.donors || !this.donors.length)) {
      return false;
    }

    return true;
  }
}
