import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import {
  Ticket,
  TicketOptionTypes,
} from '../../../models/event-api.model';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

export class AnyOption {
  id: string;
  prompt: string;
  created: string;
  type: string;
  promptAnswers: any[];
  constructor(id: string, prompt: string, created: string, type: string, promptAnswers: any[]) {
    this.id = id;
    this.prompt = prompt;
    this.created = created;
    this.type = type;
    this.promptAnswers = promptAnswers;
  }
}

@Component({
  selector: 'flow-attendee-form',
  templateUrl: './attendee-form.component.html',
  styleUrls: ['./attendee-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AttendeeFormComponent implements OnInit {
  @Input() ticket: Ticket;
  @Output() submit = new EventEmitter();

  attendeeForm: FormGroup;
  formInitialized: boolean;
  sortedOptions: AnyOption[] = [];

  TicketOptionTypes: typeof TicketOptionTypes = TicketOptionTypes;

  constructor(private formBuilder: FormBuilder) {
    this.attendeeForm = this.formBuilder.group({
      firstName: this.formBuilder.control(''),
      lastName: this.formBuilder.control(''),
    });
  }

  ngOnInit() {
    this.formInitialized = false;
    this.sortTicketOptions();
    this.addOptionsToForm();
  }

  submitForm() {
    this.submit.emit();
  }

  sortTicketOptions() {
    if (!this.ticket || !this.ticket.ticketOptions) {
      return;
    }

    this.addOptionToSort(this.ticket.ticketOptions.textTicketOptions || [], TicketOptionTypes.TEXT);
    this.addOptionToSort(this.ticket.ticketOptions.singleSelectTicketOptions || [], TicketOptionTypes.SINGLE_SELECT);
    this.addOptionToSort(this.ticket.ticketOptions.multiSelectTicketOptions || [], TicketOptionTypes.MULTI_SELECT);
    this.sortedOptions.sort((a, b) => (a.created > b.created ? 1 : -1));
  }

  private addOptionToSort(options: any[], type: string) {
    for (const option of options) {
      let id = '';

      switch (type) {
        case TicketOptionTypes.TEXT:
          id = option.textTicketOptionID;
          break;
        case TicketOptionTypes.SINGLE_SELECT:
          id = option.singleSelectTicketOptionID;
          break;
        case TicketOptionTypes.MULTI_SELECT:
          id = option.multiSelectTicketOptionID;
          break;
      }

      this.sortedOptions.push(new AnyOption(
        id,
        option.prompt,
        option.created,
        type,
        option.promptAnswers,
      ));
    }
  }

  addOptionsToForm() {
    for (const option of this.sortedOptions) {
      if (option.type === TicketOptionTypes.MULTI_SELECT) {
        for (const prompt of option.promptAnswers) {
          this.attendeeForm.addControl(prompt.multiSelectTicketOptionPromptAnswerID, new FormControl(''));
        }
      } else {
        this.attendeeForm.addControl(option.id, new FormControl(''));
      }
    }

    this.attendeeForm.valueChanges.subscribe((val) => {
      this.onFormChanges(val);
    });

    this.formInitialized = true;
  }

  private onFormChanges(values: any) {
    this.ticket.attendeeFirstName = values.firstName;
    this.ticket.attendeeLastName = values.lastName;

    for (const option of this.sortedOptions) {
      const optionID = option.id;
      const answer = values[option.id];

      switch (option.type) {
        case TicketOptionTypes.TEXT:
          const selectedTextOption = this.ticket.ticketOptions.textTicketOptions.find(
            textOption => textOption.textTicketOptionID === optionID
          );
          selectedTextOption.answer = answer;
          break;
        case TicketOptionTypes.SINGLE_SELECT:
          const selectedSingleOption = this.ticket.ticketOptions.singleSelectTicketOptions.find(
            singleSelectOption => singleSelectOption.singleSelectTicketOptionID === optionID
          );
          selectedSingleOption.userSelectedPromptAnswerID = answer;
          break;
        case TicketOptionTypes.MULTI_SELECT:
          const selectedAnswers = [];
          for (const promptAnswer of option.promptAnswers) {
            const promptAnswerID = promptAnswer.multiSelectTicketOptionPromptAnswerID;
            if (values[promptAnswerID] === true) {
              selectedAnswers.push(promptAnswerID);
            }
          }
          const selectedMultiOption = this.ticket.ticketOptions.multiSelectTicketOptions.find(
            multiSelectOption => multiSelectOption.multiSelectTicketOptionID === optionID
          );
          selectedMultiOption.userSelectedPromptAnswerIDs = selectedAnswers;
          break;
      }
    }
  }
}
