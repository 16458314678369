import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderSummaryComponent } from './order-summary.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        OrderSummaryComponent
    ]
})
export class OrderSummaryModule { }
