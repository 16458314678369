import { Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router
  ) {
    // make sure the parent always knows that main url changed
    this.signalLocationChanged();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.signalLocationChanged();
      }
    });
  }

  signalLocationChanged() {
    const newLoc = window.location.href;
    console.log('signaling location-changed: ', newLoc);
    window.parent.postMessage({
      type: 'onecause:location-changed',
      data: {
        newLocation: newLoc
      }
    }, '*');
  }
}
