import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentStateService, Flow, PageExecutionService } from 'lib';
import { CurrentPageNumberService } from 'src/app/services/current-page-number.service';

@Component({
  selector: 'app-slideshow-orch',
  templateUrl: './slideshow-orch.component.html',
  styleUrls: ['./slideshow-orch.component.scss']
})
export class SlideshowOrchComponent implements OnInit {

  currentFlow: Flow;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private currentPageNumberService: CurrentPageNumberService,
    private pageExecutionService: PageExecutionService,
    private currentState: CurrentStateService
  ) { }

  ngOnInit() {
    this.currentState.flow.subscribe((flow) => {
      this.currentFlow = flow;
      if (isNaN(this.currentPageNumberService.getCurrentPageNumber())) {
        this.goToPage(1);
      }
      this.listenForPageSubmission();
    });
  }

  private listenForPageSubmission() {
    this.pageExecutionService.listenForPageSubmission().subscribe(() => {
      this.nextPage();
    });
  }

  private nextPage() {
    if (this.currentFlow.definition.pages.length > 1) {
      let nextPageNumber = this.currentPageNumberService.getCurrentPageNumber() + 1;
      if (this.currentPageNumberService.getCurrentPageNumber() >= this.currentFlow.definition.pages.length) {
        nextPageNumber = 1;
      }
      this.goToPage(nextPageNumber);
    }
  }

  private goToPage(pageNumber: number): Promise<boolean> {
    return this.router.navigate(['.', 'page', pageNumber], { relativeTo: this.activatedRoute });
  }
}
