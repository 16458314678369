import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export enum PreviewSavedStatus {
  AllChangesSaved = 'all-changes-saved',
  LastSavedAt = 'last-saved-at',
  SavingChanges = 'saving-changes',
}

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  private previewMode = false;
  private selectedPageSubject = new ReplaySubject<string>(1);
  private selectedComponentSubject = new ReplaySubject<string>(1);
  private savedStatusSubject = new ReplaySubject<{ status: PreviewSavedStatus, savedAt?: Date }>(1);

  constructor() { }

  setPreviewMode(mode: boolean) {
    this.previewMode = mode;
  }

  isPreviewMode(): boolean {
    return this.previewMode;
  }

  selectPage(pageID: string) {
    this.selectedPageSubject.next(pageID);
  }

  getSelectedPageID(): Observable<string> {
    return this.selectedPageSubject.asObservable();
  }

  selectComponent(pageID: string) {
    this.selectedComponentSubject.next(pageID);
  }

  getSelectedComponentID(): Observable<string> {
    return this.selectedComponentSubject.asObservable();
  }

  setSavedStatus(status: PreviewSavedStatus, savedAt?: Date) {
    this.savedStatusSubject.next({ status: status, savedAt: savedAt });
  }

  getSavedStatus(): Observable<{status: PreviewSavedStatus, savedAt?: Date}> {
    return this.savedStatusSubject.asObservable();
  }
}
