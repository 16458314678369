import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { PageOutput } from '../layout-components/page-orchs/page-orch';
import { PageExecutionError } from '../models/flow-execution.model';

@Injectable({
  providedIn: 'root'
})
export class PageExecutionService {

  private pageSubmissionSubject = new Subject<PageOutput>();
  private pageExecutionErrorSubject = new Subject<PageExecutionError>();

  constructor() { }

  listenForPageSubmission(): Observable<PageOutput> {
    return this.pageSubmissionSubject.asObservable();
  }

  submitPage(pageOutput: PageOutput) {
    this.pageSubmissionSubject.next(pageOutput);
  }

  listenForPageExecutionError(): Observable<PageExecutionError> {
    return this.pageExecutionErrorSubject.asObservable();
  }

  throwPageExecutionError(error: PageExecutionError) {
    this.pageExecutionErrorSubject.next(error);
  }
}
