import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GivingRestService } from '../giving-rest.service';


declare function initializePaymentJsV2(successCallback, errorCallback, options?);

export class CardInfo {
    name: string;
    nonce: string;
    cardType: string;
    expiration: string;
    lastFourDigits: string;
}

export class GetCardInfoInput {
    organizationID: string;
    merchantAccountID: string;
    creditCardCryptogram: string;
}

export class GetCardInfoOutput {
    token: string;
    cardType: string;
    cardLastFour: string;
    cardExpiration: string;
    cardName: string;
}

@Component({
    selector: 'flow-creditcard',
    templateUrl: './creditcard.component.html',
    styleUrls: ['./creditcard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CreditCardComponent),
            multi: true
        }
    ]
})
export class CreditCardComponent implements ControlValueAccessor {
    @Input() iFrameID;
    @Input() organizationID: string;
    @Input() merchantAccountID: string;
    @Output() tokenized: EventEmitter<CardInfo> = new EventEmitter();

    private onChange: (val: CardInfo) => void = (val: CardInfo) => { };
    private onTouched: () => void = () => { };

    constructor(private givingRestService: GivingRestService) { }

    writeValue(value: CardInfo) {
        // do nothing - required for ControlValueAccessor but no value can be provided
    }

    registerOnChange(fn: (val: CardInfo) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    paymentJsLoaded() {
        const options = {
            iFrameID: this.iFrameID,
        };
        initializePaymentJsV2(
            (response) => this.handleIframeResponse(response), // Success callback
            () => this.handleIframeResponse(null), // Error callback
            options
        );
    }

    private handleIframeResponse(response: any) {
        this.onTouched();
        if (response) { // Success callback handler
            const input: GetCardInfoInput = {
                organizationID: this.organizationID,
                merchantAccountID: this.merchantAccountID,
                creditCardCryptogram: response,
            };
            this.givingRestService.getCardInfo(input).subscribe(
                (cardInfoOutput: GetCardInfoOutput) => {
                    const cardInfo: CardInfo = {
                        name: cardInfoOutput.cardName,
                        nonce: cardInfoOutput.token,
                        cardType: cardInfoOutput.cardType,
                        expiration: cardInfoOutput.cardExpiration,
                        lastFourDigits: cardInfoOutput.cardLastFour,
                    };
                    this.tokenized.next(cardInfo);
                    this.onChange(cardInfo);
                });
        } else { // Error callback handler
            this.tokenized.next(null);
        }
    }
}
