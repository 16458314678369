import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AttendeeDetailsModule } from './attendee-details/attendee-details.module';
import { ContactInfoModule } from './contact-info/contact-info.module';
import { CustomHtmlModule } from './custom-html/custom-html.module';
import { CustomTextModule } from './custom-text/custom-text.module';
import { DonationAmountModule } from './donation-amount/donation-amount.module';
import { DonorInfoModule } from './donor-info/donor-info.module';
import { EventDescriptionRegistrationModule } from './event-description-registration/event-description-registration.module';
import { EventDetailsModule } from './event-details/event-details.module';
import { EventStoryModule } from './event-story/event-story.module';
import { ForTestingModule } from './for-testing/for-testing.module';
import { HeadlineModule } from './headline/headline.module';
import { IframeModule } from './iframe/iframe.module';
import { ImageModule } from './image/image.module';
import { LogoWithTextModule } from './logo-with-text/logo-with-text.module';
import { NextPageButtonModule } from './next-page-button/next-page-button.module';
import { OrderSummaryModule } from './order-summary/order-summary.module';
import { OrgLogoModule } from './org-logo/org-logo.module';
import { ParagraphModule } from './paragraph/paragraph.module';
import { PaymentButtonModule } from './payment-button/payment-button.module';
import { PaymentModule } from './payment/payment.module';
import { ProgressModule } from './progress/progress.module';
import { ReceiptRecipientModule } from './receipt-recipient/receipt-recipient.module';
import { RecentDonorsModule } from './recent-donors/recent-donors.module';
import { RedirectModule } from './redirect/redirect.module';
import { SponsorsModule } from './sponsors/sponsors.module';
import { TicketSelectionModule } from './ticket-selection/ticket-selection.module';
import { TimerModule } from './timer/timer.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ForTestingModule,
    HeadlineModule,
    ImageModule,
    DonorInfoModule,
    DonationAmountModule,
    PaymentModule,
    ReceiptRecipientModule,
    ParagraphModule,
    NextPageButtonModule,
    PaymentButtonModule,
    ProgressModule,
    RecentDonorsModule,
    TimerModule,
    CustomTextModule,
    CustomHtmlModule,
    OrgLogoModule,
    RecentDonorsModule,
    TicketSelectionModule,
    EventDetailsModule,
    AttendeeDetailsModule,
    OrderSummaryModule,
    ContactInfoModule,
    EventDescriptionRegistrationModule,
    IframeModule,
    EventStoryModule,
    RedirectModule,
    SponsorsModule,
    LogoWithTextModule,
  ],
})
export class FlowComponentsModule { }
